import moment from 'moment'

const calcTicketDate = () => {
	const today = moment()

	// ○月20日を取得
	const twentiethDate = moment().startOf('month').add(19, 'd')

	// ○月20日00:00までは今月のチケットを、それ以降はあ来月分のチケット購入
	if (today < twentiethDate) {
		return moment()
	} else {
		return moment().add(1, 'M')
	}
}

export const availableTicketDate = () => {
	const ticketDate = calcTicketDate()
	return moment(ticketDate).format('YYYY年M月')
}
export const ticketValidatedDateStart = () => {
	const ticketDate = calcTicketDate()
	return moment(ticketDate).subtract(1, 'M').endOf('month').subtract(1, 'd').startOf('day').format('M月DD日HH:mm')
}
export const ticketValidatedDateEnd = () => {
	const ticketDate = calcTicketDate()
	return moment(ticketDate).endOf('month').subtract(2, 'd').format('M月DD日HH:mm')
}
