<template>
	<validationProvider
		:rules="{
			required: true,
			regex: /^[a-zA-Z0-9!-/:-@¥[-`{-~]*$/,
			email: true
		}"
		:name="$t('profile.email')"
		v-slot="{ errors, valid }"
	>
		<p class="no-margin-text">
			<FormTitleText :titleText="$t('profile.email')" />
			<RequiredIcon v-if="rules && rules.includes('required')" />
			<FormAttentionText :attentionText="$t('profile.onlyHalfWidthAndNum')" />
		</p>
		<v-text-field
			label="email"
			v-model="inputedEmail"
			outlined
			background-color="white"
			@change="editEmail"
			:success="valid"
			hide-details
		></v-text-field>
		<FormErrorText :errorText="errors[0]" />
	</validationProvider>
</template>

<script>
import FormTitleText from '../Atoms/FormTitleText'
import FormErrorText from '../Atoms/FormErrorText'
import FormAttentionText from '../Atoms/FormAttentionText'
import RequiredIcon from '../Atoms/RequiredIcon'

export default {
	name: 'EmailForm',
	data: () => ({
		isEditedEmail: false
	}),
	props: ['value', 'rules'],
	mounted() {},
	computed: {
		inputedEmail: {
			get() {
				return this.value
			},
			set(value) {
				this.$emit('input', value)
			}
		}
	},
	methods: {
		editEmail() {
			this.isEditedEmail = true
		}
	},
	components: {
		FormTitleText,
		FormErrorText,
		FormAttentionText,
		RequiredIcon
	}
}
</script>

<style lang="scss" scoped></style>
