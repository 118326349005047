import axios from 'axios'

export const lessonRequest = {
	namespaced: true,
	state: {
		lessonRequests: null,
		wipRequests: null,
		loading: false
	},
	getters: {
		loading: (state) => {
			return state.loading
		},
		lessonRequests: (state) => {
			return state.lessonRequests
		},
		wipRequests: (state) => {
			return state.wipRequests
		},
		wipCounts: (state) => {
			return state.wipRequests ? state.wipRequests.length : 0
		}
	},
	mutations: {
		prepareConnect(state) {
			state.loading = true
		},
		stopLoading(state) {
			state.loading = false
		},
		setLessonRequests(state, payload) {
			state.lessonRequests = payload
			state.loading = false
		},
		setWipLessonRequests(state, payload) {
			state.wipRequests = payload
			state.loading = false
		}
	},
	actions: {
		fetchLessonRequest({ commit }, payload) {
			commit('prepareConnect')
			const header = {
				headers: {
					Authorization: `Bearer ${payload.accessToken}`
				}
			}
			return axios
				.get(`/api/lessonRequests?teacherId=${payload.teacherId}`, header)
				.then((res) => {
					commit('setLessonRequests', res.data.lessonRequests)
				})
				.catch((err) => {
					commit('stopLoading')
					console.log(err)
				})
		},
		fetchWipLessonRequest({ commit }, payload) {
			commit('prepareConnect')
			const header = {
				headers: {
					Authorization: `Bearer ${payload.accessToken}`
				}
			}
			return axios
				.get(`/api/lessonRequests/wip?teacherId=${payload.teacherId}`, header)
				.then((res) => {
					commit('setWipLessonRequests', res.data.wipRequests)
				})
				.catch((err) => {
					commit('stopLoading')
					console.log(err)
				})
		},
		completeLessonRequest({ commit }, payload) {
			commit('prepareConnect')
			const header = {
				headers: {
					Authorization: `Bearer ${payload.accessToken}`
				}
			}
			const requestBody = {
				id: payload.requestId
			}
			return axios
				.post(`/api/lessonRequests/complete`, requestBody, header)
				.then(() => {
					commit('stopLoading')
					return true
				})
				.catch((err) => {
					commit('stopLoading')
					console.log(err)
				})
		}
	}
}
