<template>
	<validationProvider :rules="rules" v-slot="{ errors }" name="希望コースの選択">
		<p>
			<FormTitleText titleText="希望コース" />
			<RequiredIcon v-if="rules && rules.includes('required')" />
			<FormAttentionText attentionText="※複数選択可" />
		</p>
		<v-row :style="{ margin: '0 0 15px 0' }">
			<v-col cols="12" v-for="lessonCourseItem of lessonCourseItems" :key="lessonCourseItem" :style="{ padding: '0' }">
				<v-checkbox
					v-model="inputedHopeLessonCourse"
					:label="lessonCourseItem"
					:value="lessonCourseItem"
					color="#DCC268"
					hide-details
				>
					<!-- チェックボックスのラベルの大きさを変える方法 -->
					<template v-slot:label>
						<span style="font-size: 1em">{{ lessonCourseItem }}</span>
					</template>
				</v-checkbox>
			</v-col>
		</v-row>
		<FormErrorText :errorText="errors[0]" />
	</validationProvider>
</template>

<script>
import FormTitleText from '../Atoms/FormTitleText'
import FormErrorText from '../Atoms/FormErrorText'
import FormAttentionText from '../Atoms/FormAttentionText'
import RequiredIcon from '../Atoms/RequiredIcon'

export default {
	name: 'HopeLessonCourse',
	data: () => ({
		lessonCourseItems: ['プライベートコース', '会話コース']
	}),
	props: ['value', 'rules'],
	mounted() {},
	computed: {
		inputedHopeLessonCourse: {
			get() {
				return this.value.split(',')
			},
			set(value) {
				let combinedHopeLessonCourse = ''
				//「希望コース」の配列を送信できる形に整える
				for (let selectedHopeInLesson of value) {
					if (!selectedHopeInLesson || selectedHopeInLesson === 'null') {
						continue
					}

					if (combinedHopeLessonCourse.length === 0) {
						combinedHopeLessonCourse = selectedHopeInLesson
					} else {
						combinedHopeLessonCourse = combinedHopeLessonCourse + ',' + selectedHopeInLesson
					}
				}

				this.$emit('input', combinedHopeLessonCourse)
			}
		}
	},
	methods: {},
	components: {
		FormTitleText,
		FormErrorText,
		FormAttentionText,
		RequiredIcon
	}
}
</script>

<style lang="scss" scoped></style>
