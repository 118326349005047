<template>
	<v-container>
		<PageTitle :title-name="$t('teacherSchedule.teacherSchedule')" />
		<v-row justify="center" class="change-lang-area">
			<a @click="changeLocale('ja')"><p class="change-lang">日本語</p></a>
			/
			<a @click="changeLocale('de')"><p class="change-lang">Deutsch</p></a>
		</v-row>
		<SwitchTimeZone v-if="isShowTimeZone" @reload="reloadAll()" />
		<component
			:is="teacherInfoComponent"
			:teacherInfo="fetchedTeacherInfo"
			:teacherName="teacherName"
			:teacherImageUrl="teacherImageUrl"
			:skypeId="skypeId"
		></component>
		<TeacherScheduleCalendar v-if="showCalendar" class="calendar-area" />
		<v-row v-if="isPrivate">
			<v-col cols="12" xs="12" sm="8" md="9" lg="9" xl="9">
				<p class="primary--text" :style="{ fontSize: calcFontSize * 1.2 + 'em', fontWeight: 'bold' }">
					{{ $t('teacherSchedule.regularLessonStatus') }}
				</p>
			</v-col>
		</v-row>
		<v-row>
			<TeacherScheduleRegularTable v-if="isShowTable && isPrivate" @reloadCalendar="reloadCalendar()" />
		</v-row>
	</v-container>
</template>

<script>
import PageTitle from '../Atoms/PageTitle'
import TeacherScheduleTeacherInfo from '../Organisms/TeacherScheduleTeacherInfo'
import TeacherScheduleCalendar from '../Organisms/TeacherScheduleCalendar'
import TeacherScheduleRegularTable from '../Organisms/TeacherScheduleRegularTable'
import SwitchTimeZone from '../Atoms/SwitchTimeZone'
import { mapGetters } from 'vuex'

export default {
	name: 'RegisterTeacherSchedule',
	data: () => ({
		teacherInfoComponent: null,
		teacherName: '-',
		teacherImageUrl: require('../../assets/no_teacher.png'),
		skypeId: '',
		loading: true,
		showCalendar: true,
		isShowTable: true,
		isShowTimeZone: true,
		fetchedTeacherInfo: null
	}),
	components: {
		PageTitle,
		TeacherScheduleTeacherInfo,
		TeacherScheduleCalendar,
		TeacherScheduleRegularTable,
		SwitchTimeZone
	},
	computed: {
		...mapGetters({
			userInfo: 'user/getUserInfo'
		}),
		calcFontSize() {
			switch (this.$vuetify.breakpoint.name) {
				case 'xs':
					return 1.2
				case 'sm':
					return 1.2
				case 'md':
					return 1.5
				case 'lg':
					return 1.3
				default:
					return 1.2
			}
		},
		isPrivate() {
			if (!this.userInfo.lessonCourseArray) {
				return false
			}

			if (this.userInfo.lessonCourseArray.includes('PRIVATE')) {
				return true
			} else {
				return false
			}
		},
		judgeMatchDayOfWeek: function () {
			return function (schedule, dayOfWeek) {
				const scheduleDayOfWeek = Number(schedule.startTime.format('DD') - 1)

				if (scheduleDayOfWeek > 29) {
					if (Number(dayOfWeek) === 6) {
						return true
					}
				} else if (scheduleDayOfWeek > 6) {
					if (Number(dayOfWeek) === 0) {
						return true
					}
				} else {
					return scheduleDayOfWeek === dayOfWeek
				}
			}
		}
	},
	async created() {
		this.init()
	},
	mounted() {},
	methods: {
		async init() {
			this.fetchTeacherInfo()
		},
		fetchTeacherInfo() {
			const header = {
				headers: {
					Authorization: `Bearer ${this.userInfo.accessToken}`
				}
			}
			this.axios
				.get(`/api/teachers/${this.userInfo.teacherId}`, header)
				.then((response) => {
					if (response.data) {
						this.fetchedTeacherInfo = response.data.teacher
						this.teacherName = response.data.teacher.teacherName
						if (response.data.teacher.imageUrl) {
							this.teacherImageUrl = response.data.teacher.imageUrl
						}
						this.teacherInfoComponent = TeacherScheduleTeacherInfo
						this.skypeId = response.data.teacher.skypeId
					}
				})
				.catch((error) => {
					console.log(error)
				})
		},
		async changeLocale(lang) {
			this.$i18n.locale = lang

			// 言語の変更をしたタイミングでカレンダーとテーブルを再読み込みして反映する
			this.$vuetify.lang.current = lang
			this.showCalendar = false
			this.isShowTable = false
			this.isShowTimeZone = false
			await this.$nextTick()
			this.showCalendar = true
			this.isShowTable = true
			this.isShowTimeZone = true
		},

		async reloadTable() {
			this.isShowTable = false
			await this.$nextTick()
			this.isShowTable = true
		},
		reloadAll() {
			this.reloadTable()
		},
		async reloadCalendar() {
			this.showCalendar = false
			await this.$nextTick()
			this.showCalendar = true
		}
	},
	head: {
		title: {
			inner: 'Teacher-schedule'
		}
	}
}
</script>

<style lang="scss" scoped>
.change-lang {
	white-space: pre;
}
.calendar-area {
	margin-bottom: 100px;
}
.change-lang-area {
	margin: 3px 0 0 0;
}
</style>
