<template>
	<div class="reviewList" v-if="teacherReviews && teacherReviews.length">
		<h3 class="reviewList__title">レッスン受講生からの声</h3>
		<div v-if="teacherReviewsLoading">
			<VmLoading />
		</div>
		<div v-else>
			<div class="review" v-for="review of displayedReviews" :key="review.id">
				<div class="review__profile">
					<img
						class="reviewImage"
						:src="review.imageUrl ? review.imageUrl : require('../../assets/reviewPlaceholder.png')"
						alt="teacherReviewImage"
					/>
					<div class="review__profile__detail">
						{{ convertAgeToDecade(review.age) }}
						{{ review.occupation ? `・${review.occupation}` : '' }}
						{{ review.residence ? `・${review.residence}在住` : '' }}<br />
						<span class="lessonCourse">{{ convertToLessonCourseName(review.lessonCourseId) }}<br /></span>
						{{ review.lessonFrequency ? `受講頻度：${review.lessonFrequency}` : '' }}
						{{ review.successExams ? `・${review.successExams}` : '' }}<br />
						{{ review.learningGoal ? `${review.learningGoal}` : '' }}
						<br />
					</div>
				</div>
				<div v-if="review.impressions" class="review__impressions">
					<span v-for="impression in processImpressions(review.impressions)" :key="impression" class="tag">
						{{ impression }}
					</span>
				</div>
				<div class="review__content">
					{{ review.content }}
				</div>
				<div class="review__date">
					{{ displayedDate(review.reviewedAt) }}
				</div>
			</div>
			<div class="buttonArea">
				<v-btn v-if="showMoreButton" text @click="showMoreReviews" class="more-button">
					もっと見る
					<v-icon right>mdi-chevron-down</v-icon>
				</v-btn>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import VmLoading from '../../components/Atoms/VmLoading'
import { convertAgeToDecade } from '../../util/convertAgeToDecade'

export default {
	name: 'TeacherReviewList',
	props: {
		teacherId: {
			type: Number,
			required: true
		}
	},
	components: {
		VmLoading
	},
	data: () => ({
		maxReviewsToShow: 5
	}),
	created() {
		this.fetchTeacherReviews({
			accessToken: this.userInfo.accessToken,
			teacherId: this.teacherId
		})
	},
	mounted() {},
	computed: {
		...mapGetters({
			userInfo: 'user/getUserInfo',
			teacherReviews: 'teacherReview/teacherReviews',
			teacherReviewsLoading: 'teacherReview/loading'
		}),
		displayedReviews() {
			return this.teacherReviews.slice(0, this.maxReviewsToShow)
		},
		showMoreButton() {
			return this.teacherReviews.length > this.maxReviewsToShow
		}
	},
	methods: {
		...mapActions({
			fetchTeacherReviews: 'teacherReview/fetchTeacherReviews'
		}),
		convertAgeToDecade(age) {
			return convertAgeToDecade(age)
		},
		convertToLessonCourseName(lessonCourseId) {
			return this.$t(`labels.lessonCourses.${lessonCourseId}`)
		},
		displayedDate(date) {
			if (!date) return ''
			return this.$moment(date).format('YYYY/MM/DD')
		},
		showMoreReviews() {
			this.maxReviewsToShow += 5
		},
		processImpressions(impressions) {
			if (!impressions) {
				return []
			}
			return impressions.split(',')
		}
	}
}
</script>

<style lang="scss" scoped>
.reviewList {
	margin-bottom: 60px;

	&__title {
		font-size: 1.4em;
		font-weight: bold;
		color: #dcc268;
	}
}

.review {
	border: 2px dotted #dcc268;
	border-radius: 4px;
	padding: 20px;
	margin-top: 40px;

	&__profile {
		display: flex;

		&__detail {
			margin-left: 16px;
			line-height: 1.6;
			font-size: 0.8em;
		}
	}

	&__content {
		margin-top: 8px;
		line-height: 2;
		font-size: 0.9em;
		white-space: pre-wrap;
	}

	&__date {
		font-size: 0.8em;
		color: gray;
	}

	&__impressions {
		margin-top: 8px;
	}
}

.reviewImage {
	width: 60px;
	height: 60px;
	border-radius: 4px;
	object-fit: cover;
}

.buttonArea {
	display: flex;
	justify-content: center;
	margin-top: 40px;
}

.more-button {
	color: #dcc268;
	font-weight: bold;
}

.lessonCourse {
	background-color: #ececec;
	padding: 2px 4px;
	position: relative;
	right: 4px;
}

.tag {
	display: inline-block;
	background-color: #ececec;
	padding: 5px 10px;
	margin-right: 8px;
	margin-top: 8px;
	border-radius: 4px;
	font-size: 0.8em;
}
</style>
