<template>
	<v-container class="maintenance-area">
		<v-row>
			<PageTitle title-name="メンテナンス中です" />
		</v-row>
		<v-row justify="center">
			<v-col cols="10" xs="8" sm="6" md="4" lg="4">
				<v-img :src="require('../../assets/maintenance.jpg')" contain></v-img>
			</v-col>
		</v-row>
		<v-row justify="center">
			<v-col cols="10">
				<v-row justify="center">
					<p>現在システムメンテナンスを行なっています。大変ご迷惑をおかけしまして申し訳ございません。</p>
					<p>メンテナンス終了後に再度ページを読み込んでいただきますようお願いいたします。</p>
					<p>
						※ご質問がある方は
						<a href="https://vollmond.online/kontaktieren/" target="_blank" rel="noopener">事務局までお問い合わせ</a
						>ください
					</p>
				</v-row>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import PageTitle from '../Atoms/PageTitle'

export default {
	name: 'Maintenance',
	data: () => ({}),
	components: {
		PageTitle
	},
	methods: {}
}
</script>

<style lang="scss" scoped>
.maintenance-area {
	margin: 200px 0 80px 0;
	margin-right: auto;
	margin-left: auto;
}
</style>
