<template>
	<v-select
		:items="beginningOfWeeks"
		v-model="selectedBegginingOfWeek"
		:label="$t('teacherSchedule.beginningOfWeek')"
		hide-details
		outlined
		background-color="white"
		dense
		@change="changeBegginingOfWeek"
	></v-select>
</template>

<script>
export default {
	name: 'SwitchBeginningOfWeek',
	props: [],
	data: () => ({
		beginningOfWeeks: [],
		selectedBegginingOfWeek: '',
		userInfo: null
	}),
	components: {},
	async created() {
		this.fetchInitialData()
		this.initbeginningOfWeek()
	},
	methods: {
		// API通信を行うのに必要な情報を取得する
		fetchInitialData() {
			this.userInfo = this.$store.getters['user/getUserInfo']
		},

		initbeginningOfWeek() {
			// v-selectのitemに初期値を設定する。
			this.beginningOfWeeks = []
			this.beginningOfWeeks.push(this.$t('teacherSchedule.sunday'))
			this.beginningOfWeeks.push(this.$t('teacherSchedule.monday'))

			// 「週始まり」の初期値はユーザー情報から取得
			switch (this.userInfo.beginningOfWeek) {
				case 0:
					this.selectedBegginingOfWeek = this.$t('teacherSchedule.sunday')
					break
				case 1:
					this.selectedBegginingOfWeek = this.$t('teacherSchedule.monday')
					break
			}
		},
		changeBegginingOfWeek() {
			switch (this.selectedBegginingOfWeek) {
				case this.$t('teacherSchedule.sunday'):
					this.$store.commit('user/SET_BEGGINING_OF_WEEK', 0)
					// 週始まりを変更したタイミングでカレンダーを再読み込み
					this.$emit('reloadCalendar')

					this.putBegginingOfWeek(0)

					break
				case this.$t('teacherSchedule.monday'):
					this.$store.commit('user/SET_BEGGINING_OF_WEEK', 1)
					// 週始まりを変更したタイミングでカレンダーを再読み込み
					this.$emit('reloadCalendar')

					this.putBegginingOfWeek(1)

					break
			}
			// this.$emit('changeAction', this.selectedBegginingOfWeek);
		},
		putBegginingOfWeek(beginningOfWeek) {
			const putbeginningOfWeekForm = {
				beginningOfWeek: beginningOfWeek
			}

			const header = {
				headers: {
					Authorization: `Bearer ${this.userInfo.accessToken}`
				}
			}

			switch (this.userInfo.userAuthority) {
				case 'STUDENT':
					this.axios
						.put(`/api/students/${this.userInfo.studentId}`, putbeginningOfWeekForm, header)
						.then((response) => {
							console.log('response.data', response.data)
						})
						.catch((error) => {
							alert(error.response.data.error.message)
						})
					break
				case 'TEACHER':
					this.axios
						.put(`/api/teachers/${this.userInfo.teacherId}`, putbeginningOfWeekForm, header)
						.then((response) => {
							console.log('response.data', response.data)
						})
						.catch((error) => {
							alert(error.response.data.error.message)
						})
					break
			}
		}
	}
}
</script>

<style lang="scss" scoped></style>
