<template>
	<div :style="{ background: '#FAFAFA' }">
		<v-container>
			<PageTitle titleName="登録内容確認" />
			<v-row no-gutters justify="center">
				<v-col cols="12" xs="12" sm="6" md="6" lg="6" v-if="profileForm">
					<!-- 確認内容 -->
					<div v-for="(value, key) in this.profileForm" :key="key" class="form">
						<ConfirmedForm
							:title="convertedTitle(key)"
							:content="convertedContent(key, value)"
							v-if="showConfirmedForm(key)"
						/>
					</div>
					<!-- 登録ボタン -->
					<v-row justify="center">
						<v-col cols="12">
							<v-btn
								color="primary"
								tile
								elevation="0"
								class="rounded-sm"
								:style="{
									'font-size': '1em'
								}"
								block
								@click="register"
								height="44px"
							>
								<p :style="{ fontWeight: 'bold' }">会員登録を行う</p>
							</v-btn>
						</v-col>
					</v-row>
					<!-- 登録内容修正ボタン -->
					<v-row justify="center">
						<v-col cols="12">
							<v-btn
								color="lightGray"
								tile
								elevation="0"
								class="rounded-sm"
								:style="{
									'font-size': '1em',
									'margin-bottom': '50px'
								}"
								block
								@click="modify"
								height="44px"
							>
								<p :style="{ fontWeight: 'bold', color: 'white' }">登録内容の修正を行う</p>
							</v-btn>
						</v-col>
					</v-row>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>

<script>
import PageTitle from '../Atoms/PageTitle'
import ConfirmedForm from '../Molecules/ConfirmedForm'
import acountInfo from '@/data/translation/acountInfo'

export default {
	name: 'ConfirmRegisteredInfo',
	props: ['profileForm'],
	components: {
		PageTitle,
		ConfirmedForm
	},
	data: () => ({}),
	created() {},
	mounted() {},
	computed: {
		showConfirmedForm: function () {
			return function (key) {
				// パスワードは表示しない
				if (key === 'password') {
					return false
				}

				if (key === 'confirmedPass') {
					return false
				}

				// 会話コースを選択していない時は訂正頻度は表示しない
				if (!this.profileForm.lessonCourseIds.includes('会話コース') && key === 'correctionFrequency') {
					return false
				}

				// 生徒じゃない時は、在籍校と専門は表示しない
				if (this.profileForm.category != 'STUDENT') {
					if (key === 'school') {
						return false
					}
					if (key === 'major') {
						return false
					}
				}
				return true
			}
		}
	},
	methods: {
		convertedTitle(key) {
			return acountInfo[key]
		},
		// DBに渡すデータと内容が違うものは変換してあげる
		convertedContent(key, value) {
			// 内容が無いものは"-"で返す
			if (!value) {
				return '-'
			}

			if (key === 'sex') {
				switch (Number(value)) {
					case 0:
						return '不明'
					case 1:
						return '男性'
					case 2:
						return '女性'
					case 9:
						return 'その他'
				}
			}

			if (key === 'correctionFrequency') {
				switch (value) {
					case 'CORRECT_AT_LAST':
						return 'その場では会話に集中したいので最後にまとめて教えてほしい'
					case 'FOLLOW_TEACHER':
						return '各先生のやり方に合わせる'
					case 'CORRECT_FULLY':
						return '間違いは全てその場で直してほしい'
					case 'CORRECT_HARDLY':
						return '意味が通っているものは直さなくてOK.何よりたくさん会話したい'
					default:
						return ''
				}
			}

			if (key === 'category') {
				switch (value) {
					case 'ADULT':
						return '大人'
					case 'STUDENT':
						return '学生'
					case 'CHILD':
						return '子ども'
					default:
						return ''
				}
			}

			if (key === 'level') {
				if (value === 'A0') {
					return '完全初級'
				} else {
					return value
				}
			}

			if (key === 'country') {
				switch (value) {
					case 'JAPAN':
						return '日本'
					case 'ABROAD':
						return '海外'
					default:
						return ''
				}
			}

			return value
		},
		register() {
			let requestObject = Object.assign({}, this.profileForm) // 疑似値渡し
			requestObject.lessonCourseIds = requestObject.lessonCourseIds.split(',').map((lessounCourseName) => {
				switch (lessounCourseName) {
					case '会話コース':
						return 1
					case 'プライベートコース':
						return 2
				}
			})
			console.log('requestObject', requestObject)
			this.axios
				.post('/api/students/register', requestObject)
				.then((response) => {
					console.log(response.data.student)
					this.$router.push({ name: 'CompletedAccountRegistration' })
				})
				.catch((error) => {
					console.log(error)
				})
		},
		// 登録内容の修正を行うボタン
		modify() {
			console.log('渡すthis.profileForm', this.profileForm)
			this.$router.push({ name: 'SignUp', params: { modifiedProfileForm: this.profileForm } }).catch(() => {})
		}
	}
}
</script>

<style lang="scss" scoped>
p {
	margin: 0;
	white-space: pre-line;
}
.form {
	margin: 50px 0;
}
</style>
