import { render, staticRenderFns } from "./ChoiceCourseDialog.vue?vue&type=template&id=f56e3496&scoped=true&"
import script from "./ChoiceCourseDialog.vue?vue&type=script&lang=js&"
export * from "./ChoiceCourseDialog.vue?vue&type=script&lang=js&"
import style0 from "./ChoiceCourseDialog.vue?vue&type=style&index=0&id=f56e3496&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f56e3496",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VRow})
