import axios from 'axios'

import { PRIVATE_LESSON_COURSE_ID, CONVERSATION_LESSON_COURSE_ID } from '../../const'

export const previouslyBookedTeachers = {
	namespaced: true,
	state: {
		loading: false,
		previouslyBookedPrivateTeachers: [],
		previouslyBookedConversationTeachers: []
	},
	getters: {
		loading: (state) => state.loading,
		previouslyBookedPrivateTeachers: (state) => state.previouslyBookedPrivateTeachers,
		previouslyBookedConversationTeachers: (state) => state.previouslyBookedConversationTeachers
	},
	mutations: {
		prepareConnect(state) {
			state.loading = true
		},
		stopLoading(state) {
			state.loading = false
		},
		setPreviouslyBookedPrivateTeachers(state, teachers) {
			state.previouslyBookedPrivateTeachers = teachers
		},
		setPreviouslyBookedConversationTeachers(state, teachers) {
			state.previouslyBookedConversationTeachers = teachers
		}
	},
	actions: {
		async getPreviouslyBookedTeachers({ commit }, payload) {
			commit('prepareConnect')

			const { requestData, accessToken } = payload
			const { studentId, lessonCourseId } = requestData

			const header = {
				headers: {
					Authorization: `Bearer ${accessToken}`
				}
			}
			return axios
				.get(`/api/teachers/previouslyReservation?studentId=${studentId}&lessonCourseId=${lessonCourseId}`, header)
				.then((res) => {
					if (lessonCourseId === PRIVATE_LESSON_COURSE_ID) {
						commit('setPreviouslyBookedPrivateTeachers', res.data.teachers)
					} else if (lessonCourseId === CONVERSATION_LESSON_COURSE_ID) {
						commit('setPreviouslyBookedConversationTeachers', res.data.teachers)
					}
					commit('stopLoading')
				})
				.catch((err) => {
					commit('stopLoading')
					console.log(err)
				})
		}
	}
}
