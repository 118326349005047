<template>
	<div class="studentInfo" v-if="!!student">
		<div class="studentInfo__wrapper">
			<!-- 生徒名 -->
			<v-row>
				<p class="studentInfo__item">{{ $t('top.studentName') }}: {{ student.name }}</p>
			</v-row>
			<!-- 性別 -->
			<v-row>
				<p class="studentInfo__item">{{ $t('top.sex') }}: {{ computedSex }}</p>
			</v-row>
			<v-row>
				<p class="studentInfo__item">{{ $t('top.age') }}: {{ computedAge }}</p>
			</v-row>
			<!-- 国 -->
			<v-row>
				<p class="studentInfo__item">{{ $t('top.country') }}: {{ this.computedCountry }}</p>
			</v-row>
			<!-- レベル -->
			<v-row>
				<p class="studentInfo__item">{{ $t('top.level') }}: {{ computedLevel }}</p>
			</v-row>
			<!-- 訂正の頻度 -->
			<v-row v-if="isConversation">
				<p class="studentInfo__item">
					{{ $t('top.correctionFrequency') }}: <br />
					{{ computedCorrectionFrequency }}
				</p>
			</v-row>
			<!-- フィードバックの有無 -->
			<v-row v-if="isConversation">
				<p class="studentInfo__item">{{ $t('top.feedBackAfterLesson') }}： {{ computedMonthlyFeedBack }}</p>
			</v-row>
			<!-- 学習の目的・目標 -->
			<v-row>
				<p class="studentInfo__item">
					{{ $t('top.learningGoal') }}： <br />
					{{ !student.learningGoal ? '-' : student.learningGoal }}
				</p>
			</v-row>
			<!-- その他 -->
			<v-row>
				<p class="studentInfo__item">
					{{ $t('top.otherItem') }}： <br />
					{{ !student.otherItem ? '-' : student.otherItem }}
				</p>
			</v-row>
		</div>
	</div>
</template>

<script>
export default {
	name: 'VmStudentInfo',
	props: {
		student: Object,
		isConversation: {
			type: Boolean,
			default: false
		}
	},
	components: {},
	data: () => ({}),
	created() {},
	mounted() {},
	computed: {
		computedAge() {
			if (!this.student.birthDay) {
				return '-'
			}
			const birthday = this.$moment(this.student.birthDay)
			return this.$moment().diff(birthday, 'years')
		},
		computedLevel() {
			// ※データベースは"A1"のように大文字で格納されているため
			//ja - de辞書では大文字のkeyを持たせる
			return this.student.level ? this.$t(`top.${this.student.level}`) : '-'
		},
		computedCorrectionFrequency() {
			// ここは複雑だが
			// 1. "CORRECT_AT_LAST"がDBから（APIサーバーを通して）やってくる
			// 2. 変換辞書を使わず、ja - de の辞書を使って変換する
			// ※ja - de辞書では大文字のkeyを持たせる
			return this.student.correctionFrequency ? this.$t(`top.${this.student.correctionFrequency}`) : '-'
		},
		computedCountry() {
			switch (this.student.country) {
				case 'JAPAN':
					return this.$t('top.japan')
				case 'ABROAD':
					return this.$t('top.abroad')
				default:
					return '-'
			}
		},
		computedSex() {
			switch (this.student.sex) {
				case 0:
					return '不明'
				case 1:
					return this.$t('top.man')
				case 2:
					return this.$t('top.woman')
				case 9:
					return this.$t('top.otherSex')
				default:
					return '-'
			}
		},
		computedMonthlyFeedBack() {
			if (this.student.monthlyFeedback) {
				return this.$t('top.hoping')
			} else {
				return this.$t('top.nohoping')
			}
		}
	},
	methods: {}
}
</script>

<style lang="scss" scoped>
.studentInfo {
	padding: 12px;
	background-color: #fafafa;
	&__wrapper {
		padding: 8px 16px;
	}
	&__item {
		font-weight: bold;
		margin: 2px 0;
	}
}
</style>
