<template>
	<v-autocomplete
		:items="items"
		v-model="selectedStudentId"
		label="生徒"
		outlined
		background-color="white"
		no-data-text="生徒がいません"
		item-text="name"
		item-value="id"
		@input="handleInput"
	>
	</v-autocomplete>
</template>

<script>
export default {
	props: {
		items: {
			type: Array,
			required: true
		},
		value: {
			type: [String, Number],
			default: ''
		}
	},
	data: () => ({
		selectedStudentId: ''
	}),
	watch: {
		value(val) {
			this.selectedStudentId = val
		},
		selectedStudentId(val) {
			this.$emit('input', val)
		}
	},
	methods: {
		handleInput(selectedId) {
			const selectedStudent = this.items.find((item) => item.id === selectedId)
			this.$emit('student-selected', selectedStudent)
		}
	},
	created() {
		this.selectedStudentId = this.value
	}
}
</script>
