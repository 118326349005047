<template>
	<VmDialogCard
		:title="formTitle"
		:buttonTitle="$t('top.requestDeleteLesson.request')"
		:cancelButtonTitle="$t('top.cancel')"
		@click="requestDelete"
		@close="close"
		:height="400"
		:disabled="!buttonValidation"
	>
		<template #cardContent>
			<div class="content">
				<p>{{ $t('top.requestDeleteLesson.confirm') }}</p>
				<p class="note">{{ $t('top.requestDeleteLesson.note') }}</p>
				<VmSelect
					v-model="selectedReason"
					:options="deleteReasonItems"
					:label="$t('top.requestDeleteLesson.reasonTitle')"
				></VmSelect>
			</div>
		</template>
	</VmDialogCard>
</template>

<script>
import VmDialogCard from '../../components/Molecules/VmDialogCard'
import VmSelect from '../../components/Atoms/VmSelect'
import { mapGetters, mapActions } from 'vuex'

export default {
	name: 'RequestDeleteLessonCard',
	components: {
		VmDialogCard,
		VmSelect
	},
	props: {
		formTitle: {
			type: String,
			required: true
		},
		lessonInfo: {
			type: Object,
			required: true
		}
	},
	data: () => ({
		selectedReason: ''
	}),
	created() {},
	mounted() {},
	computed: {
		...mapGetters({
			userInfo: 'user/getUserInfo'
		}),
		deleteReasonItems() {
			const reasons = [
				this.$t('top.requestDeleteLesson.reason1'),
				this.$t('top.requestDeleteLesson.reason2'),
				this.$t('top.requestDeleteLesson.reason3'),
				this.$t('top.requestDeleteLesson.reason4')
			]

			// ラベルと値が同じオブジェクトを生成
			return reasons.map((reason) => ({ label: reason, value: reason }))
		},
		buttonValidation() {
			// 削除理由が選択されていなかったらボタンを押せないようにする
			if (!this.selectedReason) {
				return false
			}

			return true
		}
	},
	methods: {
		...mapActions({
			requestDeleteLesson: 'requestModifyLesson/requestDeleteLesson'
		}),
		close() {
			this.$emit('close')
		},
		async requestDelete() {
			let isSuccess = false
			isSuccess = await this.requestDeleteLesson({
				lessonId: this.lessonInfo.lessonId,
				teacherId: this.lessonInfo.teacherId,
				accessToken: this.userInfo.accessToken,
				reason: this.selectedReason
			})
			if (isSuccess) {
				this.$emit('close')
				this.$emit('reload')
				alert(this.$t('top.requestDeleteLesson.doneRequest'))
			}
		}
	}
}
</script>

<style lang="scss" scoped>
.content {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100%;
}
.note {
	margin-bottom: 20px;
	color: red;
}
</style>
