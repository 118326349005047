<template>
	<validationProvider
		:rules="{
			required: true,
			regex: /^[a-zA-Z0-9!-/:-@¥[-`{-~]*$/,
			min: 8,
			max: 25
		}"
		name="パスワード"
		v-slot="{ errors, valid }"
	>
		<p>
			<FormTitleText :titleText="`パスワード${isConfirmed ? '(確認用)' : ''}`" />
			<RequiredIcon v-if="rules && rules.includes('required')" />
			<FormAttentionText :attentionText="$t('profile.onlyHalfWidthAndNum') + ' ' + $t('profile.min8max25')" />
		</p>
		<v-text-field
			label="password"
			v-model="inputedPassword"
			outlined
			background-color="white"
			:success="valid"
			hide-details
			:append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
			:type="showPass ? 'text' : 'password'"
			@click:append="showPass = !showPass"
			autocomplete="off"
		></v-text-field>
		<FormErrorText :errorText="errors[0]" />
	</validationProvider>
</template>

<script>
import FormTitleText from '../Atoms/FormTitleText'
import FormErrorText from '../Atoms/FormErrorText'
import FormAttentionText from '../Atoms/FormAttentionText'
import RequiredIcon from '../Atoms/RequiredIcon'

export default {
	name: 'PasswordForm',
	data: () => ({
		showPass: false
	}),
	props: ['value', 'rules', 'isConfirmed'],
	mounted() {},
	computed: {
		inputedPassword: {
			get() {
				return this.value
			},
			set(value) {
				this.$emit('input', value)
			}
		}
	},
	methods: {},
	components: {
		FormTitleText,
		FormErrorText,
		FormAttentionText,
		RequiredIcon
	}
}
</script>

<style lang="scss" scoped></style>
