const REMIND_LIMIT_DATE = 5

// バックエンドでも実装している。バックエンドの実装が変更された場合、こちらも変更する必要がある。
export const fetchReportTargetYearMonth = () => {
	const today = new Date()
	let year = today.getFullYear() // 現在の年を取得
	let month = today.getMonth() + 1 // JavaScriptの月は0から始まるため、+1します

	const currentDay = today.getDate()

	// 現在が月の6日から19日の場合、現在の年月をそのまま使用
	if (currentDay > REMIND_LIMIT_DATE && currentDay < 20) {
		const monthStr = month.toString().padStart(2, '0')
		return `${year}${monthStr}`
	}

	// 翌月の5日までは、前の月を表示
	if (currentDay <= REMIND_LIMIT_DATE) {
		if (month === 1) {
			year -= 1 // 1月の場合、前年の12月を表示
			month = 12
		} else {
			month -= 1 // それ以外の場合、前月を表示
		}
	}

	// 月の20日以降の場合、現在の年月をそのまま使用
	const monthStr = month.toString().padStart(2, '0')
	return `${year}${monthStr}`
}

export const fetchReportTargetMonth = () => {
	const today = new Date()
	const currentMonth = today.getMonth() + 1 // JavaScriptの月は0から始まるため、+1します
	const currentDay = today.getDate()

	let displayMonth
	if (currentDay >= 20) {
		// 今月の20日以降は、現在の月を表示
		displayMonth = currentMonth
	} else if (currentDay <= 5) {
		// 翌月の5日までは、前の月（現在の月 - 1）を表示
		displayMonth = currentMonth - 1 === 0 ? 12 : currentMonth - 1 // 1月の前は12月とする
	} else {
		// それ以外の日は現在の月を表示
		displayMonth = currentMonth
	}
	return displayMonth
}
