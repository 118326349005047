import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import { user } from './user'
import loading from './loading'
import { lessonRequest } from './lessonRequest'
import { student } from './student'
import { students } from './students'
import { teachers } from './teachers'
import { lessonPlan } from './lessonPlan'
import { reserve } from './reserve'
import { notifiedLesson } from './notifiedLesson'
import { requestModifyLesson } from './requestModifyLesson'
import { manageInvoice } from './manageInvoice'
import { teacherReview } from './teacherReview'
import { adminStudent, adminTeacher, adminLesson, adminMonthlyCompleteReports } from './admin'
import { reportMonthlyComplete } from './teacherTop'
import { studentInvoices } from './studentInvoice'
import { teacherRewards } from './teacherRewards'
import { teacher } from './teacher'
import * as messageToStudent from './messageToStudent'
import { plannedTeacherLessons } from './plannedTeacherLessons'
import { plannedStudentLessons } from './plannedStudentLessons'
import { monthlyTeacherLessons } from './monthlyTeacherLessons'
import { privateTeachers } from './privateTeachers'
import { conversationTeachers } from './conversationTeachers'
import { allTeachers } from './allTeachers'
import { checkConnection } from './teacherSchedule'
import { previouslyBookedTeachers } from './studentTop'

Vue.use(Vuex)

const store = new Vuex.Store({
	state: {},
	mutations: {},
	actions: {},
	modules: {
		user,
		loading,
		lessonRequest,
		student,
		students,
		teachers,
		lessonPlan,
		adminStudent,
		adminTeacher,
		adminLesson,
		adminMonthlyCompleteReports,
		notifiedLesson,
		requestModifyLesson,
		manageInvoice,
		teacherReview,
		reserve,
		reportMonthlyComplete,
		messageToStudent,
		studentInvoices,
		teacherRewards,
		teacher,
		plannedTeacherLessons,
		plannedStudentLessons,
		monthlyTeacherLessons,
		privateTeachers,
		conversationTeachers,
		allTeachers,
		checkConnection,
		previouslyBookedTeachers
	},
	plugins: [
		createPersistedState({
			key: 'vollmondVue',
			paths: ['user']
		})
	]
})

export default store
