var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validationProvider',{attrs:{"rules":{
		required: true,
		regex: /^[a-zA-Z0-9!-/:-@¥[-`{-~]*$/,
		email: true
	},"name":_vm.$t('profile.email')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
	var errors = ref.errors;
	var valid = ref.valid;
return [_c('p',{staticClass:"no-margin-text"},[_c('FormTitleText',{attrs:{"titleText":_vm.$t('profile.email')}}),(_vm.rules && _vm.rules.includes('required'))?_c('RequiredIcon'):_vm._e(),_c('FormAttentionText',{attrs:{"attentionText":_vm.$t('profile.onlyHalfWidthAndNum')}})],1),_c('v-text-field',{attrs:{"label":"email","outlined":"","background-color":"white","success":valid,"hide-details":""},on:{"change":_vm.editEmail},model:{value:(_vm.inputedEmail),callback:function ($$v) {_vm.inputedEmail=$$v},expression:"inputedEmail"}}),_c('FormErrorText',{attrs:{"errorText":errors[0]}})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }