<template>
	<div class="loginContainer">
		<div class="loginContentArea">
			<div class="logoArea"><img :src="require('../../assets/Vollmond_logo.png')" /></div>
			<p class="login-title">予約システムログイン</p>
			<div class="text-field-area">
				<vm-text-field
					class="textField"
					label-text="メールアドレス"
					v-model="email"
					inputType="email"
					@enter="clickLogin"
				></vm-text-field>
				<vm-text-field
					class="textField"
					v-model="password"
					inputType="password"
					:is-password="true"
					@enter="clickLogin"
					label-text="パスワード"
				></vm-text-field>
				<VmLoginButton :button-title="$t('login.login')" @clickAction="clickLogin" />
			</div>
			<div class="infoArea">
				<div class="help-links">
					・<a href="https://vollmond.online/einloggen/" target="_blank" rel="noopener" class="link-text help-text"
						>ログインできない場合の対処法はこちら</a
					><br />
					・<router-link to="/passwordForget" class="link-text help-text">パスワードの再発行はこちら</router-link>
				</div>
				<p class="recommended-environment">※推奨利用環境: Chrome</p>
				<p class="to-beginner-text">
					※初めての方は、まず<a
						href="https://vollmond.online/deutschkurse/"
						target="_blank"
						rel="noopener"
						class="link-text"
						>学習コース一覧</a
					>より気になるコースへお申し込みください。
				</p>
			</div>
		</div>
		<v-dialog
			v-model="showAccountSuspendedDialog"
			:close-on-content-click="false"
			v-if="showAccountSuspendedDialog"
			offset-x
			max-width="500px"
		>
			<VmDialogCard cancelButtonTitle="閉じる" @close="showAccountSuspendedDialog = false" :height="200">
				<template #cardContent>
					<div class="suspended">
						<p class="report-subtitle">
							アカウントが停止されています。<a
								href="https://vollmond.online/kontaktieren/"
								target="_blank"
								rel="noopener"
								>事務局までお問い合わせ</a
							>ください。
						</p>
					</div>
				</template>
			</VmDialogCard>
		</v-dialog>
	</div>
</template>

<script>
import VmLoginButton from '../Atoms/VmLoginButton'
import VmDialogCard from '../Molecules/VmDialogCard'
import VmTextField from '../Atoms/VmTextField'
import { mapGetters, mapActions } from 'vuex'

export default {
	name: 'Login',
	data: () => ({
		email: '',
		password: '',
		showPass: false,
		showAccountSuspendedDialog: false
	}),
	components: {
		VmLoginButton,
		VmDialogCard,
		VmTextField
	},
	computed: {
		...mapGetters({
			userInfo: 'user/getUserInfo'
		})
	},
	methods: {
		...mapActions({
			login: 'user/login'
		}),
		clickLogin() {
			const data = { email: this.email, password: this.password }

			this.login(data)
				.then((userParam) => {
					this.$moment.tz.setDefault(userParam.timeZone)
					switch (this.userInfo.userAuthority) {
						case 'ADMIN':
							this.$moment.tz.setDefault('Asia/Tokyo')
							this.$router.push('/admin/top')
							break
						case 'OPERATOR':
							this.$moment.tz.setDefault('Asia/Tokyo')
							this.$router.push('/admin/top')
							break
						case 'STUDENT':
							if (userParam.isAccountSuspended) {
								this.showAccountSuspendedDialog = true
								return
							}
							this.$router.push('/studentTop')
							break
						case 'TEACHER':
							this.$router.push('/teacherTop')
							break
					}
				})
				.catch((error) => {
					alert(error.response.data.error.message)
				})
		},
		changeLocale(lang) {
			this.$i18n.locale = lang
		}
	}
}
</script>

<style lang="scss" scoped>
a {
	font-weight: bold;
}
p {
	margin-bottom: 0;
}
.loginContainer {
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	@media (max-width: 600px) {
		padding-left: 30px;
		padding-right: 30px;
	}
}
.loginContentArea {
	display: flex;
	flex-direction: column;
	align-items: center;

	@media (min-width: 600px) {
		width: 420px;
	}
}

.logoArea {
	max-width: 310px;

	@media (max-width: 600px) {
		max-width: 160px;
	}

	img {
		width: 100%;
	}
}

.login-title {
	margin-top: 50px;
	font-weight: bold;
	font-size: 28px;
	margin-bottom: 0;

	@media (max-width: 600px) {
		margin-top: 40px;
		font-size: 15px;
	}
}
.logoArea {
	width: 300px;
	display: flex;
	justify-content: center;
}
.text-field-area {
	width: 100%;
	margin-top: 50px;

	@media (max-width: 600px) {
		margin-top: 40px;
	}
}

.infoArea {
	margin-top: 50px;
	width: 100%;

	@media (max-width: 600px) {
		margin-top: 40px;
	}

	.help-links,
	.recommended-environment,
	.to-beginner-text {
		text-align: left;
		font-weight: bold;
	}

	.help-links {
		a {
			display: inline-block;
		}
		:not(:first-child) {
			margin-top: 20px;
		}
	}
	.link-text {
		color: #005db4;
	}
	.link-text:hover {
		color: #135a98;
	}
	.help-text {
		font-size: 0.8em;
	}
	.to-beginner-text,
	.recommended-environment {
		margin-top: 20px;
		font-size: 0.8em;
		margin-left: 1em;
	}
}
.textField {
	width: 100%;
	margin-bottom: 20px;
}
.suspended {
	margin-top: 30px;
	display: flex;
	justify-content: center;
}
</style>
