export * from './conversationTicketPrices'

export const CONVERSATION_LESSON_COURSE_ID = 1
export const PRIVATE_LESSON_COURSE_ID = 2
export const PRIVATE_SUBSCRIPTION_URL = 'https://vollmond.online/privatkurs-lehrer/'
export const CONVERSATION_SUBSCRIPTION_URL = 'https://vollmond.online/konversationskurs/'
export const TEXT_SUBSCRIPTION_URL = 'https://vollmond.online/uebungskurs/'

export const LessonStatuses = {
	Incomplete: 0,
	Complete: 1,
	Cancelled: 2
}
