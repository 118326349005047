import axios from 'axios'

export const reportMonthlyComplete = {
	namespaced: true,
	state: {
		loading: false,
		isCompleted: false
	},
	getters: {
		loading: (state) => state.loading,
		isCompleted: (state) => state.isCompleted
	},
	mutations: {
		prepareConnect(state) {
			state.loading = true
		},
		stopLoading(state) {
			state.loading = false
		},
		setCheckResult(state, isCompleted) {
			state.isCompleted = isCompleted
		}
	},
	actions: {
		async checkCompletion({ commit }, payload) {
			commit('prepareConnect')

			const { requestData, accessToken } = payload
			const { yearMonth, teacherId } = requestData

			const header = {
				headers: {
					Authorization: `Bearer ${accessToken}`
				}
			}
			return axios
				.get(`/api/monthlyCompleteReport/checkCompletion?yearMonth=${yearMonth}&teacherId=${teacherId}`, header)
				.then((res) => {
					commit('setCheckResult', res.data.isCompleted)
					commit('stopLoading')
				})
				.catch((err) => {
					commit('stopLoading')
					console.log(err)
				})
		},
		async report({ commit }, payload) {
			commit('prepareConnect')

			const { requestData, accessToken } = payload

			const header = {
				headers: {
					Authorization: `Bearer ${accessToken}`
				}
			}
			return axios
				.post(`/api/monthlyCompleteReport`, requestData, header)
				.then(() => {
					commit('setCheckResult', true)
					commit('stopLoading')
					return true
				})
				.catch((err) => {
					commit('stopLoading')
					console.log(err)
					return false
				})
		}
	}
}
