<template>
	<validationProvider :rules="rules" v-slot="{ errors, valid }" immediate :name="$t('profile.country')">
		<!-- 現在の居住地 -->
		<p>
			<FormTitleText :titleText="$t('profile.country')" />
			<RequiredIcon v-if="rules && rules.includes('required')" />
		</p>
		<v-select
			:items="$i18n.locale === 'ja' ? jaCountryItems : deCountryItems"
			v-model="inputtedCountry"
			:label="$t('profile.requireSelection')"
			outlined
			background-color="white"
			hide-details
			:success="valid"
		></v-select>
		<FormErrorText :errorText="errors[0]" />
	</validationProvider>
</template>

<script>
import FormTitleText from '../Atoms/FormTitleText'
import FormErrorText from '../Atoms/FormErrorText'
import RequiredIcon from '../Atoms/RequiredIcon'

export default {
	name: 'TeacherCountryForm',
	data: () => ({
		jaCountryItems: ['日本', 'ドイツ', 'オーストリア', 'スイス', 'その他'],
		deCountryItems: ['Japan', 'Deutschland', 'Österreich', 'Schweiz', 'Sonstiges']
	}),
	props: ['value', 'rules'],
	mounted() {},
	computed: {
		inputtedCountry: {
			get() {
				if (this.$i18n.locale === 'de') {
					const index = this.jaCountryItems.indexOf(this.value)
					return this.deCountryItems[index]
				} else {
					return this.value
				}
			},
			set(value) {
				if (this.$i18n.locale === 'de') {
					const index = this.deCountryItems.indexOf(value)
					return this.$emit('input', this.jaCountryItems[index])
				} else {
					this.$emit('input', value)
				}
			}
		}
	},
	methods: {},
	components: {
		FormTitleText,
		FormErrorText,
		RequiredIcon
	}
}
</script>

<style lang="scss" scoped></style>
