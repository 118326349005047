import { extend, localize } from 'vee-validate'
import { required, email, alpha_num, regex, size, image } from 'vee-validate/dist/rules'

extend('required', required)
extend('email', email)
extend('alpha_num', alpha_num)
extend('regex', regex)
extend('size', size)
extend('image', image)
extend('date', (value) => {
	const regex = '[0-9]{4}/[0-9]{2}/[0-9]{2}'
	if (!value.match(regex)) {
		return false
	}
	let y = value.split('/')[0]
	let m = value.split('/')[1] - 1
	let d = value.split('/')[2]
	let date = new Date(y, m, d)

	if (date.getFullYear() != y || date.getMonth() != m || date.getDate() != d) {
		return false
	}
	return true
})
extend('max', {
	validate(value, args) {
		const length = value.length
		return length <= args.max
	},
	params: ['max']
})
extend('min', {
	validate(value, args) {
		const length = value.length
		return args.min <= length
	},
	params: ['min']
})

localize('ja')
