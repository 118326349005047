<template>
	<validationProvider :rules="rules" v-slot="{ errors, valid }" immediate :name="$t('profile.specialty')">
		<p>
			<FormTitleText :titleText="$t('profile.specialty')" />
			<RequiredIcon v-if="rules && rules.includes('required')" />
		</p>
		<v-textarea
			v-model="inputedSpecialities"
			:placeholder="specialitiesLabel"
			outlined
			background-color="white"
			counter
			:success="valid"
		></v-textarea>
		<FormErrorText :errorText="errors[0]" />
	</validationProvider>
</template>

<script>
import FormTitleText from '../Atoms/FormTitleText'
import FormErrorText from '../Atoms/FormErrorText'
import mypageText from '@/data/mypage/mypageText'
import RequiredIcon from '../Atoms/RequiredIcon'

export default {
	name: 'SpecialitiesForm',
	components: {
		FormTitleText,
		FormErrorText,
		RequiredIcon
	},
	data: () => ({
		specialitiesLabel: mypageText.specialitiesLabel
	}),
	props: {
		value: {
			type: String,
			required: true
		},
		rules: {
			type: String,
			required: true
		}
	},
	mounted() {},
	computed: {
		inputedSpecialities: {
			get() {
				return this.value
			},
			set(value) {
				this.$emit('input', value)
			}
		}
	},
	methods: {}
}
</script>

<style lang="scss" scoped></style>
