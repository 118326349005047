<template>
	<div>
		<p class="remind__title">{{ text }}</p>
		<div v-if="showTimeOptions" class="remind__time-options">
			<VmCheckBox v-model="inputtedRemind2Hours" :label="$t('profile.remind2Hours')" />
			<VmCheckBox v-model="inputtedRemind24Hours" :label="$t('profile.remind24Hours')" />
			<VmCheckBox v-model="inputtedRemind72Hours" :label="$t('profile.remind72Hours')" />
		</div>
	</div>
</template>

<script>
import VmCheckBox from '../Atoms/VmCheckBox.vue'

export default {
	name: 'SelectRemindMailHoursForm',
	components: {
		VmCheckBox
	},
	props: {
		text: {
			type: String,
			required: true
		},
		showTimeOptions: {
			type: Boolean,
			default: false
		},
		value2Hours: {
			type: Boolean,
			default: false
		},
		value24Hours: {
			type: Boolean,
			default: false
		},
		value72Hours: {
			type: Boolean,
			default: false
		}
	},
	computed: {
		inputtedRemind2Hours: {
			get() {
				return this.value2Hours === null ? false : this.value2Hours
			},
			set(value) {
				this.$emit('update:value2Hours', value ? true : false)
			}
		},
		inputtedRemind24Hours: {
			get() {
				return this.value24Hours === null ? false : this.value24Hours
			},
			set(value) {
				this.$emit('update:value24Hours', value ? true : false)
			}
		},
		inputtedRemind72Hours: {
			get() {
				return this.value72Hours === null ? false : this.value72Hours
			},
			set(value) {
				this.$emit('update:value72Hours', value ? true : false)
			}
		}
	}
}
</script>

<style lang="scss" scoped>
.remind {
	&__title {
		font-weight: bold;
		margin: 0;
	}
	&__time-options {
		display: flex;
		flex-direction: row;
		gap: 20px; /* Add space between checkboxes */
	}
}
</style>
