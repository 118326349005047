<template>
	<div>
		<span class="name" :style="nameStyle">{{ name }}</span>
		<span class="teacher" :style="teacherStyle">{{ $t('teacherSchedule.honorificTitle') }}</span>
	</div>
</template>

<script>
export default {
	name: 'TeacherCardNameLabel',
	data: () => ({
		fontWeight: 'regular'
	}),
	computed: {
		nameStyle() {
			return {
				fontSize: this.calcFontSize + 'em',
				fontWeight: this.fontWeight
			}
		},
		teacherStyle() {
			return {
				fontSize: this.calcFontSize * 0.6 + 'em',
				fontWeight: this.fontWeight
			}
		},
		calcFontSize() {
			switch (this.$vuetify.breakpoint.name) {
				case 'xs':
					return 1.2
				case 'sm':
					return 1.2
				case 'md':
					return 1.5
				case 'lg':
					return 1.3
				case 'xl':
					return 1.3
				default:
					return 1.2
			}
		}
	},
	components: {},
	methods: {},
	props: ['name', 'textColor']
}
</script>

<style lang="scss" scoped>
.name {
	font-weight: bold;
}
.teacher {
	margin-left: 4px;
}
</style>
