<template>
	<validationProvider :rules="rules" v-slot="{ errors, valid }" :name="$t('profile.sex')">
		<p>
			<FormTitleText :titleText="$t('profile.ageLowerLimit')" />
			<RequiredIcon v-if="rules && rules.includes('required')" />
		</p>
		<v-select
			:items="minAvailableStudentAgeItems"
			v-model="inputtedMinAvailableStudentAge"
			item-text="label"
			item-value="value"
			:label="$t('profile.requireSelection')"
			outlined
			background-color="white"
			hide-details
			:success="valid"
		></v-select>
		<FormErrorText :errorText="errors[0]" />
	</validationProvider>
</template>

<script>
import FormTitleText from '../Atoms/FormTitleText'
import FormErrorText from '../Atoms/FormErrorText'
import RequiredIcon from '../Atoms/RequiredIcon'

export default {
	name: 'MinAvailableStudentAgeForm',
	data: () => ({}),
	props: ['value', 'rules'],
	mounted() {},
	computed: {
		minAvailableStudentAgeItems() {
			return Array.from({ length: 16 }, (_, index) => ({
				label: this.$t(`profile.ageLowerLimitItems.${index}`),
				value: index
			}))
		},
		inputtedMinAvailableStudentAge: {
			get() {
				return this.value
			},
			set(value) {
				this.$emit('input', value)
			}
		}
	},
	methods: {},
	components: {
		FormTitleText,
		FormErrorText,
		RequiredIcon
	}
}
</script>

<style lang="scss" scoped></style>
