import axios from 'axios'

export const user = {
	namespaced: true,
	state: {
		userName: '',
		accessToken: '',
		adminAccessToken: '',
		studentId: '',
		teacherId: '',
		imageUrl: '',
		userAuthority: '',
		numberOfTicket: 0,
		lessonCourseArray: [],
		timeZone: '',
		beginningOfWeek: 0,
		isReservedStripe: null,
		level: '',
		studentCategory: '',
		isAccountSuspended: false,
		isTestAccount: false,
		adminCapabilities: [],
		skypeId: '',
		birthDay: ''
	},
	getters: {
		getUserInfo: (state) => {
			return state
		},
		isTeacher: (state) => {
			return state.userAuthority === 'TEACHER'
		},
		canAccessStudent: (state) => {
			if (!state) return false
			return state.adminCapabilities.includes('STUDENT')
		},
		canAccessTeacher: (state) => {
			if (!state) return false
			return state.adminCapabilities.includes('TEACHER')
		},
		canAccessLesson: (state) => {
			if (!state) return false
			return state.adminCapabilities.includes('LESSON')
		},
		canAccessConnection: (state) => {
			if (!state) return false
			return state.adminCapabilities.includes('CONNECTION')
		},
		canAccessRegularLesson: (state) => {
			if (!state) return false
			return state.adminCapabilities.includes('REGULAR_LESSON')
		},
		canAccessText: (state) => {
			if (!state) return false
			return state.adminCapabilities.includes('TEXT')
		},
		canAccessInvoice: (state) => {
			if (!state) return false
			return state.adminCapabilities.includes('INVOICE')
		},
		canAccessPrivateReward: (state) => {
			if (!state) return false
			return state.adminCapabilities.includes('PRIVATE_REWARD')
		},
		canAccessConversationReward: (state) => {
			if (!state) return false
			return state.adminCapabilities.includes('CONVERSATION_REWARD')
		},
		canAccessMail: (state) => {
			if (!state) return false
			return state.adminCapabilities.includes('MAIL')
		},
		canAccessMessage: (state) => {
			if (!state) return false
			return state.adminCapabilities.includes('MESSAGE')
		},
		canAccessTeacherAnalysis: (state) => {
			if (!state) return false
			return state.adminCapabilities.includes('TEACHER_ANALYSIS')
		},
		canAccessStudentAnalysis: (state) => {
			if (!state) return false
			return state.adminCapabilities.includes('STUDENT_ANALYSIS')
		},
		canAccessManagementInvoice: (state) => {
			if (!state) return false
			return state.adminCapabilities.includes('MANAGEMENT_INVOICE')
		},
		canAccessTeacherReview: (state) => {
			if (!state) return false
			return state.adminCapabilities.includes('TEACHER_REVIEW')
		},
		canAccessMonthlyCompleteReports: (state) => {
			if (!state) return false
			return state.adminCapabilities.includes('MONTHLY_COMPLETE_REPORTS')
		},
		canAccessLoginAsOtherUser: (state) => {
			if (!state) return false
			return state.adminCapabilities.includes('LOGIN_AS_OTHER_USER')
		}
	},
	mutations: {
		SET_USER_INFO(state, param) {
			state.userName = param.userName
			state.accessToken = param.accessToken
			state.adminAccessToken = param.adminAccessToken
			state.studentId = param.studentId
			state.teacherId = param.teacherId
			state.imageUrl = param.imageUrl
			state.userAuthority = param.userAuthority
			state.numberOfTicket = param.numberOfTicket
			state.lessonCourseArray = param.lessonCourseArray
			state.timeZone = param.timeZone
			state.beginningOfWeek = param.beginningOfWeek
			state.isReservedStripe = param.isReservedStripe
			state.level = param.level
			state.studentCategory = param.studentCategory
			state.isAccountSuspended = param.isAccountSuspended
			state.isTestAccount = param.isTestAccount
			if (param.adminCapabilities) state.adminCapabilities = param.adminCapabilities.split(',')
			state.skypeId = param.skypeId
			state.birthDay = param.birthDay
		},
		SET_USER_TICKETNUM(state, numberOfTicket) {
			state.numberOfTicket = numberOfTicket
		},
		SET_USER_IMAGE(state, imageUrl) {
			state.imageUrl = imageUrl
		},
		SET_TEACHER_INFO(state, teacherId) {
			state.teacherId = teacherId
		},
		SET_STUDENT_INFO(state, studentId) {
			state.studentId = studentId
		},
		SET_TIMEZONE(state, timeZone) {
			state.timeZone = timeZone
		},
		SET_BEGGINING_OF_WEEK(state, beginningOfWeek) {
			state.beginningOfWeek = beginningOfWeek
		}
	},
	actions: {
		login({ commit }, data) {
			return new Promise((resolve, reject) => {
				axios
					.post('/api/login', data)
					.then((response) => {
						let userParam = {}
						switch (response.data.userAuthority) {
							case 'STUDENT':
								userParam = {
									userName: response.data.user.name,
									accessToken: response.data.user.accessToken,
									studentId: response.data.user.id,
									imageUrl: response.data.user.imageUrl,
									userAuthority: response.data.userAuthority,
									numberOfTicket: response.data.user.numberOfTicket,
									lessonCourseArray: response.data.lessonCourseArrray,
									timeZone: response.data.user.timeZone,
									beginningOfWeek: response.data.user.beginningOfWeek,
									isReservedStripe: response.data.user.stripeId ? true : false,
									level: response.data.user.level,
									studentCategory: response.data.user.category,
									isAccountSuspended: response.data.user.isAccountSuspended,
									isTestAccount: response.data.user.isTestAccount,
									birthDay: response.data.user.birthDay
								}
								break
							case 'TEACHER':
								userParam = {
									userName: response.data.user.teacherName,
									accessToken: response.data.user.accessToken,
									teacherId: response.data.user.id,
									imageUrl: response.data.user.imageUrl,
									userAuthority: response.data.userAuthority,
									lessonCourseArray: response.data.lessonCourseArrray,
									timeZone: response.data.user.timeZone,
									beginningOfWeek: response.data.user.beginningOfWeek,
									isReservedStripe: false, // 講師はstripe情報を登録していないため
									level: null,
									studentCategory: null,
									skypeId: response.data.user.skypeId,
									isTestAccount: response.data.user.isTestAccount,
									birthDay: response.data.user.birthDay
								}
								break
							case 'ADMIN':
								userParam = {
									accessToken: response.data.user.accessToken,
									adminAccessToken: response.data.user.adminAccessToken,
									userAuthority: response.data.userAuthority
								}
								break
							case 'OPERATOR':
								userParam = {
									accessToken: response.data.user.accessToken,
									adminAccessToken: response.data.user.adminAccessToken,
									userAuthority: response.data.userAuthority,
									adminCapabilities: response.data.user.adminCapabilities
								}
								break
						}
						commit('SET_USER_INFO', userParam)
						resolve(userParam)
					})
					.catch((error) => {
						reject(error)
					})
			})
		},
		async loginAsOtherUser({ state, commit }, payload) {
			return new Promise((resolve) => {
				const header = {
					headers: {
						Authorization: `Bearer ${payload.adminAccessToken}`
					}
				}
				const requestBody = {
					studentId: payload.studentId ? payload.studentId : null,
					teacherId: payload.teacherId ? payload.teacherId : null
				}
				axios
					.post('/api/login/asOtherUser', requestBody, header)
					.then((res) => {
						let userParam = {
							accessToken: res.data.user.accessToken,
							adminAccessToken: state.adminAccessToken,
							imageUrl: res.data.user.imageUrl,
							userAuthority: payload.isAdmin ? 'ADMIN' : 'OPERATOR',
							numberOfTicket: res.data.user.numberOfTicket,
							lessonCourseArray: res.data.lessonCourseArrray,
							timeZone: res.data.user.timeZone,
							beginningOfWeek: res.data.user.beginningOfWeek,
							isReservedStripe: res.data.user.stripeId ? true : false,
							level: res.data.user.level ? res.data.user.level : null,
							studentCategory: res.data.user.category ? res.data.user.category : null,
							skypeId: res.data.user.skypeId,
							birthDay: res.data.user.birthDay
						}

						if (res.data.userAuthority === 'STUDENT') {
							userParam.studentId = res.data.user.id
							userParam.userName = res.data.user.name
							commit('SET_USER_INFO', userParam)
							resolve(true)
						} else if (res.data.userAuthority === 'TEACHER') {
							userParam.teacherId = res.data.user.id
							userParam.userName = res.data.user.teacherName
							commit('SET_USER_INFO', userParam)
							resolve(true)
						}
					})
					.catch((err) => {
						console.log(err)
					})
			})
		},
		async returnToAdminScreen({ state, commit }, payload) {
			return new Promise((resolve) => {
				const userParam = {
					userName: '',
					accessToken: state.accessToken,
					adminAccessToken: state.adminAccessToken,
					studentId: '',
					teacherId: '',
					imageUrl: '',
					userAuthority: payload.isAdmin ? 'ADMIN' : 'OPERATOR',
					numberOfTicket: 0,
					lessonCourseArray: [],
					timeZone: '',
					beginningOfWeek: 0,
					isReservedStripe: null,
					level: '',
					studentCategory: ''
				}
				commit('SET_USER_INFO', userParam)
				resolve(true)
			})
		}
	}
}
