import axios from 'axios'
import moment from 'moment-timezone'

export const adminLesson = {
	namespaced: true,
	state: {
		lessons: null,
		totalPages: 0,
		loading: false
	},
	getters: {
		loading: (state) => {
			return state.loading
		},
		lessons: (state) => {
			return state.lessons
		},
		totalPages: (state) => {
			return state.totalPages
		}
	},
	mutations: {
		prepareConnect(state) {
			state.loading = true
		},
		stopLoading(state) {
			state.loading = false
		},
		setLessons(state, data) {
			state.lessons = data.lessons
			state.totalPages = data.totalPages
		}
	},
	actions: {
		async createLessons({ commit }, { accessToken, payload }) {
			commit('prepareConnect')
			const header = {
				headers: {
					Authorization: `Bearer ${accessToken}`
				}
			}
			return axios
				.post(`/api/lessons`, payload, header)
				.then(() => {
					return true
				})
				.catch(() => {
					return false
				})
				.finally(() => {
					commit('stopLoading')
				})
		},
		async fetchLessons({ commit }, { accessToken, selectedMonth, currentPage, searchWord }) {
			commit('prepareConnect')
			const header = {
				headers: {
					Authorization: `Bearer ${accessToken}`
				}
			}

			const startTime = moment(selectedMonth).startOf('month').format('YYYY-MM-DD HH:mm')
			const endTime = moment(selectedMonth).endOf('month').format('YYYY-MM-DD HH:mm')

			// ユーザーのタイムゾーンを取得
			const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

			let url = `/api/lessons?startTime=${startTime}&endTime=${endTime}&page=${currentPage}&per=50&timeZone=${timeZone}`
			if (searchWord !== undefined && searchWord !== '') {
				url += `&searchWord=${encodeURIComponent(searchWord)}`
			}
			axios
				.get(url, header)
				.then((res) => {
					console.log('res.data', res.data)
					commit('setLessons', res.data)
				})
				.catch((error) => {
					console.log(error)
				})
				.finally(() => {
					commit('stopLoading')
				})
		},
		async editLesson({ commit }, { accessToken, selectedLessonId, editedItem }) {
			commit('prepareConnect')
			const header = {
				headers: {
					Authorization: `Bearer ${accessToken}`
				}
			}

			return axios
				.put(`/api/lessons/${selectedLessonId}`, editedItem, header)
				.then((response) => {
					console.log('response.data', response.data)
					return true
				})
				.catch((error) => {
					alert(error.response.data.error.message)
					return false
				})
				.finally(() => {
					commit('stopLoading')
				})
		},
		async bulkEditLessons({ commit }, { accessToken, updatedLessonIds, payload }) {
			commit('prepareConnect')
			const header = {
				headers: {
					Authorization: `Bearer ${accessToken}`
				}
			}

			const requestBody = {
				updatedLessonIds: updatedLessonIds,
				payload: payload
			}

			return axios
				.post(`/api/lessons/bulkEdit`, requestBody, header)
				.then((response) => {
					console.log('response.data', response.data)
					return true
				})
				.catch((error) => {
					alert(error.response.data.error.message)
					return false
				})
				.finally(() => {
					commit('stopLoading')
				})
		}
	}
}
