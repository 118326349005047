<template>
	<div class="message-area" v-if="message != ''">
		<span class="box-title">講師からのメッセージ</span>
		<p v-html="$sanitize(autoLink(message))" />
	</div>
</template>

<script>
export default {
	name: 'MessageFromTeacher',
	data: () => ({}),
	props: ['message'],
	methods: {
		autoLink(text) {
			return this.isString(text)
				? text.replace(/(https?:\/\/[^\s]*)/g, "<a href='$1' target='_blank' rel='noopener'>$1</a>")
				: ''
		},
		isString(value) {
			if (typeof value === 'string' || value instanceof String) {
				return true
			} else {
				return false
			}
		}
	}
}
</script>

<style lang="scss" scoped>
.message-area {
	position: relative;
	margin-top: 20px;
	padding: 1em 1em;
	border: solid 1px #6881dc;
	border-radius: 8px;
}
.message-area .box-title {
	position: absolute;
	display: inline-block;
	top: -8px;
	left: 10px;
	padding: 0 9px;
	font-size: 0.8em;
	line-height: 1;
	background: #fff;
	color: #6881dc;
	font-weight: bold;
}
.message-area p {
	margin: 0;
	padding: 0;
	font-size: 0.9em;
}
</style>
