import axios from 'axios'

export const teacher = {
	namespaced: true,
	state: {
		data: null,
		loading: false
	},
	getters: {
		loading: (state) => state.loading,
		teacher: (state) => state.data
	},
	mutations: {
		prepareConnect(state) {
			state.loading = true
		},
		stopLoading(state) {
			state.loading = false
		},
		setTeacher(state, payload) {
			state.data = payload
			state.loading = false
		}
	},
	actions: {
		async fetchTeacherInfo({ commit }, payload) {
			commit('prepareConnect')

			const { teacherId } = payload
			return axios
				.get(`/api/teachers/${teacherId}`)
				.then((res) => {
					commit('setTeacher', res.data.teacher)
				})
				.catch(() => {
					commit('stopLoading')
				})
		}
	}
}
