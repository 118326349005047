<template>
	<p v-if="errorText" class="error-text">{{ errorText }}</p>
</template>

<script>
export default {
	name: 'FormErrorText',
	data: () => ({}),
	props: {
		errorText: {
			type: String,
			default: ''
		}
	},
	methods: {}
}
</script>

<style lang="scss" scoped>
.error-text {
	color: white;
	background-color: red;
	font-weight: bold;
	padding: 8px 10px;
	margin-bottom: 0;
	margin-top: 4px;
	font-size: 0.8em;
	border-radius: 4px;
}
</style>
