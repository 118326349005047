import axios from 'axios'

export const teachers = {
	namespaced: true,
	state: {
		data: null,
		loading: false
	},
	getters: {
		loading: (state) => {
			return state.loading
		},
		teachers: (state) => {
			return state.data
		}
	},
	mutations: {
		prepareConnect(state) {
			state.loading = true
		},
		stopLoading(state) {
			state.loading = false
		},
		setTeachers(state, payload) {
			state.data = payload
			state.loading = false
		}
	},
	actions: {
		async fetchAllTeachers({ commit }, accessToken) {
			commit('prepareConnect')
			const header = {
				headers: {
					Authorization: `Bearer ${accessToken}`
				}
			}
			return axios
				.get(`/api/teachers/`, header)
				.then((res) => {
					commit('setTeachers', res.data.teachers)
				})
				.catch(() => {
					commit('stopLoading')
				})
		}
	}
}
