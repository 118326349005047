<template>
	<div>
		<PageTitle title-name="月次業務完了報告" />
		<SelectMonth v-model="selectedMonth" @fetchData="setUpData" />
		<v-data-table
			:headers="headers"
			:items="teacherReportItems"
			:loading="teacherReportsLoading"
			no-data-text="データがありません"
		>
		</v-data-table>
	</div>
</template>

<script>
import PageTitle from '../Atoms/PageTitle'
import { mapGetters, mapActions } from 'vuex'
import SelectMonth from '../Molecules/SelectMonth'

export default {
	name: 'AdminMonthlyCompleteReports',
	props: {},
	components: {
		PageTitle,
		SelectMonth
	},
	watch: {
		selectedMonth: function () {
			this.setUpData()
		}
	},
	data: () => ({
		selectedMonth: null
	}),
	created() {},
	mounted() {},
	computed: {
		...mapGetters({
			userInfo: 'user/getUserInfo',
			teacherReports: 'adminMonthlyCompleteReports/teacherReports',
			teacherReportsLoading: 'adminMonthlyCompleteReports/loading'
		}),
		headers() {
			return [
				{
					text: '講師ID',
					align: 'start',
					value: 'teacherId'
				},
				{
					text: '講師名',
					value: 'teacherName'
				},
				{
					text: '報告完了',
					value: 'hasCompletedReport'
				}
			]
		},
		teacherReportItems() {
			if (!this.teacherReports) return []
			return this.teacherReports.map((teacherReport) => {
				let completeStatusText = teacherReport.hasCompletedReport ? '完了' : '未'
				if (teacherReport.isInactiveTeacher) completeStatusText = '-'
				return {
					teacherId: teacherReport.teacherId,
					teacherName: teacherReport.teacherName,
					hasCompletedReport: completeStatusText
				}
			})
		}
	},
	methods: {
		...mapActions({
			fetchTeacherReports: 'adminMonthlyCompleteReports/fetchTeacherReports'
		}),
		setUpData() {
			const payload = {
				accessToken: this.userInfo.accessToken,
				requestQuery: {
					year: this.$moment(this.selectedMonth).format('YYYY'),
					month: this.$moment(this.selectedMonth).format('MM')
				}
			}
			this.fetchTeacherReports(payload)
		}
	}
}
</script>

<style lang="scss" scoped></style>
