import axios from 'axios'

export const adminTeacher = {
	namespaced: true,
	state: {
		teachers: null,
		loading: false
	},
	getters: {
		loading: (state) => {
			return state.loading
		},
		teachers: (state) => {
			return state.teachers
		}
	},
	mutations: {
		prepareConnect(state) {
			state.loading = true
		},
		stopLoading(state) {
			state.loading = false
		},
		setTeachers(state, payload) {
			state.teachers = payload
			state.loading = false
		}
	},
	actions: {
		fetchTeachers({ commit }, payload) {
			commit('prepareConnect')
			const header = {
				headers: {
					Authorization: `Bearer ${payload.accessToken}`
				}
			}
			return axios
				.get(`/api/teachers`, header)
				.then((res) => {
					commit('setTeachers', res.data.teachers)
				})
				.catch(() => {
					commit('stopLoading')
				})
		}
	}
}
