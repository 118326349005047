import { render, staticRenderFns } from "./AdminAddMessageCard.vue?vue&type=template&id=0f3d3f16&scoped=true&"
import script from "./AdminAddMessageCard.vue?vue&type=script&lang=js&"
export * from "./AdminAddMessageCard.vue?vue&type=script&lang=js&"
import style0 from "./AdminAddMessageCard.vue?vue&type=style&index=0&id=0f3d3f16&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0f3d3f16",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VCard,VCardActions,VCardText,VCardTitle,VCol,VRow,VTextarea})
