<template>
	<v-row class="change-lang-area">
		<v-row justify="start" no-gutters>
			<v-col cols="8" xs="4" sm="4" md="4" lg="2">
				<v-select
					:items="timeZones"
					v-model="selectedTimeZone"
					outlined
					hide-details
					@change="changeTimeZone"
					dense
					class="select-area"
				></v-select>
			</v-col>
		</v-row>
	</v-row>
</template>
<script>
export default {
	name: 'SwitchTimeZone',
	props: [],
	data: () => ({
		timeZones: [],
		selectedTimeZone: '',
		userInfo: null,
		timeZoneIcon: require('../../assets/time_zone.png')
	}),
	components: {},
	async mounted() {
		this.fetchInitialData()
		this.initTimeZone()
	},
	methods: {
		// API通信を行うのに必要な情報を取得する
		fetchInitialData() {
			this.userInfo = this.$store.getters['user/getUserInfo']
		},
		// タイムゾーンの初期値はユーザー情報のタイムゾーンカラムのデータから取得
		initTimeZone() {
			this.timeZones.push(this.$t('common.japaneseTime'))
			this.timeZones.push(this.$t('common.germanTime'))

			if (this.userInfo.timeZone) {
				switch (this.userInfo.timeZone) {
					case 'Asia/Tokyo':
						this.selectedTimeZone = this.$t('common.japaneseTime')
						this.$moment.tz.setDefault('Asia/Tokyo')
						break
					case 'Europe/Berlin':
						this.selectedTimeZone = this.$t('common.germanTime')
						this.$moment.tz.setDefault('Europe/Berlin')
						break
				}
			} else {
				this.selectedTimeZone = this.$t('common.japaneseTime')
				this.$moment.tz.setDefault('Asia/Tokyo')
			}
		},
		async changeTimeZone() {
			switch (this.selectedTimeZone) {
				case this.$t('common.japaneseTime'):
					this.$moment.tz.setDefault('Asia/Tokyo')

					this.commitTimeZone('Asia/Tokyo')
					this.putStudentTimeZone('Asia/Tokyo')

					// 言語の変更をしたタイミングでテーブルを再読み込みして反映する
					this.$emit('reload')
					break
				case this.$t('common.germanTime'):
					this.$moment.tz.setDefault('Europe/Berlin')

					this.commitTimeZone('Europe/Berlin')
					this.putStudentTimeZone('Europe/Berlin')

					// 言語の変更をしたタイミングでテーブルを再読み込みして反映する
					this.$emit('reload')
					break
			}
		},
		commitTimeZone(timeZone) {
			this.$store.commit('user/SET_TIMEZONE', timeZone)
		},
		putStudentTimeZone(timeZone) {
			// APIたたくのはログインユーザーのみ
			if (!this.isLogin) return
			const putTimeZoneForm = {
				timeZone: timeZone
			}

			const header = {
				headers: {
					Authorization: `Bearer ${this.userInfo.accessToken}`
				}
			}

			switch (this.userInfo.userAuthority) {
				case 'STUDENT':
					this.axios
						.put(`/api/students/${this.userInfo.studentId}`, putTimeZoneForm, header)
						.then((response) => {
							console.log('response.data', response.data)
						})
						.catch((error) => {
							alert(error.response.data.error.message)
						})
					break
				case 'TEACHER':
					this.axios
						.put(`/api/teachers/${this.userInfo.teacherId}`, putTimeZoneForm, header)
						.then((response) => {
							console.log('response.data', response.data)
						})
						.catch((error) => {
							alert(error.response.data.error.message)
						})
					break
			}
		}
	}
}
</script>

<style lang="scss" scoped>
.change-lang-area {
	padding-left: 6px;
}
.select-area {
	padding-left: 0;
}
.select-area::before {
	content: '';
	display: inline-block;
	width: 30px;
	height: 30px;
	background: url('../../assets/time_zone.png') no-repeat;
	background-size: contain;
	margin-right: 5px;
	position: relative;
	top: 10px;
}
</style>
