<template>
	<VmDialogCard
		title="この機能を使うには会員登録・ログインが必要です"
		buttonTitle="ログイン画面へ"
		cancelButtonTitle="閉じる"
		@click="toLogin"
		@close="cancel"
		:height="230"
	>
		<template #cardContent>
			<p>以下のボタンからログインしてください</p>
		</template>
	</VmDialogCard>
</template>

<script>
import VmDialogCard from '../Molecules/VmDialogCard'
export default {
	name: 'RequestLoginForm',
	created() {},
	data: () => ({}),
	computed: {},
	methods: {
		toLogin() {
			this.$router.push({ name: 'Login' })
		},
		cancel() {
			this.$emit('cancelAction')
		}
	},
	components: {
		VmDialogCard
	}
}
</script>

<style lang="scss" scoped>
.button {
	margin-bottom: 20px;
}
</style>
