<template>
	<v-app id="admin-top" :style="{ background: '#FAFAFA' }">
		<!-- サイドバー -->
		<v-navigation-drawer
			class="pt-4"
			color="white lighten-3"
			permanent
			v-on:mouseover="mouseOverAction"
			v-on:mouseleave="mouseLeaveAction"
			app
		>
			<v-list nav dense>
				<v-list-item-group v-model="model" active-class="selected">
					<v-list-item v-for="n of linkItems.length" :key="n" link @click="clickTab(linkItems[n - 1].key)">
						<v-list-item-icon>
							<v-icon>{{ linkItems[n - 1].icon }}</v-icon>
						</v-list-item-icon>
						<v-list-item-title>{{ linkItems[n - 1].title }}</v-list-item-title>
					</v-list-item>
				</v-list-item-group>
			</v-list>
		</v-navigation-drawer>
		<v-main>
			<!-- 中身 -->
			<component :is="showedAdminScreen" class="no-hover-main" />
		</v-main>
	</v-app>
</template>

<script>
import AdminStudentList from '../Pages/AdminStudentList'
import AdminTeacherList from '../Pages/AdminTeacherList'
import AdminLessonList from '../Pages/AdminLessonList'
import AdminRegularScheduleList from '../Pages/AdminRegularScheduleList'
import AdminTextCourseList from '../Pages/AdminTextCourseList'
import AdminInvoiceForStudentList from '../Pages/AdminInvoiceForStudentList'
import AdminPrivateRewardList from '../Pages/AdminPrivateRewardList'
import AdminConversationRewardList from '../Pages/AdminConversationRewardList'
import AdminMailText from '../Pages/AdminMailText'
import AdminMessage from '../Pages/AdminMessage'
import AdminTeacherDataAnalysis from '../Pages/AdminTeacherDataAnalysis'
import AdminStudentDataAnalysis from '../Pages/AdminStudentDataAnalysis'
import AdminConnectionList from '../Pages/AdminConnectionList'
import AdminManagementInvoice from '../Pages/AdminManagementInvoice'
import AdminTeacherReview from '../Pages/AdminTeacherReview'
import AdminMonthlyCompleteReports from '../Pages/AdminMonthlyCompleteReports'
import { mapGetters } from 'vuex'

export default {
	name: 'AdminTop',
	data: () => ({
		selectedKey: '',
		model: 0,
		isMenuHovered: false
	}),
	components: {
		AdminStudentList,
		AdminTeacherList,
		AdminLessonList,
		AdminConnectionList,
		AdminRegularScheduleList,
		AdminTextCourseList,
		AdminInvoiceForStudentList,
		AdminPrivateRewardList,
		AdminConversationRewardList,
		AdminMailText,
		AdminMessage,
		AdminTeacherDataAnalysis,
		AdminStudentDataAnalysis,
		AdminManagementInvoice,
		AdminTeacherReview,
		AdminMonthlyCompleteReports
	},
	created() {
		if (this.linkItems.length) this.selectedKey = this.linkItems[0].key
	},
	computed: {
		...mapGetters({
			userInfo: 'user/getUserInfo',
			canAccessStudent: 'user/canAccessStudent',
			canAccessTeacher: 'user/canAccessTeacher',
			canAccessLesson: 'user/canAccessLesson',
			canAccessConnection: 'user/canAccessConnection',
			canAccessRegularLesson: 'user/canAccessRegularLesson',
			canAccessText: 'user/canAccessText',
			canAccessInvoice: 'user/canAccessInvoice',
			canAccessPrivateReward: 'user/canAccessPrivateReward',
			canAccessConversationReward: 'user/canAccessConversationReward',
			canAccessMail: 'user/canAccessMail',
			canAccessMessage: 'user/canAccessMessage',
			canAccessTeacherAnalysis: 'user/canAccessTeacherAnalysis',
			canAccessStudentAnalysis: 'user/canAccessStudentAnalysis',
			canAccessManagementInvoice: 'user/canAccessManagementInvoice',
			canAccessTeacherReview: 'user/canAccessTeacherReview',
			canAccessMonthlyCompleteReports: 'user/canAccessMonthlyCompleteReports'
		}),
		isAdmin() {
			return this.userInfo.userAuthority === 'ADMIN'
		},
		linkItems() {
			let linkItems = []

			if (this.isAdmin || this.canAccessStudent) {
				linkItems.push({
					title: '生徒一覧',
					icon: 'mdi-account',
					key: 'STUDENT'
				})
			}
			if (this.isAdmin || this.canAccessTeacher) {
				linkItems.push({
					title: '講師一覧',
					icon: 'mdi-account-tie',
					key: 'TEACHER'
				})
			}
			if (this.isAdmin || this.canAccessLesson) {
				linkItems.push({
					title: 'レッスン一覧',
					icon: 'mdi-laptop',
					key: 'LESSON'
				})
			}
			if (this.isAdmin || this.canAccessConnection) {
				linkItems.push({
					title: '紐付け一覧',
					icon: 'mdi-human-greeting-proximity',
					key: 'CONNECTION'
				})
			}
			if (this.isAdmin || this.canAccessRegularLesson) {
				linkItems.push({
					title: '定期予定一覧',
					icon: 'mdi-timer',
					key: 'REGULAR_LESSON'
				})
			}
			if (this.isAdmin || this.canAccessText) {
				linkItems.push({
					title: 'テキストコース一覧',
					icon: 'mdi-notebook-multiple',
					key: 'TEXT'
				})
			}
			if (this.isAdmin || this.canAccessInvoice) {
				linkItems.push({
					title: '生徒への請求金額一覧',
					icon: 'mdi-receipt',
					key: 'INVOICE'
				})
			}
			if (this.isAdmin || this.canAccessPrivateReward) {
				linkItems.push({
					title: '【プライベート】報酬金額一覧',
					icon: 'mdi-cash-multiple',
					key: 'PRIVATE_REWARD'
				})
			}
			if (this.isAdmin || this.canAccessConversationReward) {
				linkItems.push({
					title: '【会話】報酬金額一覧',
					icon: 'mdi-cash',
					key: 'CONVERSATION_REWARD'
				})
			}
			if (this.isAdmin || this.canAccessMail) {
				linkItems.push({
					title: 'メール文編集',
					icon: 'mdi-email',
					key: 'MAIL'
				})
			}
			if (this.isAdmin || this.canAccessMessage) {
				linkItems.push({
					title: 'メッセージ編集',
					icon: 'mdi-forum',
					key: 'MESSAGE'
				})
			}
			if (this.isAdmin || this.canAccessTeacherAnalysis) {
				linkItems.push({
					title: '講師データ分析',
					icon: 'mdi-finance',
					key: 'TEACHER_ANALYSIS'
				})
			}
			if (this.isAdmin || this.canAccessStudentAnalysis) {
				linkItems.push({
					title: '生徒データ分析',
					icon: 'mdi-chart-line',
					key: 'STUDENT_ANALYSIS'
				})
			}
			if (this.isAdmin || this.canAccessManagementInvoice) {
				linkItems.push({
					title: '請求メール管理',
					icon: 'mdi-receipt-text-check',
					key: 'MANAGEMENT_INVOICE'
				})
			}
			if (this.isAdmin || this.canAccessTeacherReview) {
				linkItems.push({
					title: '講師レビュー',
					icon: 'mdi-message-draw',
					key: 'TEACHER_REVIEW'
				})
			}
			if (this.isAdmin || this.canAccessMonthlyCompleteReports) {
				linkItems.push({
					title: '月次業務完了報告',
					icon: 'mdi-file-check',
					key: 'MONTHLY_COMPLETE_REPORTS'
				})
			}
			return linkItems
		},
		showedAdminScreen() {
			switch (this.selectedKey) {
				case 'STUDENT':
					return AdminStudentList
				case 'TEACHER':
					return AdminTeacherList
				case 'LESSON':
					return AdminLessonList
				case 'CONNECTION':
					return AdminConnectionList
				case 'REGULAR_LESSON':
					return AdminRegularScheduleList
				case 'TEXT':
					return AdminTextCourseList
				case 'INVOICE':
					return AdminInvoiceForStudentList
				case 'PRIVATE_REWARD':
					return AdminPrivateRewardList
				case 'CONVERSATION_REWARD':
					return AdminConversationRewardList
				case 'MAIL':
					return AdminMailText
				case 'MESSAGE':
					return AdminMessage
				case 'TEACHER_ANALYSIS':
					return AdminTeacherDataAnalysis
				case 'STUDENT_ANALYSIS':
					return AdminStudentDataAnalysis
				case 'MANAGEMENT_INVOICE':
					return AdminManagementInvoice
				case 'TEACHER_REVIEW':
					return AdminTeacherReview
				case 'MONTHLY_COMPLETE_REPORTS':
					return AdminMonthlyCompleteReports
				default:
					return ''
			}
		}
	},
	methods: {
		clickTab(key) {
			this.selectedKey = key
		},
		mouseOverAction() {
			this.isMenuHovered = true
		},
		mouseLeaveAction() {
			this.isMenuHovered = false
		}
	}
}
</script>

<style lang="scss" scoped>
.choose-area {
	padding: 50px;
}
.link {
	margin: 50px 0;
	font-weight: bold;
}
.v-main {
	padding: 0 30px !important;
}
.selected {
	background-color: #dcc268;
	color: white;
}
</style>
