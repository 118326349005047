import axios from 'axios'

export const allTeachers = {
	namespaced: true,
	state: {
		allTeachers: [],
		loading: false
	},
	getters: {
		loading: (state) => {
			return state.loading
		},
		allTeachers: (state) => {
			return state.allTeachers
		}
	},
	mutations: {
		prepareConnect(state) {
			state.loading = true
		},
		stopLoading(state) {
			state.loading = false
		},
		fetchAllTeachersSuccess(state, teachers) {
			state.allTeachers = teachers
		},
		resetAllTeachers(state) {
			state.allTeachers = []
		}
	},
	actions: {
		async fetchAllTeachers({ commit }, params) {
			commit('prepareConnect')

			return axios
				.get(`/api/teachers/allList`, { params })
				.then((res) => {
					let teachers = res.data.teachers

					commit('fetchAllTeachersSuccess', teachers)
					commit('stopLoading')
				})
				.catch((err) => {
					commit('stopLoading')
					console.log(err)
				})
		},
		async resetAllTeachers({ commit }) {
			commit('resetAllTeachers')
		}
	}
}
