export default {
	code: 'de',
	messages: {
		alpha: '{_field_} darf nur alphabetische Zeichen enthalten',
		alpha_num: '{_field_} darf nur Buchstaben und Zahlen enthalten',
		alpha_dash: '{_field_} darf nur Buchstaben, Zahlen, Bindestriche und Unterstriche enthalten',
		alpha_spaces: '{_field_} darf nur Buchstaben und Leerzeichen enthalten',
		between: '{_field_} muss zwischen {min} und {max} liegen',
		confirmed: '{_field_} stimmt nicht überein',
		digits: '{_field_} muss eine Zahl mit {length} Ziffern sein',
		dimensions: '{_field_} muss innerhalb von {width}px Breite und {height}px Höhe liegen',
		email: 'Das Format der E-Mail-Adresse ist falsch',
		excluded: '{_field_} enthält einen ungültigen Wert',
		ext: '{_field_} hat ein ungültiges Dateiformat',
		image: '{_field_} muss ein gültiges Bildformat sein',
		integer: '{_field_} muss eine ganze Zahl sein',
		is: '{_field_} stimmt nicht überein',
		length: '{_field_} muss {length} Zeichen lang sein',
		max_value: '{_field_} darf maximal {max} sein',
		max: '{_field_} darf maximal {max} Zeichen enthalten',
		mimes: '{_field_} muss ein gültiges Dateiformat sein',
		min_value: '{_field_} muss mindestens {min} sein',
		min: '{_field_} muss mindestens {min} Zeichen enthalten',
		numeric: '{_field_} darf nur Zahlen enthalten',
		oneOf: '{_field_} enthält keinen gültigen Wert',
		regex: 'Das Format von {_field_} ist ungültig',
		required: '{_field_} ist ein Pflichtfeld.',
		required_if: '{_field_} ist ein Pflichtfeld',
		size: '{_field_} muss kleiner als {size}KB sein',
		date: 'Bitte geben Sie das Datum im richtigen Format ein (z.B. 1990/01/01)'
	}
}
