<template>
	<div>
		<div class="card-area">
			<v-hover v-slot="{ hover }">
				<TeacherCard
					@clickAction="openDetail"
					:isShowDetail="isShowDetail"
					:teacherInfo="teacherInfo"
					:hover="hover"
					:showedAt="showedAt"
				/>
			</v-hover>
		</div>
		<div class="detail-area">
			<TeacherCardDetail v-if="isShowDetail" @clickAction="closeDetail" :teacherInfo="teacherInfo" />
		</div>
	</div>
</template>

<script>
import TeacherCard from '../Organisms/TeacherCard'
import TeacherCardDetail from '../Organisms/TeacherCardDetail'

export default {
	name: 'TeacherCardWithDetail',
	data: () => ({
		isShowDetail: false
	}),
	components: {
		TeacherCard,
		TeacherCardDetail
	},
	mounted() {},
	computed: {},
	methods: {
		openDetail() {
			this.isShowDetail = !this.isShowDetail
		},
		closeDetail() {
			this.isShowDetail = !this.isShowDetail
		}
	},
	props: ['teacherInfo', 'showedAt'],
	head: {
		title: {
			inner: 'teacher-list'
		}
	}
}
</script>

<style lang="scss" scoped>
.card-area {
	z-index: 4;
}
.detail-area {
	padding-top: 0;
}
</style>
