<template>
	<v-row justify="center">
		<BaseHeading :heading-text="$t('top.lessonInfo')" />
	</v-row>
</template>

<script>
import BaseHeading from '../Atoms/BaseHeading'

export default {
	name: 'ScheduleHeading',
	data: () => ({}),
	components: {
		BaseHeading
	},
	methods: {},
	head: {
		link: [{ rel: 'stylesheet', href: 'https://use.fontawesome.com/releases/v5.6.1/css/all.css' }]
	}
}
</script>

<style lang="scss" scoped>
h2:before {
	font-family: 'Font Awesome 5 Free';
	content: '\f129';
}
.heading {
	padding: 12px 0;
}
</style>
