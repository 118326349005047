import axios from 'axios'

export const notifiedLesson = {
	namespaced: true,
	state: {
		notifiedLessons: [],
		loading: false
	},
	getters: {
		loading: (state) => {
			return state.loading
		},
		notifiedLessons: (state) => {
			return state.notifiedLessons
		}
	},
	mutations: {
		prepareConnect(state) {
			state.loading = true
		},
		stopLoading(state) {
			state.loading = false
		},
		setLessonNotifications(state, data) {
			state.notifiedLessons = data
		}
	},
	actions: {
		fetchLessonNotifications({ commit }, { accessToken, teacherId }) {
			commit('prepareConnect')
			const header = {
				headers: {
					Authorization: `Bearer ${accessToken}`
				}
			}
			return axios
				.get(`/api/lessonNotifications?teacherId=${teacherId}`, header)
				.then((res) => {
					commit('setLessonNotifications', res.data.notifiedLessons)
					commit('stopLoading')
				})
				.catch((err) => {
					commit('stopLoading')
					console.log(err)
				})
		},
		checkLessonNotification({ commit }, { accessToken, payload }) {
			commit('prepareConnect')
			const header = {
				headers: {
					Authorization: `Bearer ${accessToken}`
				}
			}
			const reqBody = {
				lessonId: payload.lessonId
			}
			return axios
				.post(`/api/lessonNotifications/check`, reqBody, header)
				.then(() => {
					commit('stopLoading')
					return true
				})
				.catch(() => {
					commit('stopLoading')
					return false
				})
		}
	}
}
