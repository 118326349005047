import axios from 'axios'

export const monthlyTeacherLessons = {
	namespaced: true,
	state: {
		monthlyTeacherLessons: [],
		meta: {
			totalPages: 0,
			totalCount: 0
		},
		loading: false
	},
	getters: {
		loading: (state) => {
			return state.loading
		},
		monthlyTeacherLessons: (state) => {
			return state.monthlyTeacherLessons
		},
		totalPages: (state) => {
			return state.meta.totalPages
		},
		totalCount: (state) => {
			return state.meta.totalCount
		}
	},
	mutations: {
		prepareConnect(state) {
			state.loading = true
		},
		stopLoading(state) {
			state.loading = false
		},
		fetchMonthlyTeacherLessonsSuccess(state, data) {
			state.monthlyTeacherLessons = []

			state.monthlyTeacherLessons = data.lessons
			state.meta = data.meta
		}
	},
	actions: {
		async fetchMonthlyTeacherLessons({ commit }, payload) {
			commit('prepareConnect')

			const { accessToken, params } = payload

			const header = {
				headers: {
					Authorization: `Bearer ${accessToken}`
				},
				params: params
			}

			return axios
				.get(`/api/lessons/teacher/monthly`, header)
				.then((res) => {
					commit('fetchMonthlyTeacherLessonsSuccess', res.data)
					commit('stopLoading')
				})
				.catch((err) => {
					commit('stopLoading')
					console.log(err)
				})
		}
	}
}
