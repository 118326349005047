<template>
	<v-card color="lighten-4" max-width="500" class="scroll" flat>
		<v-card-text>
			<v-col>
				<v-row justify="center">
					<p class="disable-subtitle">この授業は削除できません</p>
				</v-row>
				<v-row>
					<p class="disable-content">
						生徒側の事情によるレッスン開始48時間前以降のキャンセルには、キャンセル料が発生します（『キャンセルポリシー』
						<a href="https://vollmond.online/cancel-policy/" target="_blank" rel="noopener"
							>https://vollmond.online/cancel-policy/</a
						>）。<br />
						<br />
						※先生側の事情によるキャンセルにはキャンセル料は発生しません<br />
						<br />
						<span class="red--text"
							>お休みにする場合、予定レッスン時刻までに講師へその旨直接ご連絡ください。無断キャンセルはご遠慮ください。</span
						>
					</p>
				</v-row>
			</v-col>
		</v-card-text>
		<v-card-actions>
			<v-row justify="center">
				<v-col cols="12" xs="12" sm="6" md="6" lg="6">
					<CancelButton button-title="閉じる" @clickAction="cancel" class="button" />
				</v-col>
			</v-row>
		</v-card-actions>
	</v-card>
</template>

<script>
import CancelButton from '../Atoms/CancelButton'

export default {
	name: 'CancelDisableCard',
	data: () => ({}),
	props: [],
	computed: {},
	mounted() {},
	methods: {
		cancel() {
			this.$emit('closeAction')
		}
	},
	components: {
		CancelButton
	}
}
</script>

<style lang="scss" scoped>
.disable-subtitle {
	font-size: 1.4em;
	font-weight: bold;
	margin: 20px 0 15px 0;
}
.disable-content {
	margin: 15px 10px 15px 10px;
}
</style>
