var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"rounded",attrs:{"width":"100%","tile":"","elevation":_vm.hover || _vm.isShowDetail ? 6 : 2,"hover":""}},[(!_vm.isPC)?_c('div',[_c('div',{style:({ display: 'flex' })},[_c('div',{style:({ width: '35%' })},[_c('img',{style:({
						'object-fit': 'cover',
						width: '80%',
						'aspect-ratio': '1 / 1',
						'border-radius': '10px',
						'margin-top': '10px',
						'margin-left': '12px'
					}),attrs:{"src":_vm.computedImageUrl,"alt":"teacherImage"}})]),_c('div',{staticClass:"card-content",style:({ width: '60%' })},[_c('p',{staticClass:"teacher-name"},[_vm._v(" "+_vm._s(_vm.teacherInfo.teacherName)+" "+_vm._s(_vm.$t('teacherSchedule.honorificTitle'))+" ")]),(_vm.showedAt != 'text')?_c('p',{staticClass:"info-text"},[_vm._v("在住: "+_vm._s(this.teacherInfo.country))]):_vm._e(),_c('TeacherCardOneWordLabel',{staticClass:"catch-phrase-label",attrs:{"one-word":_vm.teacherInfo.catchPhrase}}),(!_vm.isShowDetail)?_c('v-row',[_c('TeacherCardDetailButton',{attrs:{"button-title":"詳細はこちら","is-shown-detail":_vm.isShowDetail},on:{"clickAction":_vm.clickAction}})],1):_c('v-row',[_c('TeacherCardDetailButton',{attrs:{"button-title":"閉じる","is-shown-detail":_vm.isShowDetail},on:{"clickAction":_vm.clickAction}})],1)],1)])]):_c('div',[_c('v-row',{staticClass:"card-content"},[_c('v-col',{attrs:{"cols":"3"}},[_c('img',{style:({
						'object-fit': 'cover',
						width: '80%',
						'aspect-ratio': '1 / 1',
						'border-radius': '10px',
						'margin-top': '10px',
						'margin-left': '12px'
					}),attrs:{"src":_vm.computedImageUrl,"alt":"teacherImage"}})]),_c('v-col',{attrs:{"cols":"9"}},[_c('p',{staticClass:"teacher-name"},[_vm._v(" "+_vm._s(_vm.teacherInfo.teacherName)+" "+_vm._s(_vm.$t('teacherSchedule.honorificTitle'))+" ")]),(_vm.showedAt != 'text')?_c('p',{staticClass:"info-text"},[_vm._v("在住: "+_vm._s(this.teacherInfo.country))]):_vm._e(),_c('TeacherCardOneWordLabel',{staticClass:"catch-phrase-label",style:({ marginTop: _vm.pcMarginTop }),attrs:{"one-word":_vm.teacherInfo.catchPhrase}}),(!_vm.isShowDetail)?_c('v-row',[_c('TeacherCardDetailButton',{attrs:{"button-title":"詳細はこちら","is-shown-detail":_vm.isShowDetail},on:{"clickAction":_vm.clickAction}})],1):_c('v-row',[_c('TeacherCardDetailButton',{attrs:{"button-title":"閉じる","is-shown-detail":_vm.isShowDetail},on:{"clickAction":_vm.clickAction}})],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }