<template>
	<v-container>
		<v-main>
			<PageTitle title-name="チケット購入までの流れ" />
			<BuyTicketProcedure :userInfo="userInfo" />
		</v-main>
	</v-container>
</template>

<script>
import PageTitle from '../Atoms/PageTitle'
import BuyTicketProcedure from '../Organisms/BuyTicketProcedure'

export default {
	name: 'BuyTicketTop',
	data: () => ({
		userInfo: null
	}),
	mounted() {
		this.fetchInitialData()
	},
	components: {
		PageTitle,
		BuyTicketProcedure
	},
	methods: {
		// API通信を行うのに必要な情報を取得する
		fetchInitialData() {
			this.userInfo = this.$store.getters['user/getUserInfo']
		}
	},
	props: []
}
</script>

<style lang="scss" scoped></style>
