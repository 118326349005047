<template>
	<VmDialogCard
		:title="cardTitle"
		:buttonTitle="$t('teacherSchedule.addRegularLessonButton')"
		:cancelButtonTitle="$t('teacherSchedule.cancel')"
		@click="addRegularLesson"
		@close="cancel"
		:disabled="!buttonValidation"
	>
		<template #cardContent>
			<v-container>
				<!-- 曜日選択 -->
				<v-row>
					<!-- 曜日 -->
					<p class="subtitle">{{ $t('teacherSchedule.dayOfWeek') }}</p>
				</v-row>
				<v-row>
					<v-select
						:items="dayOfWeekItems"
						v-model="selectedDayOfWeek"
						:label="$t('teacherSchedule.requireSelection')"
						outlined
						background-color="white"
						dense
						hide-details
						@change="updateStartDateItems"
					></v-select>
				</v-row>
			</v-container>
			<v-container class="containerArea">
				<v-row>
					<!-- 時間 -->
					<p class="subtitle">{{ $t('teacherSchedule.lessonTime') }}</p>
					<TimeZoneIcon />
				</v-row>
				<div class="date-form-area">
					<!-- 開始時間タイムピッカー -->
					<p class="dateFormLabel">{{ $t('teacherSchedule.startTime') }}</p>
					<v-col cols="9" xs="9" sm="5" md="5" lg="5" class="picker">
						<TimePicker v-model="lessonStartTime" :minuteInterval="5" />
					</v-col>
					<p class="dateFormLabel">
						{{ $t('teacherSchedule.lessonTime') }}
					</p>
					<LessonTimeSelector
						:computedStartTime="computedStartTime"
						@validation="handleValidation"
						@update:endTime="handleEndTimeChange"
						@update:lessonTime="lessonTime = $event"
						hideEndTime
					/>
				</div>
			</v-container>
			<v-container class="containerArea">
				<!-- 生徒選択 -->
				<v-row>
					<!-- 生徒名 -->
					<p class="subtitle">{{ $t('teacherSchedule.studentName') }}</p>
				</v-row>
				<!-- 生徒を選択してください -->
				<v-row>
					<v-select
						:items="studentNames"
						v-model="selectedStudent"
						:label="$t('teacherSchedule.requireSelectionStudents')"
						outlined
						background-color="white"
						dense
						hide-details
					></v-select>
				</v-row>
			</v-container>
			<v-container class="containerArea">
				<v-row>
					<!-- レッスン開始日 -->
					<p class="subtitle">{{ $t('teacherSchedule.lessonStartDay') }}</p>
				</v-row>
				<v-row>
					<v-select
						:items="startDateItems"
						v-model="receptionStartDate"
						item-text="label"
						item-value="value"
						:label="$t('teacherSchedule.requireStartDate')"
						outlined
						background-color="white"
						dense
						hide-details
					></v-select>
				</v-row>
			</v-container>
		</template>
	</VmDialogCard>
</template>

<script>
import TimeZoneIcon from '../Atoms/TimeZoneIcon'
import TimePicker from '../Atoms/TimePicker'
import VmDialogCard from '../Molecules/VmDialogCard'
import LessonTimeSelector from '../Molecules/LessonTimeSelector'

export default {
	name: 'AddRegularLessonCardForTeacher',
	inject: ['reloadRouter'],
	components: {
		TimeZoneIcon,
		TimePicker,
		VmDialogCard,
		LessonTimeSelector
	},
	data: () => ({
		startTimeMenu: false,
		endTimeMenu: false,
		selectedDayOfWeek: '',
		lessonStartTime: '00:00',
		receptionStartDate: null,
		studentNames: [],
		selectedStudent: '',
		hasGivenClassesStudents: [],
		startDateItems: [],
		lessonEndTime: null,
		lessonTime: null,
		isSelectedLessonTimeValid: false,
		selectedStartDate: null
	}),
	props: ['courseName', 'cardTitle', 'userInfo'],
	computed: {
		dayOfWeekItems() {
			const weekDayArrayJa = ['日', '月', '火', '水', '木', '金', '土']
			const weekDayArrayDe = ['So.', 'Mo.', 'Di.', 'Mi.', 'Do.', 'Fr.', 'Sa.']
			switch (this.$i18n.locale) {
				case 'ja':
					return weekDayArrayJa
				case 'de':
					return weekDayArrayDe
				default:
					return weekDayArrayJa
			}
		},
		// 曜日を数字で扱う
		computedIntDayOfWeek() {
			const weekDayArrayJa = ['日', '月', '火', '水', '木', '金', '土']
			const weekDayArrayDe = ['So.', 'Mo.', 'Di.', 'Mi.', 'Do.', 'Fr.', 'Sa.']
			switch (this.$i18n.locale) {
				case 'ja':
					return weekDayArrayJa.indexOf(this.selectedDayOfWeek)
				case 'de':
					return weekDayArrayDe.indexOf(this.selectedDayOfWeek)
				default:
					return weekDayArrayJa.indexOf(this.selectedDayOfWeek)
			}
		},
		computedStartTime() {
			return '2021-01-01 ' + this.lessonStartTime
		},
		buttonValidation() {
			// レッスン時間を選択していなかったらアウト
			if (!this.isSelectedLessonTimeValid) return false

			// 曜日を選択していなかったらアウト
			if (!this.selectedDayOfWeek) return false

			// レッスン開始日を選択していなかったらアウト
			if (!this.receptionStartDate) return false

			// 誰も選択されていなかったらアウト
			if (!this.selectedStudent) return false

			return true
		}
	},
	async mounted() {
		this.initScheduleDate()

		try {
			await this.fetchStudentsInCharge()
		} catch (error) {
			console.log(error)
		}
		this.connectStudentIdAndName()
	},
	methods: {
		initScheduleDate() {
			this.receptionStartDate = this.$moment().format('YYYY-MM-DD')
		},
		fetchStudentsInCharge() {
			// APIから今まで授業をしたことのある生徒情報を取得する
			return new Promise((resolve) => {
				const header = {
					headers: {
						Authorization: `Bearer ${this.userInfo.accessToken}`
					}
				}
				this.axios
					.get(`/api/students/hasGivenClasses?teacherId=${this.userInfo.teacherId}&lessonCourseId=2`, header)
					.then((response) => {
						this.hasGivenClassesStudents = response.data.students.filter((v) => v)

						resolve()
					})
					.catch((error) => {
						console.log(error)
					})
			})
		},
		connectStudentIdAndName() {
			// 生徒情報を加工して 123_example の形にする
			for (let student of this.hasGivenClassesStudents) {
				const idName = student.id + '_' + student.name
				this.studentNames.push(idName)
			}
		},
		async addRegularLesson() {
			// 以下のレッスンを新しく追加しますか？\n ※生徒にレッスン予約完了メールが送信されます \n 生徒: \n 曜日: \n 開始: \n 終了:
			if (
				confirm(
					`${this.$t('teacherSchedule.addLessonConfirmation')}\n\n ${this.$t('teacherSchedule.student')}: ${
						this.selectedStudent
					} \n ${this.$t('teacherSchedule.dayOfWeek')}: ${this.selectedDayOfWeek} \n ${this.$t(
						'teacherSchedule.startTime'
					)}： ${this.lessonStartTime}  \n ${this.$t('teacherSchedule.endTime')}： ${
						this.$moment(this.lessonEndTime).format('HH:mm') === '00:00'
							? '24:00'
							: this.$moment(this.lessonEndTime).format('HH:mm')
					} \n ${this.$t('teacherSchedule.lessonStartDay')}: ${this.$moment(this.receptionStartDate).format(
						'YYYY/MM/DD'
					)} `
				)
			) {
				// 加工済みの生徒名からstudentIdを取り出す
				const studentId = Number(this.selectedStudent.split('_')[0])

				let isFirstLesson = null
				try {
					isFirstLesson = await this.judgeFirstLesson(this.userInfo.teacherId, studentId)
				} catch (error) {
					console.log(error)
				}

				const startTime = this.$convertToDateFormat(this.computedIntDayOfWeek, this.lessonStartTime)
				// 終了時刻はstartTimeにlessonTimeを足したもの
				const endTime = this.$moment(startTime).add(this.lessonTime, 'minutes').format()

				const regularScheduleData = {
					teacherId: this.userInfo.teacherId,
					studentId: studentId,
					lessonCourseId: 2, // 今は必ずプライベートコースなので
					startTime: startTime,
					endTime: endTime,
					isFirstLesson: isFirstLesson,
					receptionStartDate: this.receptionStartDate,
				}

				this.registerRequest(regularScheduleData)
			}
		},
		registerRequest(regularScheduleData) {
			const header = {
				headers: {
					Authorization: `Bearer ${this.userInfo.accessToken}`
				}
			}

			this.axios
				.post('/api/lessons/regularReserve', regularScheduleData, header)
				.then(() => {
					this.reload()
					this.cancel()
				})
				.catch((error) => {
					alert(error.response.data.error.message)
				})
		},
		cancel() {
			this.$emit('cancelAction')
		},
		reload() {
			this.$emit('reload')
		},
		// 初回授業かどうかの判定のために、studentIdと今開いている講師のteacherIdで授業を絞って取得する
		judgeFirstLesson(teacherId, studentId) {
			return new Promise((resolve, reject) => {
				const header = {
					headers: {
						Authorization: `Bearer ${this.userInfo.accessToken}`
					}
				}

				this.axios
					//TODO: 初回レッスンの変更について本番に上げていいってなったらこれあげる
					// .get(
					//   `/api/lessons?teacherId=${teacherId}&studentId=${studentId}&lessonCourseId=2&status=0&status=1`,
					//   header
					// )
					.get(`/api/lessons?teacherId=${teacherId}&studentId=${studentId}&lessonCourseId=2`, header)
					.then((response) => {
						const lessons = response.data.lessons

						if (lessons.length > 0) {
							resolve(false)
						} else {
							resolve(true)
						}
					})
					.catch((error) => {
						console.log(error)
						reject()
					})
			})
		},
		// 選択された曜日・開始時刻・終了時刻から開始日の選択肢を生成する
		updateStartDateItems() {
			if (!this.selectedDayOfWeek) {
				this.startDateItems = []
				return
			}
			this.startDateItems = []

			// selectedDayOfWeekとlessonStartTimeとlessonEndTimeから選択肢を生成する
			// 曜日・日時に対応する直近の日付を算出
			let startDate = this.$moment(
				this.$moment().add(2, 'days').startOf('week').add(this.computedIntDayOfWeek, 'days').format('YYYY-MM-DD') +
					'T' +
					this.lessonStartTime +
					this.$moment().format('Z')
			).format()

			startDate = new Date(startDate).toISOString()

			let twoDaysAfterTime = this.$moment().add(2, 'days')
			if (twoDaysAfterTime.isAfter(this.$moment(startDate))) {
				startDate = new Date(this.$moment(startDate).add(1, 'weeks').format()).toISOString()
			}

			for (let i = 0; i < 8; i++) {
				const date = this.$moment(startDate).add(i, 'weeks').startOf('day')
				const label = date.format('YYYY/MM/DD') // 表示用ラベル
				const value = date.format('YYYY-MM-DDT00:00:00+00:00');
				this.startDateItems.push({ label: label, value: value })
			}
		},
		handleValidation(isValid) {
			this.isSelectedLessonTimeValid = isValid
		},
		handleEndTimeChange(newEndTime) {
			this.lessonEndTime = newEndTime
		}
	},
	head: {}
}
</script>

<style lang="scss" scoped>
.containerArea {
	margin-top: 20px;
}
.subtitle {
	font-size: 1.4em;
	font-weight: bold;
	margin: 20px 0 15px 0;
}
.picker {
	margin: 10px 0 0 -10px;
}
.date-form-area {
	margin: 30px 0 0 0;
}
.dateFormLabel {
	font-weight: bold;
	margin-top: 10px;
	font-size: 1em;
}
</style>
