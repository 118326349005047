var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"rounded",attrs:{"width":"100%","text-color":"#272727","tile":"","elevation":hover ? 6 : 2,"hover":"","to":_vm.to},on:{"click":_vm.clickAction}},[(!_vm.isPC)?_c('div',[_c('div',{style:({ display: 'flex' })},[_c('div',{style:({ width: '35%' })},[_c('img',{style:({
							'object-fit': 'cover',
							width: '80%',
							'aspect-ratio': '1 / 1',
							'border-radius': '10px',
							'margin-top': '10px',
							'margin-left': '12px'
						}),attrs:{"src":_vm.imageUrl,"alt":"teacherImage"}})]),_c('div',{staticClass:"card-content",style:({ width: '60%' })},[_c('p',{staticClass:"teacher-name"},[_vm._v(_vm._s(_vm.teacherName)+" "+_vm._s(_vm.$t('teacherSchedule.honorificTitle')))]),_c('p',{staticClass:"skype-id-text"},[_vm._v(_vm._s(_vm.skypeId))]),_c('TeacherCardOneWordLabel',{staticClass:"catch-phrase-label",attrs:{"one-word":_vm.catchPhrase}}),_c('TeacherCardNextLabel',{attrs:{"name":"予約する＞"}})],1)])]):_c('div',[_c('v-row',{staticClass:"card-content"},[_c('v-col',{attrs:{"cols":"8","xs":"8","sm":"3","md":"3","lg":"3","xl":"3"}},[_c('img',{style:({
							'object-fit': 'cover',
							width: '80%',
							'aspect-ratio': '1 / 1',
							'border-radius': '10px',
							'margin-top': '10px',
							'margin-left': '12px'
						}),attrs:{"src":_vm.imageUrl,"alt":"teacherImage"}})]),_c('v-col',{attrs:{"cols":"9"}},[_c('p',{staticClass:"teacher-name"},[_vm._v(_vm._s(_vm.teacherName)+" "+_vm._s(_vm.$t('teacherSchedule.honorificTitle')))]),_c('p',{staticClass:"skype-id-text"},[_vm._v(_vm._s(_vm.skypeId))]),_c('TeacherCardOneWordLabel',{staticClass:"catch-phrase-label",attrs:{"one-word":_vm.catchPhrase}}),_c('TeacherCardNextLabel',{attrs:{"name":"予約する＞"}})],1)],1)],1)])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }