<template>
	<VmDialogCard
		title="レッスン追加"
		:buttonTitle="$t('teacherSchedule.addLessonButton')"
		:cancelButtonTitle="$t('teacherSchedule.cancel')"
		@click="registerLesson"
		@close="close"
		:disabled="!buttonValidation"
	>
		<template #cardContent>
			<v-container>
				<div class="date-form-area">
					<!-- 開始日時のフォーム -->
					<DateAndTimeForm
						:formLabelText="$t('teacherSchedule.startTime')"
						:date.sync="scheduleStartDate"
						:showedDate="computedScheduleStartDate"
						@closeDatePicker="inputEndDate"
						:time.sync="scheduleStartTime"
						:minuteInterval="5"
						@closeTimePicker="inputEndTime"
					/>
					<!-- 終了日時のフォーム -->
					<DateAndTimeForm
						:formLabelText="$t('teacherSchedule.endTime')"
						:date.sync="scheduleEndDate"
						:showedDate="computedScheduleEndDate"
						:time.sync="scheduleEndTime"
						:minuteInterval="5"
					/>
				</div>
			</v-container>
			<!-- レッスンコース -->
			<v-container>
				<v-row>
					<v-select
						:items="lessonCourseNames"
						v-model="selectedLessonCourse"
						label="レッスンコース"
						outlined
						background-color="white"
						no-data-text="レッスンコースがありません"
						item-text="name"
						item-value="id"
						return-object
					>
					</v-select>
				</v-row>
			</v-container>
			<!-- 生徒名 -->
			<v-container>
				<v-row>
					<v-autocomplete
						:items="studentNames"
						v-model="selectedStudent"
						label="生徒名"
						outlined
						background-color="white"
						no-data-text="生徒がいません"
						item-text="name"
						item-value="id"
						return-object
					>
					</v-autocomplete>
				</v-row>
			</v-container>
			<!-- 講師名 -->
			<v-container>
				<v-row>
					<v-autocomplete
						:items="teacherNames"
						v-model="selectedTeacher"
						label="講師名"
						outlined
						background-color="white"
						no-data-text="講師がいません"
						item-text="name"
						item-value="id"
						return-object
					>
					</v-autocomplete>
				</v-row>
			</v-container>
			<!-- ステータス -->
			<v-container>
				<v-row>
					<v-select
						:items="statusItems"
						v-model="selectedStatus"
						label="ステータス"
						outlined
						background-color="white"
						no-data-text="ステータスがありません"
						item-text="name"
						item-value="id"
						return-object
					>
					</v-select>
				</v-row>
			</v-container>
			<!-- 初回 -->
			<v-container>
				<v-row>
					<v-select
						:items="firstLessonItems"
						v-model="selectedFirstLesson"
						label="初回レッスン"
						outlined
						background-color="white"
						item-text="name"
						no-data-text="初回レッスンがありません"
						return-object
					>
					</v-select>
				</v-row>
			</v-container>
		</template>
	</VmDialogCard>
</template>

<script>
import DateAndTimeForm from '../../../Molecules/DateAndTimeForm'
import VmDialogCard from '../../../Molecules/VmDialogCard'
import { mapGetters, mapActions } from 'vuex'

export default {
	name: 'VmAdminLessonAddDialog',
	components: {
		DateAndTimeForm,
		VmDialogCard
	},
	data: () => ({
		scheduleStartDate: '2020-12-01',
		scheduleEndDate: '2020-12-01',
		scheduleStartTime: '00:00',
		scheduleEndTime: '00:00',
		selectedLessonCourse: {},
		selectedStatus: {},
		selectedFirstLesson: { name: 'いいえ', value: false },
		selectedStudent: null,
		selectedTeacher: null,
		error: ''
	}),
	props: ['courseId', 'selectedOpen', 'teacherScheduleId', 'calendarValue'],
	created() {
		this.fetchStudents({
			accessToken: this.userInfo.accessToken
		})
		this.fetchTeachers({
			accessToken: this.userInfo.accessToken
		})
	},
	async mounted() {
		this.initScheduleDate()
	},
	computed: {
		...mapGetters({
			userInfo: 'user/getUserInfo',
			students: 'adminStudent/students',
			studentsLoading: 'adminStudent/loading',
			teachers: 'adminTeacher/teachers',
			teachersLoading: 'adminTeacher/loading'
		}),
		computedStartTime() {
			const start = this.scheduleStartDate + ' ' + this.scheduleStartTime
			return this.$moment(start).format('YYYY-MM-DD HH:mm')
		},
		computedEndTime() {
			const end = this.scheduleEndDate + ' ' + this.scheduleEndTime
			return this.$moment(end).format('YYYY-MM-DD HH:mm')
		},
		computedScheduleStartDate: {
			get() {
				this.$moment.locale(this.$i18n.locale)
				return this.$moment(this.scheduleStartDate).format('YYYY/MM/DD(ddd)')
			},
			set(value) {
				this.scheduleStartDate = this.$moment(value).format('YYYY-MM-DD')
			}
		},
		computedScheduleEndDate: {
			get() {
				this.$moment.locale(this.$i18n.locale)
				return this.$moment(this.scheduleEndDate).format('YYYY/MM/DD(ddd)')
			},
			set(value) {
				this.scheduleEndDate = this.$moment(value).format('YYYY-MM-DD')
			}
		},
		lessonCourseNames() {
			return [
				{ name: '会話コース', id: 1 },
				{ name: 'プライベートコース', id: 2 },
				{ name: 'テキストコース', id: 3 }
			]
		},
		statusItems() {
			return [
				{ name: '未', id: 0 },
				{ name: '完了', id: 1 },
				{ name: 'キャンセル', id: 2 }
			]
		},
		firstLessonItems() {
			return [
				{ name: 'いいえ', value: false },
				{ name: 'はい', value: true }
			]
		},
		studentNames() {
			if (this.students && this.students.length) {
				return this.students.map((v) => {
					return { name: v.name, id: v.id }
				})
			}
			return []
		},
		teacherNames() {
			if (this.teachers && this.teachers.length) {
				return this.teachers.map((v) => {
					return { name: v.teacherName, id: v.id }
				})
			}
			return []
		},
		buttonValidation() {
			// 時間がおかしかったらアウト
			if (!this.lessonTimeValidation) {
				return false
			}

			return true
		},

		// バリデーション - 終了時刻 > 開始時刻になっていればok
		// 30分単位になっていればok
		lessonTimeValidation() {
			const start = this.$moment(this.scheduleStartDate + ' ' + this.scheduleStartTime)
			const end = this.$moment(this.scheduleEndDate + ' ' + this.scheduleEndTime)

			// 終了時刻 = 開始時刻になっているためアウト
			if (this.scheduleStartTime === this.scheduleEndTime) {
				return false
			}

			// 終了時刻 < 開始時刻になっているのでok
			if (end.isBefore(start)) {
				return false
			}
			return true
		}
	},
	methods: {
		...mapActions({
			fetchStudents: 'adminStudent/fetchStudents',
			fetchTeachers: 'adminTeacher/fetchTeachers',
			createLessons: 'adminLesson/createLessons'
		}),
		initScheduleDate() {
			this.scheduleStartDate = this.$moment().format('YYYY-MM-DD')
			this.scheduleEndDate = this.$moment().format('YYYY-MM-DD')
		},
		async registerLesson() {
			const payload = {
				startTime: this.$convertToPost(this.computedStartTime),
				endTime: this.$convertToPost(this.computedEndTime),
				teacherId: this.selectedTeacher.id,
				studentId: this.selectedStudent.id,
				lessonCourseId: this.selectedLessonCourse.id,
				status: this.selectedStatus.id,
				isFirstLesson: this.selectedFirstLesson.value
			}
			if (
				confirm(
					`以下の内容で登録します
          生徒名: ${this.selectedStudent.name}
          講師名： ${this.selectedTeacher.name}
          開始時刻： ${this.computedStartTime}
          終了時刻： ${this.computedEndTime}
          コース： ${this.selectedLessonCourse.name}
          ステータス： ${this.selectedStatus.name}
          初回： ${this.selectedFirstLesson.name}`
				)
			) {
				const isSuccess = await this.createLessons({ accessToken: this.userInfo.accessToken, payload: payload })
				if (isSuccess) {
					this.$emit('complete')
				}
			}
		},
		close() {
			this.$emit('close')
		},
		inputEndDate(val) {
			this.scheduleEndDate = val
		},
		inputEndTime(val) {
			// 開始時刻のtimepickerが入力された後の挙動

			// 入力されたstartTimeの時間を分解
			const valHour = Number(val.split(':')[0])
			const valMinute = Number(val.split(':')[1])

			// それ以外の場合は1時間プラスした時間を入れる
			let strValHour = String(valHour + 1)
			if (valHour + 1 < 10) {
				strValHour = '0' + (valHour + 1)
			}

			let strValMinute = String(valMinute)
			if (valMinute < 10) {
				strValMinute = '0' + valMinute
			}

			if (valHour == 23) {
				strValHour = '00'
				this.scheduleEndDate = this.$moment(this.scheduleStartDate).add(1, 'd').format('YYYY-MM-DD')
			}
			this.scheduleEndTime = strValHour + ':' + strValMinute
		}
	},
	head: {}
}
</script>

<style lang="scss" scoped>
.report-subtitle {
	font-size: 1.4em;
	font-weight: bold;
	margin: 20px 0 15px 0;
}
.button {
	margin-bottom: 20px;
}
.date-form-area {
	margin: 20px 0 0 0;
}
.checkbox-col {
	padding: 0;
}
</style>
