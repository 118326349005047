<template>
	<div class="accordion" @click.stop="onClick($event)" :style="{ color: textColor }">
		<p class="accordion__text" style="text-transform: none">{{ text }}</p>
		<v-icon
			:style="{
				'font-size': this.isPC ? '1.2em' : '1em',
				color: textColor
			}"
		>
			mdi-menu-down
		</v-icon>
	</div>
</template>

<script>
export default {
	name: 'VmAccordionMenu',
	props: {
		text: {
			type: String,
			default: ''
		},
		textColor: {
			type: String,
			default: '#6d6f6c'
		}
	},
	components: {},
	data: () => ({}),
	created() {},
	mounted() {},
	computed: {},
	methods: {
		onClick($event) {
			this.$emit('click', $event)
		}
	}
}
</script>

<style lang="scss" scoped>
p {
	margin: 0;
	font-weight: bold;
}
.accordion {
	display: flex;
	cursor: pointer;

	&__text {
		font-size: 0.9em;
	}
}
</style>
