<template>
	<validationProvider rules="max:30" v-slot="{ errors, valid }" immediate name="専攻名">
		<p>
			<FormTitleText titleText="専攻名" />
			<FormAttentionText attentionText="※30字まで" />
		</p>
		<v-text-field
			label=""
			v-model="inputedMajor"
			outlined
			background-color="white"
			hide-details
			:success="valid"
		></v-text-field>
		<FormErrorText :errorText="errors[0]" />
	</validationProvider>
</template>

<script>
import FormTitleText from '../Atoms/FormTitleText'
import FormErrorText from '../Atoms/FormErrorText'
import FormAttentionText from '../Atoms/FormAttentionText'

export default {
	name: 'MajorForm',
	data: () => ({}),
	props: ['value'],
	mounted() {},
	computed: {
		inputedMajor: {
			get() {
				return this.value
			},
			set(value) {
				this.$emit('input', value)
			}
		}
	},
	methods: {},
	components: {
		FormTitleText,
		FormErrorText,
		FormAttentionText
	}
}
</script>

<style lang="scss" scoped></style>
