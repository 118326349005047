import axios from 'axios'

export const conversationTeachers = {
	namespaced: true,
	state: {
		conversationTeachers: [],
		loading: false
	},
	getters: {
		loading: (state) => {
			return state.loading
		},
		conversationTeachers: (state) => {
			return state.conversationTeachers
		}
	},
	mutations: {
		prepareConnect(state) {
			state.loading = true
		},
		stopLoading(state) {
			state.loading = false
		},
		fetchConversationTeachersSuccess(state, teachers) {
			state.conversationTeachers = teachers
		},
		resetConversationTeachers(state) {
			state.conversationTeachers = []
		}
	},
	actions: {
		async fetchConversationTeachers({ commit }, params) {
			commit('prepareConnect')

			return axios
				.get(`/api/teachers/list`, { params })
				.then((res) => {
					let teachers = res.data.teachers

					// NOTE: 今はフロントでフィルタリングしているが、本来はバックエンドでフィルタリングすべき
					if (params.empty) {
						teachers = teachers.filter((teacher) => {
							switch (params.empty) {
								case '多い':
									return teacher.availableTimes >= 2400
								case '普通':
									return teacher.availableTimes >= 1200 && teacher.availableTimes < 2400
								case 'わずか':
									return teacher.availableTimes >= 0 && teacher.availableTimes < 1200
								default:
									return true
							}
						})
					}
					commit('fetchConversationTeachersSuccess', teachers)
					commit('stopLoading')
				})
				.catch((err) => {
					commit('stopLoading')
					console.log(err)
				})
		},
		async resetConversationTeachers({ commit }) {
			commit('resetConversationTeachers')
		}
	}
}
