<template>
	<div :style="{ background: '#FAFAFA' }">
		<v-container>
			<PageTitle title-name="Vollmond講師一覧" />
			<v-row justify="center" no-gutters>
				<p
					class="attention"
					:style="{
						fontSize: attentionFontSize
					}"
				>
					このページではVollmondに登録している先生一覧を表示しています。気になる先生がいたら、<a
						href="https://vollmond.online/deutschkurse/"
						target="_blank"
						rel="noopener"
						>各コース受付ページ</a
					>から申し込んで下さい。
					<br />
					<span
						:style="{
							fontSize: '0.8em'
						}"
					>
						※各講師、キャパシティがございます。新規申し込みを停止している講師もおりますこと、予めご了承ください。
					</span>
				</p>
			</v-row>
			<SortListArea @changeAction="changeSortCondition" :lessonCourseId="2" atAllList />
			<v-row justify="center" no-gutters :style="{ 'margin-left': '2px' }">
				<v-col cols="12" xs="12" sm="10" md="10" lg="10" xl="10">
					<v-checkbox
						v-if="showCheckBox"
						v-model="showOnlyAvailableTeacher"
						label="現在受付中の講師のみ表示する"
						color="#DCC268"
						@change="handleChangeCheckBox"
					></v-checkbox>
				</v-col>
			</v-row>
			<div v-if="allTeachersLoading" :style="{ 'margin-top': '40px', 'margin-bottom': '200px' }">
				<VmLoading />
			</div>
			<div v-else>
				<div v-if="this.showedTeachers && this.showedTeachers.length" :style="{ 'min-height': '800px' }">
					<v-row>
						<v-col cols="12" xs="12" sm="6" md="4" lg="4" xl="4" v-for="(teacher, i) in this.showedTeachers" :key="i">
							<TeacherListCard
								:teacherInfo="teacher"
								:style="{ 'margin-bottom': '20px' }"
								:show-empty="false"
								:disabled="!canReserve(teacher)"
								@clickAction="toSchedule(teacher)"
								showAt="ALL"
							/>
						</v-col>
					</v-row>
				</div>
				<div v-else :style="{ 'min-height': '800px' }">
					<v-row no-gutters justify="center">
						<p v-if="isChild" :style="{ 'text-align': 'center' }">
							お子様に対応できる講師には限りがあります。<br />お手数ですが「おすすめしてもらう」よりお問い合わせいただきますようお願いいたします。
						</p>
						<p v-else>条件に該当する講師がいません</p>
					</v-row>
				</div>
			</div>
		</v-container>
		<v-dialog v-model="showChoiceOfCourseDialog" v-if="showChoiceOfCourseDialog" offset-x max-width="500px">
			<ChoiceCourseDialog
				@close="showChoiceOfCourseDialog = false"
				@toPriv="toPriv(selectedTeacherId)"
				@toConv="toConv(selectedTeacherId)"
			/>
		</v-dialog>
	</div>
</template>

<script>
import PageTitle from '../Atoms/PageTitle'
import TeacherListCard from '../Organisms/TeacherListCard'
import SortListArea from '../Organisms/SortListArea'
import ChoiceCourseDialog from '../Molecules/ChoiceCourseDialog.vue'
import VmLoading from '../Atoms/VmLoading'
import { mapGetters, mapActions } from 'vuex'
import { CONVERSATION_LESSON_COURSE_ID, PRIVATE_LESSON_COURSE_ID } from '../../const'
import { calculateAgeFromBirthDate } from '../../util'

export default {
	name: 'AllTeacherList',
	components: {
		PageTitle,
		TeacherListCard,
		SortListArea,
		ChoiceCourseDialog,
		VmLoading
	},
	data: () => ({
		showTransitionDialog: false,
		selectedTeacherId: null,
		showCheckBox: false,
		showOnlyAvailableTeacher: false,
		filterItems: null,
		showChoiceOfCourseDialog: false
	}),
	created() {
		if (this.allTeachers.length === 0) {
			this.fetchTeacherList()
		}

		// レッスンコース選択がされている場合はチェックボックスを表示する
		if (this.$route.query.lessonCourseId) {
			this.showCheckBox = true
			this.showOnlyAvailableTeacher = this.$route.query.available == 'true' ? true : false
		} else {
			this.showCheckBox = false
		}
	},
	beforeRouteUpdate(to, from, next) {
		next()

		this.fetchTeacherList()
	},
	computed: {
		...mapGetters({
			userInfo: 'user/getUserInfo',
			allTeachers: 'allTeachers/allTeachers',
			allTeachersLoading: 'allTeachers/loading'
		}),
		showedTeachers() {
			if (!this.allTeachers) return []

			// プライベートコースを選択中でユーザー種別が「子供」の場合は、対象年齢を上回っている講師のみ表示するように
			if (this.selectedPrivate && this.isChild) {
				const studentAge = calculateAgeFromBirthDate(this.userInfo.birthDay)
				if (!studentAge) return []
				return this.allTeachers.filter((teacher) => teacher.minAvailableStudentAge <= studentAge)
			}

			// 管理者とオペレータは除外し、ユーザがテストアカウントでない場合はテストアカウントも除外
			return this.allTeachers.filter((teacher) => {
				const isNonAdminOperator = !teacher.isAdmin && !teacher.isOperator // 管理者とオペレータは除外

				const isCurrentUserTestAccount = this.userInfo.isTestAccount
				const isNonTestAccountTeacher = !teacher.isTestAccount
				const isNonTestAccount = isCurrentUserTestAccount || isNonTestAccountTeacher
				return isNonAdminOperator && isNonTestAccount
			})
		},
		attentionFontSize() {
			if (this.isPC) {
				return '1em'
			} else {
				return '0.8em'
			}
		},
		isChild() {
			return this.userInfo.studentCategory == 'CHILD'
		},
		selectedPrivate() {
			return (
				Boolean(this.$route.query.lessonCourseId) &&
				Number(this.$route.query.lessonCourseId) === PRIVATE_LESSON_COURSE_ID
			)
		}
	},
	methods: {
		...mapActions({
			fetchAllTeachers: 'allTeachers/fetchAllTeachers'
		}),
		async fetchTeacherList() {
			// レッスンコースが指定されていない場合は包括的なteachingLevelを使用する
			const teachingLevel = !this.$route.query.lessonCourseId ? this.$route.query.teachingLevel : undefined
			const privLevel =
				this.$route.query.lessonCourseId == PRIVATE_LESSON_COURSE_ID ? this.$route.query.teachingLevel : undefined
			const convLevel =
				this.$route.query.lessonCourseId == CONVERSATION_LESSON_COURSE_ID ? this.$route.query.teachingLevel : undefined

			const params = {
				lessonCourseId: this.$route.query.lessonCourseId ?? undefined,
				country: this.$route.query.country ?? undefined,
				compatibleLevel: this.$route.query.compatibleLevel ?? undefined,
				teachingLevel: teachingLevel,
				privLevel: privLevel,
				convLevel: convLevel,
				keyword: this.$route.query.keyword ?? undefined,
				available: this.$route.query.available ?? undefined
			}
			await this.fetchAllTeachers(params)
		},
		// ソート条件を変更した後、APIを叩きデータを再取得する
		changeSortCondition(value) {
			this.filterItems = value

			// レッスンコース選択がされている場合はチェックボックスを表示する
			if (value.lessonCourseId) {
				this.showCheckBox = true
			} else {
				this.showCheckBox = false
			}

			const level = value.compatibleLevel === '完全初級' ? 'A0' : value.compatibleLevel

			const query = {}
			if (value.lessonCourseId) query.lessonCourseId = value.lessonCourseId
			if (value.country) query.country = value.country
			if (level) query.compatibleLevel = level
			if (value.teachingLevel) query.teachingLevel = value.teachingLevel
			if (value.keyword) query.keyword = value.keyword

			// 受付中講師のみ表示するチェックボックスがあれば、引き続きクエリに追加
			if (this.$route.query.available) {
				query.available = this.$route.query.available
			}

			this.handleRoute(query)
		},
		canConvReserve(teacher) {
			return (
				teacher.lessonCourseIds.includes(Number(CONVERSATION_LESSON_COURSE_ID)) &&
				teacher.isVisibleForConv &&
				teacher.hasAvailableSchedules
			)
		},
		canPrivReserve(teacher) {
			if (!teacher.isVisibleForPriv) return false
			if (!teacher.lessonCourseIds.includes(Number(PRIVATE_LESSON_COURSE_ID))) return false

			// ユーザー種別が「子供」の場合は、対象年齢を上回っている講師
			if (this.isChild) {
				const studentAge = calculateAgeFromBirthDate(this.userInfo.birthDay)
				if (!studentAge) return false
				return teacher.minAvailableStudentAge <= studentAge
			}

			return true
		},
		canReserve(teacher) {
			return this.canConvReserve(teacher) || this.canPrivReserve(teacher)
		},
		toSchedule(teacher) {
			if (this.canConvReserve(teacher) && this.canPrivReserve(teacher)) {
				this.selectedTeacherId = teacher.id
				this.showChoiceOfCourseDialog = true
				return
			}
			if (this.canConvReserve(teacher)) {
				this.toConv(teacher.id)
				return
			}
			if (this.canPrivReserve(teacher)) {
				this.toPriv(teacher.id)
				return
			}
		},
		toConv(teacherId) {
			this.$router
				.push({
					path: 'conversationTeacherSchedule',
					query: { teacherId: teacherId }
				})
				.catch((err) => console.log(err))
		},
		toPriv(teacherId) {
			this.$router
				.push({
					path: 'privateTeacherSchedule',
					query: { teacherId: teacherId }
				})
				.catch((err) => console.log(err))
		},
		handleChangeCheckBox(value) {
			const routeQuery = {
				...this.$route.query,
				available: value
			}
			this.handleRoute(routeQuery)
		},
		handleRoute(routeQuery) {
			if (
				JSON.stringify(Object.entries(routeQuery).sort()) != JSON.stringify(Object.entries(this.$route.query).sort())
			) {
				this.$router
					.push({
						name: 'AllTeacherList',
						query: routeQuery
					})
					.catch((err) => console.log(err))
			}
		}
	},
	head: {
		title: {
			inner: 'teacher-list'
		}
	}
}
</script>

<style lang="scss" scoped>
p {
	white-space: pre-line;
}
.attention {
	margin: 0 0 30px 0;
	text-align: center;
	line-height: 2em;
	color: #6d6f6c;
}
.v-input--selection-controls {
	margin-top: 0;
}
</style>
