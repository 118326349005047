// dayOfWeek(2), startTime("00:00")を入れたら
// startTime 2021-01-03 00:00 を返してくれる関数
// dayOfWeek 日: 0, 月: 1, 火: 2, 水: 3, 木: 4, 金: 5, 土: 6
function convertToDateFormat(dayOfWeek, time) {
	// 定数で意味を明確にします。
	const BASE_YEAR = '2021'
	const SPECIAL_DATE = '2020-12-31'
	const TIME_SUFFIX = ':00'

	// 曜日のインデックス
	const DAY_INDEXES = {
		SUNDAY: 0,
		MONDAY: 1,
		TUESDAY: 2,
		WEDNESDAY: 3,
		THURSDAY: 4,
		FRIDAY: 5,
		SATURDAY: 6
	}

	const HOUR_THRESHOLD_NON_DST = 16 // サマータイム非導入時の時間のしきい値
	const HOUR_THRESHOLD_DST = 17 // サマータイム導入時の時間のしきい値

	const timeZone = this.userInfo.timeZone
	const BERLIN_TIME_ZONE = 'Europe/Berlin'

	// 現在の時刻に基づいて、サマータイム（Daylight Saving Time）が有効かどうかを判断
	const isDaylightSavingTime = this.$moment().isDST()

	// サマータイムに応じて時間のしきい値を設定します。
	const currentHourThreshold = isDaylightSavingTime ? HOUR_THRESHOLD_DST : HOUR_THRESHOLD_NON_DST

	let formattedTime
	const hour = Number(time.split(':')[0])

	// ドイツ時刻 && 土曜日 && timeがしきい値以上の時は、"2020-12-31"になる
	if (
		timeZone === BERLIN_TIME_ZONE &&
		Number(dayOfWeek) === DAY_INDEXES.SATURDAY &&
		hour >= currentHourThreshold &&
		hour <= 24
	) {
		formattedTime = SPECIAL_DATE + 'T' + time + TIME_SUFFIX
	} else {
		// 月日の生成: 曜日インデックスに基づいて調整された日付を生成します。
		const monthDay = String(Number(dayOfWeek) === DAY_INDEXES.SUNDAY ? 1 : Number(dayOfWeek) + 1).padStart(2, '0')
		formattedTime = `${BASE_YEAR}-01-${monthDay}T${time}${TIME_SUFFIX}`
	}
	formattedTime += this.$moment().format('Z')

	return formattedTime
}

export default convertToDateFormat
