<template>
	<div class="container">
		<div class="message-area">
			<h1 class="primary--text complete-reservation-title">予約が完了しました！</h1>
		</div>
		<div class="attention-message-area">
			<p class="attention-message">
				{{ attentionMessage1 }}
				キャンセルポリシー：<a href="https://vollmond.online/cancel-policy/" target="_blank" rel="noopener"
					>https://vollmond.online/cancel-policy/</a
				>
				）
				{{ attentionMessage2 }}
			</p>
		</div>
		<div class="button-area">
			<active-button button-title="続けて予約する" @clickAction="toSchedule" class="to-reserve-button" />
		</div>
		<div class="back-to-top-button">
			<router-link to="/studentTop" style="text-decoration: none">
				<p>トップ画面に戻る</p>
			</router-link>
		</div>
	</div>
</template>

<script>
import ActiveButton from '../Atoms/ActiveButton'

export default {
	name: 'CompletePrivateReservation',
	props: ['teacherId'],
	data: () => ({
		attentionMessage1:
			'■レッスンの日時変更・お休み\n【レッスン開始48時間前まで】\n▷キャンセル料は発生しません。\n・日時の変更が可能です。担当講師へ相談してください。\n・日時変更を行わずお休みにする場合は、マイページトップ画面より「キャンセル」を行ってください。講師にキャンセル通知が届きます。\n\n【レッスン開始48時間前以降】\n▷キャンセル料が発生します( ',
		attentionMessage2:
			'\n・日時変更は行えず、キャンセル扱いとなります。\n・お休みにする場合、レッスン前までに講師へその旨ご連絡ください。無断キャンセルはご遠慮ください。\n・お休みしたレッスンを別日時で振替希望の場合は、別途マイページより新しくレッスン予約を行ってください。'
	}),
	methods: {
		toSchedule() {
			this.$router.push({ path: 'privateTeacherSchedule', query: { teacherId: this.teacherId } })
		}
	},

	components: {
		ActiveButton
	}
}
</script>

<style lang="scss" scoped>
.container {
	max-width: 720px;
}
h1 {
	margin-top: 20px;
	font-weight: bold;
	text-align: center;
	font-size: 20px;
}
@media (min-width: 600px) {
	h1.complete-reservation-title {
		font-size: 32px;
	}
}
.message-area {
	display: flex;
	justify-content: center;
	align-items: center;
}
.to-reserve-button {
	max-width: 600px;
}
.attention-message-area {
	display: flex;
	justify-content: center;
	align-items: center;
}
.attention-message {
	margin: 20px 12px 0 12px;
	max-width: 720px;
	text-align: left;
	white-space: pre-line;
	padding: 20px;
	background-color: #f8f8f8;
	line-height: 1.8em;
	border-radius: 8px;
}
.button-area {
	margin: 0 12px;
	margin-top: 32px;
	display: flex;
	justify-content: center;
	align-items: center;
}
.back-to-top-button {
	margin-top: 32px;
	text-align: center;
}
</style>
