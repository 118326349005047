<template>
	<!-- 日時フォーム -->
	<v-row class="date-form">
		<!-- 開始、終了などのテキスト -->
		<v-col cols="8" xs="3" sm="2" md="2" lg="2" v-if="hasLabel">
			<p class="form-title-text">{{ formLabelText }}：</p>
		</v-col>
		<!-- dateピッカー -->
		<v-col cols="10" xs="10" sm="5" md="5" lg="5" class="picker">
			<DatePicker
				v-model="inputtedDate"
				:showedDate="showedDate"
				:minDate="minDate"
				:maxDate="maxDate"
				@closeDatePicker="closeDatePicker"
				@changeDatePicker="changeDatePicker"
			/>
		</v-col>
		<!-- timeピッカー -->
		<v-col cols="10" xs="10" sm="5" md="5" lg="5" class="picker">
			<TimePicker
				v-model="inputtedTime"
				:minuteInterval="minuteInterval"
				:minTime="minTime"
				:maxTime="maxTime"
				:form="timeForm"
				@closeTimePicker="closeTimePicker"
				@changeTimePicker="changeTimePicker"
			/>
		</v-col>
	</v-row>
</template>

<script>
import DatePicker from '../Atoms/DatePicker'
import TimePicker from '../Atoms/TimePicker'

export default {
	name: 'DateAndTimeForm',
	props: {
		formLabelText: {
			type: String,
			required: false,
			default: ''
		},
		date: {
			type: String,
			required: false,
			default: ''
		},
		showedDate: {
			type: String,
			required: false,
			default: ''
		},
		minDate: {
			type: String,
			required: false,
			default: ''
		},
		maxDate: {
			type: String,
			required: false,
			default: ''
		},
		time: {
			type: String,
			required: false,
			default: ''
		},
		minuteInterval: {
			type: Number,
			required: false,
			default: 0
		},
		minTime: {
			type: String,
			required: false,
			default: ''
		},
		maxTime: {
			type: String,
			required: false,
			default: ''
		},
		timeForm: {
			type: String,
			required: false,
			default: ''
		},
		hasLabel: {
			type: Boolean,
			required: false,
			default: true
		}
	},
	data: () => ({}),
	watch: {
		maxTime() {
			this.adjustTime('max')
		},
		minTime() {
			this.adjustTime('min')
		}
	},
	computed: {
		inputtedDate: {
			get() {
				return this.date
			},
			set(value) {
				this.$emit('update:date', value)
			}
		},
		inputtedTime: {
			get() {
				return this.time
			},
			set(value) {
				this.$emit('update:time', value)
			}
		}
	},
	methods: {
		closeDatePicker(val) {
			this.$emit('closeDatePicker', val)
		},
		closeTimePicker(val) {
			this.$emit('closeTimePicker', val)
		},
		changeDatePicker() {
			this.$emit('changeDatePicker')
		},
		changeTimePicker() {
			this.$emit('changeTimePicker')
		},
		adjustTime(type) {
			const time = this.$moment(`${this.inputtedDate} ${this[type + 'Time']}`, 'YYYY-MM-DD HH:mm')
			const selectedTime = this.$moment(`${this.inputtedDate} ${this.inputtedTime}`, 'YYYY-MM-DD HH:mm')

			if ((type === 'max' && selectedTime.isAfter(time)) || (type === 'min' && selectedTime.isBefore(time))) {
				this.inputtedTime = this[type + 'Time']
			}
		}
	},
	components: {
		DatePicker,
		TimePicker
	}
}
</script>

<style lang="scss" scoped>
.date-form-area {
	margin: 30px 0 10px 0;
}
.date-form {
	margin-top: 10px;
	margin-bottom: 10px;
}
.form-title-text {
	margin: 0 0 0 0;
	display: inline-block;
	vertical-align: bottom;
}
</style>
