<template>
	<div class="menuIconContainer">
		<v-menu bottom nudge-left="70" transition="scale-transition" origin="top right" offset-y open-on-hover>
			<template v-slot:activator="{ on }">
				<button v-on="on" class="userIcon">
					<BaseCirclePicture :imageUrl="iconImage" :imageSize="isPC ? 40 : 32" />
					<div class="iconBadge" v-if="!isStudent && wipCounts">
						<span v-if="wipCounts >= 10" class="badge rounded-pill">{{ wipCounts }}</span>
						<span v-else class="badge rounded-circle">{{ wipCounts }}</span>
					</div>
				</button>
			</template>
			<v-card>
				<v-card-actions>
					<v-col>
						<v-row justify="center">
							<div>
								<p class="teacher-name">
									{{ computedName }}
								</p>
							</div>
						</v-row>
						<!-- プロフィール画面への遷移 -->
						<v-row justify="center">
							<v-btn @click="toMypage" text>
								<p class="icon-menu-text">
									{{ $t('profile.profile') }}
								</p>
							</v-btn>
						</v-row>
						<!-- 生徒請求画面一覧への遷移（生徒のみ） -->
						<v-row v-if="isStudent" justify="center">
							<v-btn @click="toStudentInvoice" text>
								<p class="icon-menu-text">プライベートコース受講料</p>
							</v-btn>
						</v-row>
						<!-- 講師報酬画面一覧への遷移（講師のみ） -->
						<v-row v-if="!isStudent" justify="center">
							<v-btn @click="toTeacherReward" text>
								<p class="icon-menu-text">{{ $t('teacherReward.title') }}</p>
							</v-btn>
						</v-row>
						<!-- レッスンリクエスト画面への遷移（講師のみ） -->
						<v-row v-if="!isStudent" justify="center">
							<v-btn @click="toLessonRequestList" text>
								<p class="icon-menu-text">
									{{ $t('lessonRequest.title') }}
									<span v-if="wipCounts">
										<span v-if="wipCounts >= 10" class="badge rounded-pill">{{ wipCounts }}</span>
										<span v-else class="badge rounded-circle">{{ wipCounts }}</span>
									</span>
								</p>
							</v-btn>
						</v-row>
						<v-row justify="center" v-if="userInfo.isReservedStripe">
							<v-btn @click="toCustomerPortal" text>
								<p class="icon-menu-text">支払設定＆継続停止</p>
							</v-btn>
						</v-row>
						<v-row justify="center">
							<v-btn @click="toInquiries" text>
								<p class="icon-menu-text">
									{{ $t('header.inquiries') }}
								</p>
							</v-btn>
						</v-row>
						<v-row v-if="showLoginAsOtherUserFunction" justify="center">
							<v-btn @click="handleShowLoginAsOtherUserDialog" text>
								<p class="icon-menu-text">他のユーザーとしてログインする</p>
							</v-btn>
						</v-row>
						<v-row v-if="showBackToAdminFunction" justify="center">
							<v-btn @click="ClickBackToAdmin" text>
								<p class="icon-menu-text">管理画面に戻る</p>
							</v-btn>
						</v-row>
						<v-row justify="center">
							<v-btn color="primary" :style="{ fontWeight: 'bold' }" @click="logout" text>
								<p class="icon-menu-text">
									{{ $t('profile.logout') }}
								</p>
							</v-btn>
						</v-row>
					</v-col>
				</v-card-actions>
			</v-card>
		</v-menu>
		<v-dialog v-model="showDialogForLoginAsOtherUser" max-width="500px">
			<VmLoginAsOtherUserDialog @close="closeDialogForLoginAsOtherUser" @submit="clickLoginAsOtherUser" />
		</v-dialog>
	</div>
</template>

<script>
import BaseCirclePicture from '../Atoms/BaseCirclePicture'
import { mapGetters, mapActions } from 'vuex'
import VmLoginAsOtherUserDialog from '../../components/Organisms/VmLoginAsOtherUserDialog'

export default {
	name: 'HeaderMenuIcon',
	props: {},
	components: {
		BaseCirclePicture,
		VmLoginAsOtherUserDialog
	},
	data: () => ({
		showDialogForLoginAsOtherUser: false
	}),
	created() {},
	mounted() {},
	computed: {
		...mapGetters({
			wipCounts: 'lessonRequest/wipCounts',
			userInfo: 'user/getUserInfo',
			canAccessLoginAsOtherUser: 'user/canAccessLoginAsOtherUser'
		}),
		userName() {
			return this.userInfo.userName ? this.userInfo.userName : '未設定'
		},
		isStudent() {
			return this.userInfo.userAuthority === 'STUDENT'
		},
		iconImage() {
			if (!this.userInfo.imageUrl) {
				if (this.isStudent) {
					return require('../../assets/no_student.svg')
				} else {
					return require('../../assets/no_teacher.png')
				}
			} else {
				return this.userInfo.imageUrl
			}
		},
		computedName() {
			if (this.userInfo.userAuthority === 'STUDENT') {
				return this.userName + 'さん'
			} else {
				return this.userName + '先生'
			}
		},
		// 「ほかのユーザーとしてログインする」が使えるのはアドミンとその権限が与えられた運用者のみ
		canBeAsOtherUser() {
			return this.isAdmin || (this.isOperator && this.canAccessLoginAsOtherUser)
		},
		showLoginAsOtherUserFunction() {
			return this.canBeAsOtherUser && !this.underLoginAsOtherUser
		},
		showBackToAdminFunction() {
			return this.canBeAsOtherUser && this.underLoginAsOtherUser
		},
		isAdmin() {
			if (!this.userInfo) return false
			return this.userInfo.userAuthority === 'ADMIN'
		},
		isOperator() {
			if (!this.userInfo) return false
			return this.userInfo.userAuthority === 'OPERATOR'
		},
		underLoginAsOtherUser() {
			if (!this.userInfo) return false
			return this.userInfo.studentId || this.userInfo.teacherId
		}
	},
	methods: {
		...mapActions({
			fetchWipLessonRequest: 'lessonRequest/fetchWipLessonRequest',
			loginAsOtherUser: 'user/loginAsOtherUser',
			returnToAdminScreen: 'user/returnToAdminScreen'
		}),
		logout() {
			this.$router.push('/')
			window.localStorage.removeItem('vuex')
		},
		toMypage() {
			if (this.isStudent) {
				this.$router.push('/studentMypage')
			} else {
				this.$router.push('/teacherMypage')
			}
		},
		toLessonRequestList() {
			this.$router.push('/lessonRequestList')
		},
		toCustomerPortal() {
			// ポップアップブロックのため、事前にwindowを開いてから読み込むようにする
			var win = window.open('', 'child', 'width=800, height=600')
			win.document.body.innerHTML = 'loading...'
			const header = {
				headers: {
					Authorization: `Bearer ${this.userInfo.accessToken}`,
					'Content-Type': 'multipart/form-data'
				}
			}

			this.axios
				.get(`/api/stripes/customerPortal/${this.userInfo.studentId}`, header)
				.then((response) => {
					win.location.href = response.data.customerPortalUrl
				})
				.catch((error) => {
					alert(error.response.data.error.message)
				})
		},
		toInquiries() {
			window.open('https://form.run/@vollmond-system-issue')
		},
		toStudentInvoice() {
			this.$router.push('/studentInvoice')
		},
		toTeacherReward() {
			this.$router.push('/teacherReward')
		},
		async clickLoginAsOtherUser(param) {
			let isSuccess = false
			if (param.teacherId) {
				isSuccess = await this.loginAsOtherUser({
					adminAccessToken: this.userInfo.adminAccessToken,
					teacherId: param.teacherId
				})
				if (isSuccess) {
					this.$router.push('/teacherTop')
				}
			} else if (param.studentId) {
				isSuccess = await this.loginAsOtherUser({
					adminAccessToken: this.userInfo.adminAccessToken,
					studentId: param.studentId,
					isAdmin: this.isAdmin
				})
				if (isSuccess) {
					this.$router.push('/studentTop')
				}
			}
			this.closeDialogForLoginAsOtherUser()
		},
		async ClickBackToAdmin() {
			let isSuccess = false
			// 管理者に戻る
			isSuccess = await this.returnToAdminScreen({ isAdmin: this.isAdmin })
			if (isSuccess) {
				this.$router.push('/admin/top')
			}
		},
		handleShowLoginAsOtherUserDialog() {
			this.showDialogForLoginAsOtherUser = true
		},
		closeDialogForLoginAsOtherUser() {
			this.showDialogForLoginAsOtherUser = false
		}
	}
}
</script>

<style lang="scss" scoped>
.menuIconContainer {
	height: 100%;
	display: flex;
	align-items: center;
}
.userIcon {
	position: relative;
}
.iconBadge {
	position: absolute;
	top: 0;
	left: 25px;
}
.badge {
	background-color: #dcc268;
	padding: 4px 6px;
	font-weight: bold;
	color: white;
}
.teacher-name {
	font-size: 0.8em;
	margin: 0 0 5px 0;
}
.icon-menu-text {
	margin: 0;
}
</style>
