<template>
	<v-container>
		<SwitchTimeZone @reload="reload()" />
		<PageTitle title-name="プライベートコース講師スケジュール" />
		<TeacherScheduleTeacherInfo
			:teacherInfo="fetchedTeacherInfo"
			:teacherName="teacherName"
			:teacherImageUrl="teacherImageUrl"
			:skypeId="skypeId"
			:lessonCourseId="2"
			:message-from-teacher="fetchedTeacherInfo.privMessage"
			v-if="fetchedTeacherInfo"
		/>
		<v-row>
			<v-col cols="12" xs="12" sm="8" md="9" lg="9" xl="9">
				<p
					class="primary--text"
					:style="{
						fontSize: calcFontSize * 1.2 + 'em',
						fontWeight: 'bold',
						'margin-top': '24px'
					}"
				>
					予約可能状況
				</p>
			</v-col>
		</v-row>
		<!-- 子コンポーネントはReservationCalendar -->
		<component
			class="calendar-area"
			v-if="isShowCalendar"
			:is="calendarComponent"
			:availableLessons="availableLessons"
			:userInfo="userInfo"
			:studentId="userInfo.studentId"
			:teacherId="teacherId"
			:isFirstLesson="isFirstLesson"
			:accessToken="userInfo.accessToken"
			:teacherName="teacherName"
			:lessonCourseId="2"
			@reload="reload()"
		/>
		<TeacherReviewList :teacherId="teacherId" />
	</v-container>
</template>

<script>
import PageTitle from '../Atoms/PageTitle'
import TeacherScheduleTeacherInfo from '../Organisms/TeacherScheduleTeacherInfo'
import ReservationCalendar from '../Organisms/ReservationCalendar'
import TeacherReviewList from '../Organisms/TeacherReviewList'
import SwitchTimeZone from '../Atoms/SwitchTimeZone'
import { mapGetters, mapActions } from 'vuex'

export default {
	name: 'PrivateTeacherSchedule',
	props: ['teacherId'], // クエリから取ってくる
	data: () => ({
		availableLessons: [],
		calendarComponent: null,
		fetchedTeacherInfo: null,
		teacherName: '-',
		teacherImageUrl: require('../../assets/no_teacher.png'),
		skypeId: '',
		loading: true,
		isShowCalendar: true,
		lessons: [],
		isShowRegularSchedule: true,
		isFirstLesson: false
	}),
	components: {
		PageTitle,
		TeacherScheduleTeacherInfo,
		ReservationCalendar,
		SwitchTimeZone,
		TeacherReviewList
	},
	computed: {
		...mapGetters({
			userInfo: 'user/getUserInfo'
		}),
		calcFontSize() {
			switch (this.$vuetify.breakpoint.name) {
				case 'xs':
					return 1.2
				case 'sm':
					return 1.2
				case 'md':
					return 1.5
				case 'lg':
					return 1.3
				default:
					return 1.2
			}
		},
		judgeMatchDayOfWeek: function () {
			return function (schedule, dayOfWeek) {
				const scheduleDayOfWeek = Number(schedule.startTime.format('DD') - 1)

				if (scheduleDayOfWeek > 29) {
					if (Number(dayOfWeek) === 6) {
						return true
					}
				} else if (scheduleDayOfWeek > 6) {
					if (Number(dayOfWeek) === 0) {
						return true
					}
				} else {
					return scheduleDayOfWeek === dayOfWeek
				}
			}
		}
	},
	async created() {
		try {
			await this.fetchTeacherInfo()
		} catch (error) {
			console.log(error)
		}

		const canReserve = await this.checkCanReserve()
		if (!canReserve) {
			alert('こちらの講師は予約受付を行なっておりません。ログイン画面に移動します。')
			this.$router.push('/')
		}
	},
	async mounted() {
		// 単発レッスンの取得
		try {
			await this.fetchAvailableSchedule()
		} catch (error) {
			console.log(error)
		}

		this.judgeFirstLesson(this.teacherId, this.userInfo.studentId)

		// 【注意】データを取得したあとに再読み込みするために必要
		this.calendarComponent = ReservationCalendar
	},
	methods: {
		...mapActions({
			checkConnection: 'checkConnection/checkConnection'
		}),
		async checkCanReserve() {
			// 予約受付中の講師は予約可能
			if (this.fetchedTeacherInfo.isVisibleForPriv) return true

			// 予約受付中でない場合は、紐付けされていないと予約不可
			const hasConnection = await this.checkConnection({
				requestData: {
					studentId: this.userInfo.studentId,
					teacherId: this.teacherId
				},
				accessToken: this.userInfo.accessToken
			})
			return hasConnection
		},
		// 講師の予約可能な予定を取得
		fetchAvailableSchedule() {
			return new Promise((resolve) => {
				this.axios
					.get(`/api/lessons/available?teacherId=${this.teacherId}&lessonCourseId=2`)
					.then((response) => {
						if (response.data) {
							this.availableLessons = response.data.availableSchedules
							resolve()
						}
					})
					.catch((error) => {
						console.log(error)
					})
			})
		},
		fetchTeacherInfo() {
			return new Promise((resolve) => {
				this.axios
					.get(`/api/teachers/${this.teacherId}`)
					.then((response) => {
						if (response.data) {
							this.fetchedTeacherInfo = response.data.teacher
							this.teacherName = response.data.teacher.teacherName
							if (response.data.teacher.imageUrl) {
								this.teacherImageUrl = response.data.teacher.imageUrl
							}
							this.skypeId = response.data.teacher.skypeId
						}
						resolve()
					})
					.catch((error) => {
						console.log(error)
					})
			})
		},

		// 初回授業かどうかの判定のために、studentIdと今開いている講師のteacherIdで授業を絞って取得する
		judgeFirstLesson(teacherId, studentId) {
			// 未ログインの状況ではAPI叩かない
			if (!this.userInfo.accessToken) {
				return
			}

			const header = {
				headers: {
					Authorization: `Bearer ${this.userInfo.accessToken}`
				}
			}

			this.axios
				//TODO: 初回レッスンの変更について本番に上げていいってなったらこれあげる
				// .get(
				//   `/api/lessons?teacherId=${teacherId}&studentId=${studentId}&lessonCourseId=2&status=0&status=1`,
				//   header
				// )
				.get(`/api/lessons?teacherId=${teacherId}&studentId=${studentId}&lessonCourseId=2`, header)
				.then((response) => {
					const lessons = response.data.lessons

					if (lessons.length === 0) {
						this.isFirstLesson = true
					}
				})
				.catch((error) => {
					console.log(error)
				})
		},
		async reload() {
			// カレンダーと定期予定テーブルを同時にリロードする
			// 別関数に切り出していたらPromiseとasyncのやつとかで怒られたので…
			this.isShowCalendar = false

			await this.$nextTick()

			this.availableLessons = []
			// 単発レッスンの空き予定を入れ直す
			try {
				await this.fetchAvailableSchedule()
			} catch (error) {
				console.log(error)
			}

			this.isShowCalendar = true
		}
	},
	head: {
		title: {
			inner: 'Teacher-schedule'
		}
	}
}
</script>

<style lang="scss" scoped>
.calendar-area {
	margin-bottom: 100px;
}
.change-lang-area {
	margin: 3px 0 0 0;
}
.explanation-text {
	margin: 0 0 5px 0;
}
</style>
