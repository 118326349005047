<template>
	<validationProvider rules="" v-slot="{ errors, valid }" name="講師からのメッセージ" immediate>
		<p>{{ title }}</p>
		<v-textarea
			v-model="inputedTeacherMessage"
			:placeholder="placeHolderText"
			outlined
			background-color="white"
			counter
			:success="valid"
		></v-textarea>
		<FormErrorText :errorText="errors[0]" />
	</validationProvider>
</template>

<script>
import FormErrorText from '../Atoms/FormErrorText'

export default {
	name: 'TeacherMessageForm',
	data: () => ({}),
	props: ['value', 'title', 'type'],
	mounted() {},
	computed: {
		inputedTeacherMessage: {
			get() {
				return this.value
			},
			set(value) {
				this.$emit('input', value)
			}
		},
		placeHolderText() {
			switch (this.type) {
				case 'CONVERSATION':
					return this.$t('profile.convMessagePlaceHolder')
				case 'PRIVATE':
					return this.$t('profile.privMessagePlaceHolder')
				default:
					return ''
			}
		}
	},
	methods: {},
	components: {
		FormErrorText
	}
}
</script>

<style lang="scss" scoped>
p {
	margin: 10px 0 0 0;
}
</style>
