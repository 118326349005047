import { render, staticRenderFns } from "./SelfIntroductionForm.vue?vue&type=template&id=21e062c8&scoped=true&"
import script from "./SelfIntroductionForm.vue?vue&type=script&lang=js&"
export * from "./SelfIntroductionForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "21e062c8",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VTextarea})
