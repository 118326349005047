import axios from 'axios'

export const adminMonthlyCompleteReports = {
	namespaced: true,
	state: {
		teacherReports: null,
		loading: false
	},
	getters: {
		loading: (state) => {
			return state.loading
		},
		teacherReports: (state) => {
			return state.teacherReports
		}
	},
	mutations: {
		prepareConnect(state) {
			state.loading = true
		},
		stopLoading(state) {
			state.loading = false
		},
		setTeacherReports(state, payload) {
			state.teacherReports = payload
			state.loading = false
		}
	},
	actions: {
		async fetchTeacherReports({ commit }, payload) {
			commit('prepareConnect')
			const { accessToken, requestQuery } = payload
			const { year, month } = requestQuery
			const header = {
				headers: {
					Authorization: `Bearer ${accessToken}`
				}
			}
			return axios
				.get(`/api/monthlyCompleteReport/${year}/${month}`, header)
				.then((res) => {
					commit('setTeacherReports', res.data.teacherReports)
				})
				.catch(() => {
					commit('stopLoading')
				})
		}
	}
}
