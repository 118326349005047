<template>
	<v-checkbox v-model="inputtedValue" :label="label" :value="value" color="#DCC268" hide-details>
		<template v-slot:label>
			<span :style="{ 'font-size': '0.9em' }">{{ label }}</span>
		</template>
	</v-checkbox>
</template>

<script>
export default {
	name: 'VmCheckBox',
	props: {
		value: {
			type: Boolean,
			required: true
		},
		label: {
			type: String,
			required: true
		}
	},
	computed: {
		inputtedValue: {
			get() {
				return this.value
			},
			set(value) {
				this.$emit('input', value)
			}
		}
	}
}
</script>

<style scoped></style>
