<template>
	<div>
		<template v-if="buttonComponent === 'router-link'">
			<router-link :to="to" :style="linkButtonStyle" class="rounded-sm button" @click.stop="onClick($event)">
				<!-- 前に配置するアイコンのスロット -->
				<slot v-if="$slots.prefix" name="prefix" class="icon-prefix"></slot>
				<p :style="{ color: buttonTextColor }">{{ text }}</p>
				<!-- 後に配置するアイコンのスロット -->
				<slot v-if="$slots.suffix" name="suffix" class="icon-suffix"></slot>
			</router-link>
		</template>
		<template v-else>
			<v-btn
				:color="buttonColor"
				:style="buttonStyle"
				tile
				elevation="0"
				class="rounded-sm button"
				:disabled="disabled"
				:outlined="type === 'primaryOutlined'"
				@click.stop="onClick($event)"
			>
				<!-- 前に配置するアイコンのスロット -->
				<slot v-if="$slots.prefix" name="prefix" class="icon-prefix"></slot>
				<p :style="{ color: buttonTextColor }">{{ text }}</p>
				<!-- 後に配置するアイコンのスロット -->
				<slot v-if="$slots.suffix" name="suffix" class="icon-suffix"></slot>
			</v-btn>
		</template>
	</div>
</template>

<script>
export default {
	name: 'VmButton',
	props: {
		text: {
			type: String,
			default: ''
		},
		disabled: {
			type: Boolean,
			default: false
		},
		type: {
			type: String,
			default: 'primary',
			validator: (value) => ['primary', 'primaryOutlined', 'secondary', 'cancel', 'dangerous'].indexOf(value) !== -1
		},
		minWidth: {
			type: String,
			default: '200px'
		},
		to: {
			type: [String, Object],
			default: null
		}
	},
	computed: {
		buttonComponent() {
			return this.to ? 'router-link' : 'v-btn'
		},
		buttonColor() {
			switch (this.type) {
				case 'primary':
				case 'primaryOutlined':
					return 'primary'
				case 'secondary':
					return 'secondary'
				case 'dangerous':
					return 'dangerous'
				case 'cancel':
					return 'lightGray'
				default:
					return 'primary'
			}
		},
		buttonTextColor() {
			switch (this.type) {
				case 'primary':
				case 'secondary':
				case 'cancel':
				case 'dangerous':
					return 'white'
				case 'primaryOutlined':
					return 'primary'
				default:
					return 'white'
			}
		},
		linkButtonStyle() {
			let style = {
				display: 'inline-flex',
				alignItems: 'center',
				justifyContent: 'center',
				fontWeight: 'bold',
				padding: '8px 12px',
				'font-size': '0.9em',
				'min-width': this.minWidth,
				'background-color': '#DCC268',
				color: this.buttonTextColor,
				border: 'none',
				borderRadius: '4px',
				textDecoration: 'none'
			}

			if (this.type === 'primaryOutlined') {
				style['background-color'] = 'white'
				style['border'] = '1px solid #DCC268'
			}

			return style
		},
		buttonStyle() {
			let style = {
				fontWeight: 'bold',
				padding: '15px 30px',
				'font-size': '0.8em',
				'min-width': this.minWidth
			}

			if (this.type === 'primaryOutlined') {
				style['background-color'] = 'white'
			}

			return style
		}
	},
	methods: {
		onClick($event) {
			if (this.disabled) return
			this.$emit('click', $event)
		}
	}
}
</script>

<style lang="scss" scoped>
p {
	white-space: nowrap; /* テキストを一行で表示 */
	overflow: hidden; /* テキストがはみ出た場合は隠す */
	text-overflow: ellipsis;
	margin: 0;
	margin-left: 4px;
	margin-right: 4px;
}
</style>
