<template>
	<VmDialogCard
		:title="$t('teacherTop.skypeAddRemindDialog.title')"
		:description="$t('teacherTop.skypeAddRemindDialog.description')"
		:buttonTitle="$t('teacherTop.skypeAddRemindDialog.send')"
		:cancelButtonTitle="$t('labels.close')"
		@click="sendMessage"
		@close="close"
		:loading="sendMessageToStudentLoading"
	>
		<template #cardContent>
			<p class="studentName">{{ $t('labels.studentName') }}: {{ studentName }}</p>
			<p class="message">
				{{
					$t('teacherTop.skypeAddRemindDialog.message', {
						skypeId: userInfo.skypeId
					})
				}}
			</p>
		</template>
	</VmDialogCard>
</template>

<script>
import VmDialogCard from '../Molecules/VmDialogCard'
import VmTextArea from '../Atoms/VmTextArea'
import VmClickableDropdown from '../Molecules/VmClickableDropdown'
import { mapGetters, mapActions } from 'vuex'

export default {
	name: 'SkypeAddRemindDialog',
	components: {
		VmDialogCard,
		VmTextArea,
		VmClickableDropdown
	},
	props: {
		studentId: {
			type: Number,
			required: true
		},
		studentName: {
			type: String,
			required: true
		}
	},
	data: () => ({}),
	computed: {
		...mapGetters({
			userInfo: 'user/getUserInfo',
			sendMessageToStudentLoading: 'messageToStudent/loading'
		})
	},
	methods: {
		...mapActions({
			sendMessageToStudent: 'messageToStudent/sendMessage'
		}),
		close() {
			this.$emit('close')
		},
		async sendMessage() {
			if (confirm('メッセージを送信しますか？') === false) return

			const payload = {
				accessToken: this.userInfo.accessToken,
				requestBody: {
					studentId: this.studentId,
					teacherId: this.userInfo.teacherId,
					message: this.$t('teacherTop.skypeAddRemindDialog.message', {
						skypeId: this.userInfo.skypeId
					})
				}
			}
			try {
				await this.sendMessageToStudent(payload)
				this.close()
				alert('メッセージを送信しました。')
			} catch (error) {
				alert('メッセージの送信に失敗しました。')
			}
		}
	}
}
</script>

<style lang="scss" scoped>
.studentName {
	font-weight: bold;
	margin-bottom: 20px;
}
.message {
	font-size: 0.9em;
	margin-top: 4px;
	background-color: #ececec;
	padding: 12px;
	white-space: pre-line;
	border-radius: 4px;
}
</style>
