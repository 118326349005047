<template>
	<div>
		<form class="form">
			<active-button
				class="upload-button"
				buttonTitle="CSVアップロード"
				:isDisabled="this.uploadedCsv === ''"
				@clickAction="uploadCsv"
				width="160px"
				:block="false"
			/>
			<div>
				<label>
					<input @change="changeFile" type="file" name="upfile[]" :multiple="false" accept="text/csv" />
					ファイルを選択
				</label>
				<span class="fileName">
					{{ fileName }}
				</span>
			</div>
		</form>
	</div>
</template>

<script>
import ActiveButton from '../Atoms/ActiveButton.vue'

export default {
	name: 'CsvUploadArea',
	data: () => ({
		uploadedCsv: ''
	}),
	props: ['type'],
	mounted() {},
	computed: {
		fileName() {
			return this.uploadedCsv?.name ? this.uploadedCsv.name : '選択されていません'
		}
	},
	methods: {
		// CSVアップロード
		changeFile(elem) {
			this.uploadedCsv = ''
			const files = elem.target.files || elem.dataTransfer.files
			this.uploadedCsv = files[0]
		},
		uploadCsv() {
			let endPoint = ''
			switch (this.type) {
				case 'STUDENT':
					endPoint = '/api/processCsv/students'
					break
				case 'TEACHER':
					endPoint = '/api/processCsv/teachers'
					break
				case 'LESSON':
					endPoint = '/api/processCsv/lessons'
					break
				case 'TEXT':
					endPoint = '/api/processCsv/textCourses'
					break
			}

			let formData = new FormData()
			formData.append('csvData', this.uploadedCsv)

			const header = {
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			}

			this.axios
				.post(endPoint, formData, header)
				.then((response) => {
					console.log('response.data', response.data)
					alert(`データを追加しました`)
					this.$emit('reload')
				})
				.catch((error) => {
					alert(error.response.data.error.message)
				})
		}
	},
	components: {
		ActiveButton
	}
}
</script>

<style lang="scss" scoped>
label {
	padding: 4px 16px;
	font-size: 0.8em;
	color: #272727;
	background-color: #f5f5f5;
	font-weight: bold;
	cursor: pointer;
}
input[type='file'] {
	display: none;
}
.fileName {
	font-size: 0.8em;
}
</style>
