<template>
	<VmDialogCard
		:title="formTitle"
		dangerousButtonTitle="削除する"
		:cancelButtonTitle="$t('teacherSchedule.cancel')"
		:height="300"
		@clickDangerous="deleteItemConfirm"
		@close="closeDelete"
	>
		<template #cardContent>
			<p>{{ content }}</p>
		</template>
	</VmDialogCard>
</template>

<script>
import VmDialogCard from '../Molecules/VmDialogCard'

export default {
	name: 'AdminDeleteFormCard',
	data: () => ({}),
	components: {
		VmDialogCard
	},
	computed: {},
	props: {
		formTitle: {
			type: String,
			default: ''
		},
		content: {
			type: String,
			default: ''
		}
	},
	created() {},
	mounted() {},
	methods: {
		closeDelete() {
			this.$emit('closeDeleteAction')
		},
		deleteItemConfirm() {
			this.$emit('deleteItemConfirmAction')
		}
	}
}
</script>

<style lang="scss" scoped>
p {
	white-space: pre-line;
}
</style>
