<template>
	<validationProvider rules="required|max:18" v-slot="{ errors, valid }" :name="$t('profile.catchPrase')" immediate>
		<p>
			<FormTitleText :titleText="$t('profile.catchPrase')" />
			<FormAttentionText :attentionText="$t('profile.max18')" />
		</p>
		<v-text-field
			label="ドイツ語の楽しさ、伝えます！"
			v-model="inputedCatchPhrase"
			outlined
			background-color="white"
			hide-details
			:success="valid"
		></v-text-field>
		<FormErrorText :errorText="errors[0]" />
	</validationProvider>
</template>

<script>
import FormTitleText from '../Atoms/FormTitleText'
import FormErrorText from '../Atoms/FormErrorText'
import FormAttentionText from '../Atoms/FormAttentionText'

export default {
	name: 'CatchPraseForm',
	data: () => ({}),
	props: ['value'],
	mounted() {},
	computed: {
		inputedCatchPhrase: {
			get() {
				return this.value
			},
			set(value) {
				this.$emit('input', value)
			}
		}
	},
	methods: {},
	components: {
		FormTitleText,
		FormErrorText,
		FormAttentionText
	}
}
</script>

<style lang="scss" scoped></style>
