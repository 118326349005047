<template>
	<validationProvider rules="required" v-slot="{ errors, valid }" immediate name="本業">
		<p>
			<FormTitleText :titleText="$t('profile.isMainBusiness')" />
			<br />
			<FormAttentionText :attentionText="$t('profile.isMainBusinessAttention')" />
		</p>
		<v-select
			:items="isMainBusinessItems"
			v-model="inputtedIsMainBusiness"
			:label="$t('profile.requireSelection')"
			outlined
			background-color="white"
			hide-details
			:success="valid"
		></v-select>
		<FormErrorText :errorText="errors[0]" />
	</validationProvider>
</template>

<script>
import FormTitleText from '../Atoms/FormTitleText'
import FormErrorText from '../Atoms/FormErrorText'
import FormAttentionText from '../Atoms/FormAttentionText'

export default {
	name: 'IsMainBusinessForm',
	data: () => ({
		isMainBusinessItems: ['はい', 'いいえ']
	}),
	props: ['value'],
	mounted() {
		this.initSelection()
	},
	computed: {
		inputtedIsMainBusiness: {
			get() {
				switch (this.value) {
					case true:
						return this.$t('profile.yes')
					case false:
						return this.$t('profile.no')
					default:
						return ''
				}
			},
			set(value) {
				switch (value) {
					case this.$t('profile.yes'):
						this.$emit('input', true)
						break
					case this.$t('profile.no'):
						this.$emit('input', false)
						break
				}
			}
		}
	},
	methods: {
		initSelection() {
			this.isMainBusinessItems = []
			this.isMainBusinessItems.push(this.$t('profile.yes'))
			this.isMainBusinessItems.push(this.$t('profile.no'))
		}
	},
	components: {
		FormTitleText,
		FormErrorText,
		FormAttentionText
	}
}
</script>

<style lang="scss" scoped></style>
