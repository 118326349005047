import axios from 'axios'

export const studentInvoices = {
	namespaced: true,
	state: {
		data: null,
		loading: false
	},
	getters: {
		loading: (state) => {
			return state.loading
		},
		studentInvoices: (state) => {
			return state.data
		}
	},
	mutations: {
		prepareConnect(state) {
			state.loading = true
		},
		stopLoading(state) {
			state.loading = false
		},
		setStudentInvoices(state, payload) {
			state.data = payload
			state.loading = false
		}
	},
	actions: {
		async fetchStudentInvoices({ commit }, payload) {
			commit('prepareConnect')
			const { studentId, year, month, accessToken } = payload
			const header = {
				headers: {
					Authorization: `Bearer ${accessToken}`
				}
			}
			return axios
				.get(`/api/students/invoices?studentId=${studentId}&year=${year}&month=${month}`, header)
				.then((res) => {
					commit('setStudentInvoices', res.data.studentInvoices)
				})
				.catch(() => {
					commit('stopLoading')
				})
		}
	}
}
