<template>
	<div>
		<!-- タイトル -->
		<PageTitle title-name="請求メール管理" />
		<VmBox>
			<template #header>
				<div class="reload" @click="reload">
					<v-icon small color="#DCC268"> mdi-reload </v-icon>
					<span class="reloadText">再読み込み</span>
				</div>
			</template>
			<template #content>
				<div v-if="invoiceLoading">
					<VmLoading />
				</div>
				<div v-else>
					<div class="description">送信okの状態で翌日の日本時刻9:00を迎えると請求書が送信されます。</div>
					<!-- 送らないstudentIdを入力する -->
					請求書送信しない生徒を選択
					<StudentSelect :items="studentItems" v-model="studentId" @student-selected="addSelectedStudentToArray" />

					<!-- 送らない生徒一覧を表示 -->
					<h3>送らない人一覧</h3>
					<div v-if="!noInvoiceStudents.length">-</div>
					<div v-else v-for="(student, index) of noInvoiceStudents" :key="`${student.id}_${index}`">
						{{ student.name }}
						<v-icon small @click="deleteStudent(student.id)"> mdi-delete </v-icon>
					</div>
				</div>

				<div class="invoiceStatus">
					<VmToggle v-model="invoiceOk" label="送信" on-label="ok" off-label="ng" />
				</div>
			</template>
		</VmBox>
	</div>
</template>

<script>
import PageTitle from '../Atoms/PageTitle'
import VmBox from '../Atoms/VmBox'
import { mapGetters, mapActions } from 'vuex'
import StudentSelect from '../Molecules/StudentSelect'
import VmToggle from '../Atoms/VmToggle.vue'
import VmLoading from '../Atoms/VmLoading'

export default {
	name: 'AdminManagementInvoice',
	components: {
		PageTitle,
		VmBox,
		StudentSelect,
		VmToggle,
		VmLoading
	},
	data: () => ({
		studentItems: [],
		teacherItems: [],
		invoiceOk: false,
		studentId: null
	}),
	computed: {
		...mapGetters({
			userInfo: 'user/getUserInfo',
			students: 'students/students',
			teachers: 'teachers/teachers',
			invoiceLoading: 'manageInvoice/loading',
			noInvoiceStudents: 'manageInvoice/noInvoiceStudents',
			invoiceStatus: 'manageInvoice/invoiceStatus'
		})
	},
	props: [],
	async created() {
		await this.fetchData()
		this.prepareOptions()
		this.invoiceOk = this.invoiceStatus
	},
	mounted() {},
	watch: {
		async invoiceOk(newVal, oldVal) {
			if (newVal !== oldVal) {
				await this.changeInvoiceStatus({
					accessToken: this.userInfo.accessToken,
					status: newVal
				})
				await this.fetchNoInvoiceData(this.userInfo.accessToken)
				this.invoiceOk = this.invoiceStatus
			}
		}
	},
	methods: {
		...mapActions({
			fetchAllStudents: 'students/fetchAllStudents',
			fetchAllTeachers: 'teachers/fetchAllTeachers',
			fetchNoInvoiceData: 'manageInvoice/fetchNoInvoiceData',
			changeInvoiceStatus: 'manageInvoice/changeInvoiceStatus',
			addNoInvoiceStudent: 'manageInvoice/addNoInvoiceStudent',
			deleteNoInvoiceStudent: 'manageInvoice/deleteNoInvoiceStudent'
		}),
		async reload() {
			await this.fetchData()
			this.invoiceOk = this.invoiceStatus
		},
		async fetchData() {
			await Promise.all([
				this.fetchAllStudents(this.userInfo.accessToken),
				this.fetchAllTeachers(this.userInfo.accessToken),
				this.fetchNoInvoiceData(this.userInfo.accessToken)
			])
		},
		prepareOptions() {
			this.studentItems = this.students.map((student) => {
				return {
					id: student.id,
					name: `${student.id}_${student.name}`
				}
			})
			this.teacherItems = this.teachers.map((teacher) => {
				return {
					id: teacher.id,
					name: `${teacher.id}_${teacher.teacherName}`
				}
			})
		},
		async addSelectedStudentToArray(selectedStudent) {
			await this.addNoInvoiceStudent({
				selectedStudent: selectedStudent,
				accessToken: this.userInfo.accessToken
			})
			await this.fetchNoInvoiceData(this.userInfo.accessToken)
		},
		async deleteStudent(studentId) {
			await this.deleteNoInvoiceStudent({
				studentId: studentId,
				accessToken: this.userInfo.accessToken
			})
			await this.fetchNoInvoiceData(this.userInfo.accessToken)
		}
	}
}
</script>

<style lang="scss" scoped>
.description {
	margin-bottom: 30px;
}
.invoiceStatus {
	margin-top: 30px;
}
.reload {
	color: #dcc268;
	cursor: pointer;
}
.reloadText {
	margin-left: 4px;
	font-weight: bold;
}
</style>
