<template>
	<validationProvider rules="" v-slot="{ errors }" :name="$t('profile.compatibleLevel')">
		<p>
			<FormTitleText :titleText="$t('profile.compatibleLevel')" />
		</p>
		<v-row>
			<p :style="{ 'margin-left': '12px' }">
				<span
					v-for="compatibleLevelItem of inputtedCompatibleLevel"
					:key="compatibleLevelItem"
					:style="{ 'margin-right': '10px' }"
				>
					{{ compatibleLevelItem }}
				</span>
			</p>
		</v-row>
		<FormErrorText :errorText="errors[0]" />
	</validationProvider>
</template>

<script>
import FormTitleText from '../Atoms/FormTitleText'
import FormErrorText from '../Atoms/FormErrorText'

export default {
	name: 'CompatibleLessonForm',
	components: {
		FormTitleText,
		FormErrorText
	},
	data: () => ({
		compatibleLevelItems: ['完全初級', 'A1', 'A2', 'B1', 'B2', 'C1', 'C2']
	}),
	props: ['value'],
	mounted() {},
	computed: {
		inputtedCompatibleLevel: {
			get() {
				// DBではA0で格納されているため、'完全初級'に変換する必要がある。
				return this.value.split(',').map((value) => {
					if (value === 'A0') {
						return '完全初級'
					} else {
						return value
					}
				})
			},
			set(value) {
				// 順番になるようにソートする
				value = value.sort((a, b) => {
					// 完全初級の文字は必ず一番前に来てほしいので以下のような処理をしている
					if (a == '完全初級') {
						return -1
					}
					if (b == '完全初級') {
						return 1
					}
					// 他の文字列は普通に整列してあげて問題なし
					if (a < b) {
						return -1
					}
				})
				let combinedHopeInLesson = ''
				//「対応可能レベル」の配列を送信できる形に整える
				for (let selectedHopeInLesson of value) {
					if (!selectedHopeInLesson || selectedHopeInLesson === 'null') {
						continue
					}

					if (combinedHopeInLesson.length === 0) {
						// 最初に入れる値はそのまま
						combinedHopeInLesson = selectedHopeInLesson === '完全初級' ? 'A0' : selectedHopeInLesson
					} else {
						// 2個目以降に入れる値はカンマをつけて連結していく
						combinedHopeInLesson =
							combinedHopeInLesson + ',' + (selectedHopeInLesson === '完全初級' ? 'A0' : selectedHopeInLesson)
					}
				}

				this.$emit('input', combinedHopeInLesson)
			}
		}
	},
	methods: {}
}
</script>

<style lang="scss" scoped></style>
