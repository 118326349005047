<template>
	<div>
		<div v-if="!canEdit">
			<p>
				<FormTitleText :titleText="$t('profile.birthDate')" />
			</p>
			{{ displayedBirthDay }}
		</div>
		<validationProvider :rules="rules" v-slot="{ errors, valid }" name="生年月日" v-else>
			<p>
				<FormTitleText :titleText="$t('profile.birthDate')" />
				<RequiredIcon v-if="rules && rules.includes('required')" />
				<FormAttentionText :attentionText="$t('profile.onlyNum')" />
			</p>
			<v-text-field
				label="1990/01/01"
				v-model="inputedBirthDay"
				outlined
				background-color="white"
				@input="validateBirthDay($event)"
				pattern="^[0-9]+"
				@keyup="keyUpNumberRestriction"
				hide-details
				:success="valid"
				type="date"
			></v-text-field>
			<FormErrorText :errorText="errors[0]" />
		</validationProvider>
	</div>
</template>

<script>
import FormTitleText from '../Atoms/FormTitleText'
import FormErrorText from '../Atoms/FormErrorText'
import FormAttentionText from '../Atoms/FormAttentionText'
import RequiredIcon from '../Atoms/RequiredIcon'

export default {
	name: 'BirthdayForm',
	data: () => ({
		birthDay: null
	}),
	props: {
		value: {
			type: String,
			required: true
		},
		rules: {
			type: String,
			required: true
		},
		canEdit: {
			type: Boolean,
			required: false,
			default: false
		}
	},
	mounted() {
		this.birthDay = this.value
	},
	computed: {
		inputedBirthDay: {
			get() {
				return this.value
			},
			set(value) {
				this.$emit('input', value)
			}
		},
		displayedBirthDay() {
			return this.$moment(this.value).format('YYYY/MM/DD')
		}
	},
	methods: {
		validateBirthDay(element) {
			if (element.length > 10) {
				const slicedBirthDay = new String(element.slice(0, 10))
				this.inputedBirthDay = slicedBirthDay
			}
			switch (element.length) {
				case 4:
				case 7:
					this.inputedBirthDay = element + '/'
					break
				default:
					return
			}
		},
		keyUpNumberRestriction: function () {
			let tmp_value = this.birthDay
			if (tmp_value) {
				this.birthDay = tmp_value.replace(/[^0-9/]/g, '')
			}
		}
	},
	components: {
		FormTitleText,
		FormErrorText,
		FormAttentionText,
		RequiredIcon
	}
}
</script>

<style lang="scss" scoped></style>
