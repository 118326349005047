<template>
	<v-row justify="center" class="change-lang-area">
		<a @click="changeLocale('ja')"><p class="change-lang">日本語</p></a>
		/
		<a @click="changeLocale('de')"><p class="change-lang">Deutsch</p></a>
	</v-row>
</template>

<script>
export default {
	name: 'ChangeLocale',
	props: [],
	components: {},
	data: () => ({}),
	created() {},
	mounted() {},
	computed: {},
	methods: {
		async changeLocale(lang) {
			this.$i18n.locale = lang
			this.$emit('reloadAfterChangingLang')
		}
	}
}
</script>

<style lang="scss" scoped></style>
