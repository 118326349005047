<template>
	<div>
		<v-row>
			<!-- 最近の講師メモ -->
			<p class="report-subtitle">{{ $t('top.recentlyMemoForTeacher') }}</p>
		</v-row>
		<div v-if="recentlyCompletedPrivLessons.length">
			<div v-for="(recentlyCompletedPrivLesson, index) in recentlyCompletedPrivLessons" :key="index">
				<!-- 日付 -->
				<v-row>
					<p class="recentlyLessonItem">
						{{ $t('top.date') }}:
						{{ $moment(recentlyCompletedPrivLesson.startTime).format('YYYY/MM/DD') }}
					</p>
				</v-row>
				<!-- 講師名 -->
				<v-row>
					<p class="recentlyLessonItem">
						{{ $t('top.teacherName') }}:
						{{ recentlyCompletedPrivLesson['Teacher']['teacherName'] }}
					</p>
				</v-row>
				<!-- メモ -->
				<v-row>
					<p class="recentlyLessonItem">
						{{ $t('top.memo') }}:<br />
						<span v-if="recentlyCompletedPrivLesson.memoForTeacher != ''">
							{{ recentlyCompletedPrivLesson.memoForTeacher }}
						</span>
						<span v-else> - </span>
					</p>
				</v-row>
				<v-row>
					<hr />
				</v-row>
			</div>
		</div>
		<div v-else>
			<p class="no-memo">講師メモはありません。</p>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
	name: 'TeacherMemoList',
	props: {
		studentId: {
			type: Number,
			required: true
		}
	},
	components: {},
	data: () => ({
		recentlyCompletedPrivLessons: []
	}),
	created() {
		this.fetchRecentlyCompletedPrivLessons()
	},
	mounted() {},
	computed: {
		...mapGetters({
			userInfo: 'user/getUserInfo'
		})
	},
	methods: {
		// 最近のプライベートコースレッスンを取得
		fetchRecentlyCompletedPrivLessons() {
			this.recentlyCompletedPrivLessons = []
			return new Promise((resolve, reject) => {
				const header = {
					headers: {
						Authorization: `Bearer ${this.userInfo.accessToken}`
					}
				}
				this.axios
					.get(`/api/lessons/recentlyCompleted?studentId=${this.studentId}&lessonCourseId=2`, header)
					.then((response) => {
						this.recentlyCompletedPrivLessons = response.data.recentryCompletedLessons
						resolve()
					})
					.catch((error) => {
						console.log(error)
						reject()
					})
			})
		}
	}
}
</script>

<style lang="scss" scoped>
.report-subtitle {
	font-size: 1.4em;
	font-weight: bold;
	margin: 20px 0 15px 0;
}
.recentlyLessonItem {
	margin: 5px 0;
	white-space: pre-line;
}
hr {
	background-color: #dcc268;
	border: none;
	height: 1px;
	margin: 10px 0 40px 0;
	width: 100%;
}
.no-memo {
	margin-top: 10px;
}
</style>
