import axios from 'axios'

export const adminStudent = {
	namespaced: true,
	state: {
		students: null,
		loading: false
	},
	getters: {
		loading: (state) => {
			return state.loading
		},
		students: (state) => {
			return state.students
		}
	},
	mutations: {
		prepareConnect(state) {
			state.loading = true
		},
		stopLoading(state) {
			state.loading = false
		},
		setStudents(state, payload) {
			state.students = payload
			state.loading = false
		}
	},
	actions: {
		fetchStudents({ commit }, payload) {
			commit('prepareConnect')
			const header = {
				headers: {
					Authorization: `Bearer ${payload.accessToken}`
				}
			}
			return axios
				.get(`/api/students`, header)
				.then((res) => {
					commit('setStudents', res.data.students)
				})
				.catch(() => {
					commit('stopLoading')
				})
		}
	}
}
