<template>
	<VmDialogCard
		title="別のユーザーとしてログインする"
		buttonTitle="別のユーザーでログイン"
		:cancelButtonTitle="$t('labels.close')"
		@click="submit"
		@close="close"
	>
		<template #cardContent>
			<v-row>
				<v-col cols="12">
					<v-select
						:items="[
							{ text: '生徒', value: 'student' },
							{ text: '講師', value: 'teacher' }
						]"
						v-model="role"
						label="属性"
						outlined
						background-color="white"
						no-data-text="属性がありません"
						item-text="text"
						item-value="value"
						@change="changeRole"
					></v-select>
				</v-col>
			</v-row>
			<v-row>
				<v-col v-if="role === 'student'" cols="12">
					<StudentSelect :items="studentItems" v-model="studentId" />
				</v-col>
				<v-col v-if="role === 'teacher'" cols="12">
					<v-autocomplete
						:items="teacherItems"
						v-model="teacherId"
						label="講師"
						outlined
						background-color="white"
						no-data-text="講師がいません"
						item-text="name"
						item-value="id"
						v-if="role === 'teacher'"
					>
					</v-autocomplete>
				</v-col>
			</v-row>
		</template>
	</VmDialogCard>
</template>

<script>
import VmDialogCard from '../../components/Molecules/VmDialogCard'
import StudentSelect from '../Molecules/StudentSelect.vue'
import { mapGetters, mapActions } from 'vuex'

export default {
	name: 'VmLoginAsOtherUserDialog',
	props: {},
	components: {
		VmDialogCard,
		StudentSelect
	},
	data: () => ({
		role: '',
		studentId: '',
		teacherId: '',
		studentItems: [],
		teacherItems: []
	}),
	async created() {
		await this.fetchAllStudents(this.userInfo.accessToken)
		await this.fetchAllTeachers(this.userInfo.accessToken)
		this.prepareOptions()
	},
	async mounted() {},
	computed: {
		...mapGetters({
			userInfo: 'user/getUserInfo',
			students: 'students/students',
			teachers: 'teachers/teachers'
		})
	},
	methods: {
		...mapActions({
			fetchAllStudents: 'students/fetchAllStudents',
			fetchAllTeachers: 'teachers/fetchAllTeachers'
		}),
		prepareOptions() {
			this.studentItems = this.students.map((student) => {
				return {
					id: Number(student.id),
					name: `${student.id}_${student.name}`
				}
			})
			this.teacherItems = this.teachers.map((teacher) => {
				return {
					id: Number(teacher.id),
					name: `${teacher.id}_${teacher.teacherName}`
				}
			})
		},
		close() {
			this.$emit('close')
		},
		changeRole() {
			this.resetSelection()
		},
		resetSelection() {
			this.studentId = ''
			this.teacherId = ''
		},
		submit() {
			this.$emit('submit', {
				studentId: this.studentId,
				teacherId: this.teacherId
			})
			this.resetSelection()
		}
	}
}
</script>

<style lang="scss" scoped></style>
