<template>
	<div :style="{ background: '#FAFAFA' }">
		<PageTitle :titleName="teacher ? teacher.teacherName + '先生' : '講師名'" />
		<v-container :style="{ 'max-width': '900px', padding: '0 12px 100px 12px' }">
			<v-row no-gutters dense>
				<TeacherPicture :imageUrl="computedImageUrl" :imageSize="imageSize" class="teacher-picture" />
				<v-col cols="7" :style="{ 'padding-left': '15px' }">
					<v-row no-gutters :style="{}">
						<div
							class="tag rounded-pill"
							:style="{
								'margin-top': '8px'
							}"
							v-if="showPrivExpertIcon"
						>
							<p>プライベートベテラン</p>
						</div>
					</v-row>
					<v-row no-gutters :style="{}">
						<div
							class="tag rounded-pill"
							:style="{
								'margin-top': '8px'
							}"
							v-if="showConvExpertIcon"
						>
							<p>会話ベテラン</p>
						</div>
					</v-row>
					<v-row no-gutters :style="{}" v-if="teacher.category">
						<div
							class="tag rounded-pill"
							:style="{
								'margin-top': '8px'
							}"
						>
							<p>{{ categoryText }}</p>
						</div>
					</v-row>
				</v-col>
			</v-row>
			<!-- 予約ボタン -->
			<v-row no-gutters v-if="teacher.availablePrivateLesson" :style="{ 'margin-top': '12px' }">
				<VmButton type="primary" text="プライベートコース予約へ" :style="{}" @click="toPrivSchedule" />
			</v-row>
			<v-row no-gutters v-if="teacher.availableConversationLesson" :style="{ 'margin-top': '12px' }">
				<VmButton type="primary" text="会話コース予約へ" :style="{}" @click="toConvSchedule" />
			</v-row>
			<!-- 講師の詳細情報の表示 -->
			<v-row no-gutters :style="{ 'margin-top': '30px' }">
				<v-col cols="6">
					<v-row no-gutters class="card-subtitle">
						<p>在住</p>
					</v-row>
					<v-row no-gutters class="content-text">
						<p>{{ teacher.country }}</p>
					</v-row>
				</v-col>
			</v-row>
			<v-row no-gutters :style="{ 'margin-top': '20px' }">
				<v-col cols="6">
					<v-row no-gutters class="card-subtitle">
						<p>対応レベル</p>
					</v-row>
					<v-row no-gutters class="content-text">
						<p>{{ compatibleLevelText }}</p>
					</v-row>
				</v-col>
			</v-row>
			<v-row v-if="teacher.minAvailableStudentAge" no-gutters :style="{ 'margin-top': '20px' }">
				<v-col cols="6">
					<v-row no-gutters class="card-subtitle">
						<p>対象年齢</p>
					</v-row>
					<v-row no-gutters class="content-text">
						<p>{{ teacher.minAvailableStudentAge }} 歳以上</p>
					</v-row>
				</v-col>
			</v-row>
			<v-row no-gutters :style="{ 'margin-top': '20px' }">
				<v-col cols="6">
					<v-row no-gutters class="card-subtitle">
						<p>レッスン実績</p>
					</v-row>
					<p class="content-text" :style="{ 'margin-right': '12px' }">
						受講生徒数 <v-icon small color="black"> mdi-account </v-icon> ×{{ teacher.studentCount + 1 }}
					</p>
					<p class="content-text">
						レッスン回数 <v-icon small color="black"> mdi-laptop </v-icon> ×{{ teacher.lessonCount + 1 }}
					</p>
				</v-col>
			</v-row>
			<v-row no-gutters class="card-subtitle" :style="{ 'margin-top': '30px' }">
				<p>得意分野</p>
			</v-row>
			<v-row no-gutters class="content-text">
				<p>
					{{ teacher.specialities }}
				</p>
			</v-row>
			<v-row no-gutters class="card-subtitle" :style="{ 'margin-top': '30px' }">
				<p>経歴</p>
			</v-row>
			<v-row no-gutters class="content-text">
				<p>{{ teacher.background }}</p>
			</v-row>
			<v-row no-gutters class="card-subtitle" :style="{ 'margin-top': '30px' }">
				<p>趣味/特技</p>
			</v-row>
			<v-row no-gutters class="content-text">
				<p>{{ teacher.hobbies }}</p>
			</v-row>
			<v-row no-gutters class="card-subtitle" :style="{ 'margin-top': '30px' }">
				<p>コメント</p>
			</v-row>
			<v-row no-gutters class="content-text">
				<p>{{ teacher.selfIntroduction }}</p>
			</v-row>
			<div v-if="teacher.availableTextbook">
				<v-row no-gutters class="card-subtitle" :style="{ 'margin-top': '30px' }">
					<p>対応可能な教科書</p>
				</v-row>
				<v-row no-gutters class="content-text">
					<p>{{ teacher.availableTextbook }}</p>
				</v-row>
			</div>
			<div class="moreTeacherInfo" v-if="teacher.blogUrl || teacher.podcastEmbedLink">
				<p class="moreTeacherInfo__title">{{ teacher.teacherName }} 先生をもっと知る</p>
				<TeacherArticle v-if="articleData" :articleData="articleData" :blogUrl="teacher.blogUrl" />
				<div v-if="teacher.podcastEmbedLink" class="podcast-embed" v-html="teacher.podcastEmbedLink"></div>
			</div>
			<TeacherReviewList class="teacherReviewList" v-if="teacherId" :teacherId="Number(teacherId)" />
		</v-container>
	</div>
</template>

<script>
import PageTitle from '../Atoms/PageTitle'
import TeacherReviewList from '../Organisms/TeacherReviewList'
import { mapActions, mapGetters } from 'vuex'
import TeacherPicture from '../Molecules/TeacherPicture'
import VmButton from '../Atoms/VmButton'
import TeacherArticle from '../Molecules/TeacherArticle'

export default {
	name: 'TeacherProfile',
	components: {
		PageTitle,
		TeacherReviewList,
		TeacherPicture,
		VmButton,
		TeacherArticle
	},
	data: () => ({
		articleData: null
	}),
	async created() {
		await this.fetchTeacherInfo({ teacherId: this.teacherId })
		await this.fetchBlogInfo()
	},
	computed: {
		...mapGetters({
			teacher: 'teacher/teacher'
		}),
		teacherId() {
			return this.$route.params.teacherId
		},
		imageSize() {
			switch (this.$vuetify.breakpoint.name) {
				case 'xs':
					return 125 + 'px'
				case 'sm':
					return 150 + 'px'
				case 'md':
					return 150 + 'px'
				case 'lg':
					return 200 + 'px'
				case 'xl':
					return 300 + 'px'
				default:
					return 125 + 'px'
			}
		},
		computedImageUrl() {
			return this.teacher.imageUrl ? this.teacher.imageUrl : require('../../assets/no_teacher.png')
		},
		compatibleLevelText() {
			return this.teacher.compatibleLevel.replace('A0', '完全初級')
		},
		categoryText() {
			// 日本人講師 or ネイティブを表示させる
			switch (this.teacher.category) {
				case 'JAPANESE':
					return '日本人講師'
				case 'NATIVE':
					return 'ネイティブ'
				default:
					return ''
			}
		},
		computedImageUrl() {
			return this.teacher.imageUrl ? this.teacher.imageUrl : require('../../assets/no_teacher.png')
		},
		showPrivExpertIcon() {
			return this.teacher.privLevel === 'EXPERT'
		},
		showConvExpertIcon() {
			return this.teacher.convLevel === 'EXPERT'
		}
	},
	methods: {
		...mapActions({
			fetchTeacherInfo: 'teacher/fetchTeacherInfo'
		}),
		toPrivSchedule() {
			this.$router.push({
				name: 'PrivateTeacherSchedule',
				query: { teacherId: this.teacherId }
			})
		},
		toConvSchedule() {
			this.$router.push({
				name: 'ConversationTeacherSchedule',
				query: { teacherId: this.teacherId }
			})
		},
		async fetchBlogInfo() {
			const blogUrl = this.teacher.blogUrl
			if (blogUrl) {
				try {
					const response = await this.axios.get('/api/fetch-article', {
						params: { url: blogUrl }
					})
					this.articleData = response.data
				} catch (error) {
					console.error('Failed to fetch article data:', error)
				}
			}
		}
	}
}
</script>

<style lang="scss" scoped>
p {
	margin: 0;
	white-space: pre-line;
}
.tag {
	background: white;
	border: solid 1px #dcc268; /*線*/
	padding: 2px 15px;
	font-size: 0.9em;
}
.tag p {
	color: #dcc268;
	font-weight: bold;
	margin: 0;
	font-size: 0.9em;
}

.card-subtitle {
	color: #cfd2d1;
	font-weight: bold;
}

.content-text {
	font-weight: bold;
	line-height: 2em;
	color: #272727;
}
.teacherReviewList {
	margin-top: 60px;
}
.moreTeacherInfo {
	margin-top: 30px;
	&__title {
		font-size: 1.2em;
		font-weight: bold;
	}
}
</style>
