import axios from 'axios'

export const students = {
	namespaced: true,
	state: {
		data: null,
		loading: false
	},
	getters: {
		loading: (state) => {
			return state.loading
		},
		students: (state) => {
			return state.data
		}
	},
	mutations: {
		prepareConnect(state) {
			state.loading = true
		},
		stopLoading(state) {
			state.loading = false
		},
		setStudents(state, payload) {
			state.data = payload
			state.loading = false
		}
	},
	actions: {
		async fetchAllStudents({ commit }, accessToken) {
			commit('prepareConnect')
			const header = {
				headers: {
					Authorization: `Bearer ${accessToken}`
				}
			}
			return axios
				.get(`/api/students/`, header)
				.then((res) => {
					commit('setStudents', res.data.students)
				})
				.catch(() => {
					commit('stopLoading')
				})
		}
	}
}
