<template>
	<v-card>
		<v-card-title>
			<v-col>
				<v-row justify="center">
					<span class="headline">メール文編集</span>
				</v-row>
			</v-col>
		</v-card-title>
		<v-card-text>
			<v-row>
				<v-col>
					<p>{{ mailText.description }}</p>
					<p>to: {{ mailText.sentTo }}</p>
					<p>件名:</p>
					<v-text-field
						v-model="subject"
						:style="{ fontWeight: 'bold' }"
						outlined
						background-color="white"
						hide-details
					></v-text-field>
					<p>本文:</p>
					<v-textarea
						v-model="content"
						placeholder=""
						outlined
						background-color="white"
						counter
						height="500"
					></v-textarea>
				</v-col>
			</v-row>
		</v-card-text>
		<v-card-actions>
			<v-row justify="center">
				<v-col cols="10" xs="10" sm="10" md="6" lg="6" xl="6">
					<ActiveButton button-title="編集完了" @clickAction="edit" class="button" />
					<CancelButton button-title="キャンセル" @clickAction="close" class="button" />
				</v-col>
			</v-row>
		</v-card-actions>
	</v-card>
</template>

<script>
import ActiveButton from '../Atoms/ActiveButton'
import CancelButton from '../Atoms/CancelButton'

export default {
	name: 'AdminEditMailTextFormCard',
	inject: [],
	data: () => ({
		subject: '',
		content: ''
	}),
	props: ['mailText'],
	computed: {},
	components: {
		ActiveButton,
		CancelButton
	},
	created() {
		this.subject = this.mailText.subject
		this.content = this.mailText.content
	},
	mounted() {},
	methods: {
		edit() {
			if (confirm('編集を完了しますか？')) {
				let reqBody = {
					subject: this.subject,
					content: this.content
				}

				this.axios
					.put(`/api/mailTexts?mailType=${this.mailText.mailType}&sentTo=${this.mailText.sentTo}`, reqBody)
					.then((response) => {
						console.log('response.data', response.data)
						this.close()
						this.$emit('reload')
					})
					.catch((error) => {
						alert(error.response.data.error.message)
					})
			}
		},
		close() {
			this.$emit('cancelAction')
		}
	},
	head: {}
}
</script>

<style lang="scss" scoped>
.button {
	margin-bottom: 20px;
}
p {
	margin: 20px 0 0 0;
}
</style>
