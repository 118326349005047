<template>
	<v-card width="100%" tile class="rounded" :elevation="hover || isShowDetail ? 6 : 2" hover>
		<!-- PCは横長、スマホは縦長のカードUIを採用 -->
		<div v-if="!isPC">
			<div :style="{ display: 'flex' }">
				<div :style="{ width: '35%' }">
					<img
						:src="computedImageUrl"
						alt="teacherImage"
						:style="{
							'object-fit': 'cover',
							width: '80%',
							'aspect-ratio': '1 / 1',
							'border-radius': '10px',
							'margin-top': '10px',
							'margin-left': '12px'
						}"
					/>
				</div>
				<div :style="{ width: '60%' }" class="card-content">
					<!-- 〇〇先生 -->
					<p class="teacher-name">
						{{ teacherInfo.teacherName }}
						{{ $t('teacherSchedule.honorificTitle') }}
					</p>
					<p class="info-text" v-if="showedAt != 'text'">在住: {{ this.teacherInfo.country }}</p>
					<TeacherCardOneWordLabel :one-word="teacherInfo.catchPhrase" class="catch-phrase-label" />
					<v-row v-if="!isShowDetail">
						<TeacherCardDetailButton
							@clickAction="clickAction"
							button-title="詳細はこちら"
							:is-shown-detail="isShowDetail"
						/>
					</v-row>
					<v-row v-else>
						<TeacherCardDetailButton @clickAction="clickAction" button-title="閉じる" :is-shown-detail="isShowDetail" />
					</v-row>
				</div>
			</div>
		</div>
		<div v-else>
			<v-row class="card-content">
				<v-col cols="3">
					<img
						:src="computedImageUrl"
						alt="teacherImage"
						:style="{
							'object-fit': 'cover',
							width: '80%',
							'aspect-ratio': '1 / 1',
							'border-radius': '10px',
							'margin-top': '10px',
							'margin-left': '12px'
						}"
					/>
				</v-col>
				<v-col cols="9">
					<!-- 〇〇先生 -->
					<p class="teacher-name">
						{{ teacherInfo.teacherName }}
						{{ $t('teacherSchedule.honorificTitle') }}
					</p>
					<p class="info-text" v-if="showedAt != 'text'">在住: {{ this.teacherInfo.country }}</p>
					<TeacherCardOneWordLabel
						:style="{ marginTop: pcMarginTop }"
						:one-word="teacherInfo.catchPhrase"
						class="catch-phrase-label"
					/>
					<v-row v-if="!isShowDetail">
						<TeacherCardDetailButton
							@clickAction="clickAction"
							button-title="詳細はこちら"
							:is-shown-detail="isShowDetail"
						/>
					</v-row>
					<v-row v-else>
						<TeacherCardDetailButton @clickAction="clickAction" button-title="閉じる" :is-shown-detail="isShowDetail" />
					</v-row>
				</v-col>
			</v-row>
		</div>
	</v-card>
</template>

<script>
import TeacherCardDetailButton from '../Atoms/TeacherCardDetailButton'
import TeacherCardOneWordLabel from '../Atoms/TeacherCardOneWordLabel'

export default {
	name: 'TeacherCard',
	data: () => ({
		pcMarginTop: '20px'
	}),
	components: {
		TeacherCardDetailButton,
		TeacherCardOneWordLabel
	},
	mounted() {},
	computed: {
		computedImageUrl() {
			if (this.teacherInfo.imageUrl) {
				return this.teacherInfo.imageUrl
			} else {
				return require('../../assets/no_teacher.png')
			}
		},
		isShowCard() {
			if (!this.teacherInfo) {
				return false
			} else {
				return true
			}
		}
	},
	methods: {
		clickAction() {
			this.$emit('clickAction')
		}
	},
	props: ['teacherInfo', 'hover', 'isShowDetail', 'showedAt']
}
</script>

<style lang="scss" scoped>
p {
	margin-bottom: 0;
}
.teacher-name {
	font-size: 1em;
	font-weight: bold;
}
.card-content {
	margin: 0;
	padding: 5px 0;
	color: #272727;
}
.info-text {
	font-size: 0.8em;
}
.catch-phrase-label {
	margin: 5px 0 10px 0;
}
</style>
