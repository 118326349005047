<template>
	<VmDialogCard
		:title="$t('top.reportMonthlyComplete.title')"
		:buttonTitle="$t('top.reportMonthlyComplete.buttonTitle')"
		:cancelButtonTitle="$t('labels.close')"
		@click="handleButtonClick"
		@close="close"
		:disabled="!buttonValidation"
	>
		<template #cardContent>
			<p class="description">
				{{ $t('top.reportMonthlyComplete.description1') }}
			</p>
			<p class="deadLine">
				{{ $t('top.reportMonthlyComplete.deadline', { month: targetMonth == 12 ? 1 : targetMonth + 1 }) }}
			</p>
			<div>
				<v-checkbox v-model="checkedFirst" :label="firstCheckboxLabel"></v-checkbox>
				<v-checkbox v-model="checkedSecond" :label="secondCheckboxLabel"></v-checkbox>
			</div>
			<p class="description" v-html="formattedDescription"></p>
		</template>
	</VmDialogCard>
</template>

<script>
import VmDialogCard from '../Molecules/VmDialogCard'
import { fetchReportTargetMonth } from '../../util/fetchReportTarget'

export default {
	name: 'ReportMonthlyCompleteDialog',
	props: {},
	components: {
		VmDialogCard
	},
	data: () => ({
		checkedFirst: false,
		checkedSecond: false
	}),
	created() {},
	mounted() {},
	computed: {
		buttonValidation() {
			// 両方のチェックボックスがチェックされている場合にのみ、ボタンを有効にする
			return this.checkedFirst && this.checkedSecond
		},
		formattedDescription() {
			// 既存の説明テキスト
			let description = this.$t('top.reportMonthlyComplete.description2')

			// URLをアンカータグで囲む
			description = description.replace(/(https:\/\/[^\s]+)/g, '<a href="$1" target="_blank">$1</a>')

			// '1.' と '2.' で始まる行全体を太字にする正規表現パターン
			// これにより、これらの番号に続くテキストも太字になります
			const boldPattern = /(1\..*|2\..*)/g

			description = description.replace(boldPattern, '<strong>$1</strong>')

			return description
		},
		// 精算月
		targetMonth() {
			return fetchReportTargetMonth()
		},
		// 最初のチェックボックスのラベル
		firstCheckboxLabel() {
			return this.$t('top.reportMonthlyComplete.firstCheckboxLabel', {
				month: this.targetMonth + 1
			}) // 精算月の次の月を表示
		},
		// 2つ目のチェックボックスのラベル
		secondCheckboxLabel() {
			return this.$t('top.reportMonthlyComplete.secondCheckboxLabel', {
				month: this.targetMonth
			}) // 精算月を表示
		}
	},
	methods: {
		handleButtonClick() {
			this.$emit('click')
		},
		close() {
			this.$emit('close')
		}
	}
}
</script>

<style lang="scss" scoped>
.deadLine {
	font-weight: bold;
	color: red;
}

.description {
	white-space: pre-wrap;
}
</style>
