<template>
	<!-- 生徒が予約する時に使うカレンダー -->
	<div>
		<p style="font-size: 0.9em; font-weight: normal">
			※レッスン開始の48時間前まで予約できます。
			<br />
		</p>
		<p v-if="lessonCourseId === 2" style="font-size: 0.9em; font-weight: bold; margin-top: 20px">
			↓空き予定が見つからない場合は↓
		</p>
		<v-btn
			v-if="lessonCourseId === 2"
			color="secondary"
			@click.stop="showLessonRequestDialog = true"
			:style="{
				fontWeight: 'bold',
				margin: '10px 0'
			}"
			tile
			elevation="0"
			class="rounded-sm"
		>
			<p>レッスンのリクエストをする</p>
			<v-icon :style="{ 'margin-left': '5px' }">mdi-email-fast</v-icon>
		</v-btn>
		<v-row style="margin-top: 16px">
			<!-- 表示に関するセッティング。月表示、週表示など -->
			<v-col cols="6" xs="6" sm="3" md="3" lg="3">
				<v-select
					v-model="type"
					:items="typeOptions"
					label="Type"
					hide-details
					outlined
					background-color="white"
					dense
				></v-select>
			</v-col>
			<!-- 週初めに関するセッティング -->
			<v-col cols="6" xs="6" sm="4" md="4" lg="4">
				<SwitchBeginningOfWeek @reloadCalendar="reloadCalendar" />
			</v-col>
		</v-row>
		<v-sheet height="64">
			<v-toolbar flat>
				<v-btn fab text small color="grey darken-2" @click="prev">
					<v-icon small> mdi-chevron-left </v-icon>
				</v-btn>
				<v-toolbar-title v-if="$refs.calendar">
					{{ $refs.calendar.title }}
				</v-toolbar-title>
				<v-btn fab text small color="grey darken-2" @click="next">
					<v-icon small> mdi-chevron-right </v-icon>
				</v-btn>

				<v-spacer></v-spacer>
			</v-toolbar>
		</v-sheet>
		<v-dialog
			v-model="showLessonRequestDialog"
			:close-on-content-click="false"
			v-if="showLessonRequestDialog && !!this.userInfo.studentId"
			offset-x
			max-width="500px"
		>
			<LessonRequestForm @cancelAction="closeLessonRequestDialog" :teacherId="teacherId" />
		</v-dialog>
		<v-dialog
			v-model="showLessonRequestDialog"
			:close-on-content-click="false"
			v-if="showLessonRequestDialog && !this.userInfo.studentId"
			offset-x
			max-width="500px"
		>
			<RequestLoginForm @cancelAction="closeLessonRequestDialog" />
		</v-dialog>
		<v-sheet :height="calendarHeight">
			<v-calendar
				ref="calendar"
				v-model="calendarValue"
				:calendarValue="calendarValue"
				:type="type"
				:events="events"
				:weekdays="computedWeekdays"
				:start="startDate"
				@change="updateRange"
				@click:event="showEvent"
				@click:more="showMore"
			>
				<template v-slot:event="{ event, day }">
					<p :class="getSummaryClass(event)">
						{{ formatEventNameForDay(event, day) }}
					</p>
				</template>
			</v-calendar>
			<!-- 未ログインの生徒に向けてのログインフォーム -->
			<v-dialog
				v-model="showRequestForLoginDialog"
				:close-on-content-click="false"
				v-if="showRequestForLoginDialog"
				offset-x
				max-width="500px"
			>
				<RequestLoginForm @cancelAction="closeRequestLoginCard" />
			</v-dialog>

			<!-- 自分が受講していないコースを受講しようとした時に出すフォーム -->
			<v-dialog
				v-model="showRequestForSubscriptionDialog"
				:close-on-content-click="false"
				v-if="showRequestForSubscriptionDialog"
				offset-x
				max-width="500px"
			>
				<RequestSubscriptionForm :lesson-course-id="lessonCourseId" @cancelAction="closeRequestSubscriptionCard" />
			</v-dialog>

			<v-dialog
				v-model="showReservationDialog"
				:close-on-content-click="false"
				v-if="showReservationDialog"
				offset-x
				max-width="500px"
			>
				<!-- コースIDによって会話orプライベートの予約フォームの切り替えを行っている -->
				<ConversationReservationFormCard
					v-if="lessonCourseId === 1"
					:teacherName="teacherName"
					course-name="会話"
					:start-time="selectedEvent.start"
					:end-time="selectedEvent.end"
					:userInfo="userInfo"
					:studentId="studentId"
					:teacherId="teacherId"
					@cancelAction="closeReservationCard"
					:lessonCourseId="lessonCourseId"
				/>
				<PrivateReservationFormCard
					v-if="lessonCourseId === 2"
					:teacherName="teacherName"
					course-name="プライベート"
					:start-time="selectedEvent.start"
					:end-time="selectedEvent.end"
					:userInfo="userInfo"
					:studentId="studentId"
					:teacherId="teacherId"
					@cancelAction="closeReservationCard"
					@showLessonRequest="showLessonRequestDialog = true"
					:lessonCourseId="lessonCourseId"
					:isFirstLesson="isFirstLesson"
				/>
			</v-dialog>
		</v-sheet>
	</div>
</template>

<script>
import ConversationReservationFormCard from '../Molecules/ConversationReservationFormCard'
import PrivateReservationFormCard from '../Molecules/PrivateReservationFormCard'
import SwitchBeginningOfWeek from '../Atoms/SwitchBeginningOfWeek'
import RequestLoginForm from '../Molecules/RequestLoginForm'
import LessonRequestForm from '../Molecules/LessonRequestForm'
import RequestSubscriptionForm from '../Molecules/RequestSubscriptionForm'
import { PRIVATE_LESSON_COURSE_ID, CONVERSATION_LESSON_COURSE_ID } from '../../const'

export default {
	name: 'ReservationCalendar',
	components: {
		ConversationReservationFormCard,
		PrivateReservationFormCard,
		SwitchBeginningOfWeek,
		RequestLoginForm,
		LessonRequestForm,
		RequestSubscriptionForm
	},
	data: () => ({
		type: 'month',
		typeOptions: [
			{ text: 'Day', value: 'day' },
			{ text: '4 Day', value: '4day' },
			{ text: 'Week', value: 'week' },
			{ text: 'Month', value: 'month' }
		],
		events: [],
		selectedEvent: {},
		showReservationDialog: false,
		showRequestForLoginDialog: false,
		showRequestForSubscriptionDialog: false,
		calendarValue: '',
		showLessonRequestDialog: false,
		startDate: ''
	}),
	props: ['availableLessons', 'userInfo', 'studentId', 'teacherId', 'isFirstLesson', 'teacherName', 'lessonCourseId'],
	created() {
		this.startDate = this.$moment().format('YYYY-MM-DD HH:mm:ss')
	},
	mounted() {
		this.updateRange()
		// デフォルトのカレンダーの表示月を予約可能日時のうち、最も現在に近いものにする
		this.adjustMonth()
	},
	computed: {
		computedWeekdays() {
			// 週始まりを設定する
			switch (this.userInfo.beginningOfWeek) {
				case 0:
					return [0, 1, 2, 3, 4, 5, 6]
				case 1:
					return [1, 2, 3, 4, 5, 6, 0]
				default:
					return [0, 1, 2, 3, 4, 5, 6]
			}
		},
		calendarHeight() {
			if (this.isPC) {
				return 800
			} else {
				if (this.type === 'month') {
					return 1600
				}
				return 1000
			}
		}
	},
	methods: {
		showEvent({ event }) {
			// 未ログインの際にはログインを促すフォームを出す
			if (!this.isLogin) {
				this.showRequestForLoginDialog = true
				return
			}

			// 受講していないコースを受講しようとした時には受講するよう促すフォームを出す。
			// プライベートコースの時
			if (this.lessonCourseId === PRIVATE_LESSON_COURSE_ID) {
				if (!this.userInfo.lessonCourseArray.includes('PRIVATE')) {
					this.showRequestForSubscriptionDialog = true
					return
				}
			}
			// 会話コースの時
			if (this.lessonCourseId === CONVERSATION_LESSON_COURSE_ID) {
				if (!this.userInfo.lessonCourseArray.includes('CONVERSATION')) {
					this.showRequestForSubscriptionDialog = true
					return
				}
			}

			// 通常のレッスン予約フォーム
			this.selectedEvent = event
			this.showReservationDialog = true
			return
		},
		showMore(event) {
			this.calendarValue = event.date
			this.type = 'day'
		},
		updateRange() {
			const events = []

			for (let lesson of this.availableLessons) {
				const startDate = this.$moment(lesson.startTime).format('YYYY-MM-DD HH:mm')
				const endDate = this.$moment(lesson.endTime).format('YYYY-MM-DD HH:mm')

				const startDateLabel = this.$moment(lesson.startTime).format('HH:mm')
				const endDateLabel = this.$moment(lesson.endTime).format('HH:mm')

				const availableSchedule = {
					name: `${startDateLabel} - ${endDateLabel}`,
					start: startDate,
					end: endDate,
					color: 'reservationAvailable'
				}
				events.push(availableSchedule)
			}
			this.events = events
		},
		adjustMonth() {
			const sortedLessons = this.availableLessons
				.concat()
				.sort((a, b) => (this.$moment(a.startTime).isBefore(this.$moment(b.startTime)) ? -1 : 1))
			if (sortedLessons.length) {
				this.startDate = this.$moment(sortedLessons[0].startTime).format('YYYY-MM-DD HH:mm:ss')
			}
		},
		closeReservationCard() {
			this.showReservationDialog = false
		},
		closeRequestLoginCard() {
			this.showRequestForLoginDialog = false
		},
		closeRequestSubscriptionCard() {
			this.showRequestForSubscriptionDialog = false
		},
		closeLessonRequestDialog() {
			this.showLessonRequestDialog = false
		},
		prev() {
			this.$refs.calendar.prev()
		},
		next() {
			this.$refs.calendar.next()
		},
		reloadCalendar() {
			this.$emit('reload')
		},
		formatEventNameForDay(event, day) {
			const eventStart = this.$moment(event.start)
			const eventEnd = this.$moment(event.end)
			const dayMoment = this.$moment(day.date)

			// イベントが日跨ぎの場合
			if (eventStart.format('YYYY-MM-DD') !== eventEnd.format('YYYY-MM-DD')) {
				// カレンダーが日曜始まりの場合
				if (this.userInfo.beginningOfWeek === 0) {
					// eventStartが日曜日の場合、通常通り表示
					if (eventStart.day() === 0) {
						return event.name
					}

					// 土曜日の場合、開始時間のみ表示
					if (dayMoment.day() === 6) {
						return event.name.split(' - ')[0] + ' -'
					}
					// 日曜日の場合、終了時間のみ表示
					else if (dayMoment.day() === 0) {
						return '- ' + event.name.split(' - ')[1]
					}
				}
				// カレンダーが月曜始まりの場合
				else if (this.userInfo.beginningOfWeek === 1) {
					// eventStartが月曜日の場合、通常通り表示
					if (eventStart.day() === 1) {
						return event.name
					}

					// 日曜日の場合、開始時間のみ表示
					if (dayMoment.day() === 0) {
						return event.name.split(' - ')[0] + ' -'
					}
					// 月曜日の場合、終了時間のみ表示
					else if (dayMoment.day() === 1) {
						return '- ' + event.name.split(' - ')[1]
					}
				}
			}
			// その他の場合、通常通りイベント名を表示
			return event.name
		},
		getSummaryClass(event) {
			// 週表示の時にフォントサイズを0.8emに設定
			if (this.type === 'week') {
				return 'summary week-summary'
			}
			return 'summary'
		}
	}
}
</script>

<style lang="scss" scoped>
p {
	margin: 0;
}

.summary {
	margin-left: 4px;
	font-weight: bold;
	white-space: pre-line;
	text-overflow: inherit;
	font-size: 0.92em;
}
.week-summary {
	font-size: 0.75em; /* 週表示時のフォントサイズ */
}
</style>
