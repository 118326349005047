<template>
	<div class="statusExplanation" :style="{ backgroundColor: backgroundColor }">
		<p>
			<span class="statusExplanation__title">
				<v-icon small color="black" class="statusExplanation__icon"> mdi-information </v-icon>
				レッスンステータスについて
			</span>
			<br />
			完了：完了したレッスンです。<br />
			キャンセル：キャンセルされたレッスンです。<a
				href="https://vollmond.online/cancel-policy/"
				target="_blank"
				rel="noopener"
				>キャンセルポリシー</a
			>が適用されます。<br />
			-：まだ講師によって完了報告がされていないレッスンです。今しばらくお待ちください。
		</p>
	</div>
</template>

<script>
export default {
	name: 'AboutLessonStatus',
	props: {
		backgroundColor: {
			type: String,
			default: '#fafafa'
		}
	},
	components: {},
	data: () => ({}),
	created() {},
	mounted() {},
	computed: {},
	methods: {}
}
</script>

<style lang="scss" scoped>
.statusExplanation {
	padding: 16px;
	border-radius: 5px;
	margin-top: 16px;
	p {
		margin: 0;
		font-size: 0.8em;
	}
	&__title {
		display: inline-block;
		font-weight: bold;
		margin-bottom: 4px;
	}
	&__icon {
		position: relative;
		bottom: 2px;
	}
}
</style>
