<template>
	<VmDialogCard
		:title="formTitle"
		buttonTitle="一括編集する"
		cancelButtonTitle="閉じる"
		:height="300"
		@click="handleAction"
		@close="close"
	>
		<template #cardContent>
			<p>{{ content }}</p>
			<div class="field">
				<v-text-field
					v-model="payload.invoiceForStudent"
					label="生徒への請求金額"
					outlined
					type="number"
					background-color="white"
					hide-details
				></v-text-field>
			</div>
			<div class="field">
				<v-text-field
					v-model="payload.rewardForTeacher"
					label="講師への報酬金額"
					outlined
					type="number"
					background-color="white"
					hide-details
				></v-text-field>
			</div>
		</template>
	</VmDialogCard>
</template>

<script>
import VmDialogCard from '../Molecules/VmDialogCard'

export default {
	name: 'AdminBulkUpdateLessonFormCard',
	data: () => ({
		payload: {
			invoiceForStudent: null,
			rewardForTeacher: null
		}
	}),
	components: {
		VmDialogCard
	},
	computed: {},
	props: {
		formTitle: {
			type: String,
			default: ''
		},
		content: {
			type: String,
			default: ''
		}
	},
	created() {},
	mounted() {},
	methods: {
		close() {
			this.$emit('close')
		},
		handleAction() {
			this.$emit('handleAction', this.payload)
		}
	}
}
</script>

<style lang="scss" scoped>
p {
	white-space: pre-line;
}
.field {
	display: flex;
	margin-bottom: 16px;
}
</style>
