<template>
	<v-container>
		<v-row v-if="isPC">
			<v-col sm="2" md="2" lg="2" xl="1">
				<v-img :src="require('../../assets/Vollmond_logo.png')" class="my-3" contain @click="toLogin"></v-img>
			</v-col>
		</v-row>
		<v-col cols="12" class="reset-area">
			<v-container>
				<v-row justify="center" v-if="!isPC">
					<v-col cols="6" xs="6">
						<v-img :src="require('../../assets/Vollmond_logo.png')" class="my-3" contain @click="toLogin"></v-img>
					</v-col>
				</v-row>
				<PageTitle title-name="パスワード再設定" class="reset-title" />
				<v-row justify="center">
					<p class="reset-explanation">以下フォームに新しいパスワードを入力してパスワードを再設定してください。</p>
				</v-row>
				<ValidationObserver ref="observer" v-slot="{ invalid }" immediate>
					<v-row justify="center">
						<v-col cols="12" xs="12" sm="6" md="5" lg="5" xl="5" class="current-email-label">
							<p>email: {{ resetForm.email }}</p>
						</v-col>
					</v-row>
					<v-row justify="center">
						<v-col cols="12" xs="12" sm="6" md="5" lg="5" xl="5" class="text-field-area">
							<validationProvider
								:rules="{
									required: true,
									regex: /^[a-zA-Z0-9!-/:-@¥[-`{-~]*$/,
									min: 8,
									max: 25
								}"
								v-slot="{ errors, valid }"
								name="新パスワード"
							>
								<v-text-field
									label="新パスワード"
									v-model="resetForm.password"
									outlined
									background-color="white"
									hide-details
									:success="valid"
									class="password-form"
									dense
									:append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
									:type="showPass ? 'text' : 'password'"
									@click:append="showPass = !showPass"
								></v-text-field>
								<FormErrorText :errorText="errors[0]" class="form-error" />
							</validationProvider>
						</v-col>
					</v-row>
					<v-row justify="center">
						<v-col cols="12" xs="12" sm="6" md="5" lg="5" xl="5" class="text-field-area">
							<validationProvider
								:rules="{
									required: true,
									regex: /^[a-zA-Z0-9!-/:-@¥[-`{-~]*$/,
									min: 8,
									max: 25
								}"
								v-slot="{ errors, valid }"
								name="新パスワード（確認）"
							>
								<v-text-field
									label="新パスワード（確認）"
									v-model="resetForm.confirmedPassword"
									outlined
									background-color="white"
									hide-details
									:success="valid"
									dense
									class="confirmed-password-form"
									:append-icon="showConfirmPass ? 'mdi-eye' : 'mdi-eye-off'"
									:type="showConfirmPass ? 'text' : 'password'"
									@click:append="showConfirmPass = !showConfirmPass"
								></v-text-field>
								<FormErrorText :errorText="errors[0]" />
							</validationProvider>
						</v-col>
					</v-row>
					<v-row justify="center" class="reset-button-area">
						<v-col cols="12" xs="12" sm="6" md="5" lg="5" xl="5">
							<ActiveButton
								button-title="パスワードを再設定する"
								@clickAction="resetPass"
								class="reset-button"
								:isDisabled="invalid"
							/>
						</v-col>
					</v-row>
				</ValidationObserver>
			</v-container>
		</v-col>
	</v-container>
</template>

<script>
import ActiveButton from '../Atoms/ActiveButton'
import PageTitle from '../Atoms/PageTitle'
import FormErrorText from '../Atoms/FormErrorText'

export default {
	name: 'PasswordReset',
	data: () => ({
		resetForm: {
			token: '',
			email: '',
			password: '',
			confirmedPassword: ''
		},
		showPass: false,
		showConfirmPass: false
	}),
	components: {
		ActiveButton,
		PageTitle,
		FormErrorText
	},
	mounted() {
		this.resetForm.token = this.token
		this.resetForm.email = this.email
	},
	computed: {},
	methods: {
		toLogin() {
			this.$router.push('/')
		},
		resetPass() {
			if (this.resetForm.password != this.resetForm.confirmedPassword) {
				alert('入力したパスワードが一致していません')
				return
			}
			this.axios
				.post('/api/passward/reset', this.resetForm)
				.then((response) => {
					if (response.data.result) {
						alert('パスワードのリセットが完了しました。')
						this.$router.push('/')
					}
				})
				.catch((error) => {
					alert(error.response.data.error.message)
				})
		}
	},
	props: ['token', 'email']
}
</script>

<style lang="scss" scoped>
.reset-area {
	position: absolute;
	top: 45%;
	left: 50%;
	transform: translate(-50%, -50%);
}
.reset-title {
	margin-top: 30px;
	margin-bottom: 30px;
}
.reset-explanation {
	font-size: 0.8em;
	color: #6e6b6b;
	margin-bottom: 90px;
}
.reset-button-area {
	margin-top: 40px;
	margin-bottom: 20px;
}
.reset-button {
	border-radius: 8px;
}
.to-login-link {
	margin: 10px 0 0 0;
	font-size: 0.8em;
}
.current-email-label p {
	margin: 0;
}
</style>
