import axios from 'axios'

export const manageInvoice = {
	namespaced: true,
	state: {
		noInvoiceStudents: [],
		invoiceStatus: null,
		loading: false
	},
	getters: {
		loading: (state) => {
			return state.loading
		},
		noInvoiceStudents: (state) => {
			return state.noInvoiceStudents
		},
		invoiceStatus: (state) => {
			return state.invoiceStatus
		}
	},
	mutations: {
		prepareConnect(state) {
			state.loading = true
		},
		stopLoading(state) {
			state.loading = false
		},
		setNoInvoiceStudents(state, data) {
			state.noInvoiceStudents = data
		},
		setInvoiceStatus(state, data) {
			state.invoiceStatus = data
		}
	},
	actions: {
		async fetchNoInvoiceData({ commit }, accessToken) {
			commit('prepareConnect')
			const header = {
				headers: {
					Authorization: `Bearer ${accessToken}`
				}
			}
			return axios
				.get(`/api/invoice`, header)
				.then((res) => {
					commit('setNoInvoiceStudents', res.data.noInvoiceStudents)
					commit('setInvoiceStatus', res.data.status)
					commit('stopLoading')
				})
				.catch((err) => {
					commit('stopLoading')
					console.log(err)
				})
		},
		async changeInvoiceStatus({ commit }, { accessToken, status }) {
			commit('prepareConnect')
			const header = {
				headers: {
					Authorization: `Bearer ${accessToken}`
				}
			}
			const requestBody = {
				status: status
			}
			return axios
				.post(`/api/invoice/status`, requestBody, header)
				.then(() => {
					commit('stopLoading')
					return true
				})
				.catch((err) => {
					commit('stopLoading')
					console.log(err)
					return false
				})
		},
		async addNoInvoiceStudent({ commit }, { selectedStudent, accessToken }) {
			commit('prepareConnect')
			const header = {
				headers: {
					Authorization: `Bearer ${accessToken}`
				}
			}
			const requestBody = {
				studentId: selectedStudent.id
			}
			return axios
				.post(`/api/invoice/student`, requestBody, header)
				.then(() => {
					commit('stopLoading')
					return true
				})
				.catch((err) => {
					commit('stopLoading')
					console.log(err)
					return false
				})
		},
		async deleteNoInvoiceStudent({ commit }, { studentId, accessToken }) {
			commit('prepareConnect')
			const header = {
				headers: {
					Authorization: `Bearer ${accessToken}`
				}
			}
			return axios
				.delete(`/api/invoice/student/${studentId}`, header)
				.then(() => {
					commit('stopLoading')
					return true
				})
				.catch((err) => {
					commit('stopLoading')
					console.log(err)
					return false
				})
		}
	}
}
