<template>
	<VmDialogCard :title="formTitle" :cancelButtonTitle="$t('top.close')" @close="close">
		<template #cardContent>
			<v-container>
				<!-- 生徒情報（サブタイトル） -->
				<v-row>
					<p class="report-subtitle">{{ $t('top.studentInfo') }}</p>
				</v-row>
				<VmStudentInfo :student="student" :style="{ 'margin-top': '15px' }" :isConversation="isConversation" />
				<!-- レッスン計画書ダウンロード -->
				<div v-if="lessonPlans.length" class="lessonPlanPdfLink">
					<div v-for="(lessonPlan, i) in lessonPlans" :key="i" class="lessonPlanPdfLink__content">
						<a :href="lessonPlan.lessonPlanPdfUrl">
							<v-icon class="lessonPlanPdfLink__icon">mdi-file</v-icon>
							<span class="lessonPlanPdfLink__text"
								>{{ $moment(lessonPlan.createdAt).format('YYMMDD') }}_Unterrichtsplan_{{
									!!lessonPlan.Teacher && !!lessonPlan.Teacher.teacherName ? lessonPlan.Teacher.teacherName : ''
								}}</span
							>
						</a>
					</div>
				</div>
			</v-container>
			<v-container v-if="isConversation">
				<!-- レッスンの希望（サブタイトル） -->
				<v-row>
					<p class="report-subtitle">{{ $t('top.hopeForLesson') }}</p>
				</v-row>
				<v-row>
					{{ computedHopeForLesson }}
				</v-row>
			</v-container>
			<v-container>
				<v-row>
					<!-- 生徒からのメッセージ（サブタイトル） -->
					<p class="report-subtitle">{{ $t('top.messageFromStudent') }}</p>
				</v-row>
				<v-row>
					{{ computedMessageForTeacher }}
				</v-row>
			</v-container>
			<!-- 会話コース→最近のレッスン内容 -->
			<v-container v-if="isConversation">
				<v-row>
					<!-- 最近のレッスン内容 -->
					<p class="report-subtitle">{{ $t('top.recentlyLessonContent') }}</p>
				</v-row>
				<div v-for="(recentlyCompletedLesson, index) in recentlyCompletedConvLessons" :key="index">
					<!-- 日付 -->
					<v-row>
						<p class="recentlyLessonItem">
							{{ $t('top.date') }}:
							{{ $moment(recentlyCompletedLesson.startTime).format('YYYY/MM/DD') }}
						</p>
					</v-row>
					<!-- 講師名 -->
					<v-row>
						<p class="recentlyLessonItem">
							{{ $t('top.teacherName') }}:
							{{ recentlyCompletedLesson['Teacher']['teacherName'] }}
						</p>
					</v-row>
					<!-- レッスンの希望 -->
					<v-row>
						<p class="recentlyLessonItem">
							{{ $t('top.hopeForLesson') }}:<br />
							{{ computedPastHopeForLesson(recentlyCompletedLesson.hopeForLesson) }}
						</p>
					</v-row>
					<!-- 内容 -->
					<v-row>
						<p class="recentlyLessonItem">
							{{ $t('top.lessonContent') }}:<br />
							{{ recentlyCompletedLesson.lessonReport }}
						</p>
					</v-row>
					<v-row>
						<hr />
					</v-row>
				</div>
			</v-container>
			<!-- プライベートコース→講師メモ -->
			<v-container v-else>
				<TeacherMemoList :studentId="lessonInfo.studentId" />
			</v-container>
		</template>
	</VmDialogCard>
</template>

<script>
import userInfoTranslate from '@/data/translation/userInfoTranslate'
import VmStudentInfo from '../Molecules/VmStudentInfo'
import VmDialogCard from '../Molecules/VmDialogCard'
import TeacherMemoList from '../Molecules/TeacherMemoList'
import { mapGetters, mapActions } from 'vuex'

export default {
	name: 'LessonDetailCard',
	components: {
		VmStudentInfo,
		VmDialogCard,
		TeacherMemoList
	},
	data: () => ({
		lessonReportLabel:
			'今日はドイツ留学についてフリートークをしました。留学されていたとのことで、文法や単語は多く知られていて上手でした。質問に対しての答えが単語のみだったので「文で返してみましょう」など促しました。日本語での解説などは必要なさそうだったので、初めから最後までオールドイツ語で行いました。',
		recentlyCompletedConvLessons: [],
		recentlyCompletedPrivLessons: [],
		student: {}
	}),
	computed: {
		...mapGetters({
			userInfo: 'user/getUserInfo',
			lessonPlans: 'lessonPlan/lessonPlans'
		}),
		computedHopeForLesson() {
			// 生徒からのメッセージがない場合は「『レッスンの希望』はありません」と表示
			if (!this.lessonInfo.hopeForLesson) {
				return this.$t('top.noHopeForLesson')
			}
			// ※データベースは"DIALOG_WITH_THEME"のように大文字で格納されているため
			// ja-de辞書でkeyを大文字にしている
			return this.$t(`top.${this.lessonInfo.hopeForLesson}`)
		},
		computedPastHopeForLesson: function () {
			return function (hopeForLesson) {
				return userInfoTranslate[hopeForLesson]
			}
		},
		computedMessageForTeacher() {
			// 生徒からのメッセージがない場合は「『生徒からのメッセージ』はありません」と表示
			if (!this.lessonInfo.messageForTeacher) {
				return this.$t('top.noMessageFromStudent')
			}
			return this.lessonInfo.messageForTeacher
		},
		isConversation() {
			if (this.lessonInfo.lessonCourseId === 1) {
				return true
			} else {
				return false
			}
		},
		isPrivate() {
			if (this.lessonInfo.lessonCourseId === 2) {
				return true
			} else {
				return false
			}
		}
	},
	props: ['formTitle', 'lessonInfo'],
	created() {
		this.fetchLessonPlans({
			studentId: this.lessonInfo.studentId,
			accessToken: this.userInfo.accessToken
		})
	},
	mounted() {
		this.fetchRecentlyCompletedConvLessons()
		this.fetchStudentInfo()
	},
	methods: {
		...mapActions({
			fetchLessonPlans: 'lessonPlan/fetchLessonPlans'
		}),
		close() {
			this.$emit('closeAction')
		},
		report() {
			this.$emit('reportAction')
		},
		fetchStudentInfo() {
			return new Promise((resolve, reject) => {
				const header = {
					headers: {
						Authorization: `Bearer ${this.userInfo.accessToken}`
					}
				}
				this.axios
					.get(`/api/students/${this.lessonInfo.studentId}`, header)
					.then((response) => {
						this.student = response.data.student
						resolve()
					})
					.catch((error) => {
						console.log(error)
						reject()
					})
			})
		},
		fetchRecentlyCompletedConvLessons() {
			this.recentlyCompletedConvLessons = []
			return new Promise((resolve, reject) => {
				const header = {
					headers: {
						Authorization: `Bearer ${this.userInfo.accessToken}`
					}
				}
				this.axios
					.get(`/api/lessons/recentlyCompleted?studentId=${this.lessonInfo.studentId}&lessonCourseId=1`, header)
					.then((response) => {
						this.recentlyCompletedConvLessons = response.data.recentryCompletedLessons
						resolve()
					})
					.catch((error) => {
						console.log(error)
						reject()
					})
			})
		}
	}
}
</script>

<style lang="scss" scoped>
p {
	white-space: pre-line;
}
.report-subtitle {
	font-size: 1.4em;
	font-weight: bold;
	margin: 20px 0 15px 0;
}
.recentlyLessonItem {
	margin: 5px 0;
}
.lessonPlanPdfLink {
	margin-top: 10px;
	font-weight: bold;
	a {
		text-decoration: none;
	}
	&__content {
		margin-bottom: 6px;
	}
	&__icon {
		color: #dcc268;
	}
	&__text {
		font-size: 1em;
		position: relative;
		top: 2px;
		text-decoration: underline;
	}
}
</style>
