<template>
	<validationProvider :rules="rules" v-slot="{ errors, valid }" name="国">
		<!-- 現在の居住地 -->
		<p>
			<FormTitleText :titleText="$t('profile.country')" />
			<RequiredIcon v-if="rules && rules.includes('required')" />
		</p>
		<v-select
			:items="countryItems"
			v-model="inputedCountry"
			:label="$t('profile.requireSelection')"
			outlined
			background-color="white"
			hide-details
			:success="valid"
		></v-select>
		<FormErrorText :errorText="errors[0]" />
	</validationProvider>
</template>

<script>
import FormTitleText from '../Atoms/FormTitleText'
import FormErrorText from '../Atoms/FormErrorText'
import RequiredIcon from '../Atoms/RequiredIcon'

export default {
	name: 'StudentCountryForm',
	data: () => ({
		countryItems: ['日本', '海外']
	}),
	props: ['value', 'rules'],
	mounted() {},
	computed: {
		inputedCountry: {
			get() {
				switch (this.value) {
					case 'JAPAN':
						return '日本'
					case 'ABROAD':
						return '海外'
					default:
						return ''
				}
			},
			set(value) {
				switch (value) {
					case '日本':
						this.$emit('input', 'JAPAN')
						break
					case '海外':
						this.$emit('input', 'ABROAD')
						break
				}
			}
		}
	},
	methods: {},
	components: {
		FormTitleText,
		FormErrorText,
		RequiredIcon
	}
}
</script>

<style lang="scss" scoped></style>
