<template>
	<v-card>
		<v-card-title>
			<v-col>
				<v-row justify="center">
					<span class="headline">メッセージ追加</span>
				</v-row>
			</v-col>
		</v-card-title>
		<v-card-text>
			<v-row>
				<v-col>
					<p>to: {{ editedItem.displayFor }}</p>
					<p>メッセージ:</p>
					<v-textarea
						v-model="content"
						placeholder=""
						outlined
						background-color="white"
						counter
						height="500"
					></v-textarea>
				</v-col>
			</v-row>
		</v-card-text>
		<v-card-actions>
			<v-row justify="center">
				<v-col cols="10" xs="10" sm="10" md="6" lg="6" xl="6">
					<ActiveButton button-title="メッセージ追加" @clickAction="addMessage" class="button" />
					<CancelButton button-title="キャンセル" @clickAction="close" class="button" />
				</v-col>
			</v-row>
		</v-card-actions>
	</v-card>
</template>

<script>
import ActiveButton from '../Atoms/ActiveButton'
import CancelButton from '../Atoms/CancelButton'

export default {
	name: 'AdminAddMessageCard',
	inject: [],
	data: () => ({
		content: ''
	}),
	props: ['editedItem'],
	computed: {},
	components: {
		ActiveButton,
		CancelButton
	},
	created() {},
	mounted() {},
	methods: {
		addMessage() {
			let addData = {
				displayFor: this.editedItem.displayFor,
				content: this.content
			}

			this.axios
				.post(`/api/messages`, addData)
				.then((response) => {
					console.log('response.data', response.data)
					this.close()
					this.$emit('reload')
				})
				.catch((error) => {
					alert(error.response.data.error.message)
				})
		},
		close() {
			this.$emit('cancelAction')
		}
	},
	head: {}
}
</script>

<style lang="scss" scoped>
.button {
	margin-bottom: 20px;
}
p {
	margin: 20px 0 0 0;
}
</style>
