<template>
	<button class="customButton" @click="clickAction" :disabled="isDisabled">
		<span v-if="leftIcon" class="icon">{{ leftIcon }}</span>
		<p>{{ buttonTitle }}</p>
	</button>
</template>

<script>
export default {
	name: 'ActiveButton',
	data: () => ({}),
	props: {
		buttonTitle: {
			type: String,
			default: ''
		},
		isDisabled: {
			type: Boolean,
			default: false
		},
		leftIcon: {
			type: String,
			default: ''
		},
		width: {
			type: String,
			default: ''
		},
		block: {
			type: Boolean,
			default: true
		}
	},
	methods: {
		clickAction() {
			this.$emit('clickAction')
		}
	}
}
</script>

<style lang="scss" scoped>
p {
	font-weight: bold;
	margin: 0;
}

.customButton {
	width: 100%;
	color: white;
	background-color: #0966ae;
	font-weight: bold;
	border-radius: 4px;
	box-shadow: none;
	height: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 16px;

	@media screen and (max-width: 600px) {
		font-size: 14px;
	}

	&:hover {
		background-color: #135a98;
	}

	&:disabled {
		background-color: #cccccc;
		color: #666666;
	}
}

.icon {
	margin-right: 8px;
}

@media (min-width: 768px) {
	.customButton:hover {
		background-color: #135a98;
	}
}
</style>
