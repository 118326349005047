<template>
	<VmDialogCard
		:title="$t('teacherTop.cancelDisableDialog.title')"
		:cancelButtonTitle="$t('labels.close')"
		@close="close"
	>
		<template #cardContent>
			<div class="content">
				{{ $t('teacherTop.cancelDisableDialog.content') }}
			</div>
		</template>
	</VmDialogCard>
</template>

<script>
import VmDialogCard from '../Molecules/VmDialogCard'

export default {
	name: 'LessonCancelDisableCard',
	props: {},
	components: {
		VmDialogCard
	},
	data: () => ({}),
	created() {},
	mounted() {},
	computed: {},
	methods: {
		close() {
			this.$emit('closeAction')
		}
	}
}
</script>

<style lang="scss" scoped>
.content {
	display: flex;
}
</style>
