<template>
	<h2>{{ headingText }}</h2>
</template>

<script>
export default {
	name: 'BaseHeading',
	data: () => ({}),
	components: {},
	methods: {},
	props: ['headingText'],
	head: {
		link: [{ rel: 'stylesheet', href: 'https://use.fontawesome.com/releases/v5.6.1/css/all.css' }]
	}
}
</script>

<style lang="scss" scoped>
h2 {
	position: relative;
	background: #f4f4f4;
	padding: 2px 5px 2px 20px;
	font-size: 20px;
	color: #dcc268;
	border-radius: 0 10px 10px 0;
	margin-left: 30px;
	width: 100%;
}
h2:before {
	display: inline-block;
	line-height: 40px;
	position: absolute;
	padding: 0em;
	color: white;
	background: #dcc268;
	font-weight: 900;
	width: 40px;
	text-align: center;
	height: 40px;
	line-height: 40px;
	left: -1.35em;
	top: 50%;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
	border-radius: 50%;
	box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.29);
}
</style>
