<template>
	<div>
		<div v-if="notifiedLessonLoading">
			<VmLoading />
		</div>
		<div v-else>
			<button v-if="notifiedLessons.length" class="notification" @click="showDetail = !showDetail">
				<v-icon color="white" class="info">mdi-information-outline</v-icon>
				<span class="notification__count">{{
					$t('top.lessonNotification.lessonCount', {
						count: notifiedLessons.length
					})
				}}</span>
				<v-icon color="white" class="openDetailIcon">mdi-menu-down</v-icon>
			</button>
			<transition name="detail">
				<div class="notification__detail" v-if="notifiedLessons.length && showDetail">
					<ul :style="ulStyle">
						<li class="notification__item" v-for="lesson of notifiedLessons" :key="lesson.id">
							<span class="dot" :class="{ noDisplay: !isPC }"></span>
							{{ lessonDateTime(lesson.startTime, lesson.endTime) }}
							{{ lessonCourse(lesson.lessonCourseId) }}
							{{ lesson.Student.name }} {{ $t('labels.honorific') }}
							<button class="check" @click="check(lesson.id)">
								{{ $t('top.lessonNotification.check') }}
							</button>
						</li>
					</ul>
				</div>
			</transition>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import VmLoading from '../Atoms/VmLoading'
import { getLessonCourseName } from '../../util/getPropertyNameById'

export default {
	name: 'LessonNotification',
	props: [],
	components: {
		VmLoading
	},
	data: () => ({
		showDetail: false
	}),
	created() {
		this.fetchLessonNotifications({
			accessToken: this.userInfo.accessToken,
			teacherId: this.userInfo.teacherId
		})
	},
	mounted() {},
	computed: {
		...mapGetters({
			userInfo: 'user/getUserInfo',
			notifiedLessonLoading: 'notifiedLesson/loading',
			notifiedLessons: 'notifiedLesson/notifiedLessons'
		}),
		ulStyle() {
			if (!this.isPC) {
				return { 'padding-left': '12px' }
			} else {
				return {}
			}
		}
	},
	methods: {
		...mapActions({
			fetchLessonNotifications: 'notifiedLesson/fetchLessonNotifications',
			checkLessonNotification: 'notifiedLesson/checkLessonNotification'
		}),
		lessonDateTime(startTime, endTime) {
			this.$moment.locale(this.$i18n.locale)
			return this.$moment(startTime).format('YYYY/MM/DD (dd) HH:mm') + '-' + this.$moment(endTime).format('HH:mm')
		},
		lessonCourse(lessonCourseId) {
			return getLessonCourseName(lessonCourseId)
		},
		async check(lessonId) {
			const payload = {
				lessonId: lessonId
			}
			const isSuccess = await this.checkLessonNotification({
				accessToken: this.userInfo.accessToken,
				payload: payload
			})
			if (isSuccess)
				this.fetchLessonNotifications({
					accessToken: this.userInfo.accessToken,
					teacherId: this.userInfo.teacherId
				})
		}
	}
}
</script>

<style lang="scss" scoped>
.notification {
	position: relative;
	width: 98%;
	margin: 0 auto;
	display: flex;
	background-color: #6881dc;
	padding: 10px;
	border-radius: 4px;
	color: white;
	font-weight: bold;
	z-index: 10;

	&__count {
		text-align: left;
	}

	&__detail {
		margin: 0 auto;
		width: 98%;
		border: 1px solid #cfd2d1;
		border-top: none;
		padding-top: 4px;
		padding-bottom: 16spx;
		position: relative;
		bottom: 4px;
		border-bottom-left-radius: 4px;
		border-bottom-right-radius: 4px;
		z-index: 0;

		.check {
			color: #dcc268;
			font-weight: bold;
			text-decoration: underline;
			min-width: 52px;
		}
	}
	&__item {
		display: flex;
		list-style-type: none !important;
		margin: 20px 0;
		font-size: 0.9em;

		.dot {
			width: 0.5em;
			height: 0.5em;
			background: #cfd2d1;
			border-radius: 50%;
			margin-right: 20px;
			position: relative;
			top: 8px;
		}
	}
}
.v-application .info {
	background-color: #6881dc !important;
	margin: auto 0;
	margin-right: 4px;
}
.openDetailIcon {
	margin: auto 0;
	margin-left: auto;
}
.noDisplay {
	display: none;
}

.detail-enter-active,
.detail-leave-active {
	transition: opacity 0.2s;
}
.detail-enter, .detail-leave-to /* .fade-leave-active below version 2.1.8 */ {
	opacity: 0;
}
</style>
