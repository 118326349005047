<template>
	<div class="lessonTime">
		<div v-if="isFirstLesson">
			<span class="lessonTime__number">30</span>分 ※初回授業は30分です
			<p class="lessonTime__end">終了時間: {{ computedEndTime }}</p>
		</div>
		<div v-else>
			<div v-if="lessonTimeItems.length > 0">
				<VmSelect v-model="selectedLessonTime" :options="lessonTimeItems" label="レッスン時間"></VmSelect>
				<p v-if="selectedLessonTime && !hideEndTime">終了時間: {{ computedEndTime }}</p>
			</div>
			<p v-else>利用可能なレッスン時間がありません。開始時刻を選択し直してください。</p>
		</div>
	</div>
</template>

<script>
import VmSelect from '../../components/Atoms/VmSelect'
export default {
	name: 'LessonTimeSelector',
	components: {
		VmSelect
	},
	props: {
		computedStartTime: {
			type: String,
			required: true
		},
		isFirstLesson: {
			type: Boolean,
			default: false
		},
		endTime: {
			type: String,
			required: false
		},
		// レッスン予約時など、講師の終了時間によって選択できる時間を変更したい時
		limitedOptionWithEndTime: {
			type: Boolean,
			default: false
		},
		hideEndTime: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			selectedLessonTime: null
		}
	},
	computed: {
		isButtonValid() {
			if (!this.isFirstLesson) {
				return this.lessonTimeItems.length > 0 && this.selectedLessonTime !== null
			}
			return true
		},
		computedEndTime() {
			if (this.isFirstLesson) {
				return this.$moment(this.computedStartTime).add(30, 'minutes').format('YYYY-MM-DD HH:mm')
			} else if (this.selectedLessonTime) {
				const endTime = this.$moment(this.computedStartTime).add(this.selectedLessonTime, 'minutes')
				return endTime.format('YYYY-MM-DD HH:mm')
			}
			return null
		},
		lessonTimeItems() {
			const predefinedIntervals = [
				{ label: '30分', value: 30 },
				{ label: '60分', value: 60 },
				{ label: '90分', value: 90 }
			]
			if (!this.limitedOptionWithEndTime) {
				return predefinedIntervals
			}
			const format = 'YYYY-MM-DD HH:mm'
			const startTimeMoment = this.$moment(this.computedStartTime, format)
			const endTimeMoment = this.$moment(this.endTime, format)

			const availableMinutes = endTimeMoment.diff(startTimeMoment, 'minutes')

			const availableIntervals = predefinedIntervals.filter((interval) => interval.value <= availableMinutes)

			return availableIntervals
		}
	},
	watch: {
		// 親の開始時刻が変更されたら、選択をリセット
		computedStartTime(newVal, oldVal) {
			if (newVal !== oldVal) {
				this.selectedLessonTime = null // 選択をリセット
			}
		},
		computedEndTime(newVal) {
			if (newVal) {
				this.$emit('update:endTime', this.computedEndTime)
			}
		},
		selectedLessonTime(newVal) {
			this.$emit('validation', this.isButtonValid)
			if (newVal) {
				this.$emit('update:endTime', this.computedEndTime)
				this.$emit('update:lessonTime', this.selectedLessonTime)
			}
		},
		lessonTimeItems() {
			this.$emit('validation', this.isButtonValid)
		}
	}
}
</script>
<style lang="scss" scoped>
.lessonTime {
	margin-top: 20px;
	&__number {
		font-size: 1.5em;
		font-weight: bold;
	}
}
</style>
