export const isWithinReportingPeriod = (date) => {
	const currentDay = date.getDate()

	// 現在の月を取得 (JavaScriptでは月は0から始まるため、実際の月に+1)
	const currentMonth = date.getMonth() + 1

	if (currentDay >= 20) {
		return true
	} else if (currentDay <= 5) {
		// 今月が1月で、日にちが5日以下の場合、12月の20日以降とみなす
		if (currentMonth === 1 && currentDay <= 5) {
			return true
		} else {
			// 翌月の5日までの間は、前月の20日以降として扱う
			return true
		}
	}
	return false
}
