<template>
	<div>
		<p v-if="isJapanTimeZone" class="timezone-icon-text">
			{{ $t('common.japaneseTime') }}
		</p>
		<p v-if="isGermanTimeZone" class="timezone-icon-text">
			{{ $t('common.germanTime') }}
		</p>
	</div>
</template>

<script>
export default {
	name: 'TimeZoneIcon',
	data: () => ({
		userInfo: null
	}),
	props: [],
	mounted() {
		this.fetchInitialData()
	},
	computed: {
		isJapanTimeZone() {
			if (!this.userInfo) {
				return false
			}
			if (this.userInfo.timeZone === 'Asia/Tokyo') {
				return true
			} else {
				return false
			}
		},
		isGermanTimeZone() {
			if (!this.userInfo) {
				return false
			}
			if (this.userInfo.timeZone === 'Europe/Berlin') {
				return true
			} else {
				return false
			}
		}
	},
	methods: {
		// API通信を行うのに必要な情報を取得する
		fetchInitialData() {
			this.userInfo = this.$store.getters['user/getUserInfo']
		}
	}
}
</script>

<style lang="scss" scoped>
p {
	margin: 0;
}
.timezone-icon-text {
	color: white;
	background-color: #dcc268;
	border-radius: 5px;
	font-weight: bold;
	padding: 2px 8px;
	display: inline-block;
	margin: 15px 5px;
}
</style>
