// "〇〇:〇〇"の形の時間同士を比べる関数
// 第一引数の方が後ならtrue
function compareTime(time1, time2) {
	const time1Minutes = Number(time1.split(':')[0] * 60) + Number(time1.split(':')[1])

	const time2Minutes = Number(time2.split(':')[0] * 60) + Number(time2.split(':')[1])

	if (time1Minutes >= time2Minutes) {
		return true
	} else {
		return false
	}
}

export default compareTime
