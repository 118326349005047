<template>
	<VmToggle
		v-model="inputtedRemind"
		:label="text"
		:on-label="$t('profile.pleaseRemind')"
		:off-label="$t('profile.dontRemind')"
	/>
</template>

<script>
import VmToggle from '../Atoms/VmToggle.vue'

export default {
	name: 'RemindMailForm',
	components: {
		VmToggle
	},
	data: () => ({}),
	props: ['value', 'text'],
	mounted() {},
	computed: {
		inputtedRemind: {
			get() {
				return this.value
			},
			set(value) {
				this.$emit('input', value)
			}
		}
	},
	methods: {}
}
</script>

<style lang="scss" scoped></style>
