<template>
	<div class="dropdown" @click="toggleSelect">
		<div class="dropdown-label">
			<v-icon color="#DCC268">mdi-plus</v-icon>
			<span class="label">{{ labelName }}</span>
		</div>
		<ul v-if="showDropdown" class="dropdown-list">
			<li v-for="template in templates" :key="template.id" @click="selectTemplate($event, template)">
				{{ template.name }}
			</li>
		</ul>
	</div>
</template>

<script>
export default {
	name: 'VmClickableDropdown',
	props: {
		templates: {
			type: Array,
			default: () => []
		},
		labelName: {
			type: String,
			default: 'テンプレートを選択'
		}
	},
	data() {
		return {
			showDropdown: false
		}
	},
	created() {
		document.addEventListener('click', this.closeOnClickOutside)
	},
	destroyed() {
		document.removeEventListener('click', this.closeOnClickOutside)
	},
	methods: {
		toggleSelect() {
			this.showDropdown = !this.showDropdown // ドロップダウンメニューをトグルします。
		},
		selectTemplate(event, template) {
			event.stopPropagation() // イベント伝播を停止
			this.$emit('template-selected', template.text)
			this.showDropdown = false // テンプレートを選択した後でドロップダウンを閉じます。
		},
		// 領域外をクリックしたら閉じるように
		closeOnClickOutside(event) {
			if (!this.$el.contains(event.target) && this.showDropdown) {
				this.showDropdown = false
			}
		}
	}
}
</script>

<style scoped>
.label {
	color: #dcc268;
	font-weight: bold;
}
.dropdown {
	position: relative;
	display: inline-block;
	cursor: pointer;
}

.dropdown-label {
	display: flex;
	align-items: center;
}

.dropdown-list {
	display: block;
	position: absolute;
	background-color: #f9f9f9;
	min-width: 160px;
	box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
	z-index: 1;
}

.dropdown-list li {
	padding: 12px 16px;
	text-decoration: none;
	display: block;
	cursor: pointer;
}

.dropdown-list li:hover {
	background-color: #f1f1f1;
}
</style>
