var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[(_vm.isPC)?_c('v-row',[_c('v-col',{attrs:{"sm":"2","md":"2","lg":"2","xl":"1"}},[_c('v-img',{staticClass:"my-3",attrs:{"src":require('../../assets/Vollmond_logo.png'),"contain":""},on:{"click":_vm.toLogin}})],1)],1):_vm._e(),_c('v-col',{staticClass:"reset-area",attrs:{"cols":"12"}},[_c('v-container',[(!_vm.isPC)?_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"6","xs":"6"}},[_c('v-img',{staticClass:"my-3",attrs:{"src":require('../../assets/Vollmond_logo.png'),"contain":""},on:{"click":_vm.toLogin}})],1)],1):_vm._e(),_c('PageTitle',{staticClass:"reset-title",attrs:{"title-name":"パスワード再設定"}}),_c('v-row',{attrs:{"justify":"center"}},[_c('p',{staticClass:"reset-explanation"},[_vm._v("以下フォームに新しいパスワードを入力してパスワードを再設定してください。")])]),_c('ValidationObserver',{ref:"observer",attrs:{"immediate":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{staticClass:"current-email-label",attrs:{"cols":"12","xs":"12","sm":"6","md":"5","lg":"5","xl":"5"}},[_c('p',[_vm._v("email: "+_vm._s(_vm.resetForm.email))])])],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{staticClass:"text-field-area",attrs:{"cols":"12","xs":"12","sm":"6","md":"5","lg":"5","xl":"5"}},[_c('validationProvider',{attrs:{"rules":{
								required: true,
								regex: /^[a-zA-Z0-9!-/:-@¥[-`{-~]*$/,
								min: 8,
								max: 25
							},"name":"新パスワード"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
							var errors = ref.errors;
							var valid = ref.valid;
return [_c('v-text-field',{staticClass:"password-form",attrs:{"label":"新パスワード","outlined":"","background-color":"white","hide-details":"","success":valid,"dense":"","append-icon":_vm.showPass ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.showPass ? 'text' : 'password'},on:{"click:append":function($event){_vm.showPass = !_vm.showPass}},model:{value:(_vm.resetForm.password),callback:function ($$v) {_vm.$set(_vm.resetForm, "password", $$v)},expression:"resetForm.password"}}),_c('FormErrorText',{staticClass:"form-error",attrs:{"errorText":errors[0]}})]}}],null,true)})],1)],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{staticClass:"text-field-area",attrs:{"cols":"12","xs":"12","sm":"6","md":"5","lg":"5","xl":"5"}},[_c('validationProvider',{attrs:{"rules":{
								required: true,
								regex: /^[a-zA-Z0-9!-/:-@¥[-`{-~]*$/,
								min: 8,
								max: 25
							},"name":"新パスワード（確認）"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
							var errors = ref.errors;
							var valid = ref.valid;
return [_c('v-text-field',{staticClass:"confirmed-password-form",attrs:{"label":"新パスワード（確認）","outlined":"","background-color":"white","hide-details":"","success":valid,"dense":"","append-icon":_vm.showConfirmPass ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.showConfirmPass ? 'text' : 'password'},on:{"click:append":function($event){_vm.showConfirmPass = !_vm.showConfirmPass}},model:{value:(_vm.resetForm.confirmedPassword),callback:function ($$v) {_vm.$set(_vm.resetForm, "confirmedPassword", $$v)},expression:"resetForm.confirmedPassword"}}),_c('FormErrorText',{attrs:{"errorText":errors[0]}})]}}],null,true)})],1)],1),_c('v-row',{staticClass:"reset-button-area",attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"6","md":"5","lg":"5","xl":"5"}},[_c('ActiveButton',{staticClass:"reset-button",attrs:{"button-title":"パスワードを再設定する","isDisabled":invalid},on:{"clickAction":_vm.resetPass}})],1)],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }