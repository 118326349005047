// "00:00" "10:00"みたいに2つの時刻の文字列が与えられた時に
// ちゃんと定期レッスンの条件を満たしているかを判定する
function validationForRegularLessonTime(startTime, endTime) {
	const start = this.$moment('2021-01-01 ' + startTime)
	const end = this.$moment('2021-01-01 ' + endTime)
	const lessonTime = this.$moment(end).diff(start, 'minutes')

	// 30分で割り切れなかったらアウト
	if (lessonTime % 30 != 0) {
		return false
	}

	const numberOfTableItem = lessonTime / 30

	// 開始が終了より遅い時間ならアウト
	if (this.$moment(end).isBefore(this.$moment(start))) {
		return false
	}

	// 0分の場合アウト
	if (numberOfTableItem === 0) {
		return false
	}

	// 2時間以上の場合アウト
	if (numberOfTableItem > 3) {
		return false
	}

	// 00:00は24:00とみなす
	if (this.lessonEndTime === '00:00') {
		return true
	}

	if (end.isBefore(start)) {
		return false
	}
	return true
}

export default validationForRegularLessonTime
