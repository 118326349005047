<template>
	<validationProvider v-slot="{ errors, valid }" immediate :name="$t('profile.availableTextbook')">
		<p :style="{ margin: '0' }">
			<FormTitleText :titleText="$t('profile.availableTextbook')" />
		</p>
		<p>
			<FormAttentionText :attentionText="$t('profile.availableTextbookAttention')" />
		</p>
		<v-textarea
			v-model="inputtedAvailableTextbook"
			:placeholder="$t('profile.availableTextbookLabel')"
			outlined
			background-color="white"
			counter
			:success="valid"
		></v-textarea>
		<FormErrorText :errorText="errors[0]" />
	</validationProvider>
</template>

<script>
import FormTitleText from '../Atoms/FormTitleText'
import FormErrorText from '../Atoms/FormErrorText'
import FormAttentionText from '../Atoms/FormAttentionText'

export default {
	name: 'AvailableTextbookForm',
	components: {
		FormTitleText,
		FormErrorText,
		FormAttentionText
	},
	props: {
		value: {
			type: String,
			required: true
		}
	},
	data: () => ({}),
	mounted() {},
	computed: {
		inputtedAvailableTextbook: {
			get() {
				return this.value
			},
			set(value) {
				this.$emit('input', value)
			}
		}
	},
	methods: {}
}
</script>

<style lang="scss" scoped></style>
