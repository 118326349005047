<template>
	<v-btn
		color="rgba(0, 0, 0, 0.12)"
		class="grey--text"
		depressed
		@click.stop="clickAction"
		:style="{ fontWeight: textFontWeight }"
		block
		tile
		:disabled="isDisabled"
		>{{ buttonTitle }}</v-btn
	>
</template>

<script>
export default {
	name: 'TouchableDisbaleButton',
	data: () => ({
		textFontWeight: 'bold'
	}),
	props: ['buttonTitle', 'isDisabled'],
	methods: {
		clickAction() {
			this.$emit('clickAction')
		}
	}
}
</script>

<style lang="scss" scoped></style>
