<template>
	<v-container>
		<PageTitle title-name="会話コース講師スケジュール" />
		<SwitchTimeZone @reload="reloadCalendar()" />
		<TeacherScheduleTeacherInfo
			v-if="fetchedTeacherInfo"
			:teacherInfo="fetchedTeacherInfo"
			:teacherName="teacherName"
			:teacherImageUrl="teacherImageUrl"
			:skypeId="skypeId"
			:lessonCourseId="1"
			:message-from-teacher="fetchedTeacherInfo.convMessage"
		/>
		<v-row>
			<v-col cols="12" xs="12" sm="8" md="9" lg="9" xl="9">
				<p
					class="primary--text"
					:style="{
						fontSize: calcFontSize * 1.2 + 'em',
						fontWeight: 'bold',
						'margin-top': '24px'
					}"
				>
					予約可能状況
				</p>
			</v-col>
		</v-row>
		<component
			class="calendar-area"
			v-if="isShowCalendar"
			:is="calendarComponent"
			:availableLessons="availableLessons"
			:userInfo="userInfo"
			:studentId="studentId"
			:teacherId="teacherId"
			:accessToken="accessToken"
			:teacherName="teacherName"
			:lessonCourseId="1"
			@reload="reloadCalendar()"
		/>
		<TeacherReviewList :teacherId="teacherId" />
	</v-container>
</template>

<script>
import PageTitle from '../Atoms/PageTitle'
import TeacherScheduleTeacherInfo from '../Organisms/TeacherScheduleTeacherInfo'
import ReservationCalendar from '../Organisms/ReservationCalendar'
import TeacherScheduleCalendar from '../Organisms/TeacherScheduleCalendar'
import TeacherReviewList from '../Organisms/TeacherReviewList'
import SwitchTimeZone from '../Atoms/SwitchTimeZone'

export default {
	name: 'ConversationTeacherSchedule',
	props: ['teacherId'], // クエリから取ってくる
	components: {
		PageTitle,
		TeacherScheduleTeacherInfo,
		ReservationCalendar,
		TeacherScheduleCalendar,
		SwitchTimeZone,
		TeacherReviewList
	},
	data: () => ({
		availableLessons: [],
		calendarComponent: null,
		fetchedTeacherInfo: null,
		teacherName: '-',
		teacherImageUrl: require('../../assets/no_teacher.png'),
		skypeId: '',
		userInfo: null,
		studentId: '',
		accessToken: null,
		userAuthority: null,
		loading: true,
		isShowCalendar: true
	}),
	computed: {
		calcFontSize() {
			switch (this.$vuetify.breakpoint.name) {
				case 'xs':
					return 1.2
				case 'sm':
					return 1.2
				case 'md':
					return 1.5
				case 'lg':
					return 1.3
				default:
					return 1.2
			}
		}
	},
	async created() {},
	mounted() {
		this.init()
	},
	methods: {
		init() {
			this.fetchInitialData()
			this.fetchTeacherInfo()

			this.fetchAvailableSchedule()
		},
		// API通信を行うのに必要な情報を取得する
		fetchInitialData() {
			this.userInfo = this.$store.getters['user/getUserInfo']
			this.accessToken = this.userInfo.accessToken
			this.studentId = this.userInfo.studentId
			this.userAuthority = this.userInfo.userAuthority
		},
		// 講師の予約可能な予定を取得
		fetchAvailableSchedule() {
			this.axios
				.get(`/api/lessons/available?teacherId=${this.teacherId}&lessonCourseId=1`)
				.then((response) => {
					if (response.data) {
						this.availableLessons = response.data.availableSchedules

						// 【注意】データを取得したあとに再読み込みするために必要
						this.calendarComponent = ReservationCalendar
					}
				})
				.catch((error) => {
					console.log(error)
				})
		},
		fetchTeacherInfo() {
			this.axios
				.get(`/api/teachers/${this.teacherId}`)
				.then((response) => {
					if (response.data) {
						this.fetchedTeacherInfo = response.data.teacher
						this.teacherName = response.data.teacher.teacherName
						if (response.data.teacher.imageUrl) {
							this.teacherImageUrl = response.data.teacher.imageUrl
						}
						this.skypeId = response.data.teacher.skypeId
					}
				})
				.catch((error) => {
					console.log(error)
				})
		},
		async reloadCalendar() {
			this.isShowCalendar = false
			await this.$nextTick()
			this.isShowCalendar = true
		}
	},
	head: {
		title: {
			inner: 'Teacher-schedule'
		}
	}
}
</script>

<style lang="scss" scoped>
.calendar-area {
	margin-bottom: 100px;
}
.change-lang-area {
	margin: 3px 0 0 0;
}
</style>
