<template>
	<v-app id="app">
		<Loading v-show="$store.getters['loading/getLoading']" id="loading"></Loading>
		<v-main>
			<Header v-if="isShowHeader" />
			<router-view v-if="isRouterShow" />
			<Footer v-if="isShowFooter" />
		</v-main>
	</v-app>
</template>

<script>
import Header from './components/Organisms/Header'
import Footer from './components/Organisms/Footer'
import Loading from './components/Pages/Loading'
import { localize as veeValidateLocalize } from 'vee-validate'
import ja from '@/lang/vee-validate/ja'
import de from '@/lang/vee-validate/de'

export default {
	name: 'App',
	created() {
		if (process.env.VUE_APP_UNDER_PRODUCTION === 'true') {
			this.checkMaintenance()
		}
		// 万が一ローディングがリセットされなかった時用
		this.$store.commit('loading/SET_LOADING', false)
	},

	provide() {
		return {
			reloadHeader: this.reloadHeader,
			reloadRouter: this.reloadRouter
		}
	},

	components: {
		Header,
		Footer,
		Loading
	},

	data: () => ({
		isRouterShow: true,
		isReloadHeader: false //reload時にのみ使用される
	}),

	watch: {
		// Vue I18n の言語設定が変更されたときに呼び出される
		'$i18n.locale'(newLocale) {
			// VeeValidate のローカライズを更新
			if (newLocale === 'ja') {
				veeValidateLocalize('ja', ja)
			} else if (newLocale === 'de') {
				veeValidateLocalize('de', de)
			}
		}
	},

	computed: {
		isShowHeader() {
			if (this.isReloadHeader) {
				return false
			}

			if (this.$route.path === '/') {
				return false
			}

			if (this.$route.path === '/maintenance') {
				return false
			}

			if (this.$route.path === '/passwordForget') {
				return false
			}

			// パスワードリセットのurlは/passwordReset/:tokenなので
			if (this.$route.path.split('/')[1] === 'passwordReset') {
				return false
			}

			return true
		},
		isShowFooter() {
			if (
				this.$route.path === '/' ||
				this.$route.path === '/passwordForget' ||
				this.$route.path.split('/')[1] === 'passwordReset' // パスワードリセットのurlは/passwordReset/:tokenなので
			) {
				return false
			} else {
				return true
			}
		}
	},
	methods: {
		checkMaintenance() {
			this.axios
				.get('/api/maintenance')
				.then((res) => {
					if (res.status !== 200) {
						this.$router.push({ name: 'Maintenance' })
					}
				})
				.catch(() => {
					this.$router.push({ name: 'Maintenance' })
				})
		},
		async reloadHeader() {
			this.isReloadHeader = true
			await this.$nextTick()
			this.isReloadHeader = false
		},
		async reloadRouter() {
			this.isRouterShow = false
			await this.$nextTick()
			this.isRouterShow = true
		}
	}
}
</script>

<style>
#loading {
	z-index: 9999;
}
#app {
	font-family: 'Hiragino Kaku Gothic ProN', 'Hiragino Sans', sans-serif;
	font-weight: 500;
}

.v-data-table tbody tr {
	background: #f0f8ff;
}
.v-data-table tbody tr:nth-child(odd) {
	background: #fff;
}
.v-data-table tbody tr:hover td {
	background: rgba(220, 194, 104, 0.1);
}
.v-data-table tbody tr.v-data-table__selected {
	background: rgba(220, 194, 104, 1) !important;
	color: white;
	font-weight: bold;
}

/* カレンダー関連の設定 */
.v-calendar .v-event-summary {
	white-space: pre-line !important;
	text-overflow: inherit;
}
@media (max-width: 600px) {
	.reservationAvailable {
		height: 40px !important;
	}
	.conversationCourse {
		height: 40px !important;
	}
	.privateCourse {
		height: 40px !important;
	}
	.vacantSchedule {
		height: 40px !important;
	}
}
@media (max-width: 368px) {
	.reservationAvailable {
		height: 60px !important;
	}
	.conversationCourse {
		height: 60px !important;
	}
	.privateCourse {
		height: 60px !important;
	}
	.vacantSchedule {
		height: 60px !important;
	}
}
</style>
