<template>
	<div :style="{ background: '#FAFAFA' }">
		<v-container>
			<PageTitle title-name="プライベートコース講師一覧" />
			<v-row justify="center">
				<v-col cols="11">
					<p
						class="attention"
						:style="{
							fontSize: attentionFontSize
						}"
					>
						現在空きスケジュールのある講師のみ表示しています。
						<br />
						講師はそれぞれのタイミングで受付可能日時を登録するので、タイミングによって予約できる講師が変わります。
					</p>
				</v-col>
			</v-row>
			<SortListArea @changeAction="changeSortCondition" :lessonCourseId="2" :studentLevel="userInfo.level" />
			<v-row
				justify="center"
				no-gutters
				:style="{
					'margin-bottom': '20px',
					'margin-left': '2px'
				}"
			>
				<v-col cols="12" xs="12" sm="10" md="10" lg="10" xl="10">
					<RecommendTeacherButton v-if="!!this.userInfo.studentId" :lesson-course-id="2" />
				</v-col>
			</v-row>
			<div v-if="privateTeachersLoading" :style="{ 'margin-top': '40px', 'margin-bottom': '200px' }">
				<VmLoading />
			</div>
			<div v-else>
				<div v-if="this.showedTeachers && this.showedTeachers.length" :style="{ 'min-height': '800px' }">
					<v-row>
						<v-col cols="12" xs="12" sm="6" md="4" lg="4" xl="4" v-for="(teacher, i) in this.showedTeachers" :key="i">
							<TeacherListCard
								:teacherInfo="teacher"
								:style="{ 'margin-bottom': '20px' }"
								showAt="PRIVATE"
								@clickAction="toSchedule(teacher.id)"
							/>
						</v-col>
					</v-row>
				</div>
				<div v-else-if="isChild" :style="{ 'min-height': '800px' }">
					<v-row no-gutters justify="center">
						<p v-if="isChild" :style="{ 'text-align': 'center' }">
							お子様に対応できる講師には限りがあります。<br />お手数ですが「おすすめしてもらう」よりお問い合わせいただきますようお願いいたします。
						</p>
						<p v-else>条件に該当する講師がいません</p>
					</v-row>
				</div>
			</div>
		</v-container>
	</div>
</template>

<script>
import PageTitle from '../Atoms/PageTitle'
import SortListArea from '../Organisms/SortListArea'
import TeacherListCard from '../Organisms/TeacherListCard'
import RecommendTeacherButton from '../Organisms/RecommendTeacherButton'
import VmLoading from '../Atoms/VmLoading'
import { mapGetters, mapActions } from 'vuex'
import { PRIVATE_LESSON_COURSE_ID } from '../../const'
import { calculateAgeFromBirthDate } from '../../util'

export default {
	name: 'PrivateTeacherList',
	data: () => ({}),
	components: {
		PageTitle,
		SortListArea,
		TeacherListCard,
		RecommendTeacherButton,
		VmLoading
	},
	created() {
		if (this.privateTeachers.length === 0) {
			this.fetchTeacherList()
		}
	},
	beforeRouteUpdate(to, from, next) {
		next()
		this.fetchTeacherList()
	},
	computed: {
		...mapGetters({
			userInfo: 'user/getUserInfo',
			privateTeachers: 'privateTeachers/privateTeachers',
			privateTeachersLoading: 'privateTeachers/loading'
		}),
		showedTeachers() {
			if (!this.privateTeachers) return []

			// ユーザー種別が「子供」の場合は、対象年齢を上回っている講師のみ表示するように
			if (this.isChild) {
				const studentAge = calculateAgeFromBirthDate(this.userInfo.birthDay)
				if (!studentAge) return []
				return this.privateTeachers.filter((teacher) => teacher.minAvailableStudentAge <= studentAge)
			}

			// 管理者とオペレータは除外し、ユーザがテストアカウントでない場合はテストアカウントも除外
			return this.privateTeachers.filter((teacher) => {
				const isNonAdminOperator = !teacher.isAdmin && !teacher.isOperator // 管理者とオペレータは除外

				const isCurrentUserTestAccount = this.userInfo.isTestAccount
				const isNonTestAccountTeacher = !teacher.isTestAccount
				const isNonTestAccount = isCurrentUserTestAccount || isNonTestAccountTeacher
				return isNonAdminOperator && isNonTestAccount
			})
		},
		attentionFontSize() {
			if (this.isPC) {
				return '0.8em'
			} else {
				return '0.6em'
			}
		},
		isChild() {
			return this.userInfo.studentCategory == 'CHILD'
		}
	},
	methods: {
		...mapActions({
			fetchPrivateTeachers: 'privateTeachers/fetchPrivateTeachers'
		}),
		toSchedule(teacherId) {
			this.$router.push({
				path: 'privateTeacherSchedule',
				query: { teacherId: teacherId }
			})
		},
		async fetchTeacherList() {
			const params = {
				lessonCourseId: PRIVATE_LESSON_COURSE_ID,
				country: this.$route.query.country ?? undefined,
				compatibleLevel: this.$route.query.compatibleLevel ?? undefined,
				privLevel: this.$route.query.teachingLevel ?? undefined,
				keyword: this.$route.query.keyword ?? undefined,
				start: this.$route.query.start ?? undefined,
				end: this.$route.query.end ?? undefined,
				empty: this.$route.query.empty ?? undefined
			}
			await this.fetchPrivateTeachers(params)
		},
		// ソート条件を変更した後、APIを叩きデータを再取得する
		changeSortCondition(value) {
			const level = value.compatibleLevel === '完全初級' ? 'A0' : value.compatibleLevel

			const query = {}
			if (value.country) query.country = value.country
			if (level) query.compatibleLevel = level
			if (value.teachingLevel) query.teachingLevel = value.teachingLevel
			if (value.empty) query.empty = value.empty
			if (value.keyword) query.keyword = value.keyword
			if (value.dateRange && value.dateRange[0]) query.start = value.dateRange[0]
			if (value.dateRange && value.dateRange[1]) query.end = value.dateRange[1]

			this.$router.push({
				name: 'PrivateTeacherList',
				query: query
			})
		}
	},
	head: {
		title: {
			inner: 'teacher-list'
		}
	}
}
</script>

<style lang="scss" scoped>
p {
	white-space: pre-line;
}
.attention {
	margin: 0 0 30px 0;
	text-align: center;
	line-height: 2em;
	color: #6d6f6c;
}
</style>
