import axios from 'axios'

export const student = {
	namespaced: true,
	state: {
		data: null,
		loading: false
	},
	getters: {
		loading: (state) => {
			return state.loading
		},
		student: (state) => {
			return state.data
		}
	},
	mutations: {
		prepareConnect(state) {
			state.loading = true
		},
		stopLoading(state) {
			state.loading = false
		},
		setStudent(state, payload) {
			state.data = payload
			state.loading = false
		}
	},
	actions: {
		fetchStudentInfo({ commit }, payload) {
			commit('prepareConnect')
			const header = {
				headers: {
					Authorization: `Bearer ${payload.accessToken}`
				}
			}
			return axios
				.get(`/api/students/${payload.studentId}`, header)
				.then((res) => {
					commit('setStudent', res.data.student)
				})
				.catch(() => {
					commit('stopLoading')
				})
		}
	}
}
