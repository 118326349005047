<template>
	<VmDialogCard title="生徒情報" :cancelButtonTitle="$t('top.close')" @close="close" :height="450">
		<template #cardContent>
			<VmStudentInfo :student="student" :style="{ 'margin-top': '15px' }" :isConversation="false" />
			<v-container>
				<TeacherMemoList :studentId="studentId" />
			</v-container>
		</template>
	</VmDialogCard>
</template>

<script>
import VmStudentInfo from '../Molecules/VmStudentInfo'
import VmDialogCard from '../Molecules/VmDialogCard'
import TeacherMemoList from '../Molecules/TeacherMemoList'
import { mapGetters, mapActions } from 'vuex'

export default {
	name: 'StudentDetailCard',
	props: ['studentId'],
	components: {
		VmStudentInfo,
		VmDialogCard,
		TeacherMemoList
	},
	data: () => ({}),
	created() {
		this.fetchStudentInfo({
			studentId: this.studentId,
			accessToken: this.userInfo.accessToken
		})
	},
	mounted() {},
	computed: {
		...mapGetters({
			student: 'student/student',
			studentLoading: 'student/loading',
			userInfo: 'user/getUserInfo'
		})
	},
	methods: {
		...mapActions({
			fetchStudentInfo: 'student/fetchStudentInfo'
		}),
		close() {
			this.$emit('closeAction')
		}
	}
}
</script>

<style lang="scss" scoped></style>
