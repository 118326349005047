<template>
	<v-container>
		<v-row>
			<div class="cardWrapper">
				<v-card v-if="articleData" class="mx-auto my-4" elevation="0">
					<v-row no-gutters>
						<v-col cols="12" md="8">
							<v-card-title>{{ articleData.title }}</v-card-title>
							<v-card-subtitle>{{ articleData.firstParagraph }}</v-card-subtitle>
							<v-card-actions>
								<v-btn text @click="openArticle">続きを読む</v-btn>
							</v-card-actions>
						</v-col>
						<v-col cols="12" md="4">
							<v-img :src="articleData.image" alt="OGP Image" class="align-end"></v-img>
						</v-col>
					</v-row>
				</v-card>
			</div>
		</v-row>
	</v-container>
</template>

<script>
export default {
	name: 'TeacherArticle',
	props: {
		articleData: {
			type: Object,
			required: true
		},
		blogUrl: {
			type: String,
			required: true
		}
	},
	methods: {
		openArticle() {
			window.open(this.blogUrl, '_blank')
		}
	}
}
</script>

<style scoped>
.cardWrapper {
	width: 100%;
}
.v-card {
	border: 1px solid #e0e0e0;
	border-radius: 8px;
	overflow: hidden;
}
.v-card-title {
	font-size: 1.25rem;
	font-weight: bold;
}
.v-card-subtitle {
	font-size: 1rem;
	color: #757575;
}
.v-img {
	height: 100%;
	object-fit: cover;
}
</style>
