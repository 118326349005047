<template>
	<div>
		<div v-if="!canEdit">
			<p>
				<FormTitleText :titleText="$t('profile.skypeId')" />
			</p>
			{{ value }}
		</div>
		<validationProvider
			v-else
			:rules="{ required: true, regex: /^[a-zA-Z0-9!-/:-@¥[-`{-~]*$/ }"
			v-slot="{ errors, valid }"
			name="スカイプID"
			immediate
		>
			<p>
				<FormTitleText :titleText="$t('profile.skypeId')" />
				<FormAttentionText :attentionText="$t('profile.onlyHalfWidthAndNum')" />
			</p>
			<v-text-field
				label="skypeid"
				v-model="inputedSkypeId"
				outlined
				background-color="white"
				hide-details
				:success="valid"
			></v-text-field>
			<FormErrorText :errorText="errors[0]" />
		</validationProvider>
	</div>
</template>

<script>
import FormTitleText from '../Atoms/FormTitleText'
import FormErrorText from '../Atoms/FormErrorText'
import FormAttentionText from '../Atoms/FormAttentionText'

export default {
	name: 'SkypeIdForm',
	data: () => ({}),
	props: {
		value: {
			type: String,
			required: true
		},
		canEdit: {
			type: Boolean,
			required: false,
			default: false
		}
	},
	mounted() {},
	computed: {
		inputedSkypeId: {
			get() {
				return this.value
			},
			set(value) {
				this.$emit('input', value)
			}
		}
	},
	methods: {},
	components: {
		FormTitleText,
		FormErrorText,
		FormAttentionText
	}
}
</script>

<style lang="scss" scoped></style>
