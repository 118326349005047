<template>
	<div>
		<SubHeading sub-heading-text="予約したことのある講師" class="subheading" />
		<div class="teacher-grid">
			<div v-for="teacher in visibleTeachers" :key="teacher.id">
				<SimpleTeacherCard
					class="teacher-card"
					:catchPhrase="computedCatchPhrase(teacher.catchPhrase)"
					:teacherName="computedTeacherName(teacher.teacherName)"
					:imageUrl="computedImageUrl(teacher.imageUrl)"
					:skypeId="computedSkypeId(teacher.skypeId)"
					:to="`/${courseType}TeacherSchedule?teacherId=${teacher.id}`"
				/>
			</div>
		</div>
		<VmAccordionMenu
			class="accordion"
			text="さらに表示"
			@click="toggleHiddenTeachers"
			v-if="teachers.length > 3"
			textColor="#DCC268"
		/>
		<div v-if="showHiddenTeachers">
			<div class="teacher-grid">
				<div v-for="teacher in hiddenTeachers" :key="teacher.id">
					<SimpleTeacherCard
						class="teacher-card"
						:catchPhrase="computedCatchPhrase(teacher.catchPhrase)"
						:teacherName="computedTeacherName(teacher.teacherName)"
						:imageUrl="computedImageUrl(teacher.imageUrl)"
						:skypeId="computedSkypeId(teacher.skypeId)"
						:to="`/${courseType}TeacherSchedule?teacherId=${teacher.id}`"
					/>
				</div>
			</div>
		</div>
		<div v-if="!teachers.length">
			<p class="no-latest-teacher">まだ予約したことのある講師はいません</p>
		</div>
	</div>
</template>

<script>
import SubHeading from '../Atoms/SubHeading'
import SimpleTeacherCard from '../Organisms/SimpleTeacherCard'
import VmButton from '../Atoms/VmButton'
import VmAccordionMenu from '../Atoms/VmAccordionMenu'

export default {
	name: 'PreviouslyBookedTeachers',
	components: {
		SubHeading,
		SimpleTeacherCard,
		VmButton,
		VmAccordionMenu
	},
	props: {
		teachers: {
			type: Array,
			required: true
		},
		courseType: {
			type: 'private' | 'conversation',
			required: true
		}
	},
	data() {
		return {
			showHiddenTeachers: false
		}
	},
	computed: {
		visibleTeachers() {
			return this.teachers.slice(0, 3)
		},
		hiddenTeachers() {
			return this.teachers.slice(3)
		},
		computedCatchPhrase() {
			return (catchPhrase) => catchPhrase || '-'
		},
		computedTeacherName() {
			return (teacherName) => teacherName || '-'
		},
		computedImageUrl() {
			return (imageUrl) => imageUrl || require('../../assets/no_teacher.png')
		},
		computedSkypeId() {
			return (skypeId) => skypeId || '-'
		}
	},
	methods: {
		toggleHiddenTeachers() {
			this.showHiddenTeachers = !this.showHiddenTeachers
		}
	}
}
</script>

<style scoped>
.accordion {
	margin-top: 20px;
	margin-bottom: 8px;
}
.subheading {
	margin: 20px 0 20px 0;
}
.teacher-grid {
	display: grid;
	gap: 20px;
}

.teacher-card {
	width: 100%;
}

@media (min-width: 600px) {
	.teacher-grid {
		width: 50%;
	}
}

.no-latest-teacher {
	color: #dcc268;
	font-weight: bold;
	font-size: 0.9em;
	margin: 30px 0 30px 20px;
}
</style>
