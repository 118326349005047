<template>
	<div>
		<VmAccordionMenu text="※料金について" @click="showFeeNote = !showFeeNote" />
		<div v-if="showFeeNote" class="fee-note">
			<p class="blackColor--text">
				※初回料金の適用は初回30分のみ（初回レッスンが1時間の場合: 前半30分は初回料金、後半30分は通常料金）です。
				<br />
				<br />
				※学生料金は高校生・大学生のみ適用されます。中学生以下および語学学校の場合は通常料金となりますので予めご了承ください。
			</p>
		</div>
	</div>
</template>

<script>
import VmAccordionMenu from '../Atoms/VmAccordionMenu'

export default {
	name: 'TeacherInfoFeeArea',
	components: {
		VmAccordionMenu
	},
	props: {},
	data: () => ({
		showFeeNote: false
	}),
	computed: {}
}
</script>
<style lang="scss" scoped>
.fee-note {
	border: solid 0.5px #272727;
	padding: 12px;
	font-size: 0.8em;
	p {
		margin: 0;
	}
}
</style>
