<template>
	<div>
		<div class="custom-switch">
			<div class="switch-box" :class="{ on: inputtedValue }" @click="toggle"></div>
			<div class="label-text" @click="toggle">{{ label }}: {{ inputtedValue ? this.onLabel : this.offLabel }}</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'VmToggle',
	props: {
		value: {
			type: Boolean,
			required: true
		},
		label: {
			type: String,
			required: true
		},
		onLabel: {
			type: String,
			required: true
		},
		offLabel: {
			type: String,
			required: true
		}
	},
	components: {},
	data: () => ({}),
	created() {},
	mounted() {},
	computed: {
		inputtedValue: {
			get() {
				return this.value
			},
			set(value) {
				this.$emit('input', value)
			}
		}
	},
	methods: {
		toggle() {
			this.inputtedValue = !this.inputtedValue
		}
	}
}
</script>

<style lang="scss" scoped>
.custom-switch {
	display: flex;
	align-items: center;
	margin-bottom: 12px;
}
.switch-box,
.label-text {
	cursor: pointer;
}

.switch-box {
	width: 40px;
	height: 24px;
	border: 1px solid #ccc;
	border-radius: 12px;
	margin-right: 10px;
	position: relative;
	background: #ccc;
	transition: background 0.3s;
}

.switch-box.on {
	background: #dcc268;
}

.switch-box::before {
	content: '';
	position: absolute;
	top: 3px;
	left: 2px;
	width: 16px;
	height: 16px;
	background: white;
	border-radius: 50%;
	transition: left 0.3s;
}

.switch-box.on::before {
	left: 20px;
}
.label-text {
	color: rgba(0, 0, 0, 0.6);
}
</style>
