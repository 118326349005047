export const CONVERSATION_REWARD_TO_TEACHER = 900 // 通常の会話レッスンの報酬
export const CANCELED_CONVERSATION_REWARD_TO_TEACHER = 450 // 会話レッスンのキャンセル時の報酬
export const CONVERSATION_REWARD_WITH_FEEDBACK_TO_TEACHER = 1000 // フィードバック付きの会話レッスンの報酬

export const CONVERSATION_TICKET_PRICE = 1430 // 会話レッスンのチケット価格

export const CONVERSATION_TICKET_PRICE_DISCOUNT_THRESHOLD_5 = 5 // 5%offになる閾値（枚数）
export const CONVERSATION_TICKET_PRICE_DISCOUNT_THRESHOLD_10 = 10 // 10%offになる閾値（枚数）

export const CONVERSATION_FEEDBACK_OPTION = 2200 // フィードバックのオプション価格（/月）
