<template>
	<div class="vm-info">
		<div class="vm-info__icon">
			<v-icon color="#a9a9a9">mdi-help-circle</v-icon>
		</div>
		<div class="vm-info__text">
			<slot />
		</div>
	</div>
</template>

<script>
export default {
	name: 'VmInfo'
}
</script>

<style lang="scss" scoped>
.vm-info {
	display: inline-flex;
	align-items: center;
	padding: 8px;
	border-radius: 4px;
	background-color: #f5f6f9; /* 灰色の背景 */
	&__icon {
		flex: 0 0 16px;
		min-width: 16px;
	}
	&__text {
		margin-left: 12px;
		line-height: 1.5;
		font-size: 0.9em;
		white-space: pre-wrap;
	}
}
</style>
