import axios from 'axios'

export const plannedStudentLessons = {
	namespaced: true,
	state: {
		plannedStudentLessons: [],
		meta: {
			totalPages: 0,
			totalCount: 0
		},
		loading: false
	},
	getters: {
		loading: (state) => {
			return state.loading
		},
		plannedStudentLessons: (state) => {
			return state.plannedStudentLessons
		},
		totalPages: (state) => {
			return state.meta.totalPages
		},
		totalCount: (state) => {
			return state.meta.totalCount
		}
	},
	mutations: {
		prepareConnect(state) {
			state.loading = true
		},
		stopLoading(state) {
			state.loading = false
		},
		fetchPlannedLessonsSuccess(state, data) {
			state.plannedStudentLessons = data.plannedLessons
			state.meta = data.meta
		}
	},
	actions: {
		async fetchPlannedStudentLessons({ commit }, payload) {
			commit('prepareConnect')

			const { accessToken, params } = payload

			const header = {
				headers: {
					Authorization: `Bearer ${accessToken}`
				},
				params: params
			}

			return axios
				.get(`/api/lessons/planned/student`, header)
				.then((res) => {
					commit('fetchPlannedLessonsSuccess', res.data)
					commit('stopLoading')
				})
				.catch((err) => {
					commit('stopLoading')
					console.log(err)
				})
		}
	}
}
