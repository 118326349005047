import axios from 'axios'

export const requestModifyLesson = {
	namespaced: true,
	state: {
		loading: false
	},
	getters: {
		loading: (state) => {
			return state.loading
		}
	},
	mutations: {
		prepareConnect(state) {
			state.loading = true
		},
		stopLoading(state) {
			state.loading = false
		}
	},
	actions: {
		async requestDeleteLesson({ commit }, payload) {
			commit('prepareConnect')
			const header = {
				headers: {
					Authorization: `Bearer ${payload.accessToken}`
				}
			}
			const requestBody = {
				lessonId: payload.lessonId,
				teacherId: payload.teacherId,
				reason: payload.reason
			}
			return axios
				.post(`/api/lessons/request/delete`, requestBody, header)
				.then(() => {
					commit('stopLoading')
					return true
				})
				.catch((err) => {
					commit('stopLoading')
					console.log(err)
					return false
				})
		}
	}
}
