<template>
	<div>
		<VmToggle
			v-if="canToggleNewLesson"
			v-model="inputtedAvailability"
			:label="formTitle"
			:on-label="$t('profile.reception')"
			:off-label="$t('profile.receptionStop')"
		/>
		<p v-else>
			{{ formTitle }}:
			{{ value ? $t('profile.reception') : $t('profile.receptionStop') }}
		</p>
	</div>
</template>

<script>
import VmToggle from '../Atoms/VmToggle.vue'

export default {
	name: 'ReceptionStatusForm',
	components: {
		VmToggle
	},
	data: () => ({}),
	props: {
		formTitle: {
			type: String,
			required: true
		},
		value: {
			type: Boolean,
			required: true
		},
		canToggleNewLesson: {
			type: Boolean,
			required: true
		}
	},
	mounted() {},
	computed: {
		inputtedAvailability: {
			get() {
				return this.value
			},
			set(value) {
				this.$emit('input', value)
			}
		}
	},
	methods: {}
}
</script>

<style lang="scss" scoped></style>
