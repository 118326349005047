<template>
	<div>
		<p><FormTitleText :titleText="formTitle" /></p>
		<p>{{ name }}</p>
	</div>
</template>

<script>
import FormTitleText from '../Atoms/FormTitleText'

export default {
	name: 'NameForm',
	data: () => ({
		isEditedEmail: false
	}),
	props: ['formTitle', 'name'],
	mounted() {},
	computed: {},
	methods: {},
	components: {
		FormTitleText
	}
}
</script>

<style lang="scss" scoped></style>
