<template>
	<div class="detail-area">
		<v-card class="comment-card" tile flat>
			<p class="detail-title">コメント</p>
			<p class="item-detail">
				{{ teacherInfo.selfIntroduction }}
			</p>
		</v-card>
		<v-card class="comment-card" tile flat>
			<p class="detail-title">講師情報</p>
			<div class="heading">
				<p>
					<span>得意分野</span>
				</p>
			</div>
			<p class="item-detail">{{ teacherInfo.specialities }}</p>
			<div class="heading">
				<p>
					<span>趣味・特技</span>
				</p>
			</div>
			<p class="item-detail">{{ teacherInfo.hobbies }}</p>
		</v-card>

		<v-row justify="center">
			<TeacherCardDetailCloseButton @clickAction="clickAction" />
		</v-row>
	</div>
</template>

<script>
import TeacherCardDetailCloseButton from '../Atoms/TeacherCardDetailCloseButton'

export default {
	name: 'TeacherCardIntroRibbon',
	data: () => ({}),
	components: {
		TeacherCardDetailCloseButton
	},
	methods: {
		clickAction() {
			this.$emit('clickAction')
		}
	},
	props: ['teacherInfo']
}
</script>

<style lang="scss" scoped>
.detail-title {
	color: #272727;
	font-weight: bold;
}
.detail-area {
	background-color: rgba(220, 194, 104, 0.1);
	padding: 15px !important;
}
.comment-card {
	padding: 10px;
	line-height: 2em;
}
.detail-title {
	color: #272727;
	font-weight: bold;
}
.heading {
	position: relative;
	text-align: left;
}
.heading p span {
	position: relative;
	z-index: 2;
	display: inline-block;
	padding: 0 1em 0 0;
	background-color: #fff;
	text-align: left;
}
.heading p::before {
	position: absolute;
	top: 50%;
	z-index: 1;
	content: '';
	display: block;
	width: 100%;
	height: 1px;
	background-color: #dcc268;
}
.item-detail {
	font-size: 0.8em;
}
</style>
