// 定期予約のTBなどで使用している
// サマータイムの影響をなくす関数
// 例1)サマータイムの場合
// "2020-01-01T15:00:00.000Z" → Moment<2020-01-01T17:00:00+02:00>
// 例2)サマータイムじゃない場合
// "2020-01-01T15:00:00.000Z" → Moment<2020-01-01T16:00:00+01:00>
import moment from 'moment-timezone'

function convertToAccurateTime(time) {
	const fomattedTime = moment(time)
		.subtract(Number(moment(time).format('ZZ').split('')[2]), 'hours')
		.add(moment().format('ZZ').split('')[2], 'hours')
	return fomattedTime
}

export default convertToAccurateTime
