<template>
	<div :style="{ background: '#FAFAFA', minHeight: '80vh' }">
		<v-container>
			<PageTitle titleName="プライベートコース受講料" />
			<p class="notification">
				プライベートコースは月毎の「後払い」です。今月分のレッスン代につきましては、来月5日までに請求メールを送信いたしますのでそのメールをお待ちください（件名「受講料ご入金のお願い」）。メールが届いていない場合は
				<a href="https://vollmond.online/kontaktieren/" target="_blank" rel="noopener"> 事務局までご連絡 </a>
				ください。<br />
				講師の受講料変更のタイミングが重なる場合など、稀に表示料金と請求料金が異なるケースがあります。予めご了承ください。
				<br />
				その他お支払い情報に関するお問い合わせは
				<a href="https://vollmond.online/kontaktieren/" target="_blank" rel="noopener">事務局までご連絡 </a>
				ください。<br />
				支払い方法にはクレジットカード・PayPal・Wise・銀行振込が使用できます。
			</p>
			<AboutLessonStatus class="aboutLessonStatus" background-color="#ffffff" />
			<SelectMonth v-model="selectedMonth" @fetchData="setUpData" :disabled="isDisabledMonthSelect">
				<template #additionalContent> 合計金額: {{ totalInvoiceForStudent }} 円 </template>
			</SelectMonth>
			<v-data-table
				:headers="headers"
				:items="invoiceItems"
				:loading="studentInvoiceLoading"
				disable-sort
				no-data-text="受講したレッスンはありません"
				:items-per-page="-1"
				hide-default-footer
			>
			</v-data-table>
		</v-container>
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import PageTitle from '../Atoms/PageTitle'
import SelectMonth from '../Molecules/SelectMonth'
import AboutLessonStatus from '../Atoms/AboutLessonStatus'

export default {
	name: 'StudentInvoice',
	props: {},
	components: {
		PageTitle,
		SelectMonth,
		AboutLessonStatus
	},
	watch: {
		selectedMonth: function (newVal, oldVal) {
			this.setUpData()
		}
	},
	data: () => ({
		selectedMonth: null,
		isDisabledMonthSelect: false
	}),
	created() {},
	mounted() {},
	computed: {
		...mapGetters({
			userInfo: 'user/getUserInfo',
			studentInvoices: 'studentInvoices/studentInvoices',
			studentInvoiceLoading: 'studentInvoices/loading'
		}),
		headers() {
			return [
				{
					text: '開始時刻',
					align: 'start',
					value: 'startTime'
				},
				{
					text: '終了時刻',
					value: 'endTime'
				},
				{
					text: '担当講師',
					value: 'teacherName'
				},
				{
					text: 'ステータス',
					value: 'status'
				},
				{
					text: '',
					value: 'isFirstLesson'
				},
				{
					text: '金額',
					value: 'invoiceForStudent'
				}
			]
		},
		// テーブルに表示するデータを整形する
		invoiceItems() {
			if (!this.studentInvoices) return []
			return this.studentInvoices.map((invoice) => {
				// 数値をコンマ区切りの文字列に変換
				const formattedInvoiceForStudent = Number(invoice.invoiceForStudent).toLocaleString('ja-JP')

				return {
					startTime: this.$moment(invoice.startTime).tz('Asia/Tokyo').format('YYYY/MM/DD HH:mm'),
					endTime: this.$moment(invoice.endTime).tz('Asia/Tokyo').format('YYYY/MM/DD HH:mm'),
					teacherName: invoice.Teacher.teacherName,
					status: this.$t(`labels.lessonStatus.${invoice.status}`),
					invoiceForStudent: formattedInvoiceForStudent,
					isFirstLesson: invoice.isFirstLesson ? '初回' : ''
				}
			})
		},
		// 請求の合計金額
		totalInvoiceForStudent() {
			if (!this.studentInvoices) return '0'

			const total = this.studentInvoices.reduce((accumulator, invoice) => {
				return accumulator + Number(invoice.invoiceForStudent)
			}, 0)

			// 数値をコンマ区切りの文字列に変換して返す
			return total.toLocaleString('ja-JP')
		}
	},
	methods: {
		...mapActions({
			fetchStudentInvoices: 'studentInvoices/fetchStudentInvoices'
		}),
		setUpData() {
			const payload = {
				studentId: this.userInfo.studentId,
				year: this.$moment(this.selectedMonth).format('YYYY'),
				month: this.$moment(this.selectedMonth).format('MM'),
				accessToken: this.userInfo.accessToken
			}
			this.fetchStudentInvoices(payload)
		}
	}
}
</script>

<style lang="scss" scoped>
.notification {
	font-size: 0.9em;
	line-height: 2;
	color: #6e6b6b;
}
.aboutLessonStatus {
	margin-bottom: 20px;
}
</style>
