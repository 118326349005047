<template>
	<div>
		<!-- タイトル -->
		<PageTitle title-name="講師データ分析" />
		<VmBox>
			<template #header>
				<v-col>
					<VueJsonToCsv :json-data="downloadedCSVData" :csv-title="computedCSVTitle">
						<v-btn class="csv-button"> csvダウンロード </v-btn>
					</VueJsonToCsv>
				</v-col>
				<!-- 月変更できるところ -->
				<v-row>
					<SelectMonth v-model="selectedMonth" @fetchData="reloadTable" @reload="reloadTable" />
				</v-row>
			</template>
			<template #content>
				<v-data-table
					:headers="headers"
					:items="teacherDataArray"
					multi-sort
					class="elevation-1 overflow-y-auto"
					disable-pagination
					no-data-text="データがありません"
					hide-default-footer
					:loading="isLoading"
					fixed-header
					:height="teacherDataArray.length > 15 ? 48 * 15 - 1 : undefined"
				>
					<template v-for="(avgKey, key, index) in Object.keys(avgList)" v-slot:[`item.${avgKey}`]="{ item }">
						<div v-if="judgeHighOrLowAvg(item[avgKey], avgKey)" :key="index">
							{{ fomatPercent(item[avgKey]) }}
						</div>
						<v-chip color="dangerous" dark v-else :key="index">
							{{ fomatPercent(item[avgKey]) }}
						</v-chip>
					</template>
				</v-data-table>
			</template>
		</VmBox>
	</div>
</template>

<script>
import PageTitle from '../Atoms/PageTitle'
import SelectMonth from '../Molecules/SelectMonth'
import VmBox from '../Atoms/VmBox'

export default {
	name: 'AdminTeacherDataAnalysis',
	components: {
		PageTitle,
		SelectMonth,
		VmBox
	},
	data: () => ({
		teacherDataArray: [],
		isLoading: false,
		avgList: {
			maintenanceRate: 0,
			onceRate: 0,
			threeTimesRate: 0,
			tenTimesRate: 0,
			oneMonthRate: 0,
			threeMonthsRate: 0,
			halfYearRate: 0,
			oneYearRate: 0
		},
		downloadedCSVData: [],
		selectedMonth: null
	}),
	computed: {
		headers() {
			return [
				{
					text: 'teacherId',
					value: 'teacherId',
					align: 'start',
					sortable: false
				},
				{ text: '講師名', value: 'teacherName' },
				{ text: '総時間', value: 'totalHours' },
				{ text: '総回数', value: 'totalNumOfLessons' },
				{ text: '初回総数', value: 'totalNumOfFirstLessons' },
				{ text: '既存維持率', value: 'maintenanceRate' },
				{ text: 'リピート1回率', value: 'onceRate' },
				{ text: '3回', value: 'threeTimesRate' },
				{ text: '10回', value: 'tenTimesRate' },
				{ text: 'リピート1ヶ月率', value: 'oneMonthRate' },
				{ text: '3ヶ月', value: 'threeMonthsRate' },
				{ text: '半年', value: 'halfYearRate' },
				{ text: '1年', value: 'oneYearRate' }
			]
		},
		judgeHighOrLowAvg: function () {
			return function (val, key) {
				if (!val) {
					return true
				}
				if (val >= this.avgList[key]) {
					return true
				} else {
					return false
				}
			}
		},
		// CSVファイルのタイトル
		computedCSVTitle() {
			return this.$moment(this.selectedMonth).format('YYYY_MM_') + '講師データ'
		}
	},
	props: [],
	created() {
		this.fetchInitialData()
	},
	mounted() {},
	methods: {
		reloadTable() {
			this.downloadedCSVData = []
			this.teacherDataArray = []
			this.fetchAnalysisData()
		},
		// API通信を行うのに必要な情報を取得する
		fetchInitialData() {
			const user_info = this.$store.getters['user/getUserInfo']
			this.accessToken = user_info.accessToken
		},
		fetchAnalysisData() {
			this.isLoading = true
			return new Promise((resolve, reject) => {
				const header = {
					headers: {
						Authorization: `Bearer ${this.accessToken}`
					}
				}

				const startTime = this.$moment(this.selectedMonth).startOf('month').format('YYYY-MM-DD HH:mm')
				const endTime = this.$moment(this.selectedMonth).endOf('month').format('YYYY-MM-DD HH:mm')

				this.axios
					.get(`/api/analysis/teacher?startTime=${startTime}&endTime=${endTime}`, header)
					.then((response) => {
						const teacherData = response.data.allStatisticsData
						this.makeTeacherDataArray(teacherData)
						this.avgList = response.data.avgData
						this.isLoading = false
						resolve()
					})
					.catch((error) => {
						console.log(error)
						this.isLoading = false
						reject()
					})
			})
		},
		makeTeacherDataArray(dataArray) {
			dataArray.forEach((data) => {
				const teacherData = {
					teacherId: data.teacherId,
					teacherName: data.Teacher ? data.Teacher.teacherName : '',
					totalHours: data.totalHours,
					totalNumOfLessons: data.totalNumOfLessons,
					totalNumOfFirstLessons: data.totalNumOfFirstLessons,
					maintenanceRate: data.maintenanceRate,
					onceRate: data.onceRate,
					threeTimesRate: data.threeTimesRate,
					tenTimesRate: data.tenTimesRate,
					oneMonthRate: data.oneMonthRate,
					threeMonthsRate: data.threeMonthsRate,
					halfYearRate: data.halfYearRate,
					oneYearRate: data.oneYearRate
				}
				this.teacherDataArray.push(teacherData)
				// CSV出力用に入れておく
				this.downloadedCSVData.push(teacherData)
			})
		},
		fomatPercent(number) {
			if (number == null) {
				return '-'
			}
			return new Intl.NumberFormat('ja', {
				style: 'percent',
				maximumSignificantDigits: 3
			}).format(number)
		}
	}
}
</script>

<style lang="scss" scoped>
v-data-table {
	width: 100%;
	border-collapse: collapse;
	border-spacing: 0;
	background-color: #dcc268;
}

thead.v-data-table-header {
	background-color: #dcc268;
}

.filter-area {
	padding: 0 20px;
}
.filter-item {
	padding: 10px 5px;
}
.table-img {
	width: 100px;
	height: 100px;
	object-fit: cover;
}
.csv-upload-area {
	margin-bottom: 10px;
}
.v-data-table {
	white-space: nowrap;
}
</style>
