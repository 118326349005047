// サマータイムの影響を受けているレッスン予定の時刻を修正する

function modifySummerTimeLesson(lessons) {
	let modifiedLesson = []

	lessons.forEach((lesson) => {
		// 定期予定によって作られた予定のみ適用
		if (lesson.isRegular) {
			// 1. サマータイムじゃない時に作られている、サマータイムの予定
			// 例) createdAt: 2/22 2:00, startTime: 4/1 2:00
			if (
				this.$moment(lesson.createdAt).format('ZZ') === '+0100' &&
				this.$moment(lesson.startTime).format('ZZ') === '+0200'
			) {
				lesson.startTime = this.$moment(lesson.startTime).subtract(1, 'hours').format('YYYY-MM-DD HH:mm')
				lesson.endTime = this.$moment(lesson.endTime).subtract(1, 'hours').format('YYYY-MM-DD HH:mm')
				modifiedLesson.push(lesson)
				return
			}
			// 2. サマータイムに作られている、サマータイムじゃない予定
			// 例) createdAt: 10/22 2:00, startTime: 11/1 2:00
			if (
				this.$moment(lesson.createdAt).format('ZZ') === '+0200' &&
				this.$moment(lesson.startTime).format('ZZ') === '+0100'
			) {
				lesson.startTime = this.$moment(lesson.startTime).add(1, 'hours').format('YYYY-MM-DD HH:mm')
				lesson.endTime = this.$moment(lesson.endTime).add(1, 'hours').format('YYYY-MM-DD HH:mm')
				modifiedLesson.push(lesson)
				return
			}
		}

		modifiedLesson.push(lesson)
	})

	return modifiedLesson
}

export default modifySummerTimeLesson
