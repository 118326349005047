<template>
	<v-container>
		<!-- タイトル -->
		<PageTitle title-name="メッセージ一覧" />
		<VmBox>
			<template #header> </template>
			<template #content>
				<p class="message-title">生徒向けメッセージ</p>
				<v-data-table
					:headers="headers"
					:items="messageForStudentArray"
					:sort-by="['id']"
					:sort-desc="[false, true]"
					multi-sort
					class="elevation-1"
					disable-pagination
					no-data-text="メッセージがありません"
					hide-default-footer
				>
					<template v-slot:top>
						<v-btn color="primary" dark class="mb-2" @click="openAddForm('STUDENT')"> 新規メッセージ </v-btn>
					</template>
					<template v-slot:[`item.actions`]="{ item }">
						<v-icon small class="mr-2" @click="openEditForm(item)"> mdi-pencil </v-icon>
						<v-icon small @click="openDeleteForm(item)"> mdi-delete </v-icon>
					</template>
				</v-data-table>
				<p><span class="message-title">講師向けメッセージ</span>※2つ以上の場合いずれかをランダムで表示</p>
				<v-data-table
					:headers="headers"
					:items="messageForTeacherArray"
					:sort-by="['id']"
					:sort-desc="[false, true]"
					multi-sort
					class="elevation-1 teacher-message-table-area"
					disable-pagination
					no-data-text="メッセージがありません"
					hide-default-footer
				>
					<template v-slot:top>
						<v-btn color="primary" dark class="mb-2" @click="openAddForm('TEACHER')"> 新規メッセージ </v-btn>
					</template>
					<template v-slot:[`item.actions`]="{ item }">
						<v-icon small class="mr-2" @click="openEditForm(item)"> mdi-pencil </v-icon>
						<v-icon small @click="openDeleteForm(item)"> mdi-delete </v-icon>
					</template>
				</v-data-table>
			</template>
		</VmBox>
		<v-row>
			<v-dialog v-model="showAddDialog" max-width="500px">
				<AdminAddMessageCard @cancelAction="closeAddForm" @reload="reload" :editedItem="addItem" />
			</v-dialog>
			<v-dialog v-model="showEditingDialog" max-width="500px">
				<AdminEditingFormCard
					@closeAction="closeEditForm"
					@saveAction="updateMessage"
					:editedItem.sync="editedItem"
					formTitle="メッセージ内容編集"
				/>
			</v-dialog>
			<v-dialog v-model="showDeleteDialog" max-width="500px">
				<AdminDeleteFormCard
					@closeDeleteAction="closeDeleteForm"
					@deleteItemConfirmAction="deleteMessage"
					formTitle="このメッセージを削除しますか？"
				/>
			</v-dialog>
		</v-row>
	</v-container>
</template>

<script>
import AdminEditingFormCard from '../Organisms/AdminEditingFormCard'
import AdminDeleteFormCard from '../Organisms/AdminDeleteFormCard'
import PageTitle from '../Atoms/PageTitle'
import AdminAddMessageCard from '../Organisms/AdminAddMessageCard'
import VmBox from '../Atoms/VmBox'

export default {
	name: 'AdminMessage',
	components: {
		AdminEditingFormCard,
		AdminDeleteFormCard,
		PageTitle,
		AdminAddMessageCard,
		VmBox
	},
	data: () => ({
		messageForStudentArray: [],
		messageForStudent: [],

		messageForTeacherArray: [],
		messageForTeacher: [],

		showEditingDialog: false,
		editedItem: null,

		showDeleteDialog: false,
		deletedItem: null,

		showAddDialog: false,
		addItem: {
			displayFor: null,
			content: null
		}
	}),
	computed: {
		headers() {
			return [
				{
					text: 'id',
					align: 'start',
					value: 'id'
				},
				{ text: 'for', value: 'displayFor' },
				{ text: '内容', value: 'content' },
				{ text: 'Actions', value: 'actions', sortable: false }
			]
		}
	},
	props: [],
	async created() {
		try {
			await this.fetchMessageForStudent()
		} catch (error) {
			console.log(error)
		}
		try {
			await this.fetchMessageForTeacher()
		} catch (error) {
			console.log(error)
		}
		this.makeMessageArray()
	},
	mounted() {},
	methods: {
		async reload() {
			this.messageForStudentArray = []
			this.messageForTeacherArray = []
			this.messageForStudent = []
			this.messageForTeacher = []
			try {
				await this.fetchMessageForStudent()
			} catch (error) {
				console.log(error)
			}
			try {
				await this.fetchMessageForTeacher()
			} catch (error) {
				console.log(error)
			}
			this.makeMessageArray()
		},
		// 生徒向けメッセージを取得
		fetchMessageForStudent() {
			return new Promise((resolve, reject) => {
				this.axios
					.get(`/api/messages?displayFor=STUDENT`)
					.then((response) => {
						this.messageForStudent = response.data.message
						resolve()
					})
					.catch((error) => {
						console.log(error)
						reject()
					})
			})
		},
		// 講師向けメッセージを取得
		fetchMessageForTeacher() {
			return new Promise((resolve, reject) => {
				this.axios
					.get(`/api/messages?displayFor=TEACHER`)
					.then((response) => {
						this.messageForTeacher = response.data.message
						resolve()
					})
					.catch((error) => {
						console.log(error)
						reject()
					})
			})
		},
		makeMessageArray() {
			for (let message of this.messageForStudent) {
				const messageForStudentData = {
					id: message.id,
					displayFor: message.displayFor,
					content: message.content
				}
				this.messageForStudentArray.push(messageForStudentData)
			}

			for (let message of this.messageForTeacher) {
				const messageForTeacherData = {
					id: message.id,
					displayFor: message.displayFor,
					content: message.content
				}
				this.messageForTeacherArray.push(messageForTeacherData)
			}
		},

		// メッセージ追加
		openAddForm(messageFor) {
			this.showAddDialog = true
			this.addItem.displayFor = messageFor
		},
		closeAddForm() {
			this.showAddDialog = false
		},
		// メッセージ編集
		openEditForm(item) {
			this.showEditingDialog = true
			this.editedItem = item
		},
		closeEditForm() {
			this.showEditingDialog = false
		},
		updateMessage() {
			this.axios
				.put(`/api/messages/${this.editedItem.id}`, this.editedItem)
				.then((response) => {
					console.log('response.data', response.data)
					this.closeEditForm()
					this.reload()
				})
				.catch((error) => {
					alert(error.response.data.error.message)
				})
		},

		// メッセージ削除
		openDeleteForm(item) {
			this.showDeleteDialog = true
			this.deletedItem = item
		},
		closeDeleteForm() {
			this.showDeleteDialog = false
		},
		deleteMessage() {
			this.axios
				.delete(`/api/messages/${this.deletedItem.id}`)
				.then((response) => {
					console.log('response.data', response.data)
					this.closeDeleteForm()
					this.reload()
				})
				.catch((error) => {
					alert(error.response.data.error.message)
				})
		}
	}
}
</script>

<style lang="scss" scoped>
.message-title {
	font-size: 1.2em;
	font-weight: bold;
}
p {
	margin: 20px 0 0 0;
}
.teacher-message-table-area {
	margin-bottom: 50px;
}
</style>
