<template>
	<v-select
		:items="options"
		item-text="label"
		item-value="value"
		v-model="internalValue"
		:label="label"
		hide-details
		outlined
		background-color="white"
		dense
		@change="changeAction"
		:style="customStyle"
	>
		<!-- ここのスロットは必要に応じて調整してください -->
		<template v-slot:item="{ item }">
			<span class="selectText">{{ item.label }}</span>
		</template>
	</v-select>
</template>

<script>
export default {
	name: 'VmSelect',
	props: {
		value: [Number, String],
		options: {
			type: Array,
			required: true
		},
		label: {
			type: String,
			default: ''
		},
		customStyle: {
			type: Object,
			default: () => ({})
		}
	},
	computed: {
		internalValue: {
			get() {
				return this.value
			},
			set(newValue) {
				this.$emit('input', newValue)
			}
		}
	},
	methods: {
		changeAction(selectedValue) {
			this.$emit('change', selectedValue)
		}
	}
}
</script>

<style lang="scss" scoped>
.selectText {
	font-size: 0.8125rem;
	padding: 6px 0;
}
</style>
