<template>
	<div>
		<v-card elevation="2" class="mx-auto teacher-card" height="500px">
			<v-container>
				<div class="touchable-area" :class="{ 'clickable-area': !disabled }" @click="click">
					<v-row no-gutters dense>
						<v-col cols="5">
							<img
								:src="computedImageUrl"
								alt="teacherImage"
								:style="{
									'object-fit': 'cover',
									width: '100%',
									'aspect-ratio': '1 / 1',
									'border-radius': '10px',
									'margin-top': '10px'
								}"
							/>
						</v-col>
						<v-col
							cols="7"
							:style="{
								'padding-left': ' 15px'
							}"
						>
							<!-- 名前・在住 -->
							<v-row
								no-gutters
								:style="{
									'font-size': '1em',
									'font-weight': 'bold',
									'padding-top': ' 0.5em'
								}"
							>
								<p>{{ teacherInfo.teacherName }}先生</p>
								{{ countryEmoji }}
								<p v-if="teacherInfo.isTestAccount" class="testIcon">テスト</p>
							</v-row>
							<v-spacer />
							<!-- ベテラン -->
							<v-row no-gutters :style="{}" v-if="showPrivExpertIcon">
								<div
									class="tag rounded-pill"
									:style="{
										'margin-top': '8px'
									}"
								>
									<p>プライベートベテラン</p>
								</div>
							</v-row>
							<v-row no-gutters :style="{}" v-if="showConvExpertIcon">
								<div
									class="tag rounded-pill"
									:style="{
										'margin-top': '8px'
									}"
								>
									<p>会話ベテラン</p>
								</div>
							</v-row>
							<v-row no-gutters :style="{}" v-if="teacherInfo.category">
								<div
									class="tag rounded-pill"
									:style="{
										'margin-top': '8px'
									}"
								>
									<p>{{ categoryText }}</p>
								</div>
							</v-row>
							<!-- 空き予定 -->
							<v-row
								no-gutters
								:style="{
									'font-weight': 'bold',
									'padding-top': ' 0.5em'
								}"
								v-if="showEmpty"
								class="empty-plan"
							>
								<p>空き: {{ emptyText }}</p>
								<div
									:style="{
										'max-width': '16px',
										margin: '3px 0 0 5px'
									}"
								>
									<v-img
										:src="emptyIcon"
										:style="{
											'object-fit': 'contain'
										}"
									></v-img>
								</div>
							</v-row>
							<!-- 対応レベル -->
							<div class="compatible-level">
								<v-row no-gutters>
									<p class="content-text">対応レベル: {{ compatibleLevelText }}</p>
								</v-row>
							</div>
						</v-col>
					</v-row>
					<v-row
						no-gutters
						:style="{
							'margin-top': '10px'
						}"
						v-if="!disabled"
					>
						<!-- 予約ボタン -->
						<VmButton type="primary" text="予約する" :style="{}" @click="click" />
					</v-row>
					<v-row
						no-gutters
						:style="{
							'font-size': '1em',
							'font-weight': 'bold',
							'margin-top': '20px',
							height: '20px'
						}"
					>
						<p>{{ teacherInfo.catchPhrase }}</p>
					</v-row>
					<v-row no-gutters class="card-subtitle" :style="{ 'margin-top': '30px' }">
						<p>得意分野</p>
					</v-row>
					<v-row no-gutters class="content-text">
						<p class="specialities">
							{{ teacherInfo.specialities }}
						</p>
					</v-row>
				</div>
				<v-row no-gutters dense justify="center">
					<v-btn text @click="openDetail" class="more-button"> 詳細を見る </v-btn>
				</v-row>
			</v-container>
		</v-card>
	</div>
</template>

<script>
import VmButton from '../Atoms/VmButton'

export default {
	name: 'TeacherListCard',
	components: {
		VmButton
	},
	props: {
		teacherInfo: {
			type: Object,
			default: () => {}
		},
		showEmpty: {
			type: Boolean,
			default: true
		},
		disabled: {
			type: Boolean,
			default: false
		},
		showAt: {
			type: String,
			default: ''
		}
	},
	data: () => ({}),
	created() {},
	mounted() {},
	computed: {
		compatibleLevelText() {
			if (!this.teacherInfo || !this.teacherInfo.compatibleLevel) return '-'
			return this.teacherInfo.compatibleLevel.replace('A0', '完全初級')
		},
		categoryText() {
			// 日本人講師 or ネイティブを表示させる
			switch (this.teacherInfo.category) {
				case 'JAPANESE':
					return '日本人講師'
				case 'NATIVE':
					return 'ネイティブ'
				default:
					return ''
			}
		},
		emptyText() {
			// 講師予定の空き具合によって返すアイコンを変更する
			if (this.teacherInfo.availableTimes >= 2400) {
				return '多い'
			} else if (this.teacherInfo.availableTimes >= 1200) {
				return '普通'
			} else {
				return 'わずか'
			}
		},
		emptyIcon() {
			// 講師予定の空き具合によって返すアイコンを変更する（分）
			if (this.teacherInfo.availableTimes > 2400) {
				return require('../../assets/full_empty_icon.png')
			} else if (this.teacherInfo.availableTimes > 1200) {
				return require('../../assets/half_empty_icon.png')
			} else {
				return require('../../assets/little_empty_icon.png')
			}
		},
		computedImageUrl() {
			return this.teacherInfo.imageUrl ? this.teacherInfo.imageUrl : require('../../assets/no_teacher.png')
		},
		countryEmoji() {
			switch (this.teacherInfo.country) {
				case '日本':
					return '🇯🇵'
				case 'ドイツ':
					return '🇩🇪'
				case 'オーストリア':
					return '🇦🇹'
				case 'その他':
					return ''
				default:
					return ''
			}
		},
		showPrivExpertIcon() {
			return this.teacherInfo.privLevel === 'EXPERT' && (this.showAt === 'PRIVATE' || this.showAt === 'ALL')
		},
		showConvExpertIcon() {
			return this.teacherInfo.convLevel === 'EXPERT' && (this.showAt === 'CONVERSATION' || this.showAt === 'ALL')
		}
	},
	methods: {
		openDetail() {
			this.$router.push({
				name: 'TeacherProfile',
				params: { teacherId: this.teacherInfo.id }
			})
		},
		click() {
			this.$emit('clickAction')
		}
	}
}
</script>

<style lang="scss" scoped>
p {
	margin: 0;
	white-space: pre-line;
}
.tag {
	background: white;
	border: solid 1px #dcc268; /*線*/
	padding: 2px 15px;
	font-size: 0.9em;
}
.tag p {
	color: #dcc268;
	font-weight: bold;
	font-size: 0.8em;
	margin: 0;
}

.card-subtitle {
	font-size: 0.9em;
	color: #cfd2d1;
	font-weight: bold;
	line-height: 2em;
}
.row + .row {
	margin-top: 0;
}
.content-text {
	font-size: 0.9em;
	font-weight: bold;
	line-height: 1.5em;
	word-break: break-all;
	color: #272727;
}
.clickable-area:hover {
	cursor: pointer;
	opacity: 0.6;
}
.more-button {
	text-decoration: underline;
	position: absolute;
	text-transform: none;
	font-weight: bold;
	color: #dcc268;
	bottom: 20px;
}
.compatible-level {
	font-size: 0.8em;
	margin-top: 10px;
	&__title {
		margin-right: 4px;
	}
}
.empty-plan {
	font-size: 0.8em;
}
.specialities {
	overflow: hidden;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 3;
}
.touchable-area {
	width: 100%;
	height: 450px;
}
.testIcon {
	margin: 0;
	font-size: 0.8em;
	color: white;
	margin-left: 5px;
	padding: 0 8px;
	border-radius: 4px;
	background-color: #f00000;
}
</style>
