export const flattenObjectValues = (sourceObj) => {
	let targetObj = {}

	for (let key in sourceObj) {
		if (
			Object.prototype.hasOwnProperty.call(sourceObj, key) &&
			sourceObj[key] !== undefined &&
			Object.prototype.hasOwnProperty.call(sourceObj[key], 'value')
		) {
			targetObj[key] = sourceObj[key].value
		}
	}

	return targetObj
}
