<template>
	<VmDialogCard
		:title="isFirstLesson ? '初回レッスン予約' : 'レッスン予約'"
		buttonTitle="予約完了する"
		@click="reserve"
		@close="cancel"
		:disabled="!buttonValidation || loading"
	>
		<template #cardContent>
			<div>
				<div>
					<p class="report-subtitle">レッスン情報</p>
				</div>
				<div class="lesson-info-area">
					<div>
						<p>
							◎担当講師:
							<span>{{ teacherName }} 先生</span>
						</p>
					</div>
					<div>
						<p>
							◎受講コース:
							<span>{{ courseName }}</span>
						</p>
					</div>
					<div>
						<SharedStudentInfo />
					</div>
				</div>
			</div>
			<v-container>
				<v-row>
					<p class="report-subtitle">レッスン開始時間</p>
					<TimeZoneIcon />
				</v-row>
				<div class="date-form-area" v-if="isFormShow">
					<!-- 開始日時のフォーム -->
					<DateAndTimeForm
						:formLabelText="$t('teacherSchedule.startTime')"
						:date.sync="lessonStartDate"
						:showedDate="computedLessonStartDate"
						:minDate="selectableStartMinDate"
						:maxDate="selectableStartMaxDate"
						:time.sync="lessonStartTime"
						:minuteInterval="5"
						:minTime="selectableStartMinTime"
						:maxTime="selectableStartMaxTime"
						:hasLabel="false"
					/>
				</div>
			</v-container>
			<v-container>
				<v-row>
					<p class="report-subtitle">レッスン時間</p>
				</v-row>
				<LessonTimeSelector
					:computedStartTime="computedStartTime"
					:endTime="endTime"
					:isFirstLesson="isFirstLesson"
					limitedOptionWithEndTime
					@validation="handleValidation"
					@update:endTime="handleEndTimeChange"
				/>
			</v-container>
			<!-- 先生へのメッセージは初回のみ表示する -->
			<div v-if="isFirstLesson">
				<div>
					<p class="report-subtitle">先生へのメッセージ</p>
				</div>
				<div class="message-area">
					<div>
						<v-textarea v-model="messageForTeacher" :placeholder="messageForTeacherLabel" solo counter></v-textarea>
					</div>
					<p>ネイティブ講師の場合はドイツ語での記入を推奨します。</p>
				</div>
			</div>
			<v-container>
				<v-row>
					<p class="report-subtitle">以下の{{ isFirstLesson ? 2 : 3 }}点をご確認ください</p>
				</v-row>
				<ul class="remind-list">
					<li v-if="!isFirstLesson">
						レッスンに対する希望がある場合は、講師のスカイプもしくはメールアドレス宛にメッセージを送信してください。
					</li>
					<li>予約完了すると受講料金が発生します （※レッスン開始時刻の48時間前までにキャンセルした場合を除く）</li>
					<li>
						キャンセルポリシーは
						<a href="https://vollmond.online/cancel-policy" target="_blank" rel="noopener" style="text-decoration: none"
							>こちら</a
						>
					</li>
				</ul>
			</v-container>
		</template>
		<template #underButton>
			<div class="underButtonArea">
				予約を完了できない、やり方が分からない場合は<br />
				<ul>
					<li>
						<a href="#" @click.prevent="showLessonRequest" style="text-decoration: none">
							<button>講師にレッスンリクエストを送る</button>
						</a>
					</li>
					<li>
						<a href="https://form.run/@LessonReservation" target="_blank" rel="noopener" style="text-decoration: none"
							>レッスン仮予約を依頼する
						</a>
					</li>
				</ul>
			</div>
		</template>
	</VmDialogCard>
</template>

<script>
import TimeZoneIcon from '../Atoms/TimeZoneIcon'
import DateAndTimeForm from '../Molecules/DateAndTimeForm'
import VmDialogCard from '../Molecules/VmDialogCard'
import SharedStudentInfo from '../Molecules/SharedStudentInfo'
import { mapGetters, mapActions } from 'vuex'
import VmSelect from '../../components/Atoms/VmSelect'
import LessonTimeSelector from '../Molecules/LessonTimeSelector'
import VmInfo from '../Atoms/VmInfo'

export default {
	name: 'PrivateReservationFormCard',
	inject: ['reloadHeader'],
	components: {
		TimeZoneIcon,
		DateAndTimeForm,
		VmDialogCard,
		SharedStudentInfo,
		VmSelect,
		LessonTimeSelector,
		VmInfo
	},
	data: () => ({
		textFontWeight: 'bold',
		lessonStartDate: '2020-12-01',
		lessonStartTime: '00:00',
		startDate: '2020-12-01',
		endDate: '2020-12-01',
		messageForTeacher: '',
		isFormShow: true,
		lessonEndTime: null,
		isSelectedLessonTimeValid: false
	}),
	props: [
		'teacherName',
		'courseName',
		'startTime',
		'endTime',
		'selectedOpen',
		'studentId',
		'teacherId',
		'lessonCourseId',
		'isFirstLesson'
	],

	computed: {
		...mapGetters({
			userInfo: 'user/getUserInfo',
			loading: 'reserve/loading'
		}),
		selectableStartMinDate() {
			return this.$moment(this.startTime).format('YYYY-MM-DD')
		},
		selectableStartMaxDate() {
			let endTime = this.$moment(this.endTime)

			// endTimeが00:00の場合、1日減らす
			if (endTime.format('HH:mm') === '00:00') {
				endTime = endTime.subtract(1, 'days')
			}

			return endTime.format('YYYY-MM-DD')
		},
		selectableStartMinTime() {
			// ①選択範囲の開始日と同じ場合は"this.startTime"の時刻が最小
			// ②それ以外の場合は00:00

			if (this.lessonStartDate === this.startDate) {
				return this.$moment(this.startTime).format('HH:mm')
			} else {
				return '00:00'
			}
		},
		selectableStartMaxTime() {
			if (this.isFirstLesson) {
				// 初回レッスンの場合、終了時間から30分を差し引きます。
				const maxTimeForFirstLesson = this.$moment(this.endTime).subtract(30, 'minutes')

				// 開始日が終了日と同じで、現在の時刻が計算された最大時刻より前の場合、その時刻を返します。
				if (this.lessonStartDate === this.endDate) {
					return maxTimeForFirstLesson.format('HH:mm')
				}
			}

			// ①選択範囲の終了日と同じ場合は"this.endTime"の時刻が最大
			// ②それ以外の場合は24:00

			if (this.lessonStartDate === this.endDate) {
				return this.$moment(this.endTime).format('HH:mm')
			} else {
				return '23:55'
			}
		},
		computedStartTime() {
			const start = this.lessonStartDate + ' ' + this.lessonStartTime
			return this.$moment(start).format('YYYY-MM-DD HH:mm')
		},
		buttonValidation() {
			return this.isSelectedLessonTimeValid
		},
		computedLessonStartDate: {
			get() {
				this.$moment.locale(this.$i18n.locale)
				return this.$moment(this.lessonStartDate).format('YYYY-MM-DD(ddd)')
			},
			set(value) {
				this.lessonStartDate = this.$moment(value).format('YYYY-MM-DD')
			}
		},
		messageForTeacherLabel() {
			if (this.isFirstLesson) {
				return '初めまして、○○と申します。\n文法と会話を中心にしたレッスンを希望します。過去に少し勉強していたことがありますがほぼ完全初心者です。日本語での説明を希望します。\n初回レッスンを楽しみにしています！'
			} else {
				return 'レッスンで行いたい具体的な希望がある場合記入してください。'
			}
		}
	},
	mounted() {
		this.initLessonStartDate()
		this.initSelectedScheduleStartDate()
	},
	methods: {
		...mapActions({
			privateReserve: 'reserve/privateReserve'
		}),
		initLessonStartDate() {
			this.lessonStartDate = this.$moment(this.startTime).format('YYYY-MM-DD')
			this.lessonStartTime = this.$moment(this.startTime).format('HH:mm')
		},
		initSelectedScheduleStartDate() {
			this.startDate = this.$moment(this.startTime).format('YYYY-MM-DD')
			this.endDate = this.$moment(this.endTime).format('YYYY-MM-DD')
		},
		async reserve() {
			if (
				confirm(
					`【予約確認】\n 予約を完了しますか？ \n 担当講師：${this.teacherName}先生 \n コース名：プライベートコース \n レッスン開始時刻： ${this.computedStartTime} \n レッスン終了時刻： ${this.lessonEndTime} `
				)
			) {
				const reserveData = {
					studentId: this.studentId,
					teacherId: this.teacherId,
					lessonCourseId: this.lessonCourseId,
					startTime: this.$moment(this.computedStartTime).format(),
					endTime: this.$moment(this.lessonEndTime).format(),
					messageForTeacher: this.messageForTeacher,
					status: 0,
					isFirstLesson: this.isFirstLesson
				}

				const isSuccess = await this.privateReserve({
					accessToken: this.userInfo.accessToken,
					reserveData: reserveData
				})

				if (isSuccess) {
					this.cancel()
					this.$router.push({
						name: 'CompletePrivateReservation',
						params: {
							teacherId: this.teacherId
						}
					})
				}
			}
		},
		cancel() {
			this.$emit('cancelAction')
		},
		showLessonRequest() {
			this.$emit('cancelAction')
			this.$emit('showLessonRequest')
		},
		handleValidation(isValid) {
			this.isSelectedLessonTimeValid = isValid
		},
		handleEndTimeChange(newEndTime) {
			this.lessonEndTime = newEndTime
		}
	},
	head: {}
}
</script>

<style lang="scss" scoped>
.lesson-info-area {
	margin-bottom: 24px;
	padding: 0px 12px;
}
.message-area {
	margin-bottom: 24px;
	padding: 0px 12px;
}
.remind-list {
	margin-top: 12px;
	li {
		margin-top: 12px;
		margin-left: 4px;
	}
}
ul {
	padding-left: 12px;
}
.scroll {
	overflow-y: auto;
}
.report-subtitle {
	font-size: 1.4em;
	font-weight: bold;
	margin: 20px 0 15px 0;
}
.validation-text-col {
	padding: 0 0 0 12px;
}
.validation-text {
	color: red;
	margin: 0;
}
.date-form-area {
	margin: 20px 0 0 0;
}
.underButtonArea {
	padding: 0 16px;
	margin: 30px 0 20px 0;
	font-size: 0.8em;
}
</style>
