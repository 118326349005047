<template>
	<VmDialogCard
		title="このコースを予約するには申し込みが必要です。"
		:buttonTitle="buttonTitle"
		cancelButtonTitle="閉じる"
		@click="toSubscribe"
		@close="cancel"
		:height="230"
	>
		<template #cardContent>
			<p>以下のボタンから申し込みしてください</p>
		</template>
	</VmDialogCard>
</template>

<script>
import VmDialogCard from '../Molecules/VmDialogCard'
import {
	PRIVATE_LESSON_COURSE_ID,
	CONVERSATION_LESSON_COURSE_ID,
	PRIVATE_SUBSCRIPTION_URL,
	CONVERSATION_SUBSCRIPTION_URL
} from '../../const'

export default {
	name: 'RequestSubscriptionForm',
	props: {
		lessonCourseId: {
			type: Number,
			required: true
		}
	},
	created() {},
	data: () => ({}),
	computed: {
		buttonTitle() {
			if (this.lessonCourseId === PRIVATE_LESSON_COURSE_ID) {
				return 'プライベートコース申し込み'
			} else if (this.lessonCourseId === CONVERSATION_LESSON_COURSE_ID) {
				return '会話コース申し込み'
			}
			return ''
		}
	},
	methods: {
		toSubscribe() {
			if (this.lessonCourseId === PRIVATE_LESSON_COURSE_ID) {
				window.open(PRIVATE_SUBSCRIPTION_URL, '_blank')
			} else if (this.lessonCourseId === CONVERSATION_LESSON_COURSE_ID) {
				window.open(CONVERSATION_SUBSCRIPTION_URL, '_blank')
			}
		},
		cancel() {
			this.$emit('cancelAction')
		}
	},
	components: {
		VmDialogCard
	}
}
</script>

<style lang="scss" scoped>
.button {
	margin-bottom: 20px;
}
</style>
