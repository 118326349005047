<template>
	<div :style="{ background: '#FAFAFA' }">
		<v-container>
			<PageTitle titleName="プロフィール編集" />
			<v-row justify="center" v-if="isShowProfile">
				<v-col cols="12" xs="12" sm="8" md="6" lg="6">
					<p class="pfofile-notification">
						こちらでご記入いただいたプロフィール情報は、レッスンを予約した際に事前情報として講師に公開されます。自身では変更できない項目を変更したい場合は事務局までお問い合わせください。
					</p>
					<ValidationObserver ref="observer" v-slot="{ invalid }">
						<!-- 名前 -->
						<div class="form">
							<NameForm formTitle="名前" :name="profileForm.name" />
						</div>
						<!-- メールアドレス -->
						<div class="form">
							<EmailForm v-model="profileForm.email" rules="required" />
						</div>
						<!-- 生年月日 -->
						<div class="form">
							<BirthdayForm v-model="profileForm.birthDay" rules="required" />
						</div>
						<!-- 性別 -->
						<div class="form">
							<SexForm v-model="profileForm.sex" rules="" />
						</div>
						<!-- 区分 -->
						<div class="form">
							<CategoryForm :category="profileForm.category" />
						</div>
						<!-- 在籍学校 -->
						<div class="form" v-if="isStudentCategory">
							<SchoolForm v-model="profileForm.school" />
						</div>
						<!-- 専攻名 -->
						<div class="form" v-if="isStudentCategory">
							<MajorForm v-model="profileForm.major" />
						</div>
						<!-- 現在の居住地（日本・海外） -->
						<div class="form">
							<StudentCountryForm v-model="profileForm.country" />
						</div>
						<!-- 現在のドイツ語レベル -->
						<div class="form">
							<LevelForm v-model="profileForm.level" />
						</div>
						<!-- レッスンで学びたいこと（文法、読解、作文添削、リスニング、発音、会話、試験対策） -->
						<div class="form">
							<HopeInLessonForm v-model="profileForm.hopeInLesson" />
						</div>
						<!-- （会話コースの場合）訂正頻度 -->
						<div class="form" v-if="isConversationStudent">
							<CorrectionFrequencyForm v-model="profileForm.correctionFrequency" />
						</div>
						<!-- ドイツ語を勉強している目的や目標 -->
						<div class="form">
							<LearningGoalForm v-model="profileForm.learningGoal" />
						</div>
						<!-- その他講師に伝えておきたいこと -->
						<div class="form">
							<OtherItemForm v-model="profileForm.otherItem" />
						</div>
						<!-- リマインドメールの受け取り -->
						<div class="form">
							<FormTitleText titleText="リマインドメールの受け取り" />
							<p>
								<FormAttentionText attentionText="リマインドメールを受け取るタイミンングを選択してください。" />
							</p>
							<SelectRemindMailHoursForm
								text="会話コース"
								:value2Hours.sync="profileForm.isRemindedConv2Hours"
								:value24Hours.sync="profileForm.isRemindedConv24Hours"
								:value72Hours.sync="profileForm.isRemindedConv72Hours"
								:showTimeOptions="true"
								v-if="userInfo.lessonCourseArray.includes('CONVERSATION')"
							/>
							<SelectRemindMailHoursForm
								:style="{ marginTop: '32px' }"
								text="プライベートコース"
								:value2Hours.sync="profileForm.isRemindedPriv2Hours"
								:value24Hours.sync="profileForm.isRemindedPriv24Hours"
								:value72Hours.sync="profileForm.isRemindedPriv72Hours"
								:showTimeOptions="true"
								v-if="userInfo.lessonCourseArray.includes('PRIVATE')"
							/>
						</div>
						<v-row justify="center" class="button-area">
							<v-col cols="12" sm="6" md="6">
								<active-button :buttonTitle="$t('profile.save')" @clickAction="saveProfile" :isDisabled="invalid" />
							</v-col>
						</v-row>
					</ValidationObserver>
				</v-col>
			</v-row>
			<v-row justify="center">
				<!-- 退会ページリンク -->
				<a
					href="https://docs.google.com/forms/d/e/1FAIpQLScFqN1KDGu7NOVfjRfTeuaFdVtJCer01rBEWmUWn1WkV44nrQ/viewform?usp=sf_link"
					target="_blank"
					rel="noopener"
					class="withdrawal-text"
					>退会はこちらから</a
				>
			</v-row>
		</v-container>
	</div>
</template>

<script>
import PageTitle from '../Atoms/PageTitle'
import ActiveButton from '../Atoms/ActiveButton'
import mypageText from '@/data/mypage/mypageText'
import FormTitleText from '../Atoms/FormTitleText'
import FormAttentionText from '../Atoms/FormAttentionText'
import NameForm from '../Molecules/NameForm'
import EmailForm from '../Molecules/EmailForm'
import BirthdayForm from '../Molecules/BirthdayForm'
import SexForm from '../Molecules/SexForm'
import CategoryForm from '../Molecules/CategoryForm'
import SchoolForm from '../Molecules/SchoolForm'
import MajorForm from '../Molecules/MajorForm'
import StudentCountryForm from '../Molecules/StudentCountryForm'
import LevelForm from '../Molecules/LevelForm'
import HopeInLessonForm from '../Molecules/HopeInLessonForm'
import CorrectionFrequencyForm from '../Molecules/CorrectionFrequencyForm'
import LearningGoalForm from '../Molecules/LearningGoalForm'
import OtherItemForm from '../Molecules/OtherItemForm'
import SelectRemindMailHoursForm from '../Molecules/SelectRemindMailHoursForm'

export default {
	name: 'StudentMypage',
	inject: ['reloadRouter', 'reloadHeader'],
	components: {
		PageTitle,
		ActiveButton,
		NameForm,
		FormTitleText,
		FormAttentionText,
		EmailForm,
		BirthdayForm,
		SexForm,
		CategoryForm,
		SchoolForm,
		MajorForm,
		StudentCountryForm,
		LevelForm,
		HopeInLessonForm,
		CorrectionFrequencyForm,
		LearningGoalForm,
		OtherItemForm,
		SelectRemindMailHoursForm
	},
	data: () => ({
		profileForm: {
			email: '',
			birthDay: '',
			sex: '',
			attribute: '',
			school: '',
			major: '',
			country: '',
			level: '',
			hopeInLesson: '',
			correctionFrequency: '',
			learningGoal: '',
			otherItem: '',
			isRemindedConv2Hours: false,
			isRemindedConv24Hours: false,
			isRemindedConv72Hours: false,
			isRemindedPriv2Hours: false,
			isRemindedPriv24Hours: false,
			isRemindedPriv72Hours: false,
			image: null
		},
		scheduleLabel: mypageText.scheduleLabel,
		content: '',
		isEnableButton: false,
		isEditedEmail: false,
		userInfo: null,
		isShowProfile: true
	}),
	computed: {
		isStudentCategory() {
			if (this.profileForm.category === 'STUDENT') {
				return true
			} else {
				return false
			}
		},
		// 会話コースを受けている生徒かどうか判断する
		isConversationStudent() {
			const lessonCourseArray = this.userInfo.lessonCourseArray

			// 会話コース以外のコースが1つでもあればtrue
			for (let lessonCourse of lessonCourseArray) {
				if (lessonCourse === 'CONVERSATION') {
					return true
				}
			}
			return false
		}
	},
	created() {
		this.fetchInitialData()
		this.fetchStudentInfo()
	},
	mounted() {},
	methods: {
		// API通信を行うのに必要な情報を取得する
		fetchInitialData() {
			this.userInfo = this.$store.getters['user/getUserInfo']
		},
		fetchStudentInfo() {
			return new Promise((resolve, reject) => {
				const header = {
					headers: {
						Authorization: `Bearer ${this.userInfo.accessToken}`
					}
				}
				this.axios
					.get(`/api/students/${this.userInfo.studentId}`, header)
					.then((response) => {
						const student = response.data.student
						this.profileForm = student

						// 日付の表示を変更
						this.profileForm.birthDay = this.$moment(this.profileForm.birthDay).format('YYYY-MM-DD')

						// 画像をセット
						this.uploadImageUrl = this.profileForm.imageUrl
						resolve()
					})
					.catch((error) => {
						console.log(error)
						reject()
					})
			})
		},
		async getFileContent(file) {
			try {
				const content = await this.readFileAsync(file)
				this.uploadImageUrl = content
				this.uploadImage = file
			} catch (e) {
				this.uploadImageUrl = require('../../assets/user_placeholder.png')
				console.log(e)
			}
		},
		readFileAsync(file) {
			return new Promise((resolve, reject) => {
				const reader = new FileReader()
				reader.readAsDataURL(file)
				reader.addEventListener('load', () => {
					resolve(reader.result)
				})
				reader.onerror = reject
			})
		},
		saveProfile() {
			console.log('this.profileForm', this.profileForm)
			//バリデーション通るかチェック
			this.$refs.observer.validate().then((result) => {
				if (result) {
					// 日付のフォーマットを変更する
					this.profileForm.birthDay = this.$moment(this.profileForm.birthDay).format()

					// 画像を含むためformDataで送信
					let formData = new FormData()

					const obj = this.profileForm
					Object.keys(obj).forEach(function (key) {
						if (obj[key] != null) {
							formData.append(key, obj[key])
						}
					})
					formData.append('image', this.uploadImage)
					const header = {
						headers: {
							Authorization: `Bearer ${this.userInfo.accessToken}`,
							'Content-Type': 'multipart/form-data'
						}
					}

					this.axios
						.put(`/api/students/${this.userInfo.studentId}`, formData, header)
						.then((response) => {
							// 変更を保存しました
							alert(this.$t('profile.doneSave'))
							this.$store.commit('user/SET_USER_IMAGE', response.data.student.imageUrl)

							// リロード
							this.reloadRouter()
							this.reloadHeader()
						})
						.catch((error) => {
							alert(error.response.data.error.message)
						})

					this.profileForm.birthDay = this.$moment(this.profileForm.birthDay).format('YYYY-MM-DD')
				} else {
					alert('入力された内容が不十分な箇所があります。')
				}
			})
		}
	}
}
</script>

<style lang="scss" scoped>
.pfofile-notification {
	font-size: 0.8em;
	color: #6e6b6b;
}
.change-lang-area {
	margin: 3px 0 0 0;
}
.image-form-title {
	margin-bottom: 50px;
}
.image-form {
	margin: 50px 0;
}
.form {
	margin: 50px 0;
}
.attention {
	font-size: 0.8em;
}
.error-text {
	color: red;
	margin: 0;
	font-size: 0.8em;
}
.button-area {
	margin-bottom: 30px;
}
.withdrawal-text {
	text-decoration: none;
	margin-bottom: 40px;
	font-size: 0.8em;
}
</style>
