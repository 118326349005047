<template>
	<validationProvider :rules="rules" v-slot="{ errors, valid }" immediate :name="$t('profile.classInterval')">
		<p><FormTitleText :titleText="$t('profile.classInterval')" /></p>
		<p>{{ $t('profile.classIntervalExplanation') }}</p>
		<v-select
			:items="classIntervalItems"
			v-model="inputtedClassInterval"
			:label="$t('profile.requireSelection')"
			outlined
			background-color="white"
			hide-details
			:success="valid"
		></v-select>
		<FormErrorText :errorText="errors[0]" />
	</validationProvider>
</template>

<script>
import FormTitleText from '../Atoms/FormTitleText'
import FormErrorText from '../Atoms/FormErrorText'

export default {
	name: 'ClassIntervalForm',
	data: () => ({
		classIntervalItems: [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60]
	}),
	props: ['value', 'rules'],
	mounted() {},
	computed: {
		inputtedClassInterval: {
			get() {
				return this.value
			},
			set(value) {
				this.$emit('input', value)
			}
		}
	},
	methods: {},
	components: {
		FormTitleText,
		FormErrorText
	}
}
</script>

<style lang="scss" scoped></style>
