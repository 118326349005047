import axios from 'axios'

export const privateTeachers = {
	namespaced: true,
	state: {
		privateTeachers: [],
		loading: false
	},
	getters: {
		loading: (state) => {
			return state.loading
		},
		privateTeachers: (state) => {
			return state.privateTeachers
		}
	},
	mutations: {
		prepareConnect(state) {
			state.loading = true
		},
		stopLoading(state) {
			state.loading = false
		},
		fetchPrivateTeachersSuccess(state, teachers) {
			state.privateTeachers = teachers
		},
		resetPrivateTeachers(state) {
			state.privateTeachers = []
		}
	},
	actions: {
		async fetchPrivateTeachers({ commit }, params) {
			commit('prepareConnect')

			return axios
				.get(`/api/teachers/list`, { params })
				.then((res) => {
					let teachers = res.data.teachers

					// NOTE: 今はフロントでフィルタリングしているが、本来はバックエンドでフィルタリングすべき
					if (params.empty) {
						teachers = teachers.filter((teacher) => {
							switch (params.empty) {
								case '多い':
									return teacher.availableTimes >= 2400
								case '普通':
									return teacher.availableTimes >= 1200 && teacher.availableTimes < 2400
								case 'わずか':
									return teacher.availableTimes >= 0 && teacher.availableTimes < 1200
								default:
									return true
							}
						})
					}
					commit('fetchPrivateTeachersSuccess', teachers)
					commit('stopLoading')
				})
				.catch((err) => {
					commit('stopLoading')
					console.log(err)
				})
		},
		async resetPrivateTeachers({ commit }) {
			commit('resetPrivateTeachers')
		}
	}
}
