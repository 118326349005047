<template>
	<div>
		<v-menu v-model="menu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y>
			<template v-slot:activator="{ on, attrs }">
				<v-text-field
					v-bind="attrs"
					v-on="on"
					label="開始と終了を選択"
					:prepend-icon="prependIcon"
					readonly
					:value="dateRangeText"
					:outlined="false"
					:filled="false"
					dense
					hide-details
					solo
					:height="12"
					:flat="true"
					class="custom-text-field"
				></v-text-field>
			</template>
			<v-card flat>
				<v-date-picker
					range
					v-model="dateRange"
					no-title
					scrollable
					@input="updateDateRange"
					no-auto-close-on-range-select
					:allowed-dates="allowedDates"
				></v-date-picker>
				<v-card-actions>
					<v-btn text color="primary" @click="resetDateRange">日付を削除</v-btn>
				</v-card-actions>
			</v-card>
		</v-menu>
	</div>
</template>

<script>
export default {
	name: 'VmDateRangePicker',
	props: {
		initialDateRange: {
			type: Array,
			default: () => []
		}
	},
	data: () => ({
		menu: false,
		dateRange: []
	}),
	created() {
		if (this.initialDateRange.length === 2) {
			this.dateRange = this.initialDateRange
		}
	},
	computed: {
		dateRangeText() {
			return this.dateRange.length === 2 ? `${this.dateRange[0]} - ${this.dateRange[1]}` : ''
		},
		prependIcon() {
			return this.isPC ? 'mdi-calendar' : ''
		}
	},
	methods: {
		updateDateRange() {
			if (this.dateRange.length === 2) {
				this.$emit('update:dateRange', this.dateRange)
				this.menu = false
			}
		},
		resetDateRange() {
			this.dateRange = []
			this.$emit('update:dateRange', this.dateRange)
			this.menu = false
		},
		allowedDates(date) {
			const now = this.$moment()
			const minDate = now.add(48, 'hours').startOf('day')

			if (this.dateRange.length === 1) {
				return date >= this.dateRange[0]
			}
			return this.$moment(date).isSameOrAfter(minDate)
		}
	}
}
</script>
<style lang="scss" scoped>
.custom-text-field {
	background-color: white;
	border: 1px solid rgba(0, 0, 0, 0.38);
	border-radius: 4px;
	padding: 0 0 0 8px;
	&:hover {
		border: 1px solid rgba(0, 0, 0, 1);
	}
	height: 40px;
	font-size: 1em;
}
</style>
