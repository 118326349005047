import VueI18n from 'vue-i18n'
import Vue from 'vue'

Vue.use(VueI18n)

export const i18n = new VueI18n({
	locale: 'ja', // set locale
	messages: {
		ja: require('./lang/multiLang/ja.json'),
		de: require('./lang/multiLang/de.json')
	}
})
