<template>
	<div>
		<p>
			<FormTitleText titleText="区分" />
			<RequiredIcon v-if="rules && rules.includes('required')" />
		</p>
		<p>{{ computedCategory }}</p>
		<div class="definition-area">
			<a @click="displayDefinition" color="primary" class="display-definition-button">
				<v-icon small color="primary" v-if="isVisibleDefinition"> mdi-chevron-down </v-icon>
				<v-icon small color="primary" v-else> mdi-chevron-right </v-icon>
				学生について
			</a>

			<p v-if="isVisibleDefinition" class="student-definition">
				Vollmondで「学生」として区分されるのは、原則高校生・大学生のみです。学生の方にはプライベートコースにおいて、学生料金が適用されます。中学生以下の「子ども」にあたる方には通常料金が適用されますので予めご了承ください。区分を変更する必要のある方は、お手数ですが事務局までご一報ください。
			</p>
		</div>
	</div>
</template>

<script>
import FormTitleText from '../Atoms/FormTitleText'
import RequiredIcon from '../Atoms/RequiredIcon'

export default {
	name: 'CategoryForm',
	data: () => ({
		isVisibleDefinition: false
	}),
	props: ['category', 'rules'],
	mounted() {},
	computed: {
		computedCategory() {
			switch (this.category) {
				case 'ADULT':
					return '大人'
				case 'STUDENT':
					return '学生'
				case 'CHILD':
					return '子ども'
				default:
					return '未設定'
			}
		}
	},
	methods: {
		displayDefinition() {
			this.isVisibleDefinition = !this.isVisibleDefinition
		}
	},
	components: {
		FormTitleText,
		RequiredIcon
	}
}
</script>

<style lang="scss" scoped>
.definition-area {
	margin-bottom: 10px;
}
.display-definition-button {
	font-size: 0.8em;
}
.student-definition {
	color: 6e6b6b;
	font-size: 0.9em;
}
</style>
