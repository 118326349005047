<template>
	<div>
		<!-- おすすめボタン -->
		<VmButton type="primaryOutlined" text="おすすめしてもらう" @click="showRecommendModal = true" />
		<v-dialog v-model="showRecommendModal" max-width="500px">
			<VmDialogCard
				title="事務局におすすめしてもらう"
				description="連絡をいただいた後、最適だと思われる講師をメールでご案内いたします。"
				buttonTitle="おすすめしてもらう"
				@click="hopeToBeRecommended"
				@close="closeRecommendModal"
				:disabled="!buttonValidation"
				:height="420"
			>
				<template #cardContent>
					<!-- どのような講師をお探しですか？ -->
					<VmDialogSubTitle text="どのような講師をお探しですか？" />
					<v-textarea
						v-model="hopeContent"
						:placeholder="hopeContentPlaceholder"
						outlined
						background-color="white"
						counter
						hide-details
						class="textarea"
					></v-textarea>
					※希望が多い場合、希望の優先順位をご記入いただけると助かります。
				</template>
			</VmDialogCard>
		</v-dialog>
	</div>
</template>

<script>
import VmButton from '../Atoms/VmButton'
import VmDialogCard from '../Molecules/VmDialogCard'
import VmDialogSubTitle from '../Atoms/VmDialogSubTitle'
import { mapState } from 'vuex'

export default {
	name: 'RecommendTeacherButton',
	props: {
		lessonCourseId: {
			type: Number,
			default: 1
		}
	},
	components: {
		VmButton,
		VmDialogCard,
		VmDialogSubTitle
	},
	data: () => ({
		showRecommendModal: false,
		hopeContent: ''
	}),
	created() {},
	mounted() {},
	computed: {
		...mapState({
			userInfo: (state) => state.user
		}),
		buttonValidation() {
			if (!this.hopeContent) {
				return false
			}
			return true
		},
		hopeContentPlaceholder() {
			switch (this.lessonCourseId) {
				case 1:
					return 'ドイツ語を話した経験がほとんどないので、そういった生徒にも慣れている先生'
				case 2:
					return '・B1試験対策が得意な先生（テストが○月にあります）\n・優しい話し方の先生\nよろしくお願いします。'
				default:
					return ''
			}
		}
	},
	methods: {
		hopeToBeRecommended() {
			if (confirm('事務局におすすめ依頼を出しますか？')) {
				const header = {
					headers: {
						Authorization: `Bearer ${this.userInfo.accessToken}`
					}
				}

				const recommendPayload = {
					studentId: this.userInfo.studentId,
					hopeContent: this.hopeContent,
					lessonCourseId: this.lessonCourseId
				}

				this.axios
					.post('/api/teachers/recommended', recommendPayload, header)
					.then(() => {
						alert('講師リクエストを送信しました。')
						this.closeRecommendModal()
						this.hopeContent = ''
					})
					.catch((error) => {
						alert(error.response.data.error.message)
					})
			}
		},
		closeRecommendModal() {
			this.showRecommendModal = false
		}
	}
}
</script>

<style lang="scss" scoped></style>
