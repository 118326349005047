<template>
	<validationProvider :rules="rules" v-slot="{ errors, valid }" :name="$t('profile.sex')">
		<p>
			<FormTitleText :titleText="$t('profile.sex')" />
			<RequiredIcon v-if="rules && rules.includes('required')" />
		</p>
		<v-select
			:items="sexItems"
			v-model="inputedSex"
			:label="$t('profile.requireSelection')"
			outlined
			background-color="white"
			hide-details
			:success="valid"
		></v-select>
		<FormErrorText :errorText="errors[0]" />
	</validationProvider>
</template>

<script>
import FormTitleText from '../Atoms/FormTitleText'
import FormErrorText from '../Atoms/FormErrorText'
import RequiredIcon from '../Atoms/RequiredIcon'

export default {
	name: 'SexForm',
	data: () => ({
		sexItems: ['女性', '男性', 'その他']
	}),
	props: ['value', 'rules'],
	mounted() {
		this.initSelection()
	},
	computed: {
		inputedSex: {
			get() {
				switch (this.value) {
					case 0:
						return '不明'
					case 1:
						return this.$t('profile.man')
					case 2:
						return this.$t('profile.woman')
					case 9:
						return this.$t('profile.otherSex')
					default:
						return ''
				}
			},
			set(value) {
				switch (value) {
					case '不明':
						this.$emit('input', 0)
						break
					case this.$t('profile.man'):
						this.$emit('input', 1)
						break
					case this.$t('profile.woman'):
						this.$emit('input', 2)
						break
					case this.$t('profile.otherSex'):
						this.$emit('input', 9)
						break
				}
			}
		}
	},
	methods: {
		initSelection() {
			this.sexItems = []
			this.sexItems.push(this.$t('profile.woman'))
			this.sexItems.push(this.$t('profile.man'))
			this.sexItems.push(this.$t('profile.otherSex'))
		}
	},
	components: {
		FormTitleText,
		FormErrorText,
		RequiredIcon
	}
}
</script>

<style lang="scss" scoped></style>
