<template>
	<v-btn
		color="secondary"
		@click.stop="clickAction"
		:style="{ fontWeight: 'bold' }"
		tile
		:disabled="isDisabled"
		:width="width"
		:block="block"
	>
		<v-icon left v-if="leftIcon">{{ leftIcon }}</v-icon>
		<p>{{ buttonTitle }}</p>
	</v-btn>
</template>

<script>
export default {
	name: 'ActiveButton',
	data: () => ({}),
	props: {
		buttonTitle: {
			type: String,
			default: ''
		},
		isDisabled: {
			type: Boolean,
			default: false
		},
		leftIcon: {
			type: String,
			default: ''
		},
		width: {
			type: String,
			default: ''
		},
		block: {
			type: Boolean,
			default: true
		}
	},
	methods: {
		clickAction() {
			this.$emit('clickAction')
		}
	}
}
</script>

<style lang="scss" scoped>
p {
	font-weight: bold;
	margin: 0;
}
</style>
