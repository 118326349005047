<template>
	<v-main>
		<div class="container">
			<div class="message-area">
				<h1 class="primary--text complete-reservation-title">予約が完了しました！</h1>
			</div>
			<div>
				<p class="accent-text complete-reservation-text">
					<span>初めての先生の場合は、続いてスカイプで講師にメッセージを送ってください</span>
				</p>
				<div class="important-attention-area">
					<p class="important-attention">⚠︎メッセージはレッスン開始時刻24時間前までに行ってください⚠︎</p>
				</div>
				<div class="skype-link">
					<a href="https://vollmond.online/lernen/skype/" target="_blank" rel="noopener">スカイプの使い方はこちら！</a>
				</div>
				<div class="attention-message-area">
					<p class="attention-message">
						{{ attentionMessage2 }}
						・キャンセルポリシー：<a href="https://vollmond.online/cancel-policy/" target="_blank" rel="noopener"
							>https://vollmond.online/cancel-policy/</a
						>
					</p>
				</div>
			</div>
			<div class="button-area">
				<active-button button-title="続けて予約する" @clickAction="toSchedule" class="to-reserve-button" />
				<router-link class="back-to-top" to="/studentTop" style="text-decoration: none">
					<p>トップ画面に戻る</p>
				</router-link>
			</div>
		</div>
	</v-main>
</template>

<script>
import ActiveButton from '../Atoms/ActiveButton'

export default {
	name: 'CompleteConversationReservation',
	props: ['teacherId'],
	data: () => ({
		attentionMessage2:
			'■日時変更やキャンセルについて\n・レッスン開始時刻48時間前までであれば、講師と相談の上変更ができます。直接スカイプメッセージにて交渉しください。\n\n・48時間前以降の日時変更は原則受け付けず、キャンセル扱いとなります。\n\n'
	}),
	methods: {
		toSchedule() {
			this.$router.push({ path: 'conversationTeacherSchedule', query: { teacherId: this.teacherId } })
		}
	},

	components: {
		ActiveButton
	}
}
</script>

<style lang="scss" scoped>
.container {
	max-width: 720px;
}
h1 {
	margin-top: 20px;
	font-weight: bold;
	text-align: center;
	font-size: 20px;
}
@media (min-width: 600px) {
	// タブレット以上のサイズ
	h1.complete-reservation-title {
		font-size: 32px; // タブレット以上のフォントサイズ
	}
}
.message-area {
	display: flex;
	justify-content: center;
	align-items: center;
}
.complete-reservation-text {
	font-weight: bold;
	margin: 20px 0 0 0;
	line-height: 1.6em;
	text-align: center;

	@media (min-width: 600px) {
		font-size: 1.2em;
	}
}
.skype-link {
	margin-top: 20px;
	text-align: center;
}
.button-area {
	margin: 0 12px;
	display: flex;
	flex-direction: column;
	align-items: center;
}
.back-to-top {
	margin-top: 20px;
}
.to-reserve-button {
	margin-top: 40px;
}
.attention-message-area {
	display: flex;
	justify-content: center;
	align-items: center;
}
.attention-message {
	margin: 40px 12px 0 12px;
	text-align: left;
	white-space: pre-line;
	padding: 20px;
	background-color: #f8f8f8;
	line-height: 1.8em;
	border-radius: 8px;
}
.important-attention-area {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 12px;
}
.important-attention {
	width: auto;
	margin: 0;
	padding: 10px 20px;
	background-color: yellow;
	font-size: 0.8em;
	font-weight: bold;
}
</style>
