<template>
	<v-card>
		<v-card-title>
			<span class="headline">{{ formTitle }}</span>
		</v-card-title>
		<v-card-text>
			<v-container>
				<v-row>
					<p>{{ explanation }}</p>
				</v-row>
				<v-row>
					<div v-for="n in this.editedItemArray.length" :key="n">
						<v-col cols="12">
							<v-text-field
								v-if="editedItemArray[n - 1] != 'image'"
								v-model="inputData[editedItemArray[n - 1]]"
								:label="editedItemArray[n - 1]"
							></v-text-field>

							<form v-if="editedItemArray[n - 1] === 'image'">
								<p>画像</p>
								<input @change="changeFile" type="file" name="upfile[]" multiple accept="image/*" />
							</form>
						</v-col>
					</div>
				</v-row>
			</v-container>
		</v-card-text>
		<v-card-actions>
			<v-spacer></v-spacer>
			<v-btn color="blue darken-1" text @click="close"> Cancel </v-btn>
			<v-btn color="blue darken-1" text @click="save"> Save </v-btn>
		</v-card-actions>
	</v-card>
</template>

<script>
export default {
	name: 'AdminEditingFormCard',
	data: () => ({
		tmpEditedItem: {}
	}),
	computed: {
		editedItemArray() {
			return Object.keys(this.inputData)
		},
		inputData: {
			get() {
				return this.editedItem
			},
			set(value) {
				this.tmpEditedItem = value
				this.$emit('update:editedItem', this.childEditedItem)
			}
		}
	},
	props: ['editedItem', 'formTitle', 'explanation'],
	components: {},
	created() {},
	mounted() {},
	methods: {
		close() {
			this.$emit('closeAction')
		},
		save() {
			this.$emit('saveAction')
		},
		changeFile(elem) {
			this.$emit('changeFile', elem)
		}
	}
}
</script>

<style lang="scss" scoped>
p {
	white-space: pre-line;
}
</style>
