<template>
	<VmDialogCard title="コースを選んでください" :cancelButtonTitle="$t('labels.close')" @close="close" :height="260">
		<template #cardContent>
			<v-row no-gutters justify="center" class="buttonArea">
				<VmButton type="primary" @click="toConv" text="会話コース" class="button" />
				<VmButton type="primary" @click="toPriv" text="プライベートコース" class="button" />
			</v-row>
		</template>
	</VmDialogCard>
</template>
<script>
import VmDialogCard from '../Molecules/VmDialogCard.vue'
import VmButton from '../Atoms/VmButton'
export default {
	name: 'ChoiceCourseDialog',
	props: {
		teacherInfo: {
			type: Object,
			default: () => {}
		}
	},
	components: {
		VmDialogCard,
		VmButton
	},
	data: () => ({}),
	created() {},
	mounted() {},
	computed: {},
	methods: {
		close() {
			this.$emit('close')
		},
		toConv() {
			this.$emit('toConv')
		},
		toPriv() {
			this.$emit('toPriv')
		}
	}
}
</script>

<style lang="scss" scoped>
.buttonArea {
	margin-top: 50px;
}
.button {
	margin: 0 5px 20px 5px;
}
</style>
