import { render, staticRenderFns } from "./RequiredIcon.vue?vue&type=template&id=cbf89dd4&scoped=true&"
import script from "./RequiredIcon.vue?vue&type=script&lang=js&"
export * from "./RequiredIcon.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cbf89dd4",
  null
  
)

export default component.exports