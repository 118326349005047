<template>
	<v-container>
		<!-- 講師の統計データテーブル -->
		<v-row>
			<v-col>
				<v-data-table
					:headers="headers"
					:items="staticsItems"
					:class="{ 'elevation-1': true }"
					disable-sort
					:no-data-text="$t('top.noStaticsData')"
					hide-default-footer
					:loading="isLoading"
				>
					<template v-for="(headerItem, key, index) in headers" v-slot:[`item.${headerItem.value}`]="{ item }">
						<div v-if="headerItem.value == 'name'" :key="index">
							{{ item[headerItem.value] }}
						</div>
						<v-chip
							color="secondary"
							dark
							v-else-if="judgeHighOrLowAvg(item[headerItem.value], headerItem.value) && item.name != 'vollmond平均'"
							:key="index"
						>
							{{ fomatPercent(item[headerItem.value]) }}
						</v-chip>
						<div v-else :key="index">
							{{ fomatPercent(item[headerItem.value]) }}
						</div>
					</template>
				</v-data-table>
			</v-col>
		</v-row>
		<!-- 統計データの算出方法 -->
		<v-row>
			<VmAccordionMenu
				:text="$t('top.howToCalcRate')"
				@click="showHowToCalc = !showHowToCalc"
				:style="{ 'margin-left': '12px' }"
			/>
		</v-row>
		<v-row v-if="showHowToCalc" :style="{ padding: '15px' }">
			<div :style="{ background: '#FAFAFA', padding: '10px 15px' }">
				<p
					:style="{
						'font-size': '0.8em',
						'line-height': '2.3'
					}"
				>
					{{ $t('top.explanationOfCalc') }}
				</p>
			</div>
		</v-row>
	</v-container>
</template>

<script>
import VmAccordionMenu from '../Atoms/VmAccordionMenu'

export default {
	name: 'TeacherStaticsTable',
	inject: ['reloadRouter'],
	components: {
		VmAccordionMenu
	},
	data: () => ({
		userInfo: null,
		headers: [
			{ text: '', value: 'name' },
			{ text: '既存維持率', value: 'maintenanceRate' },
			{ text: 'リピート1回率', value: 'onceRate' },
			{ text: '3回', value: 'threeTimesRate' },
			{ text: '10回', value: 'tenTimesRate' },
			{ text: 'リピート1ヶ月率', value: 'oneMonthRate' },
			{ text: '3ヶ月', value: 'threeMonthsRate' },
			{ text: '半年', value: 'halfYearRate' },
			{ text: '1年', value: 'oneYearRate' }
		],
		staticsItems: [],
		showHowToCalc: false,
		avgData: {},
		isLoading: false
	}),
	computed: {
		judgeHighOrLowAvg: function () {
			return function (val, key) {
				if (!val) {
					return false
				}
				if (val >= this.avgData[key]) {
					return true
				} else {
					return false
				}
			}
		}
	},
	props: [],
	async created() {
		this.fetchInitialData()
		this.setupColumn()
		this.fetchTeacherStatics()
	},
	mounted() {},
	methods: {
		// API通信を行うのに必要な情報を取得する
		fetchInitialData() {
			this.userInfo = this.$store.getters['user/getUserInfo']
		},

		setupColumn() {
			// 多言語対応のため
			this.headers[1].text = this.$t('top.maintenanceRate')
			this.headers[2].text = this.$t('top.onceRate')
			this.headers[3].text = this.$t('top.threeTimesRate')
			this.headers[4].text = this.$t('top.tenTimesRate')
			this.headers[5].text = this.$t('top.oneMonthRate')
			this.headers[6].text = this.$t('top.threeMonthsRate')
			this.headers[7].text = this.$t('top.halfYearRate')
			this.headers[8].text = this.$t('top.oneYearRate')
		},

		// 講師の統計データを取得
		fetchTeacherStatics() {
			return new Promise((resolve, reject) => {
				this.isLoading = true
				const header = {
					headers: {
						Authorization: `Bearer ${this.userInfo.accessToken}`
					}
				}

				this.axios
					.get(`api/analysis/oneTeacher?teacherId=${this.userInfo.teacherId}`, header)
					.then((response) => {
						const teacherData = response.data.teacherStatistics
						this.avgData = response.data.avgData

						this.makeListItem(teacherData, this.avgData)
						this.isLoading = false
						resolve()
					})
					.catch((error) => {
						console.log(error)
						reject()
					})
			})
		},

		makeListItem(teacherData, avgData) {
			const teacherDataItem = {
				name: teacherData.Teacher.teacherName + '先生',
				maintenanceRate: teacherData.maintenanceRate,
				onceRate: teacherData.onceRate,
				threeTimesRate: teacherData.threeTimesRate,
				tenTimesRate: teacherData.tenTimesRate,
				oneMonthRate: teacherData.oneMonthRate,
				threeMonthsRate: teacherData.threeMonthsRate,
				halfYearRate: teacherData.halfYearRate,
				oneYearRate: teacherData.oneYearRate
			}

			this.staticsItems.push(teacherDataItem)

			const avgDataItem = {
				name: this.$t('top.avgOfVollmond'),
				maintenanceRate: avgData.maintenanceRate,
				onceRate: avgData.onceRate,
				threeTimesRate: avgData.threeTimesRate,
				tenTimesRate: avgData.tenTimesRate,
				oneMonthRate: avgData.oneMonthRate,
				threeMonthsRate: avgData.threeMonthsRate,
				halfYearRate: avgData.halfYearRate,
				oneYearRate: avgData.oneYearRate
			}

			this.staticsItems.push(avgDataItem)
		},
		fomatPercent(number) {
			if (number == null) {
				return '-'
			}
			return new Intl.NumberFormat('ja', {
				style: 'percent',
				maximumSignificantDigits: 3
			}).format(number)
		}
	}
}
</script>

<style lang="scss" scoped>
p {
	margin: 0;
	white-space: pre-line;
}
.hoverPointer td {
	cursor: pointer;
}
.pagenation {
	margin: 20px 0 0 0;
}
</style>
