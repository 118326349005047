<template>
	<div :style="{ background: '#FAFAFA', minHeight: '80vh' }">
		<v-container>
			<PageTitle :titleName="$t('teacherReward.title')" />
			<ChangeLocale />
			<p class="notification">
				{{ $t('teacherReward.preLink') }}
				<a href="https://vollmond.online/kontaktieren/" target="_blank" rel="noopener">
					{{ $t('teacherReward.linkText') }}
				</a>
				{{ $t('teacherReward.postLink') }}
			</p>
			<SelectMonth v-model="selectedMonth" @fetchData="setUpData" :disabled="isDisabledMonthSelect">
				<template #additionalContent>
					{{ $t('teacherReward.sum') }}: {{ totalRewardForTeacher }}
					{{ $t('teacherReward.yen') }}
				</template>
			</SelectMonth>
			<v-data-table
				:headers="headers"
				:items="rewardItems"
				:loading="teacherRewardLoading"
				disable-sort
				:no-data-text="$t('teacherReward.noLesson')"
				:items-per-page="-1"
				hide-default-footer
			>
			</v-data-table>
		</v-container>
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import PageTitle from '../Atoms/PageTitle'
import SelectMonth from '../Molecules/SelectMonth'
import ChangeLocale from '../Molecules/ChangeLocale'

export default {
	name: 'TeacherReward',
	props: {},
	components: {
		PageTitle,
		SelectMonth,
		ChangeLocale
	},
	watch: {
		selectedMonth: function (newVal, oldVal) {
			this.setUpData()
		}
	},
	data: () => ({
		selectedMonth: null,
		isDisabledMonthSelect: false
	}),
	created() {},
	mounted() {},
	computed: {
		...mapGetters({
			userInfo: 'user/getUserInfo',
			teacherRewards: 'teacherRewards/teacherRewards',
			teacherRewardLoading: 'teacherRewards/loading'
		}),
		headers() {
			return [
				{
					text: this.$t('labels.startTime'),
					align: 'start',
					value: 'startTime'
				},
				{
					text: this.$t('labels.endTime'),
					value: 'endTime'
				},
				{
					text: this.$t('labels.lessonCourse'),
					value: 'lessonCourseName'
				},
				{
					text: this.$t('labels.studentName'),
					value: 'studentName'
				},
				{
					text: this.$t('labels.status'),
					value: 'status'
				},
				{
					text: '',
					value: 'isFirstLesson'
				},
				{
					text: this.$t('labels.amount'),
					value: 'rewardForTeacher'
				}
			]
		},
		rewardItems() {
			if (!this.teacherRewards) return []
			return this.teacherRewards.map((reward) => {
				const formattedRewardForTeacher = Number(reward.rewardForTeacher).toLocaleString('ja-JP')

				return {
					startTime: this.$moment(reward.startTime).tz('Asia/Tokyo').format('YYYY/MM/DD HH:mm'),
					endTime: this.$moment(reward.endTime).tz('Asia/Tokyo').format('YYYY/MM/DD HH:mm'),
					lessonCourseName: this.$t(`labels.${reward.LessonCourse.courseName}`),
					studentName: reward.Student.name,
					status: this.$t(`labels.lessonStatus.${reward.status}`),
					rewardForTeacher: formattedRewardForTeacher,
					isFirstLesson: reward.isFirstLesson ? this.$t('labels.isFirstLesson') : ''
				}
			})
		},
		totalRewardForTeacher() {
			if (!this.teacherRewards) return '0'

			const total = this.teacherRewards.reduce((accumulator, reward) => {
				return accumulator + Number(reward.rewardForTeacher)
			}, 0)

			return total.toLocaleString('ja-JP')
		}
	},
	methods: {
		...mapActions({
			fetchTeacherRewards: 'teacherRewards/fetchTeacherRewards'
		}),
		setUpData() {
			const payload = {
				teacherId: this.userInfo.teacherId,
				year: this.$moment(this.selectedMonth).format('YYYY'),
				month: this.$moment(this.selectedMonth).format('MM'),
				accessToken: this.userInfo.accessToken
			}
			this.fetchTeacherRewards(payload)
		}
	}
}
</script>

<style lang="scss" scoped>
.notification {
	font-size: 0.8em;
	color: #6e6b6b;
}
</style>
