<template>
	<div :style="{ background: '#FAFAFA' }">
		<v-container>
			<PageTitle title-name="会話コース講師一覧" />
			<v-row justify="center">
				<v-col cols="11">
					<p
						class="attention"
						:style="{
							fontSize: attentionFontSize
						}"
					>
						現在空きスケジュールのある講師のみ表示しています。
						<br />
						講師はそれぞれのタイミングで受付可能日時を登録するので、タイミングによって予約できる講師が変わります。
						<br />
						対象レベルは目安であり、自己判断で構いません
					</p>
				</v-col>
			</v-row>
			<!-- ソート条件 -->
			<SortListArea @changeAction="changeSortCondition" :lessonCourseId="1" :studentLevel="userInfo.level" />
			<v-row
				justify="center"
				no-gutters
				:style="{
					'margin-bottom': '20px',
					'margin-left': '2px'
				}"
			>
				<v-col cols="12" xs="12" sm="10" md="10" lg="10" xl="10">
					<RecommendTeacherButton v-if="!!this.userInfo.studentId" :lesson-course-id="1" />
				</v-col>
			</v-row>
			<div v-if="conversationTeachersLoading" :style="{ 'margin-top': '40px', 'margin-bottom': '200px' }">
				<VmLoading />
			</div>
			<div v-else>
				<div v-if="this.showedTeachers && this.showedTeachers.length" :style="{ 'min-height': '800px' }">
					<v-row>
						<v-col cols="12" xs="12" sm="6" md="4" lg="4" xl="4" v-for="(teacher, i) in this.showedTeachers" :key="i">
							<TeacherListCard
								:teacherInfo="teacher"
								:style="{ 'margin-bottom': '20px' }"
								showAt="CONVERSATION"
								@clickAction="toSchedule(teacher.id)"
							/>
						</v-col>
					</v-row>
				</div>
				<div v-else :style="{ 'min-height': '800px' }">
					<v-row no-gutters justify="center"> <p>条件に該当する講師がいません</p></v-row>
				</div>
			</div>
		</v-container>
	</div>
</template>

<script>
import PageTitle from '../Atoms/PageTitle'
import TeacherListCard from '../Organisms/TeacherListCard'
import SortListArea from '../Organisms/SortListArea'
import RecommendTeacherButton from '../Organisms/RecommendTeacherButton'
import VmLoading from '../Atoms/VmLoading'
import { mapGetters, mapActions } from 'vuex'
import { CONVERSATION_LESSON_COURSE_ID } from '../../const'

export default {
	name: 'ConversationTeacherList',
	data: () => ({
		sortConditions: {
			country: '',
			compatibleLevel: '',
			empty: ''
		}
	}),
	components: {
		PageTitle,
		TeacherListCard,
		SortListArea,
		RecommendTeacherButton,
		VmLoading
	},
	created() {
		if (this.conversationTeachers.length === 0) {
			this.fetchTeacherList()
		}
	},
	beforeRouteUpdate(to, from, next) {
		next()

		this.fetchTeacherList()
	},
	computed: {
		...mapGetters({
			userInfo: 'user/getUserInfo',
			conversationTeachers: 'conversationTeachers/conversationTeachers',
			conversationTeachersLoading: 'conversationTeachers/loading'
		}),
		showedTeachers() {
			if (!this.conversationTeachers) return []

			// 管理者とオペレータは除外し、ユーザがテストアカウントでない場合はテストアカウントも除外
			return this.conversationTeachers.filter((teacher) => {
				const isNonAdminOperator = !teacher.isAdmin && !teacher.isOperator // 管理者とオペレータは除外

				const isCurrentUserTestAccount = this.userInfo.isTestAccount
				const isNonTestAccountTeacher = !teacher.isTestAccount
				const isNonTestAccount = isCurrentUserTestAccount || isNonTestAccountTeacher
				return isNonAdminOperator && isNonTestAccount
			})
		},
		attentionFontSize() {
			if (this.isPC) {
				return '0.8em'
			} else {
				return '0.6em'
			}
		},
		computedTeacherInfo: function () {
			return function (val) {
				if (!this.teachers || this.teachers.length === 0) {
					return ''
				} else {
					return this.teachers[val]
				}
			}
		}
	},
	methods: {
		...mapActions({
			fetchConversationTeachers: 'conversationTeachers/fetchConversationTeachers'
		}),
		toSchedule(teacherId) {
			this.$router.push({
				path: 'conversationTeacherSchedule',
				query: { teacherId: teacherId }
			})
		},
		async fetchTeacherList() {
			const params = {
				lessonCourseId: CONVERSATION_LESSON_COURSE_ID,
				country: this.$route.query.country ?? undefined,
				compatibleLevel: this.$route.query.compatibleLevel ?? undefined,
				privLevel: this.$route.query.teachingLevel ?? undefined,
				keyword: this.$route.query.keyword ?? undefined,
				start: this.$route.query.start ?? undefined,
				end: this.$route.query.end ?? undefined,
				empty: this.$route.query.empty ?? undefined
			}
			await this.fetchConversationTeachers(params)
		},
		// ソート条件を変更した後、APIを叩きデータを再取得する
		changeSortCondition(value) {
			const level = value.compatibleLevel === '完全初級' ? 'A0' : value.compatibleLevel

			const query = {}
			if (value.country) query.country = value.country
			if (level) query.compatibleLevel = level
			if (value.teachingLevel) query.teachingLevel = value.teachingLevel
			if (value.empty) query.empty = value.empty
			if (value.keyword) query.keyword = value.keyword
			if (value.dateRange && value.dateRange[0]) query.start = value.dateRange[0]
			if (value.dateRange && value.dateRange[1]) query.end = value.dateRange[1]

			this.$router.push({
				name: 'ConversationTeacherList',
				query: query
			})
		}
	},
	head: {
		title: {
			inner: 'teacher-list'
		}
	}
}
</script>

<style lang="scss" scoped>
p {
	margin: 0;
}
.attention {
	font-size: 0.8em;
	margin: 10px 0 20px 0;
	text-align: center;
	line-height: 2em;
	color: #6d6f6c;
}
</style>
