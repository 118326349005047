var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validationProvider',{attrs:{"rules":{
		required: true,
		regex: /^[a-zA-Z0-9!-/:-@¥[-`{-~]*$/,
		min: 8,
		max: 25
	},"name":"パスワード"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
	var errors = ref.errors;
	var valid = ref.valid;
return [_c('p',[_c('FormTitleText',{attrs:{"titleText":("パスワード" + (_vm.isConfirmed ? '(確認用)' : ''))}}),(_vm.rules && _vm.rules.includes('required'))?_c('RequiredIcon'):_vm._e(),_c('FormAttentionText',{attrs:{"attentionText":_vm.$t('profile.onlyHalfWidthAndNum') + ' ' + _vm.$t('profile.min8max25')}})],1),_c('v-text-field',{attrs:{"label":"password","outlined":"","background-color":"white","success":valid,"hide-details":"","append-icon":_vm.showPass ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.showPass ? 'text' : 'password',"autocomplete":"off"},on:{"click:append":function($event){_vm.showPass = !_vm.showPass}},model:{value:(_vm.inputedPassword),callback:function ($$v) {_vm.inputedPassword=$$v},expression:"inputedPassword"}}),_c('FormErrorText',{attrs:{"errorText":errors[0]}})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }