<template>
	<v-textarea
		outlined
		background-color="white"
		:value="value"
		counter
		@input="$emit('input', $event)"
		class="vm-text-area"
		:placeholder="placeholder"
	></v-textarea>
</template>

<script>
export default {
	name: 'VmTextArea',
	props: {
		value: {
			type: String,
			default: ''
		},
		placeholder: {
			type: String,
			default: ''
		}
	}
}
</script>

<style lang="scss" scoped>
.vm-text-area {
	width: 100%;
	min-height: 120px;
}
</style>
