<template>
	<validationProvider :rules="rules" v-slot="{ errors }" name="会話コースの訂正頻度">
		<p>
			<FormTitleText titleText="会話コースの訂正頻度" />
			<RequiredIcon v-if="rules && rules.includes('required')" />
		</p>
		<v-radio-group v-model="inputedCorrectionFrequency">
			<v-radio
				v-for="correctionFrequencyItem of correctionFrequencyItems"
				:key="correctionFrequencyItem"
				:label="correctionFrequencyItem"
				:value="correctionFrequencyItem"
			></v-radio>
		</v-radio-group>
		<FormErrorText :errorText="errors[0]" />
	</validationProvider>
</template>

<script>
import FormTitleText from '../Atoms/FormTitleText'
import FormErrorText from '../Atoms/FormErrorText'
import RequiredIcon from '../Atoms/RequiredIcon'

export default {
	name: 'CorrectionFrequencyForm',
	data: () => ({
		correctionFrequencyItems: [
			'その場では会話に集中したいので最後にまとめて教えてほしい',
			'各先生のやり方に合わせる',
			'間違いは全てその場で直してほしい',
			'意味が通っているものは直さなくてOK.何よりたくさん会話したい'
		]
	}),
	props: ['value', 'rules'],
	mounted() {},
	computed: {
		inputedCorrectionFrequency: {
			get() {
				switch (this.value) {
					case 'CORRECT_AT_LAST':
						return 'その場では会話に集中したいので最後にまとめて教えてほしい'
					case 'FOLLOW_TEACHER':
						return '各先生のやり方に合わせる'
					case 'CORRECT_FULLY':
						return '間違いは全てその場で直してほしい'
					case 'CORRECT_HARDLY':
						return '意味が通っているものは直さなくてOK.何よりたくさん会話したい'
					default:
						return ''
				}
			},
			set(value) {
				switch (value) {
					case 'その場では会話に集中したいので最後にまとめて教えてほしい':
						this.$emit('input', 'CORRECT_AT_LAST')
						break
					case '各先生のやり方に合わせる':
						this.$emit('input', 'FOLLOW_TEACHER')
						break
					case '間違いは全てその場で直してほしい':
						this.$emit('input', 'CORRECT_FULLY')
						break
					case '意味が通っているものは直さなくてOK.何よりたくさん会話したい':
						this.$emit('input', 'CORRECT_HARDLY')
						break
				}
			}
		}
	},
	methods: {},
	components: {
		FormTitleText,
		FormErrorText,
		RequiredIcon
	}
}
</script>

<style lang="scss" scoped></style>
