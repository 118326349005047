import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)

export default new Vuetify({
	theme: {
		themes: {
			light: {
				primary: '#DCC268',
				secondary: '#6881DC',
				accent: '#6881DC',
				dangerous: '#F00000',
				cancel: '#9D9D9D',
				darkGray: '#6D6F6C',
				lightGray: '#CFD2D1',
				lightGray2: '#ececec', // lightGrayとhighLightGrayの中間色
				highLightGray: '#FAFAFA',
				reservationAvailable: '#DCC268',
				conversationCourse: 'EAA142',
				privateCourse: 'DE755E',
				vacantSchedule: '9D9D9D',
				redColor: '#de755e',
				blackColor: '#272727'
			}
		}
	}
})
