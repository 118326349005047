import axios from 'axios'

export const teacherReview = {
	namespaced: true,
	state: {
		teacherReviews: null,
		loading: false
	},
	getters: {
		loading: (state) => {
			return state.loading
		},
		teacherReviews: (state) => {
			return state.teacherReviews
		}
	},
	mutations: {
		prepareConnect(state) {
			state.loading = true
		},
		stopLoading(state) {
			state.loading = false
		},
		setTeacherReviews(state, payload) {
			state.teacherReviews = payload
			state.loading = false
		}
	},
	actions: {
		async fetchTeacherReviews({ commit }, payload) {
			commit('prepareConnect')
			const header = {
				headers: {
					Authorization: `Bearer ${payload.accessToken}`
				}
			}
			return axios
				.get(`/api/teacherReviews/teacher/${payload.teacherId}`, header)
				.then((res) => {
					commit('setTeacherReviews', res.data.teacherReviews)
				})
				.catch((err) => {
					commit('stopLoading')
					console.log(err)
				})
		}
	}
}
