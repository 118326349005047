<template>
	<div class="footer">
		<v-img :src="require('../../assets/footer.png')" width="100%" contain class="footer-img"></v-img>
		<p class="copy-right">Copyright © Vollmond All Rights Reserved.</p>
	</div>
</template>

<script>
export default {
	name: 'Footer',
	created() {},
	data: () => ({}),
	computed: {},
	methods: {},
	components: {}
}
</script>

<style lang="scss" scoped>
.footer {
	background-color: #dcc268;
	height: 200px;

	position: relative;
}
.footer-img {
	position: absolute;
	bottom: 0;
	width: 100%;
}
.copy-right {
	color: #fff;
	font-weight: bold;
	position: absolute;
	bottom: 20%;
	left: 50%;
	transform: translateX(-50%);
	-webkit-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
}
@media screen and (max-width: 350px) {
	.footer {
		height: 100px;
	}
	.copy-right {
		font-size: 0.4em;
	}
}
@media screen and (min-width: 351px) and (max-width: 768px) {
	.footer {
		height: 100px;
	}
	.copy-right {
		font-size: 0.8em;
	}
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
	.footer {
		height: 120px;
	}
}
@media screen and (min-width: 1024px) {
}
</style>
