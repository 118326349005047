<template>
	<p>「{{ oneWord }}」</p>
</template>

<script>
export default {
	name: 'TeacherCardOneWordLabel',
	data: () => ({}),
	components: {},
	methods: {},
	props: ['oneWord']
}
</script>

<style lang="scss" scoped>
p {
	font-weight: bold;
	margin-bottom: 5px;
}
</style>
