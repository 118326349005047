<!-- 定期レッスン予定の変更 -->
<template>
	<VmDialogCard
		:title="cardTitle"
		:buttonTitle="$t('teacherSchedule.changeRegularLesson')"
		:cancelButtonTitle="$t('teacherSchedule.cancel')"
		@click="edit"
		@close="cancel"
		:disabled="!buttonValidation"
	>
		<template #cardContent>
			<v-container>
				<!-- 現在の定期予約情報 -->
				<v-row>
					<p class="subtitle">
						{{ $t('teacherSchedule.currentRegularScheduleInfo') }}
					</p>
					<TimeZoneIcon />
				</v-row>
				<v-row>
					<v-col>
						<!-- 生徒名: -->
						<p class="regularScheduleInfoItem">
							{{ $t('teacherSchedule.student') }}:
							{{ scheduleInfo.studentName }}
						</p>
						<!-- 曜日: -->
						<p class="regularScheduleInfoItem">
							{{ $t('teacherSchedule.dayOfWeek') }}:
							{{ scheduleInfo.dayOfWeek }}
						</p>
						<!-- 開始時間: -->
						<p class="regularScheduleInfoItem">
							{{ $t('teacherSchedule.startTime') }}:
							{{ scheduleInfo.startTime }}
						</p>
						<!-- 終了時間:  -->
						<p class="regularScheduleInfoItem">{{ $t('teacherSchedule.endTime') }}: {{ scheduleInfo.endTime }}</p>
					</v-col>
				</v-row>
			</v-container>
			<v-container>
				<!-- 曜日 -->
				<v-row>
					<p class="subtitle">{{ $t('teacherSchedule.dayOfWeek') }}</p>
				</v-row>
				<v-row>
					<v-col cols="8">
						<v-select
							:items="dayOfWeekItems"
							v-model="selectedDayOfWeek"
							:label="$t('teacherSchedule.requireSelection')"
							solo
							hide-details
						></v-select>
					</v-col>
				</v-row>
			</v-container>
			<v-container>
				<v-row>
					<!-- 時間 -->
					<p class="subtitle">{{ $t('teacherSchedule.time') }}</p>
					<TimeZoneIcon />
				</v-row>
				<v-row no-gutters class="date-form-area">
					<!-- 開始時間タイムピッカー -->
					<v-col cols="9" xs="9" sm="5" md="5" lg="5" class="picker">
						<v-row no-gutters justify="start">
							<TimePicker v-model="lessonStartTime" :minuteInterval="5" />
						</v-row>
					</v-col>
					<!-- 終了時間タイムピッカー -->
					<v-col cols="1" xs="1" sm="2" md="2" lg="2" class="hyphen-text"> - </v-col>
					<v-col cols="9" xs="9" sm="5" md="5" lg="5" class="picker">
						<v-row no-gutters justify="start">
							<TimePicker v-model="lessonEndTime" :minuteInterval="5" format="kk:mm" />
						</v-row>
					</v-col>
				</v-row>
				<v-row v-if="!lessonTimeValidation">
					<v-col class="validation-text-col">
						<p class="validation-text">
							{{ $t('teacherSchedule.editScheduleAlert') }}
						</p>
					</v-col>
				</v-row>
			</v-container>
		</template>
	</VmDialogCard>
</template>

<script>
import TimeZoneIcon from '../Atoms/TimeZoneIcon'
import TimePicker from '../Atoms/TimePicker'
import VmDialogCard from '../Molecules/VmDialogCard'

export default {
	name: 'EditRegularLessonCard',
	components: {
		TimeZoneIcon,
		TimePicker,
		VmDialogCard
	},
	data: () => ({
		startTimeMenu: false,
		endTimeMenu: false,
		selectedDayOfWeek: '',
		lessonStartTime: '00:00',
		lessonEndTime: '00:00'
	}),
	props: ['courseName', 'cardTitle', 'userInfo', 'scheduleInfo'],
	computed: {
		dayOfWeekItems() {
			const weekDayArrayJa = ['日', '月', '火', '水', '木', '金', '土']
			const weekDayArrayDe = ['So.', 'Mo.', 'Di.', 'Mi.', 'Do.', 'Fr.', 'Sa.']
			switch (this.$i18n.locale) {
				case 'ja':
					return weekDayArrayJa
				case 'de':
					return weekDayArrayDe
				default:
					return weekDayArrayJa
			}
		},
		// 【変更先】曜日を数字で扱う
		computedIntDayOfWeek() {
			const weekDayArrayJa = ['日', '月', '火', '水', '木', '金', '土']
			const weekDayArrayDe = ['So.', 'Mo.', 'Di.', 'Mi.', 'Do.', 'Fr.', 'Sa.']
			switch (this.$i18n.locale) {
				case 'ja':
					return weekDayArrayJa.indexOf(this.selectedDayOfWeek)
				case 'de':
					return weekDayArrayDe.indexOf(this.selectedDayOfWeek)
				default:
					return weekDayArrayJa.indexOf(this.selectedDayOfWeek)
			}
		},
		// 【変更元】曜日を数字で扱う
		computedIntOriginalDayOfWeek() {
			const weekDayArrayJa = ['日', '月', '火', '水', '木', '金', '土']
			const weekDayArrayDe = ['So.', 'Mo.', 'Di.', 'Mi.', 'Do.', 'Fr.', 'Sa.']
			switch (this.$i18n.locale) {
				case 'ja':
					return weekDayArrayJa.indexOf(this.scheduleInfo.dayOfWeek)
				case 'de':
					return weekDayArrayDe.indexOf(this.scheduleInfo.dayOfWeek)
				default:
					return weekDayArrayJa.indexOf(this.scheduleInfo.dayOfWeek)
			}
		},
		buttonValidation() {
			if (!this.lessonTimeValidation) {
				return false
			}
			return true
		},
		// バリデーション - 終了時刻 > 開始時刻になっていればok
		lessonTimeValidation() {
			return this.$validationForRegularLessonTime(this.lessonStartTime, this.lessonEndTime)
		}
	},
	mounted() {
		this.initScheduleInfo()
	},
	methods: {
		initScheduleInfo() {
			this.selectedDayOfWeek = this.scheduleInfo.dayOfWeek
			this.lessonStartTime = this.scheduleInfo.startTime
			this.lessonEndTime = this.scheduleInfo.endTime
		},
		edit() {
			if (
				confirm(
					` 以下のように定期レッスン日時を変更しますか？ \n 曜日: ${this.scheduleInfo.dayOfWeek} → ${this.selectedDayOfWeek} \n レッスン開始時刻： ${this.scheduleInfo.startTime} → ${this.lessonStartTime} \n レッスン終了時刻： ${this.scheduleInfo.endTime} → ${this.lessonEndTime} `
				)
			) {
				// dayOfWeek(2), startTime("00:00")を入れたら
				// startTime 2021-01-03 00:00 を返してくれる関数！
				const startTime = this.$convertToDateFormat(this.computedIntDayOfWeek, this.lessonStartTime)
				const endTime = this.$convertToDateFormat(this.computedIntDayOfWeek, this.lessonEndTime)

				const originalStartTime = this.$convertToDateFormat(
					this.computedIntOriginalDayOfWeek,
					this.scheduleInfo.startTime
				)
				const originalEndTime = this.$convertToDateFormat(this.computedIntOriginalDayOfWeek, this.scheduleInfo.endTime)

				const regularScheduleData = {
					regularScheduleId: this.scheduleInfo.regularScheduleId,
					teacherId: this.userInfo.teacherId,
					studentId: this.scheduleInfo.studentId,
					lessonCourseId: 2, // 今は必ずプライベートコースなので
					startTime: startTime,
					endTime: endTime,
					originalStartTime: originalStartTime,
					originalEndTime: originalEndTime
				}

				this.updateRequest(regularScheduleData)
			}
		},
		updateRequest(regularScheduleData) {
			const header = {
				headers: {
					Authorization: `Bearer ${this.userInfo.accessToken}`
				}
			}

			this.axios
				.put('/api/regularSchedules/update', regularScheduleData, header)
				.then((response) => {
					console.log('response.data', response.data)
					this.cancel()
					this.reload()
				})
				.catch((error) => {
					alert(error.response.data.error.message)
				})
		},
		cancel() {
			this.$emit('cancelAction')
		},
		reload() {
			this.$emit('reload')
		},
		allowedMinutes(m) {
			return m % 30 === 0
		}
	},
	head: {}
}
</script>

<style lang="scss" scoped>
.subtitle {
	font-size: 1.4em;
	font-weight: bold;
	margin: 20px 0 15px 0;
}
.button {
	margin-bottom: 20px;
}
.hyphen-text {
	font-size: 1.2em;
	margin: 20px 0 0 0;
	text-align: center;
}
.regularScheduleInfoItem {
	margin: 2px 0;
}
.validation-text-col {
	padding: 0 0 0 12px;
}
.validation-text {
	color: red;
	margin: 0;
}
</style>
