var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"mx-auto teacher-card",attrs:{"elevation":"2","height":"500px"}},[_c('v-container',[_c('div',{staticClass:"touchable-area",class:{ 'clickable-area': !_vm.disabled },on:{"click":_vm.click}},[_c('v-row',{attrs:{"no-gutters":"","dense":""}},[_c('v-col',{attrs:{"cols":"5"}},[_c('img',{style:({
								'object-fit': 'cover',
								width: '100%',
								'aspect-ratio': '1 / 1',
								'border-radius': '10px',
								'margin-top': '10px'
							}),attrs:{"src":_vm.computedImageUrl,"alt":"teacherImage"}})]),_c('v-col',{style:({
							'padding-left': ' 15px'
						}),attrs:{"cols":"7"}},[_c('v-row',{style:({
								'font-size': '1em',
								'font-weight': 'bold',
								'padding-top': ' 0.5em'
							}),attrs:{"no-gutters":""}},[_c('p',[_vm._v(_vm._s(_vm.teacherInfo.teacherName)+"先生")]),_vm._v(" "+_vm._s(_vm.countryEmoji)+" "),(_vm.teacherInfo.isTestAccount)?_c('p',{staticClass:"testIcon"},[_vm._v("テスト")]):_vm._e()]),_c('v-spacer'),(_vm.showPrivExpertIcon)?_c('v-row',{style:({}),attrs:{"no-gutters":""}},[_c('div',{staticClass:"tag rounded-pill",style:({
									'margin-top': '8px'
								})},[_c('p',[_vm._v("プライベートベテラン")])])]):_vm._e(),(_vm.showConvExpertIcon)?_c('v-row',{style:({}),attrs:{"no-gutters":""}},[_c('div',{staticClass:"tag rounded-pill",style:({
									'margin-top': '8px'
								})},[_c('p',[_vm._v("会話ベテラン")])])]):_vm._e(),(_vm.teacherInfo.category)?_c('v-row',{style:({}),attrs:{"no-gutters":""}},[_c('div',{staticClass:"tag rounded-pill",style:({
									'margin-top': '8px'
								})},[_c('p',[_vm._v(_vm._s(_vm.categoryText))])])]):_vm._e(),(_vm.showEmpty)?_c('v-row',{staticClass:"empty-plan",style:({
								'font-weight': 'bold',
								'padding-top': ' 0.5em'
							}),attrs:{"no-gutters":""}},[_c('p',[_vm._v("空き: "+_vm._s(_vm.emptyText))]),_c('div',{style:({
									'max-width': '16px',
									margin: '3px 0 0 5px'
								})},[_c('v-img',{style:({
										'object-fit': 'contain'
									}),attrs:{"src":_vm.emptyIcon}})],1)]):_vm._e(),_c('div',{staticClass:"compatible-level"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('p',{staticClass:"content-text"},[_vm._v("対応レベル: "+_vm._s(_vm.compatibleLevelText))])])],1)],1)],1),(!_vm.disabled)?_c('v-row',{style:({
						'margin-top': '10px'
					}),attrs:{"no-gutters":""}},[_c('VmButton',{style:({}),attrs:{"type":"primary","text":"予約する"},on:{"click":_vm.click}})],1):_vm._e(),_c('v-row',{style:({
						'font-size': '1em',
						'font-weight': 'bold',
						'margin-top': '20px',
						height: '20px'
					}),attrs:{"no-gutters":""}},[_c('p',[_vm._v(_vm._s(_vm.teacherInfo.catchPhrase))])]),_c('v-row',{staticClass:"card-subtitle",style:({ 'margin-top': '30px' }),attrs:{"no-gutters":""}},[_c('p',[_vm._v("得意分野")])]),_c('v-row',{staticClass:"content-text",attrs:{"no-gutters":""}},[_c('p',{staticClass:"specialities"},[_vm._v(" "+_vm._s(_vm.teacherInfo.specialities)+" ")])])],1),_c('v-row',{attrs:{"no-gutters":"","dense":"","justify":"center"}},[_c('v-btn',{staticClass:"more-button",attrs:{"text":""},on:{"click":_vm.openDetail}},[_vm._v(" 詳細を見る ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }