import axios from 'axios'

export const namespaced = true

export const state = {
	loading: false
}

export const getters = {
	loading: (state) => state.loading
}

export const mutations = {
	prepareConnect(state) {
		state.loading = true
	},
	stopLoading(state) {
		state.loading = false
	}
}

export const actions = {
	async sendMessage({ commit }, payload) {
		commit('prepareConnect')

		const { requestBody, accessToken } = payload
		const header = {
			headers: {
				Authorization: `Bearer ${accessToken}`
			}
		}

		try {
			await axios.post('/api/messages/toStudent', requestBody, header)

			commit('stopLoading')
			return true
		} catch (error) {
			commit('stopLoading')
			throw error
		}
	}
}
