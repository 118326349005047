<template>
	<v-container>
		<v-row v-if="isPC">
			<v-col sm="2" md="2" lg="2" xl="1">
				<v-img :src="require('../../assets/Vollmond_logo.png')" class="my-3" contain @click="toLogin"></v-img>
			</v-col>
		</v-row>
		<v-col cols="12" class="forget-area">
			<v-container>
				<v-row justify="center" v-if="!isPC">
					<v-col cols="6" xs="6">
						<v-img :src="require('../../assets/Vollmond_logo.png')" class="my-3" contain @click="toLogin"></v-img>
					</v-col>
				</v-row>
				<PageTitle title-name="パスワード再発行" class="forget-title" />
				<v-row justify="center">
					<p class="forget-explanation">
						以下フォームに、登録メールアドレスを入力してパスワード再発行メールを送信してください。
					</p>
				</v-row>
				<v-row justify="center">
					<v-col cols="12" xs="12" sm="6" md="5" lg="5" xl="5" class="text-field-area">
						<v-text-field
							label="E-Mail"
							color="#F0AF67"
							v-model="email"
							type="email"
							outlined
							background-color="white"
							dense
							class="login-text-field"
							hide-details
						></v-text-field>
					</v-col>
				</v-row>
				<v-row justify="center" class="forget-button-area">
					<v-col cols="12" xs="12" sm="6" md="5" lg="5" xl="5">
						<ActiveButton
							button-title="パスワード再発行リンクを送信する"
							@clickAction="sendResetEmail"
							class="forget-button"
							:isDisabled="disableButton"
						/>
					</v-col>
				</v-row>
				<v-row>
					<v-col cols="12">
						<v-row justify="center">
							<router-link to="/" class="to-login-link">ログイン画面へ戻る</router-link>
						</v-row>
					</v-col>
				</v-row>
			</v-container>
		</v-col>
	</v-container>
</template>

<script>
import ActiveButton from '../Atoms/ActiveButton'
import PageTitle from '../Atoms/PageTitle'

export default {
	name: 'PasswordForget',
	data: () => ({
		email: '',
		disableButton: false
	}),
	components: {
		ActiveButton,
		PageTitle
	},
	computed: {},
	methods: {
		toLogin() {
			this.$router.push('/')
		},
		sendResetEmail() {
			this.axios
				.post('/api/passward/email', { email: this.email })
				.then((response) => {
					if (response.data.result) {
						alert('入力されたメールアドレスにメッセージ送信しました。')
						this.disableButton = true
					}
				})
				.catch((error) => {
					alert(error.response.data.error.message)
				})
		}
	}
}
</script>

<style lang="scss" scoped>
.forget-area {
	position: absolute;
	top: 45%;
	left: 50%;
	transform: translate(-50%, -50%);
}
.forget-title {
	margin-top: 30px;
	margin-bottom: 30px;
}
.forget-explanation {
	font-size: 0.8em;
	color: #6e6b6b;
	margin-bottom: 90px;
}
.forget-button-area {
	margin-top: 40px;
	margin-bottom: 20px;
}
.forget-button {
	border-radius: 8px;
}
.to-login-link {
	margin: 10px 0 0 0;
	font-size: 0.8em;
}
</style>
