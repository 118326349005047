<template>
	<v-btn class="arrowButton" color="secondary" @click="clickAction">
		<v-icon left v-if="leftIcon">{{ leftIcon }}</v-icon>
		<p>{{ buttonTitle }}</p>
		<v-icon right>mdi-chevron-right</v-icon>
	</v-btn>
</template>

<script>
export default {
	name: 'ActiveButton',
	data: () => ({}),
	props: ['buttonTitle', 'leftIcon'],
	methods: {
		clickAction() {
			this.$emit('clickAction')
		}
	}
}
</script>

<style lang="scss" scoped>
p {
	font-weight: bold;
	margin: 0;
}
.arrowButton {
	width: 100%;
	@media screen and (min-width: 600px) {
		width: 50%;
	}
}
</style>
