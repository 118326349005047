<template>
	<div>
		<div class="fullview">
			<div class="loading-spacer"></div>
			<vue-loading type="spin" color="#DCC268" :size="{ width: '50px', height: '50px' }"> </vue-loading>
		</div>
	</div>
</template>

<script>
import { VueLoading } from 'vue-loading-template'

export default {
	name: 'loading',
	components: {
		VueLoading
	}
}
</script>

<style>
.fullview {
	width: 100%;
	height: 100%;
	background: #272727;
	opacity: 0.4;
	position: fixed;
	top: 0;
	left: 0;
}
.loading-spacer {
	height: 30%;
}
</style>
