<template>
	<div>
		<VmButton
			type="primary"
			@click="openReportMonthlyCompleteDialog"
			:text="$t('top.reportMonthlyComplete.title')"
			class="lessonTable__title__reportMonthlyCompleteButton"
			:disabled="!buttonAvailable"
		>
			<template #prefix>
				<v-icon color="white">mdi-file-check</v-icon>
			</template>
		</VmButton>
		<v-dialog
			v-model="showReportMonthlyCompleteDialog"
			v-if="showReportMonthlyCompleteDialog"
			offset-x
			max-width="500px"
		>
			<ReportMonthlyCompleteDialog @click="reportMonthlyComplete()" @close="closeReportCompleteDialog()" />
		</v-dialog>
	</div>
</template>

<script>
import VmButton from '../../components/Atoms/VmButton'
import ReportMonthlyCompleteDialog from '../../components/Molecules/ReportMonthlyCompleteDialog'
import { mapGetters, mapActions } from 'vuex'
import { fetchReportTargetYearMonth, fetchReportTargetMonth } from '../../util/fetchReportTarget'
import { isWithinReportingPeriod } from '../../util/isWithinReportingPeriod'

export default {
	name: 'ReportMonthlyCompleteButton',
	props: {},
	components: {
		VmButton,
		ReportMonthlyCompleteDialog
	},
	data: () => ({
		showReportMonthlyCompleteDialog: false
	}),
	created() {
		const payload = {
			requestData: {
				yearMonth: this.targetYearMonth, // '202310'の形式
				teacherId: this.userInfo.teacherId
			},
			accessToken: this.userInfo.accessToken
		}
		this.checkCompletion(payload)
	},
	mounted() {},
	computed: {
		...mapGetters({
			userInfo: 'user/getUserInfo',
			isCompletedReportMonthly: 'reportMonthlyComplete/isCompleted',
			reportMonthlyLoading: 'reportMonthlyComplete/loading'
		}),
		// 精算月
		targetMonth() {
			return fetchReportTargetMonth()
		},
		targetYearMonth() {
			return fetchReportTargetYearMonth()
		},
		buttonAvailable() {
			if (this.reportMonthlyLoading) return false
			const today = new Date()

			// 現在が20日 ~ 翌月5日かの報告期間かどうかチェック
			const isWithinInterval = isWithinReportingPeriod(today)

			// 報告期間なのに報告が終わっていない人だけボタンを有効にする
			return isWithinInterval && !this.isCompletedReportMonthly
		}
	},
	methods: {
		...mapActions({
			checkCompletion: 'reportMonthlyComplete/checkCompletion',
			report: 'reportMonthlyComplete/report'
		}),
		openReportMonthlyCompleteDialog() {
			this.showReportMonthlyCompleteDialog = true
		},
		closeReportCompleteDialog() {
			this.showReportMonthlyCompleteDialog = false
		},
		reportMonthlyComplete() {
			if (confirm('月末業務完了報告を行いますか？')) {
				const payload = {
					requestData: {
						yearMonth: this.targetYearMonth, // '202310'の形式
						teacherId: this.userInfo.teacherId
					},
					accessToken: this.userInfo.accessToken
				}
				const isSuccess = this.report(payload)

				if (isSuccess) {
					this.closeReportCompleteDialog()
				}
			}
		}
	}
}
</script>

<style lang="scss" scoped></style>
