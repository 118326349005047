<template>
	<span class="title-text">{{ titleText }}</span>
</template>

<script>
export default {
	name: 'FormTitleText',
	data: () => ({}),
	props: ['titleText'],
	methods: {}
}
</script>

<style lang="scss" scoped>
.title-text {
	font-weight: bold;
	font-size: 1em;
}
</style>
