<template>
	<validationProvider :rules="rules" v-slot="{ errors, valid }" name="現在のドイツ語レベル">
		<p>
			<FormTitleText titleText="現在のドイツ語レベル" />
			<RequiredIcon v-if="rules && rules.includes('required')" />
		</p>
		<v-select
			:items="levelItems"
			v-model="inputedLevel"
			:label="$t('profile.requireSelection')"
			outlined
			background-color="white"
			hide-details
			:success="valid"
		></v-select>
		<FormErrorText :errorText="errors[0]" />
	</validationProvider>
</template>

<script>
import FormTitleText from '../Atoms/FormTitleText'
import FormErrorText from '../Atoms/FormErrorText'
import RequiredIcon from '../Atoms/RequiredIcon'

export default {
	name: 'LevelForm',
	data: () => ({
		levelItems: ['完全初級', 'A1', 'A2', 'B1', 'B2', 'C1', 'C2']
	}),
	props: ['value', 'rules'],
	mounted() {},
	computed: {
		inputedLevel: {
			get() {
				if (this.value === 'A0') {
					return '完全初級'
				} else {
					return this.value
				}
			},
			set(value) {
				if (value === '完全初級') {
					this.$emit('input', 'A0')
				} else {
					this.$emit('input', value)
				}
			}
		}
	},
	methods: {},
	components: {
		FormTitleText,
		FormErrorText,
		RequiredIcon
	}
}
</script>

<style lang="scss" scoped></style>
